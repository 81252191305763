var I18nJS = require('i18n-js');

var createReactElements = require('./react');

var createRollbarReporter = require('./rollbar');

I18nJS.reset();
var options = {
  "locale": "html.lang",
  "defaultLocale": "en",
  "rollbarReports": "false"
};
{}
var currentLocale = options.locale;

if (currentLocale == 'html.lang') {
  if (typeof document !== 'undefined') {
    currentLocale = document.querySelector('html').lang;

    if (!currentLocale) {
      currentLocale = options.defaultLocale;
    }
  } else {
    currentLocale = options.defaultLocale;
  }
}

for (key in options) {
  I18nJS[key] = options[key];
}

I18nJS.locale = currentLocale;
I18nJS.translations = {
  "en": {
    "activities": {
      "kind": {
        "generic": "Generic",
        "medical": "Appointment",
        "prescription": "Prescription",
        "generic_health": "Task",
        "referral": "Referral"
      },
      "assignments": {
        "assignment": {
          "accept": "Accept",
          "decline": "Decline",
          "assign_someone_else": "Assign someone else",
          "what_would_you_do": "What would you like to do?",
          "let_anyone_claim": "Let anyone claim",
          "claim": "I'll do it!",
          "message": "Send message",
          "help_requested": "A volunteer has been requested to help with this task"
        },
        "claim_task": {
          "claim": "I'll do it!",
          "claim_task": "By claiming this task, you agree to take on the responsibility of making sure this task gets done."
        },
        "index": {
          "no_one_assigned": "This task has not been assigned yet."
        },
        "my_assignment": {
          "me": "Me",
          "will_you_accept": "Will you accept this task?",
          "accept": "Yes, I will!",
          "decline": "Sorry, I can't",
          "something_changed": "Something changed?",
          "claim": "I'll do it!"
        }
      },
      "delete": "Delete",
      "edit_modal": {
        "edit": "Edit"
      }
    },
    "activity_feed": {
      "comments": {
        "comment_header": {
          "comment": {
            "one": "1 Comment",
            "other": "%{count} Comments"
          },
          "hide": "Hide"
        },
        "form": {
          "text": "Share a comment with your family and friends",
          "note": "Note: comments will not be seen by your healthcare provider",
          "submit": "Post"
        }
      },
      "activity_header": {
        "appointment": "Created an appointment",
        "medicaldocument": "Uploaded a document",
        "prescription": "Added a prescription",
        "task": "Added a task",
        "status": "Posted an update"
      },
      "appointment": {
        "when": "When: ",
        "with": "With: ",
        "where": "Where: "
      },
      "assignments": {
        "assigned_to": "Assigned to: ",
        "pending": "Pending",
        "accepted": "Accepted",
        "declined": "Declined",
        "open": "Open"
      },
      "index": {
        "welcome": "Post a quick message to let everyone know you've joined!",
        "empty": "No results"
      },
      "prescription": {
        "status": "Status: ",
        "filled": "Filled",
        "to_be_filled": "Not filled"
      }
    },
    "activity_modal": {
      "add_appointment_title": "Add Appointment",
      "add_check_in_title": "Add Check-in",
      "add_task_title": "Add Task",
      "add_form_title": "Add Form",
      "edit_appointment_title": "Edit Appointment",
      "edit_task_title": "Edit Task",
      "description_field_title": "Description",
      "error_empty": "Required",
      "errors": {
        "api_error": "Error: Something went wrong",
        "form_api_error": "Something went wrong and we can't add this form. If the problem continues, please email support@getcareteam.com."
      },
      "footer": {
        "delete_button": {
          "delete": "Delete",
          "confirm": "Confirm?"
        },
        "cancel": "Cancel",
        "save": "Save"
      },
      "form_fields": {
        "resources_list": {
          "unnamed_resource": "Unnamed resource"
        },
        "validators": {
          "error_empty": "Required",
          "invalid_link": "Invalid link",
          "missing_http": "Please add http:// or https:// at the start of your link",
          "missing_check_in_template": "Please select Check-in type from the library",
          "missing_form_template": "Please select Form from the library"
        },
        "what": {
          "error_empty": "Required",
          "description": "What:",
          "appointment_description": "Appointment description",
          "task_description": "Task description",
          "form_description": "Form name"
        },
        "who": {
          "title": "Assign",
          "description": "Who:",
          "error_contacts_load": "Error: Could not load contacts.",
          "open_button": "Assign",
          "no_team_members": "No team members to display.",
          "notification_header": "%{patientFirstName} and their Primary Supporters will be notified when any new Tasks are added, even if it's unassigned or assigned to someone else."
        },
        "where": {
          "description": "Where:",
          "virtual_visit_link_description": "Virtual visit link (Optional)",
          "invalid_link": "Invalid link",
          "missing_http": "Please add http:// or https:// at the start of your link",
          "address": {
            "description": "Address",
            "add_address": "Add address",
            "remove_address": "Remove address",
            "street_address": "Street address",
            "extended_address": "Apartment, suite, etc. (optional)",
            "city": "City",
            "province": "Province",
            "state": "State",
            "postal_code": "Postal code",
            "zip_code": "ZIP code",
            "postcode": "Postcode",
            "country": "Country"
          }
        },
        "when": {
          "description": "When",
          "scheduled": "Scheduled",
          "not_scheduled": "Not Yet Scheduled",
          "days": "Day(s)",
          "weeks": "Week(s)",
          "months": "Month(s)",
          "repeats_every": "Repeats every",
          "ends": "Ends:",
          "never": "Never",
          "on": "On",
          "after": "After",
          "times": "times",
          "date_and_time": "Date & time",
          "repeats": "Repeats"
        },
        "visibility": {
          "hcp_only": "Health Team only",
          "default": "Default",
          "everyone": "Everyone",
          "who_should_see": "Who can see this Check-in?"
        },
        "when_should_be_completed": {
          "check_in_label": "When should this Check-in be completed?",
          "form_label": "When should this Form be completed?",
          "task_label": "When should this Task be completed?",
          "appointment_label": "When should this Appointment be completed?",
          "scheduled": "Scheduled - once or repeating",
          "any_time": "Any time",
          "to_be_booked": "Not booked"
        },
        "templates": {
          "unnamed_appointment": "Unnamed appointment",
          "unnamed_task": "Unnamed task",
          "unnamed_form": "Unnamed form",
          "api_error": "Error: Could not load templates.",
          "no_templates": "No templates to display.",
          "fill_using_a_template": "Fill using a template"
        },
        "select_from_library": {
          "unnamed_check_in": "Unnamed check-in",
          "unnamed_form": "Unnamed form",
          "api_error": "Error: Could not load library.",
          "no_templates": "Nothing to display.",
          "select_check_in": "Select Check-in from library",
          "select_form": "Select Form from library"
        },
        "resources": {
          "title": "Resources",
          "api_error": "Error: Could not load resources.",
          "links": "Links:",
          "no_resources": "No resources to display.",
          "add_resources": "Add resources"
        }
      }
    },
    "appointments": {
      "fields": {
        "description": "Description",
        "practitioner": "With",
        "virtual_visit": "Virtual visit url",
        "time": "Time",
        "assignments": "Assign",
        "cancel": "Cancel",
        "optional": "(Optional)",
        "completed_at": "Appointment needs to be booked",
        "save": "Save",
        "address": {
          "street_address": "Street address",
          "extended_address": "Apartment, suite, etc (optional)",
          "city": "City",
          "province": "Province",
          "state": "State",
          "postal_code": "Postal code",
          "zip_code": "ZIP code",
          "postcode": "Postcode",
          "country": "Country"
        },
        "validators": {
          "error_empty": "Required",
          "invalid_link": "Invalid link",
          "missing_http": "Please add http:// or https:// at the start of your link"
        }
      },
      "edit_form": {
        "request_volunteer": "Request volunteer",
        "link_resources": "Link Resources",
        "resources": "Resources",
        "no_resources_present": "No Resources are available to add to this Appointment",
        "cancel": "Cancel",
        "save": "Save"
      },
      "actions_container": {
        "edit": "Edit"
      }
    },
    "aside": {
      "tooltips": {
        "close": "Close"
      }
    },
    "base": {
      "action_headers": {
        "add": "Add",
        "cancel": "Cancel",
        "edit": "Edit",
        "carehub_sync": "Fields that can sync to Care Hub"
      },
      "print_button": {
        "print": "Print"
      },
      "error_occurred": "An error occurred.",
      "invite_code": {
        "link": "Link"
      },
      "invite_qr_code": {
        "download": "Download",
        "copy": "Copy",
        "copied": "Copied!"
      },
      "radio_button_list": {
        "search": "Search...",
        "search_aria": "Search",
        "required": "Required"
      }
    },
    "blocks": {
      "no_data": "No data",
      "days": {
        "Sun": "Sun",
        "Mon": "Mon",
        "Tue": "Tue",
        "Wed": "Wed",
        "Thu": "Thu",
        "Fri": "Fri",
        "Sat": "Sat"
      },
      "index": {
        "fetch_error": "An error occurred while the data was being retrieved."
      },
      "recommendations": {
        "title": "Recommendations",
        "fetch_error": "An error occurred while the data was being retrieved.",
        "save_error": "An error occurred while the data was being saved.",
        "show_older": "Show older recommendations",
        "hide_older": "Hide older recommendations",
        "empty": "None for this Action Plan.",
        "created_by_with_date": "Created by %{name} on %{created_at}",
        "updated_by_with_date": "Updated by %{name} on %{updated_at}",
        "edit": "Edit",
        "remove": "Remove",
        "save": "Save",
        "cancel": "Cancel",
        "discard_changes": "Discard changes",
        "required": "Required",
        "confirm_remove_prompt": "Are you sure you want to remove this recommendation?",
        "confirm_remove": "Yes, remove",
        "cancel_remove": "No, keep",
        "placeholder": "Enter your recommendation"
      },
      "symptom_intensity": {
        "title": "Intensity of symptoms",
        "fetch_error": "An error occurred while the data was being retrieved.",
        "categories": ["Very low", "Low", "Medium", "High", "Very high", "Did not occur", "No data"]
      },
      "symptom_occurrence": {
        "title": "When symptoms occurred",
        "fetch_error": "An error occurred while the data was being retrieved.",
        "categories": ["Action taken", "Stomach ache", "Headache", "Back pain", "Dizziness", "Limb pain", "Fast heartbeat", "Nausea", "Body weakness"],
        "legend": ["Occurred", "Did not occur", "No data"]
      },
      "worry_intensity": {
        "title": "How worried I was",
        "fetch_error": "An error occurred while the data was being retrieved.",
        "categories": ["Avoidance", "Very low", "Low", "Medium", "High", "Very high"]
      },
      "worried_about": {
        "title": "What I was worried about",
        "fetch_error": "An error occurred while the data was being retrieved.",
        "categories": ["Family", "Friends", "Strangers", "School", "Sports", "Health"],
        "legend": ["Occurred", "Did not occur", "No data"]
      },
      "certainty_expectation": {
        "title": "How certain I was",
        "fetch_error": "An error occurred while the data was being retrieved.",
        "extremely": "Extremely",
        "not_at_all": "Not at all",
        "event_happened": "Event happened",
        "legend": ["How worried", "How certain", "No data"]
      },
      "expected_vs_reality": {
        "title": "Problems: expected vs reality",
        "extremely": "Extremely",
        "not_at_all": "Not at all",
        "fetch_error": "An error occurred while the data was being retrieved.",
        "legend": ["Morning (expected)", "Afternoon (real)", "Evening (real)", "No data"]
      },
      "going_to_school": {
        "title": "Going to school",
        "fetch_error": "An error occurred while the data was being retrieved.",
        "doctor": "Doctor",
        "home_school": "Home school",
        "sick": "Sick",
        "online": "Online",
        "holiday": "Holiday",
        "vacation": "Vacation",
        "weekend": "Weekend",
        "attended": "Attended",
        "chronic_pain": "Chronic pain",
        "no_data": "No data"
      },
      "friend_interaction": {
        "title": "My worry about interacting with friends",
        "fetch_error": "An error occurred while the data was being retrieved.",
        "extremely": "Extremely",
        "not_at_all": "Not at all",
        "legend": ["Morning (expected)", "Afternoon (real)", "Evening (real)", "No data"]
      },
      "friend_reaction": {
        "title": "Getting along with my friends",
        "fetch_error": "An error occurred while the data was being retrieved.",
        "legend": ["Morning (expected)", "Afternoon (real)", "Evening (real)", "No data"],
        "categories": ["Very negative", "Negative", "Neutral", "Positive", "Very positive", "No interactions"]
      },
      "my_emotions": {
        "title": "My emotions",
        "subtitle": "Lines represent emotion levels over time, while the bubbles represent the overall summary",
        "fetch_error": "An error occurred while the data was being retrieved.",
        "extremely": "Extremely",
        "not_at_all": "Not at all",
        "worried_scared": "Worried/scared",
        "happy": "Happy",
        "miserable": "Miserable",
        "angry": "Angry",
        "frustrated": "Frustrated"
      },
      "my_sleep": {
        "title": "My sleep",
        "fetch_error": "An error occurred while the data was being retrieved.",
        "hours": "Hours",
        "noon": "Noon",
        "midnight": "Midnight",
        "am": "am",
        "pm": "pm",
        "legend": ["Poor", "Average", "Good", "Great", "No data"]
      }
    },
    "care_plan_action_plan_page": {
      "activities_container": {
        "api_error": "Error: Could not load user information.",
        "unexpected_error": "Error: An unexpected error occurred.",
        "appointments_card": {
          "api_error": "Error: Could not load appointments.",
          "unexpected_error": "Error: An unexpected error occurred.",
          "empty": "None for this Action Plan.",
          "title": "Appointments",
          "appointment": {
            "unnamed_appointment": "Unnamed appointment",
            "booked": "Booked",
            "not_booked": "Not booked",
            "edit": "Edit",
            "help_requested": "A volunteer has been requested to help with this task."
          },
          "repeated_appointment": {
            "unnamed_appointment": "Unnamed appointment",
            "repeat_statement": "Repeats %{localizedFrequency} at %{formattedTime} until %{until}",
            "repeat_statement_infinity": "Repeats %{localizedFrequency} at %{formattedTime}"
          }
        },
        "check_ins_card": {
          "api_error": "Error: Could not load check-ins.",
          "unexpected_error": "Error: An unexpected error occurred.",
          "empty": "None for this Action Plan.",
          "title": "Check-ins",
          "check_in": {
            "unnamed_check_in": "Unnamed check-in",
            "completed": "Complete",
            "not_completed": "Incomplete",
            "edit": "Edit",
            "due_at_statement": "At %{dueDate}"
          },
          "repeated_check_in": {
            "unnamed_check_in": "Unnamed check-in",
            "repeat_statement": "Repeats %{localizedFrequency} until %{until}",
            "repeat_statement_infinity": "Repeats %{localizedFrequency}"
          }
        },
        "tasks_card": {
          "api_error": "Error: Could not load tasks.",
          "unexpected_error": "Error: An unexpected error occurred.",
          "empty": "None for this Action Plan.",
          "title": "Tasks",
          "task": {
            "unnamed_task": "Unnamed task",
            "completed": "Complete",
            "not_completed": "Incomplete",
            "edit": "Edit",
            "due_at_statement": "At %{dueDate}"
          },
          "repeated_task": {
            "unnamed_task": "Unnamed task",
            "repeat_statement": "Repeats %{localizedFrequency} at %{formattedTime} until %{until}",
            "repeat_statement_infinity": "Repeats %{localizedFrequency} at %{formattedTime}"
          }
        },
        "forms_card": {
          "api_error": "Error: Could not load forms.",
          "unexpected_error": "Error: An unexpected error occurred.",
          "empty": "None for this Action Plan.",
          "title": "Forms",
          "form": {
            "unnamed_form": "Unnamed form",
            "completed": "Complete",
            "not_completed": "Incomplete",
            "edit": "Edit",
            "due_at_statement": "At %{dueDate}"
          },
          "repeated_form": {
            "unnamed_form": "Unnamed form",
            "repeat_statement": "Repeats %{localizedFrequency} at %{formattedTime} until %{until}",
            "repeat_statement_infinity": "Repeats %{localizedFrequency} at %{formattedTime}"
          }
        }
      }
    },
    "care_plan_creators": {
      "name": {
        "title_label": "Action Plan template name",
        "title_sublabel": "This name will be used internally to organize the various templates available.",
        "title_placeholder": "Examples: \"Mild dementia - Ottawa area - English\"",
        "next": "Next"
      },
      "library_task_templates": {
        "form": {
          "title_label": "* Task template name",
          "title_sublabel": "This name will be used internally to organize the various templates available.",
          "title_placeholder": "Example: \"Pre-Appointment Task Template\"",
          "description_label": "* Task default description",
          "description_sublabel": "This is the description the Task will have if not edited by the practitioner.",
          "recurrence_label": "Schedule task",
          "recurrence_sublabel": "This is optional, you can schedule the task now or have the practitioner do it when the Action Plan is created.",
          "next": "Next",
          "link_resources": "Link Resources",
          "resources": "Resources"
        }
      },
      "library_appointment_templates": {
        "form": {
          "street_address": "Street address",
          "extended_address": "Apartment, suite, etc. (optional)",
          "city": "City",
          "province": "Province",
          "state": "State",
          "postal_code": "Postal code",
          "zip_code": "ZIP code",
          "postcode": "Postcode",
          "country": "Country",
          "practitioner": "With",
          "virtual_visit": "Virtual visit url",
          "title_label": "* Appointment template name",
          "title_sublabel": "This name will be used internally to organize the various templates available.",
          "title_placeholder": "Example: \"6 Month Oncology Follow-Up\"",
          "description_label": "* Appointment default description",
          "description_sublabel": "This is the description the Appointment will have if not edited by the practitioner.",
          "recurrence_label": "Schedule appointment",
          "recurrence_sublabel": "This is optional, you can schedule the appointment now or have the practitioner do it when the Action Plan is created.",
          "next": "Next",
          "link_resources": "Link Resources",
          "resources": "Resources"
        }
      },
      "check_in_templates": {
        "name": {
          "title_label": "Check-in Template name",
          "title_sublabel": "This name will be used internally to organize the various templates available.",
          "title_placeholder": "Example: \"Ottawa valley Pain Scale Check-in\"",
          "next": "Next"
        },
        "questions": {
          "question_type": "Question Type",
          "blank": "--Select--",
          "pain_scale_5": "Pain scale 5",
          "generic_scale_5": "Generic scale 1-5",
          "generic_scale_10": "Generic scale 1-10",
          "generic_scale_11": "Generic scale 0-10",
          "generic_scale_0_100": "Generic scale 0-100",
          "numeric_input": "Numeric Input",
          "yes_no": "Yes No",
          "mood_scale_10": "Mood scale 10",
          "multiple_choice": "Multiple Choice",
          "free_text": "Free Text",
          "title_label": "Question Title",
          "response_label": "Question response",
          "conditional_response": "Add conditional response confirmation",
          "default_response": "Thank you %name. Your check-in has been recorded.",
          "units": "Units",
          "upper_limit": "Upper Limit",
          "lower_limit": "Lower Limit",
          "add_response": "Add another response option",
          "confirmation": "Are you sure you want to delete this response?",
          "delete_response": "Delete response",
          "add_question": "Add another question",
          "save_question": "Save question",
          "delete": "Delete",
          "cancel": "Cancel",
          "next": "Next",
          "edit": "Edit",
          "placeholders": {
            "generic_scale_description": "Description",
            "generic_scale_text": "Text",
            "pain_scale_description": "Description",
            "custom_response": "Response text",
            "pain_scale_text": "Text",
            "numeric_input_units": "e.g. \"Lbs\" or \"Kgs\"",
            "numeric_input_upper_title": "Limits are optional, leave blank if no limits are needed",
            "numeric_input_upper_limit": "e.g. \"400\"",
            "numeric_input_lower_limit": "e.g. \"0\"",
            "mood_scale_description": "Description",
            "mood_scale_text": "Text",
            "free_text_description": "Description",
            "free_text_text": "Text",
            "multiple_choice_response": "Response text",
            "multiple_choice_description": "Long description (optional)",
            "multiple_choice_text": "Short description"
          }
        },
        "visibility": {
          "who_can_see": "Who can see this Check-in?",
          "everyone": "Everyone",
          "hcp_only": "Health Team only",
          "will_not_affect": "Setting this visibility will not affect check-ins already added to individual Action Plans.",
          "next": "Next",
          "api_error": "Something went wrong. Please try again."
        }
      },
      "details": {
        "next": "Next",
        "skip": "Skip",
        "details_form": {
          "title_label": "Name the Action Plan",
          "title_sublabel": "Give the Action Plan a name that everyone on the team will recognize. Try to use words that will be familiar to the person and their Support team.",
          "list_label": "Concerns for this Action Plan:",
          "empty_list_none_added": "No concerns have been added for this Action Plan.<br/> Click 'Add concern' to start.",
          "diagnosis_placeholder": "Enter concern description...",
          "title_placeholder": "Examples: \"Memory\" or \"Wellness\" or \"Beat cancer\"",
          "add_diagnosis": "Add concern",
          "edit": "Edit",
          "date": "Action Plan start date: %{startAt}",
          "date_modal_header": "Change Action Plan start date",
          "date_update_label": "Changing the start date will automatically apply the same change to all scheduled dates in the plan.",
          "revert_to_default_label": "Revert to original date: %{date}",
          "done": "Done",
          "apply": "Apply Change"
        }
      },
      "careteam": {
        "index": {
          "subtitle": "Add %{name}, a Primary Supporter, or both to this Action Plan"
        },
        "patient_form": {
          "header": "Invite %{name}",
          "invite": "Send invitation",
          "sublabel": "Enter an email address to invite them to the Action Plan (if applicable)",
          "placeholder": "Enter email address...",
          "email": "E-mail",
          "skip": "Skip",
          "save": "Save",
          "edit": "Edit",
          "add": "+ Add",
          "hint": "If the person for whom the Action Plan was created will not be accessing Careteam themselves, leave this field blank and invite caregivers below instead.",
          "submit_error": "Sorry, we couldn't send the email. Please try again or contact us."
        },
        "primary_caregiver_form": {
          "sublabel": "Inviting a Primary Supporter ensures %{firstName} has better support. This is usually their primary caregiver or someone able to co-pilot their care. They will have full access to this Action Plan on par with what %{firstName} can do. Primary Supporters can always be added later.",
          "header": "Invite a Primary Supporter",
          "email": "E-mail",
          "first_name": "First name",
          "last_name": "Last name",
          "phone": "Phone",
          "empty_list": "Primary Supporters have not been added yet. <br/>Click 'Add Primary Supporter' to start.",
          "relationship": "Relationship",
          "skip": "Skip",
          "save": "Save",
          "next": "Next",
          "edit": "Edit",
          "add": "Invite a Primary Supporter",
          "permission": "I have permission to allow this person access to %{firstName}'s information and to act on their behalf through Careteam",
          "remove": "Delete",
          "sdm_type": {
            "assign_button": "Assign this person as %{patient_first_name}'s Substitute Decision Maker",
            "label": "Substitute Decision Maker",
            "placeholder": "Select a type ...",
            "substitute_decision_maker": "Substitute Decision Maker",
            "poa_personal_care": "Power of Attorney for Personal Care",
            "guardian_trustee": "Guardian/Trustee"
          },
          "sdm_confirmation": {
            "substitute_decision_maker": "I have confirmed their Substitute Decision Maker status",
            "poa_personal_care": "I have confirmed their Power of Attorney status, including necessary paperwork",
            "guardian_trustee": "I have confirmed their guardian/trustee status"
          }
        },
        "support_input": {
          "email": "E-mail"
        },
        "supports_form": {
          "header": "Invite people to join as an Extended Support Team - Contact",
          "email": "E-mail",
          "skip": "Skip",
          "save": "Save",
          "edit": "Edit",
          "add": "+ Add"
        }
      },
      "documents": {
        "library_documents": "Library documents:",
        "uploaded_documents": "Uploaded documents:",
        "upload_documents": "Upload documents",
        "file_name": "File name:",
        "file_size": "File size:",
        "created": "Created:",
        "updated": "Updated:",
        "close": "Close",
        "download": "Download",
        "document_preview": "Document: %{name}",
        "uploaded_document_list": {
          "upload_document": "Upload document",
          "edit_document": "Edit document",
          "no_files_uploaded": "No files have been uploaded to this Action Plan. <br/>Click 'Upload documents' to start."
        }
      },
      "instructions": {
        "subheader": "Would you like to provide any other instructions or information for the person and their Support team?",
        "label": "Instructions"
      },
      "resources": {
        "from_library": "From Library",
        "create_new": "Create new",
        "done": "Done",
        "next": "Next",
        "cancel": "Cancel",
        "search_placeholder": "Search resources...",
        "all_resources": "All Resources",
        "resources": "Resources",
        "service_kinds": {
          "information": "Information",
          "other": "Other",
          "people": "People",
          "practitioner": "Healthcare providers",
          "service": "Services"
        },
        "no_resources_present": "No resources are available to add to this Action Plan",
        "select_all": "Select all",
        "show_only_selected": "Show only selected items",
        "add_resource": "Add resource",
        "modal_form_title": {
          "create": "Add resource",
          "update": "Update resource"
        },
        "form": {
          "placeholder": {
            "name": "Examples: \"Meals on Wheels\" or \"Valley Medical Labs\"",
            "description": "Add a short description of this resource...",
            "tag": "Add a tag...",
            "url": "http://www.example.com",
            "number": "Phone number",
            "category": "Eg. Fitness Programs",
            "subcategory": "Eg. Aqua fitness"
          },
          "name": "Name",
          "description": "Description",
          "tag": "Tags",
          "kinds": {
            "information": "Information",
            "other": "Other",
            "people": "People",
            "practitioner": "Healthcare providers",
            "service": "Services"
          },
          "url": "Website",
          "number": "Number",
          "address": "Address",
          "update": "Update resource",
          "add": "Add resource",
          "cancel": "Cancel",
          "linkReferral": "Link a referral",
          "removeReferral": "Remove referral",
          "referralDescription1a": "To link a referral, enter the Category and Subcategory from",
          "referralDescription1b": "Caredove's website.",
          "referralDescription2": "Please enter the text exactly how it is displayed in Caredove.",
          "referralCategoryLabel": "Category",
          "referralSubcategoryLabel": "Subcategory (optional)",
          "referralLabel": "Label",
          "linkedReferralDescription": "Your linked referral will display as: ",
          "missingReferralLabel": "(enter a label)"
        },
        "search": {
          "label": "Find a resource",
          "search_placeholder": "Search resources by title or tag"
        }
      },
      "new": {
        "dd": "DD",
        "mm": "MM",
        "yyyy": "YYYY"
      },
      "tasks": {
        "index": {
          "empty_html": "No activities have been added for this Action Plan<br/> Click 'Add activity' to start.",
          "add_activity": "Add activity",
          "none": "Select a task",
          "cancel": "Cancel",
          "submit": "Add activity",
          "referral_header": "Add referral",
          "link_resources": "Link resources to task"
        }
      },
      "repeated_options": {
        "after": "After",
        "blank": "--Select--",
        "end_never": "Never",
        "end_on_a_day": "On a day",
        "once": "Once - any time",
        "scheduled": "Once - scheduled",
        "recurring": "Recurring",
        "daily": "Daily",
        "weekly": "Weekly",
        "monthly": "Monthly"
      },
      "forms": {
        "index": {
          "add_form": "Add Form",
          "empty_html": "No Forms have been added for this Action Plan.<br/> Click 'Add Form' to start."
        }
      },
      "check_ins": {
        "index": {
          "empty_html": "No check-ins have been added for this Action Plan<br/> Click 'Add Check-in' to start.",
          "add_check_in": "Add Check-in",
          "none": "Select a Check-in",
          "cancel": "Cancel",
          "submit": "Add Check-in",
          "invalid": "is invalid",
          "no_templates_available": "No check-in templates available."
        },
        "form": {
          "check_in_type": "Check-in Type",
          "blank": "--Select--",
          "visibility": "Who can see this Check-in?",
          "everyone": "Everyone",
          "health_team_only": "Health Team only",
          "once": "Once - any time",
          "scheduled": "Once - scheduled",
          "recurring": "Recurring",
          "days_offset": "Scheduled date",
          "due_at": "Start Date and Time",
          "days_offset_text": "after Action Plan start date",
          "every": "Every",
          "repeats": "Repeats",
          "end_repeat": "End repeat",
          "end_date": "End Date",
          "end_never": "Never",
          "after": "After",
          "end_on_a_day": "On a day",
          "daily": "Daily",
          "weekly": "Weekly",
          "monthly": "Monthly",
          "submit": "Submit Check-in",
          "count": {
            "one": "time",
            "other": "times"
          }
        }
      },
      "section_footer": {
        "skip": "Skip",
        "next": "Next"
      },
      "pill_actions": {
        "document-download": "Download",
        "file-edit": "Edit",
        "external-link": "View",
        "minus-circle": "Remove",
        "file-edit-blue": "Edit",
        "minus-circle-blue": "Remove"
      }
    },
    "care_plan_place_invitations": {
      "index": {
        "sent_at": "Invitation sent: %{sent_at}",
        "pending_header": "Pending Invitations",
        "reviewed_header": "Past Invitations",
        "subheader": "This is your list of invitations from other organizations to join a person's Action Plan. This is usually when you are collaborating in the care of a person, or the person is transitioning from one care setting to another. You can accept or decline each invitation.",
        "invitations": "invitations",
        "external_team_invitations": "External team invitations",
        "signups": "signups",
        "patient_signups": "Patient signups",
        "support_team_contact_signups": "Primary Supporter signups",
        "status": {
          "all": "All",
          "pending": "Pending",
          "accepted": "Accepted",
          "declined": "Declined"
        },
        "status_input": {
          "all": "Show all status",
          "pending": "Show pending",
          "accepted": "Show accepted",
          "declined": "Show declined"
        },
        "search_input": {
          "place_holder": "Search by people in the Action Plan"
        }
      },
      "signups_container": {
        "target_signup_modal": {
          "title_hcp": "External team invitation",
          "title_patient": "Patient signup request",
          "title_caregiver": "Primary Supporter request",
          "header_caregiver": "%{caregiverName} has self-registered as %{patientName}'s Primary Supporter",
          "header_patient": "%{patientName} has self-registered as a patient",
          "header_hcp": "%{placeName} has requested to collaborate on this action plan",
          "warning_accepted_by": "This request was accepted by %{resolvedBy} on %{resolvedAt}",
          "warning_declined_by": "This request was declined by %{resolvedBy} on %{resolvedAt}",
          "warning_accepted": "This request was accepted on %{resolvedAt}",
          "warning_declined": "This request was declined on %{resolvedAt}",
          "personal_details": {
            "title_caregiver": "Primary Supporter",
            "person": "Person",
            "action_plan": "Action Plan",
            "request_date": "Request Date",
            "title_hcp": "Invited by"
          },
          "legal_warning": {
            "title": "By accepting, you",
            "warning_caregiver_point_1": "Confirm that this request came from %{patientName}’s Supporter %{caregiverName}",
            "warning_caregiver_point_2": "Confirm that you have %{patientName}'s consent for %{caregiverName} to use Careteam to: access %{patientName}’s Personal Information, invite others to join their team, and communicate on their behalf to others",
            "warning_caregiver_point_3": "Agree to collaborate on this Action Plan on behalf of %{placeName}",
            "warning_hcp_point_1": "Confirm that this request came from %{invitedBy}",
            "warning_hcp_point_2": "Agree to collaborate on this Action Plan on behalf of %{placeName}",
            "warning_patient_point_1": "Confirm that this request came from %{patientName}",
            "warning_patient_point_2": "Agree to collaborate on this Action Plan on behalf of %{placeName}"
          },
          "api_success": "Updated successfully",
          "action_buttons": {
            "view_action_plan": "View Action Plan",
            "decline": "Decline",
            "done": "Done",
            "accept": "Accept"
          },
          "row_headers": {
            "patient": "Patient",
            "health_care_provider": "Health Care Provider",
            "support_team_contact": "Primary Supporter",
            "submitted": "Submitted",
            "resolved": "Resolved",
            "status": "Status"
          }
        }
      }
    },
    "care_plans": {
      "export_modal": {
        "header": "Export Action Plan to PDF",
        "body": "This action will export an unencrypted PDF copy of this Action Plan with relevant documents and will be logged in the audit log. The resulting .zip file will contain confidential Personal Health Information (PHI) that will be outside of the Careteam platform, subject to your organization's policies for protection of PHI.",
        "cancel": "Cancel",
        "export": "Export"
      },
      "document_section": {
        "edit_header": "Edit documents",
        "show_header": "Documents",
        "show": {
          "no_documents": "No one has uploaded any documents in your Action Plan yet."
        },
        "edit": {
          "library": "Library",
          "uploaded": "Uploaded",
          "upload_documents": "Upload documents",
          "done": "Done",
          "library_documents": {
            "subheader": "Select documents from the Careteam library relevant to your Action Plan or upload documents using the button below.",
            "selected_section_header": "Selected library documents",
            "unselected_section_header": "Library documents:",
            "selection_empty": "No documents have been selected from the library",
            "library_empty": "No one has uploaded any documents to the library yet.",
            "all_selected": "You have selected all of the documents available in the library",
            "no_search_match": "No documents found",
            "search_placeholder": "Search documents..."
          },
          "uploaded_documents": {
            "subheader": "Click 'Upload documents' below to add more documents to this Action Plan.",
            "list_header": "Uploaded documents",
            "upload_document": "Upload document",
            "edit_document": "Edit document",
            "document_list": {
              "no_files_uploaded": "No files have been uploaded to this Action Plan."
            }
          }
        }
      },
      "goal_section": {
        "index": {
          "header": "Personal goals",
          "show_all": "Show all goals",
          "hide_completed": "Hide completed goals",
          "empty": "You haven't created any goals for your care yet. Think about something achievable that you would like to work towards with the help of your team and share it here!",
          "empty_hca": "No goals added yet."
        },
        "goal": {
          "achieved": "Achieved %{datetime}",
          "complete": "Complete",
          "incomplete": "Incomplete",
          "edit": "Edit"
        },
        "goal_form": {
          "title": "Title",
          "description": "Description",
          "photo": "Photo",
          "submit": "Add Goal",
          "upload": "Upload photo",
          "optional": "(optional)",
          "update": "Update photo",
          "default_alt": "Photo"
        }
      },
      "generic_order_section": {
        "index": {
          "header": "Tasks",
          "add_header": "Add task",
          "submit": "Add",
          "cancel": "Cancel",
          "empty": "None for this Action Plan.",
          "link_resources": "Link resources to task",
          "resources": "Resources"
        },
        "generic_order": {
          "complete": "Complete",
          "incomplete": "Incomplete",
          "edit": "Edit",
          "no_help_request": "Recurring tasks cannot have help requests",
          "help_requested": "A volunteer has been requested to help with this task",
          "view_details": "View details",
          "request_volunteer": "Request volunteer",
          "resources": "Resources",
          "link_resources": "Link resources",
          "repeated_task": {
            "repeat_statement": "Repeats %{localizedFrequency} at %{formattedTime} until %{until}",
            "repeat_statement_infinity": "Repeats %{localizedFrequency} at %{formattedTime}"
          }
        },
        "generic_order_form": {
          "assignments": "Assign",
          "assignments_header": "%{name} and their Primary Supporters will be notified when any new Tasks are added, even if it's unassigned or assigned to someone else.",
          "optional": "(Optional)",
          "text": "Description",
          "add_request": "Request volunteer",
          "edit_request": "Edit volunteer request",
          "resources": "Resources",
          "recurring": "Recurring",
          "due_at": "Start Date and Time",
          "repeats": "Repeats",
          "every": "Every",
          "end_repeat": "End repeat",
          "after": "After",
          "end_date": "End Date",
          "task_template": "Choose task from library",
          "select_task_template": "Find a task template",
          "count": {
            "one": "time",
            "other": "times"
          },
          "start_new_task": "or start new task"
        }
      },
      "medical_order_section": {
        "index": {
          "header": "Appointments",
          "add_header": "Add appointment",
          "submit": "Add",
          "cancel": "Cancel",
          "empty": "None for this Action Plan.",
          "link_resources": "Link Resources"
        },
        "medical_order": {
          "booked": "Booked",
          "not_booked": "Not booked",
          "edit": "Edit",
          "help_requested": "A volunteer has been requested to help with this task.",
          "view_details": "View details"
        },
        "medical_order_form": {
          "assignments": "Assign",
          "assignments_header": "%{name} and their Primary Supporters will be notified when any new Tasks are added, even if it's unassigned or assigned to someone else.",
          "optional": "(Optional)",
          "practitioner": "With",
          "virtual_visit": "Virtual visit url",
          "text": "Description",
          "completed_at": "Appointment needs to be booked",
          "due_at": "Schedule for",
          "address": "Address",
          "city": "City",
          "region": "Region",
          "postal_code": "Postal code",
          "country_code": "Country code",
          "save": "Save",
          "cancel": "Cancel",
          "request_volunteer": "Request volunteer",
          "time": "Time",
          "resources": "Resources",
          "choose_appointment_template": "Choose appointment from library",
          "select_appointment_template": "Find an appointment template",
          "start_new_appointment": "or start new appointment"
        }
      },
      "form_section": {
        "index": {
          "header": "Forms",
          "submit": "Add",
          "empty": "None for this Action Plan.",
          "invalid": "is invalid"
        },
        "modal": {
          "form_title": "What do you want to call this form?",
          "form_type": "Select form from library",
          "header": "Add Form",
          "submit": "Add Form",
          "cancel": "Cancel",
          "check_in_type": "Form Type",
          "blank": "--Select--",
          "once": "Once - any time",
          "scheduled": "Once - scheduled",
          "recurring": "When should it be completed?",
          "due_at": "Start Date and Time",
          "time_offset": "Time",
          "every": "Every",
          "repeats": "Repeats",
          "end_repeat": "End repeat",
          "end_date": "End Date",
          "end_never": "Never",
          "after": "After",
          "end_on_a_day": "On a day",
          "daily": "Daily",
          "weekly": "Weekly",
          "monthly": "Monthly",
          "count": {
            "one": "time",
            "other": "times"
          },
          "days_offset": "Scheduled date",
          "days_offset_text": "after Action Plan start date"
        },
        "form_order": {
          "completed": "Complete",
          "not_completed": "Incomplete",
          "edit": "Edit",
          "repeat_statement": "Repeats %{localizedFrequency} until %{until}",
          "repeat_statement_infinity": "Repeats %{localizedFrequency}",
          "due_at_statement": "At %{dueDate}"
        }
      },
      "checkin_section": {
        "index": {
          "header": "Check-ins",
          "submit": "Add",
          "empty": "None for this Action Plan.",
          "invalid": "is invalid"
        },
        "checkin_order": {
          "completed": "Complete",
          "not_completed": "Incomplete",
          "edit": "Edit",
          "repeat_statement": "Repeats %{localizedFrequency} until %{until}",
          "repeat_statement_infinity": "Repeats %{localizedFrequency}",
          "due_at_statement": "At %{dueDate}"
        },
        "modal": {
          "header": "Create Check-in",
          "submit": "Add Check-in",
          "cancel": "Cancel",
          "check_in_type": "Check-in Type",
          "blank": "--Select--",
          "once": "Once - any time",
          "scheduled": "Once - scheduled",
          "recurring": "Recurring",
          "due_at": "Start Date and Time",
          "every": "Every",
          "repeats": "Repeats",
          "end_repeat": "End repeat",
          "end_date": "End Date",
          "end_never": "Never",
          "after": "After",
          "end_on_a_day": "On a day",
          "daily": "Daily",
          "weekly": "Weekly",
          "monthly": "Monthly",
          "count": {
            "one": "time",
            "other": "times"
          }
        }
      },
      "prescription_section": {
        "index": {
          "header": "Prescriptions",
          "submit": "Add",
          "empty": "There are no prescriptions for this Action Plan."
        },
        "prescription": {
          "filled": "Filled",
          "not_filled": "Not filled",
          "edit": "Edit"
        },
        "prescription_form": {
          "medication": "Medication",
          "completed_at": "Patient needs to fill"
        }
      },
      "health_team_banner": {
        "banner": {
          "header": "The Action Plan is now activated for %{patientName}!",
          "body_one": "If there are additional health teams you would like to add to view this Action Plan and work with the person you can invite them now.",
          "body_two": "You can also add health teams later from the Team section of the Action Plan under \"Health Team Members\".",
          "close": "Close",
          "invite": "Invite health teams"
        },
        "consent_form": {
          "header": "Confirm health teams to invite",
          "permission": "I confirm that I have the person's consent to invite the health teams as listed above to view this Action Plan.",
          "cancel": "Cancel",
          "back": "Back",
          "invite": "Invite"
        },
        "place_selection_form": {
          "header": "Choose an eligible health team from the list below.",
          "list_header": "Available health teams to invite",
          "cancel": "Cancel",
          "next": "Next"
        }
      },
      "referral_section": {
        "index": {
          "header": "Referrals",
          "empty": "None for this Action Plan.",
          "add_referral": "Add referral",
          "error_message": "Something went wrong, please try again.",
          "title": "Request Referral via Caredove",
          "description": "Caredove will find services available in the appropriate geographic area.  Please confirm that the person’s address below is correct, or enter a different address:"
        },
        "referral": {
          "service_name": "%{serviceName} via %{provider}",
          "status": {
            "draft": "Sent",
            "requested": "Pending",
            "received": "Pending",
            "accepted": "In-progress",
            "ready": "In-progress",
            "in_progress": "In-progress",
            "rejected": "Rejected",
            "cancelled": "Cancelled",
            "failed": "Cancelled",
            "entered-in-error": "Cancelled",
            "on_hold": "On-hold",
            "completed": "Completed",
            "booked": "Booked"
          }
        }
      },
      "recurrence_input": {
        "after": "After",
        "count": {
          "one": "time",
          "other": "times"
        },
        "due_at": "Start Date and Time",
        "due_at_appt": "Schedule for",
        "end_date": "End Date",
        "end_repeat": "End repeat",
        "every": "Every",
        "recurring": "Recurring",
        "repeats": "Repeats",
        "time": "Time",
        "days_offset": "Scheduled date",
        "days_offset_text": "after Action Plan start date"
      }
    },
    "country_codes": {
      "AF": "Afghanistan",
      "AX": "Åland Islands",
      "AL": "Albania",
      "DZ": "Algeria",
      "AS": "American Samoa",
      "AD": "Andorra",
      "AO": "Angola",
      "AI": "Anguilla",
      "AQ": "Antarctica",
      "AG": "Antigua and Barbuda",
      "AR": "Argentina",
      "AM": "Armenia",
      "AW": "Aruba",
      "AU": "Australia",
      "AT": "Austria",
      "AZ": "Azerbaijan",
      "BS": "Bahamas",
      "BH": "Bahrain",
      "BD": "Bangladesh",
      "BB": "Barbados",
      "BY": "Belarus",
      "BE": "Belgium",
      "BZ": "Belize",
      "BJ": "Benin",
      "BM": "Bermuda",
      "BT": "Bhutan",
      "BO": "Bolivia, Plurinational State of",
      "BQ": "Bonaire, Sint Eustatius and Saba",
      "BA": "Bosnia and Herzegovina",
      "BW": "Botswana",
      "BV": "Bouvet Island",
      "BR": "Brazil",
      "IO": "British Indian Ocean Territory",
      "BN": "Brunei Darussalam",
      "BG": "Bulgaria",
      "BF": "Burkina Faso",
      "BI": "Burundi",
      "KH": "Cambodia",
      "CM": "Cameroon",
      "CA": "Canada",
      "CV": "Cape Verde",
      "KY": "Cayman Islands",
      "CF": "Central African Republic",
      "TD": "Chad",
      "CL": "Chile",
      "CN": "China",
      "CX": "Christmas Island",
      "CC": "Cocos (Keeling) Islands",
      "CO": "Colombia",
      "KM": "Comoros",
      "CG": "Congo",
      "CD": "Congo, The Democratic Republic of the",
      "CK": "Cook Islands",
      "CR": "Costa Rica",
      "CI": "Côte d'Ivoire",
      "HR": "Croatia",
      "CU": "Cuba",
      "CW": "Curaçao",
      "CY": "Cyprus",
      "CZ": "Czech Republic",
      "DK": "Denmark",
      "DJ": "Djibouti",
      "DM": "Dominica",
      "DO": "Dominican Republic",
      "EC": "Ecuador",
      "EG": "Egypt",
      "SV": "El Salvador",
      "GQ": "Equatorial Guinea",
      "ER": "Eritrea",
      "EE": "Estonia",
      "ET": "Ethiopia",
      "FK": "Falkland Islands (Malvinas)",
      "FO": "Faroe Islands",
      "FJ": "Fiji",
      "FI": "Finland",
      "FR": "France",
      "GF": "French Guiana",
      "PF": "French Polynesia",
      "TF": "French Southern Territories",
      "GA": "Gabon",
      "GM": "Gambia",
      "GE": "Georgia",
      "DE": "Germany",
      "GH": "Ghana",
      "GI": "Gibraltar",
      "GR": "Greece",
      "GL": "Greenland",
      "GD": "Grenada",
      "GP": "Guadeloupe",
      "GU": "Guam",
      "GT": "Guatemala",
      "GG": "Guernsey",
      "GN": "Guinea",
      "GW": "Guinea-Bissau",
      "GY": "Guyana",
      "HT": "Haiti",
      "HM": "Heard Island and McDonald Islands",
      "VA": "Holy See (Vatican City State)",
      "HN": "Honduras",
      "HK": "Hong Kong",
      "HU": "Hungary",
      "IS": "Iceland",
      "IN": "India",
      "ID": "Indonesia",
      "IR": "Iran, Islamic Republic of",
      "IQ": "Iraq",
      "IE": "Ireland",
      "IM": "Isle of Man",
      "IL": "Israel",
      "IT": "Italy",
      "JM": "Jamaica",
      "JP": "Japan",
      "JE": "Jersey",
      "JO": "Jordan",
      "KZ": "Kazakhstan",
      "KE": "Kenya",
      "KI": "Kiribati",
      "KP": "Korea, Democratic People's Republic of",
      "KR": "Korea, Republic of",
      "KW": "Kuwait",
      "KG": "Kyrgyzstan",
      "LA": "Lao People's Democratic Republic",
      "LV": "Latvia",
      "LB": "Lebanon",
      "LS": "Lesotho",
      "LR": "Liberia",
      "LY": "Libya",
      "LI": "Liechtenstein",
      "LT": "Lithuania",
      "LU": "Luxembourg",
      "MO": "Macao",
      "MK": "Macedonia, Republic of",
      "MG": "Madagascar",
      "MW": "Malawi",
      "MY": "Malaysia",
      "MV": "Maldives",
      "ML": "Mali",
      "MT": "Malta",
      "MH": "Marshall Islands",
      "MQ": "Martinique",
      "MR": "Mauritania",
      "MU": "Mauritius",
      "YT": "Mayotte",
      "MX": "Mexico",
      "FM": "Micronesia, Federated States of",
      "MD": "Moldova, Republic of",
      "MC": "Monaco",
      "MN": "Mongolia",
      "ME": "Montenegro",
      "MS": "Montserrat",
      "MA": "Morocco",
      "MZ": "Mozambique",
      "MM": "Myanmar",
      "NA": "Namibia",
      "NR": "Nauru",
      "NP": "Nepal",
      "NL": "Netherlands",
      "NC": "New Caledonia",
      "NZ": "New Zealand",
      "NI": "Nicaragua",
      "NE": "Niger",
      "NG": "Nigeria",
      "NU": "Niue",
      "NF": "Norfolk Island",
      "MP": "Northern Mariana Islands",
      "OM": "Oman",
      "PK": "Pakistan",
      "PW": "Palau",
      "PS": "Palestine, State of",
      "PA": "Panama",
      "PG": "Papua New Guinea",
      "PY": "Paraguay",
      "PE": "Peru",
      "PH": "Philippines",
      "PN": "Pitcairn",
      "PL": "Poland",
      "PT": "Portugal",
      "PR": "Puerto Rico",
      "QA": "Qatar",
      "RE": "Réunion",
      "RO": "Romania",
      "RU": "Russian Federation",
      "RW": "Rwanda",
      "BL": "Saint Barthélemy",
      "SH": "Saint Helena, Ascension and Tristan da Cunha",
      "KN": "Saint Kitts and Nevis",
      "LC": "Saint Lucia",
      "MF": "Saint Martin (French part)",
      "PM": "Saint Pierre and Miquelon",
      "VC": "Saint Vincent and the Grenadines",
      "WS": "Samoa",
      "SM": "San Marino",
      "ST": "Sao Tome and Principe",
      "SA": "Saudi Arabia",
      "SN": "Senegal",
      "RS": "Serbia",
      "SC": "Seychelles",
      "SL": "Sierra Leone",
      "SG": "Singapore",
      "SX": "Sint Maarten (Dutch part)",
      "SK": "Slovakia",
      "SI": "Slovenia",
      "SB": "Solomon Islands",
      "SO": "Somalia",
      "ZA": "South Africa",
      "GS": "South Georgia and the South Sandwich Islands",
      "SS": "South Sudan",
      "ES": "Spain",
      "LK": "Sri Lanka",
      "SD": "Sudan",
      "SR": "Suriname",
      "SJ": "Svalbard and Jan Mayen",
      "SZ": "Swaziland",
      "SE": "Sweden",
      "CH": "Switzerland",
      "SY": "Syrian Arab Republic",
      "TW": "Taiwan",
      "TJ": "Tajikistan",
      "TZ": "Tanzania, United Republic of",
      "TH": "Thailand",
      "TL": "Timor-Leste",
      "TG": "Togo",
      "TK": "Tokelau",
      "TO": "Tonga",
      "TT": "Trinidad and Tobago",
      "TN": "Tunisia",
      "TR": "Turkey",
      "TM": "Turkmenistan",
      "TC": "Turks and Caicos Islands",
      "TV": "Tuvalu",
      "UG": "Uganda",
      "UA": "Ukraine",
      "AE": "United Arab Emirates",
      "GB": "United Kingdom",
      "UM": "United States Minor Outlying Islands",
      "US": "United States",
      "UY": "Uruguay",
      "UZ": "Uzbekistan",
      "VU": "Vanuatu",
      "VE": "Venezuela, Bolivarian Republic of",
      "VN": "Vietnam",
      "VG": "Virgin Islands, British",
      "VI": "Virgin Islands, U.S.",
      "WF": "Wallis and Futuna",
      "EH": "Western Sahara",
      "YE": "Yemen",
      "ZM": "Zambia",
      "ZW": "Zimbabwe",
      "blank": "Choose country…",
      "NO": "Norway"
    },
    "ct": {
      "components": {
        "diagnosis_template": {
          "placeholder": "Enter concern description",
          "delete": "Delete",
          "remove": "Remove"
        },
        "file_upload": {
          "attach_document": "Attach document"
        },
        "validator": {
          "url": "is not a valid url. Did you include http or https?",
          "email": "must be a valid email",
          "text": "can't be blank",
          "textarea": "can't be blank",
          "file": "can't be blank",
          "oversized_file": "The selected file must not be larger than 50 MB",
          "select-one": "please select an option",
          "datetime": "must be a valid date",
          "datetime-local": "must be a valid date",
          "tel": "must be a valid phone number"
        },
        "remote_handlers": {
          "medical_document": {
            "author": "Added by",
            "created_at": "Date added",
            "document_file_name": "File name",
            "event_at": "Date of event",
            "name": "Document title",
            "tags": "Tags",
            "view": "View"
          }
        },
        "confirm": {
          "delete_ok": "Yes, delete"
        }
      }
    },
    "dashboard": {
      "glucose_levels": "Glucose levels",
      "insulin_pump_settings": "Insulin pump settings",
      "insulin_dosing": "Insulin dosing",
      "device": {
        "data_not_available": "Data not available.",
        "unauthorized": "Access has not yet been granted to your device. Please check your Care Hub account.",
        "connection_lost": "We are unable to connect to your device. Please check your Care Hub account.",
        "no_data_present": "Device is connected, but there's no data.",
        "missing_fhir_token": "Data is not available until %{firstName} or their parent logs in to Careteam.",
        "no_data_present_timeframe": "Device is connected, but there's no data for the selected time frame.",
        "consent_missing_hcp_message": "Only partial data is being shown. Consent hasn't been given to access all of %{firstName}'s device data. Please ask %{firstName} (if they are old enough to consent), or their parent to give permission in their Care Hub.",
        "consent_missing_patient_message": "To view all of your available data, finish connecting your devices.",
        "connection_failed": "We tried to connect your device, but can't. Please check that your devices are connected in your Care Hub account.",
        "cgm_active_below_70": "CGM active needs to be more than 70%",
        "time_range_below_14_days": "Needs at least 14 days of data",
        "time_range_below_12_days": "Needs at least 12 days of data",
        "no_user_accepted_error": "Data not available yet. %{firstName} or their parent needs to log into Careteam and accept the invite first.",
        "unknown_error": "Something went wrong. Try again or contact us by emailing ",
        "no_chdb_data_present_patient_part_1": "No data for selected time frame. If you're expecting data, check data sources in ",
        "no_chdb_data_present_patient_part_2": "your Care Hub Account.",
        "no_chdb_data_present_placeuser": "No data for the selected time frame. If you're expecting data, ask the patient to check data sources in their Care Hub account.",
        "empty_comparison_chart_data": "No data for the selected time frame.",
        "average_glucose": "Average glucose",
        "gmi": "GMI",
        "cgm_active": "CGM active",
        "number_placeholder": "~0.0",
        "percent_placeholder": "~0.0%"
      },
      "insulin": {
        "long_insulin_type": "Long Insulin Type",
        "rapid_insulin_type": "Rapid Insulin Type",
        "dose": "Dose",
        "long": "Long",
        "ICR": "ICR",
        "ISF": "ISF",
        "breakfast": "Breakfast",
        "lunch": "Lunch",
        "dinner": "Dinner",
        "bedtime": "Bedtime",
        "save": "Save",
        "save_error": "An error occurred while the data was being saved.",
        "fetch_error": "An error occurred while the data was being retrieved.",
        "insulin": "Insulin",
        "rapid": "Rapid",
        "start": "Start",
        "active": "active",
        "exceeds_maximum_dose": "Cannot be 100 or above",
        "last_uploaded_on": "Last uploaded on %{date}",
        "updated_at": "Last updated by %{userName} on %{updatedAt}"
      },
      "chart": {
        "glucose": "Glucose",
        "avg_glucose_mmol_L": "Average Glucose (mmol/L)",
        "time_of_day": "Time of Day",
        "time_of_day_abbreviated": "Time",
        "50_median": "50% Median",
        "50_median_abbreviated": "50%",
        "25_75_IQR": "25 / 75% interquartile range",
        "25_75_IQR_abbreviated": "25 / 75%",
        "10_90_IDR": "10 / 90% interdecile range",
        "10_90_IDR_abbreviated": "10 / 90%",
        "target_range": "Target range",
        "monday": "Monday",
        "tuesday": "Tuesday",
        "wednesday": "Wednesday",
        "thursday": "Thursday",
        "friday": "Friday",
        "saturday": "Saturday",
        "sunday": "Sunday",
        "monday_abbreviated": "Mo",
        "tuesday_abbreviated": "Tu",
        "wednesday_abbreviated": "We",
        "thursday_abbreviated": "Th",
        "friday_abbreviated": "Fr",
        "saturday_abbreviated": "Sa",
        "sunday_abbreviated": "Su",
        "reset": "Reset",
        "comparison_date_range": "%{displayBeginDate} to %{displayEndDate}"
      },
      "time_range": {
        "24hrs": "24 hours",
        "1day": "1 day",
        "7days": "7 days",
        "14days": "14 days",
        "30days": "30 days",
        "90days": "90 days",
        "24h": "24h",
        "1d": "1d",
        "7d": "7d",
        "14d": "14d",
        "30d": "30d",
        "90d": "90d",
        "compare": "Compare"
      },
      "bolus_calculator": "Open bolus calculator",
      "percent_symbol": "%",
      "level": "Level",
      "mmol_per_litre": "mmol/L",
      "days_active": "(%{days} days)",
      "glucose_average": {
        "average_glucose": "Average glucose",
        "gmi": "GMI",
        "gmi_tooltip": "GMI (Glucose Management Indicator) is the estimated HbA1c from your continuous glucose monitor’s average glucose readings over a period of time.",
        "cv": "Coefficient of variation",
        "cv_tooltip": "CV (Coefficient of variation) is a measure of glucose variability from your continuous glucose monitor over a period of time. Research suggests a target CV of 36% or lower.",
        "sd": "SD mmol/L"
      },
      "glucose_range": {
        "low": "Low",
        "in_range": "In range",
        "high": "High"
      },
      "clipboard_copy": {
        "date_of_birth": "Date of birth",
        "exported_date": "Exported date",
        "reporting_period": "Reporting period",
        "average_daily_readings": "Average daily readings",
        "copy_as_text": "Copy as text",
        "copied": "Copied!",
        "average_daily_insulin": "Average daily insulin",
        "standard_deviation": "Standard deviation",
        "basal_rates_title": "Basal rate",
        "insulin_action": "Active insulin time",
        "basal_rate_unit": "U/hr",
        "i_c_ratio_settings": "I:C ratio settings",
        "i_c_ration_unit": "g/U",
        "isf_programs": "ISF programs",
        "isf_unit": "mmol/L/U",
        "target_blood_glucose": "Target blood glucose",
        "bg_target": "Target",
        "average_daily_dose": "Average daily dose",
        "average_basal": "Basal/day",
        "average_bolus": "Bolus/day",
        "units": "units"
      }
    },
    "editor": {
      "edit": "Edit",
      "save": "Save",
      "remove": "Remove",
      "visit": "Visit URL:",
      "enter_link": "Enter link:"
    },
    "bounce_messages": {
      "addresschange": "The recipient has requested an address change.",
      "autoresponder": "Automatic email responder (ex: \"Out of Office\" or \"On Vacation\").",
      "bademailaddress": "The address is not a valid email address.",
      "blocked": "Blocked from this ISP due to content or blacklisting.",
      "challengeverification": "The bounce is a challenge asking for verification you actually sent the email. Typcial challenges are made by Spam Arrest, or MailFrontier Matador.",
      "dmarcpolicy": "Email rejected due DMARC Policy.",
      "dnserror": "A temporary DNS error.",
      "hardbounce": "The server was unable to deliver your message (ex: unknown user, mailbox not found).",
      "inbounderror": "Unable to deliver inbound message to destination inbound hook.",
      "invitationerror": "Invitation error",
      "manuallydeactivated": "The email was manually deactivated.",
      "openrelaytest": "The NDR is actually a test email message to see if the mail server is an open relay.",
      "smtpapierror": "An error occurred while accepting an email through the SMTP API.",
      "softbounce": "Unable to temporarily deliver message (i.e. mailbox full, account disabled, exceeds quota, out of disk space).",
      "spamcompliant": "The subscriber explicitly marked this message as spam.",
      "spamnotification": "The message was delivered, but was either blocked by the user, or classified as spam, bulk mail, or had rejected content.",
      "subscribe": "Subscribe request from someone wanting to get added to the mailing list",
      "templaterenderingfailed": "An error occurred while attempting to render your template.",
      "transient": "The server could not temporarily deliver your message (ex: Message is delayed due to network troubles).",
      "unconfirmed": "The subscriber has not clicked on the confirmation link upon registration or import.",
      "unknown": "Unable to classify the NDR.",
      "unsubscribe": "Unsubscribe or Remove request",
      "virusnotification": "The bounce is actually a virus notification warning about a virus/code infected message."
    },
    "emergency_instruction": {
      "header": "Emergency instruction",
      "info": "This will be displayed to Patients and Supporters when they are messaging your team and organization.",
      "default": "If this is an emergency, please go to the nearest hospital, or call 911.",
      "updated": "Updated",
      "by": "By",
      "characters_left": "characters left",
      "instruction_translation": "If someone has another language set in their settings, the default emergency instruction is translated for them. You and your team's emergency instruction will not be translated.",
      "discard": "Discard changes",
      "preview": "Preview",
      "save": "Save",
      "error": "Sorry, an error occurred and we couldn't save this information. Please try again or contact us.",
      "revert": "Revert to default"
    },
    "locale": {
      "en": "English",
      "fr": "French",
      "es": "Spanish",
      "ko": "Korean",
      "sv": "Swedish"
    },
    "role": {
      "patient": "Patient",
      "primary_caregiver": "Primary Supporter",
      "caregiver": "Support team",
      "support": "Extended support",
      "unaffiliated_health_care": "Invited healthcare provider"
    },
    "relationship": {
      "empty_relationship": "Select a relationship...",
      "other": "Other",
      "spouse": "Spouse",
      "parent": "Parent",
      "child": "Child",
      "sibling": "Sibling",
      "cousin": "Cousin",
      "friend": "Friend",
      "neighbour": "Neighbour",
      "step_parent": "Step parent",
      "ex_spouse": "Ex-spouse",
      "grandchild": "Grandchild",
      "professional_caregiver": "Professional caregiver",
      "volunteer": "Volunteer",
      "unaffiliated_health_care": "Health Team Member"
    },
    "errors": {
      "email": {
        "invalid": "Invalid e-mail address",
        "already_exists": "User already belongs to this Action Plan",
        "blank": "Email can't be blank"
      }
    },
    "forms": {
      "no_options": "No options",
      "password_input": {
        "hide": "Hide",
        "show": "Show"
      },
      "address_form": {
        "address": "Street address",
        "extended_address": "Apartment, suite, etc (optional)",
        "province": "Province",
        "state": "State",
        "postal_code": "Postal code",
        "zip_code": "ZIP code",
        "postcode": "Postcode",
        "city": "City",
        "country": "Country",
        "edit": {
          "label": "Update Address"
        }
      },
      "date_time_input": {
        "am": "AM",
        "pm": "PM"
      },
      "document_form": {
        "cancel": "Cancel",
        "change_file": "Change file",
        "save": "Save",
        "document_name_label": "Document name",
        "event_at_label": "Date of event",
        "tags_label": "Tags",
        "file_size_exceeded": "The selected file must not be larger than 50 MB"
      },
      "library_document_select": {
        "no_documents_available": "There are no documents currently available in the library"
      },
      "locale_input": {
        "language": "Language"
      },
      "phone_number_form": {
        "mobile": "Mobile",
        "primary_residence": "Primary residence",
        "secondary_residence": "Secondary residence",
        "work": "Work"
      },
      "profile_picture_upload": {
        "default_alt": "Upload a profile picture",
        "generic_error": "The profile picture could not be uploaded",
        "upload": "Upload profile picture",
        "update": "Update profile picture",
        "optional": "(optional)",
        "explanation": "Adding a picture to your profile helps make the experience better for everyone.",
        "file_size_exceeded": "The selected file must not be larger than 50 MB"
      },
      "help_request": {
        "placeholder": {
          "header": "Match a volunteer to help with a task for someone in-need.",
          "pickup": "Examples: Grocery store, Home, etc.",
          "delivery": "Examples: House, Apartment, etc.",
          "recipient_phone": "Number",
          "due_at": "Delivery will be attempted within 2 hours of specified time"
        },
        "blank": "can't be blank",
        "invalid_address": "Address is not valid",
        "invalid_phone": "Phone number is not valid",
        "task_type": "Task type",
        "transportation": "Transportation",
        "pickup_name": "Pick-up location name",
        "pickup_address": "Pick-up address",
        "delivery_name": "Delivery location name",
        "delivery_address": "Delivery address",
        "recipient_phone": "Recipient's phone number",
        "due_at": "Preferred time of delivery",
        "notes": "Notes",
        "cancel": "Cancel",
        "submit": "Submit request",
        "success": "A volunteer has been requested to help with this task.",
        "outside_hours": "Unfortunately, your request didn't match any volunteers based on their availability and/or location proximity."
      },
      "submit": {
        "save": "Save"
      },
      "label": {
        "optional": "Optional"
      }
    },
    "goals": {
      "show": {
        "status": {
          "header": "Status",
          "status": {
            "complete": "Complete",
            "incomplete": "Incomplete"
          },
          "action": {
            "complete": "Revert",
            "incomplete": "Complete"
          }
        }
      }
    },
    "header": {
      "notifications": "Notifications",
      "language": "Language",
      "menu": {
        "index": {
          "my_profile": "My profile"
        }
      }
    },
    "inbox": {
      "disconnected": "An unexpected error has occurred. Please refresh the page",
      "conversation": {
        "disclaimer": {
          "header": "Careteam note",
          "non_health_care": "Messaging is a great way to clarify questions you might have about your Action Plan. Your message will be sent to the team inbox and will be answered by the first available team member. Please note this may take some time.",
          "health_care": "This secure messaging inbox is for routine communications. If it's urgent, please contact the person by phone.",
          "health_care_channel": "Please note that messages within a channel will not be automatically accessible to other members of the care team and that clinically relevant information must be recorded in the Action Plan or in your organization's records."
        },
        "form": {
          "body": "Write message here…",
          "submit": "Send",
          "error": "Something went wrong, try again."
        },
        "header": {
          "view_details": "View details",
          "view_careplan": "View Action Plan",
          "loading": "Loading...",
          "emergency_instruction_header": "Emergency instruction",
          "emergency_instruction_p1": "Messaging is for non-urgent communication. View",
          "emergency_instruction_p2": "alternate contacts"
        },
        "messages": {
          "new_desk_conversation_one": "Welcome to the message centre!",
          "new_desk_conversation_two": "Type a message below to contact %{name} about appointments or other enquiries.",
          "new_conversation_one": "You have not messaged with %{name} before.",
          "new_conversation_two": "Begin your conversation by sending a message below.",
          "no_conversations": "You haven't started any conversations yet, click on a contact to start.",
          "emoji": "Insert emoji"
        }
      },
      "side_nav": {
        "care_plans": {
          "header": "Select an Action Plan",
          "back": "Back",
          "no_results": "No Action Plans",
          "filter": "Search for an Action Plan...",
          "health_care": {
            "carePlans": {
              "info": "To create a desk conversation you need to first select an Action Plan."
            },
            "channelCarePlans": {
              "info": "To create an Action Plan channel you need to first select an Action Plan."
            }
          },
          "non_health_care": {
            "channelCarePlans": {
              "info": "To talk to a healthcare provider or to start a group channel please select the Action Plan you want to talk about."
            }
          }
        },
        "channels": {
          "info": "Team members for this Action Plan:",
          "next": "Next",
          "channel_name": "Channel name:",
          "channel_name_hint": "The name of the Action Plan will be used if no name is specified.",
          "start": "Start new channel",
          "invalid_name": "That channel name already exists. To proceed, please update the channel name.",
          "channel_members": "Members of this Channel (%{count}):",
          "channelMembers": {
            "header": "Select Action Plan channel members"
          },
          "ongoing": {
            "header": "Select Action Plan channel members"
          },
          "channelName": {
            "header": "Create Action Plan channel"
          },
          "select_all": "Select All (%{count})"
        },
        "contacts": {
          "contact_list": {
            "no_contacts": {
              "header": "Build your support team to exchange messages securely",
              "info": "Visit the Team page to add family, friends, and caregivers supporting you in your health journey. Then return here to message them directly.",
              "no_contacts": "No conversations"
            }
          },
          "filters": {
            "search": "Search for a contact...",
            "newest": "Newest",
            "oldest": "Oldest",
            "nameAsc": "Name A - Z",
            "nameDesc": "Name Z - A"
          },
          "header": {
            "desk": "Health team desks",
            "direct": "Direct messages",
            "health_care_direct": "Desk conversations",
            "channels": "Action Plan channels",
            "desk_subtext": "Contact reception",
            "direct_subtext": "1:1 support team messages",
            "channels_subtext": "1:1 or group messages",
            "health_care_direct_subtext": "General requests",
            "users": "New channel",
            "user-circle": "New message",
            "desk-conversations": "New conversation",
            "hospital": "New desk conversation"
          }
        },
        "new_contacts": {
          "header": "New direct message",
          "desk_header": "New desk conversation",
          "info": "Below is a list of caregivers and family members you can message with."
        }
      }
    },
    "notifications": {
      "header": "Notifications",
      "close": "Close",
      "empty": {
        "title": "Congrats!",
        "message": "You are caught up on your notifications."
      },
      "tooltips": {
        "close": "Close",
        "remove_notification": "Remove notification"
      }
    },
    "pagination": {
      "one_page": {
        "display_entries": {
          "one": "Displaying 1 %{entry_name}",
          "other": "Displaying all %{count} %{entry_name}"
        }
      },
      "more_pages": {
        "display_entries": "Displaying %{entry_name} %{first}-%{last} of %{total} in total"
      }
    },
    "patient": {
      "edit": {
        "address": {
          "label": "Address type",
          "address": "Address",
          "home": "Home",
          "work": "Work",
          "temporary": "Temporary",
          "country": "Country",
          "street_address": "Street address",
          "extended_address": "Apartment, suite, etc. (optional)",
          "city": "City",
          "province": "Province",
          "state": "State",
          "postal_code": "Postal code",
          "zip_code": "ZIP code",
          "postcode": "Postcode",
          "mailing_address": "Mailing address",
          "cancel": "Cancel",
          "save": "Save"
        },
        "index": {
          "first_name": "First name",
          "last_name": "Last name",
          "female": "Female",
          "male": "Male",
          "other": "Other",
          "gender": "Gender",
          "birthday": "Date of birth",
          "medical_record_number": "Medical insurance number",
          "contact_information": "Contact information",
          "email": "E-mail",
          "invitation_message": "An invitation to join the Action Plan will be sent to this email when you save.",
          "manage_invitation": "Manage invitation",
          "send_invitation": "Send invitation",
          "phone_number": "Phone number",
          "address": "Address",
          "street_address": "Street address",
          "new": "+ Add",
          "primary_physician": {
            "header": "Primary care provider",
            "placeholder": "Enter physician details here"
          },
          "submit": "Save",
          "submit_error": "Sorry, an error occurred and we couldn't save the information. Please try again or contact us.",
          "select": "Select",
          "update_carehub": "Update in Care Hub"
        },
        "phi_section": {
          "submit": "Save"
        }
      },
      "index": {
        "filter_checkbox_label": "Show:",
        "filter_patients_my_list": "My list",
        "filter_patients_archived": "Archived",
        "search_patients_placeholder": "Search",
        "search_patients_aria": "Search",
        "sort_tooltip": "Sort",
        "sort_patients_aria": "Sort",
        "empty": "No results",
        "header": "People we serve",
        "sort": {
          "createdAtDesc": "Newest",
          "createdAtAsc": "Oldest",
          "nameAsc": "Last name A-Z",
          "nameDesc": "Last name Z-A",
          "sort_tooltip": "Sort",
          "sort_patients_aria": "Sort"
        },
        "new_care_plan": "New Action Plan",
        "add_action_plan": "Start new Action Plan",
        "self_signups": "Self-signups",
        "self_signup_link": "Self-signup link",
        "error_occurred": "Something went wrong, please try again.",
        "new_care_plan_menu": {
          "create_new_care_plan": "Create new",
          "use_template": "Use template"
        },
        "care_plan_templates_modal": {
          "title": "What kind of Action Plan do you want to start?",
          "content_banner": "To save time, choose from the available Templates created by your organization. Templates are already set up to save you time and effort.",
          "list_header": "Select Template",
          "modal_failure": "Something went wrong. Please try again.",
          "next": "Next",
          "create_action_plan": "Create Action Plan",
          "cancel": "Cancel"
        },
        "patient": {
          "medical_record_number": "Medical insurance number",
          "archived": "Archived",
          "pending": "Invitations pending",
          "new_patient": "New patient",
          "new_referral": "New referral",
          "last_logged_in": "Last logged in %{date} at %{time}",
          "self_registered": "New self-registration",
          "unknown": "Unknown",
          "years_old": "years old"
        },
        "patients": {
          "empty": "No results"
        }
      },
      "new": {
        "address": {
          "label": "Address type",
          "home": "Home",
          "work": "Work",
          "temporary": "Temporary",
          "mailing_address": "Mailing address",
          "submit": "Save"
        }
      },
      "show": {
        "address": {
          "label": "Address type",
          "home": "Home",
          "work": "Work",
          "temporary": "Temporary",
          "mailing_address": "Mailing address",
          "submit": "Save",
          "edit": "Edit",
          "unknown": "Unknown"
        },
        "addresses": {
          "header": "Additional addresses"
        },
        "index": {
          "first_name": "First name",
          "last_name": "Last name",
          "female": "Female",
          "male": "Male",
          "other": "Other",
          "gender": "Gender",
          "birthday": "Date of birth",
          "medical_record_number": "Medical insurance number",
          "contact_information": "Contact information",
          "email": "E-mail",
          "manage_invitation": "Manage invitation",
          "phone_number": "Phone number",
          "address": "Address",
          "street_address": "Street address",
          "new": "+ Add",
          "primary_physician": {
            "header": "Primary care provider",
            "placeholder": "Enter physician details here"
          },
          "submit": "Save",
          "name": "Name",
          "unknown": "Unknown"
        },
        "phone_number": {
          "mobile": "Mobile",
          "primary_residence": "Primary residence",
          "secondary_residence": "Secondary residence",
          "work": "Work",
          "edit": "Edit",
          "unknown": "Unknown"
        },
        "phone_numbers": {
          "header": "Additional phone numbers"
        }
      },
      "patient": {
        "header": "%{name}'s Profile",
        "resend_invitation": "Resend invitation",
        "invitation_sent": "Invitation sent!"
      },
      "phi_section": {
        "current_medications": "Current medications",
        "past_medications": "Past medications",
        "immunizations": "Immunizations",
        "allergies": "Allergies",
        "family_history": "Family history",
        "notes": "Notes",
        "none": "No details added yet."
      },
      "check_in_result": {
        "header": "Check-in Results",
        "submitted": "Submitted",
        "submittedBy": " By %{name}",
        "none": "No details added yet.",
        "past_submissions": "Past Submissions",
        "skipped": "Skipped",
        "submitted_late": "Submitted late",
        "last_checkin": "Last Check-in:",
        "previous_checkins": "Previous Check-ins:",
        "show_more_submissions": "Show more Check-in submissions"
      },
      "form_result": {
        "header": "Forms",
        "submitted_by": "Submitted at %{date} by %{name}",
        "none": "No details added yet."
      },
      "check_in_score": {
        "0": "No",
        "1": "Yes",
        "min": "Min - %{val}",
        "max": "Max - %{val}",
        "placeholder": "Type in your answer",
        "remaining": "Remaining - %{val}"
      }
    },
    "referrals": {
      "status_badge": {
        "draft": "Draft: <b>Submitting</b>",
        "submitted": "In progress: <b>Submitted</b>"
      }
    },
    "registration": {
      "index": {
        "already_have_account": "Already have an account?",
        "login": "Login",
        "welcome": "Welcome to Careteam",
        "subtitle": "Your all-in-one platform to healthcare",
        "teasers": ["Find instructions, appointments, and documents.", "Access it 24/7 in one secure place, on any device.", "Connect it to all the people that need to be involved."]
      },
      "code": {
        "enter_code": "Enter code",
        "subtitle": "Enter your Careteam sign up code. If you don't have one, contact us.",
        "label": "Code",
        "next": "Next"
      },
      "representative": {
        "role": "Who is this account for?",
        "myself": "Myself",
        "patient_role": "Sign up to manage your own Action Plan.",
        "someone_else": "Someone else",
        "caregiver_role": "Sign up to help someone else. To protect their privacy, you will have limited access until %{place} agrees to collaborate on this Action Plan.",
        "next": "Next"
      },
      "user": {
        "create_account": "Create your account",
        "first_name": "First name",
        "last_name": "Last name",
        "email": "Email",
        "password": "Password",
        "password_hint": "Must be 8 or more characters with at least one lowercase letter, one uppercase letter, one number and one symbol",
        "terms_of_service": "I agree to the <a target=\"_blank\" href=\"/terms_of_service\" id=\"show-terms-of-service\">Terms of Service</a> &amp; <a target=\"_blank\" href=\"/privacy_policy\" id=\"show-privacy-policy\">Privacy Policy</a>",
        "consent": "I am signing up to use this Action Plan provided by %{place}. The team at %{place} will be notified that I have signed up. Their team members will be able to view and contribute to my Action Plan, and communicate with me and anyone I have designated on Careteam as a Contact member of my Support Team.",
        "next": "Next",
        "blank_first_name": "Cannot be blank",
        "blank_last_name": "Cannot be blank",
        "blank_email": "Cannot be blank",
        "invalid_email": "Invalid email",
        "blank_password": "Cannot be blank",
        "invalid_password": "Must contain at least an uppercase letter, a lowercase letter, a digit and a symbol",
        "must_have": "Must have at least",
        "eight_chars": "8 characters",
        "upper_case": "1 upper case letter",
        "lower_case": "1 lower case letter",
        "special_char": "1 number or special character (e.g. ! @ # $ % ^ & * ( )",
        "passwords_no_match": "Passwords do not match"
      },
      "patient": {
        "who": "Who are you creating this plan for?",
        "subtitle": "Enter in the information for the person you are acting as a caregiver for.",
        "first_name": "Their first name",
        "last_name": "Their last name",
        "next": "Next",
        "blank_first_name": "Cannot be blank",
        "blank_last_name": "Cannot be blank"
      },
      "avatar": {
        "upload": "Upload profile photo",
        "subtitle": "Adding a photo of yourself helps make the experience better for the others on the Action Plan",
        "skip": "Skip",
        "finish": "Finish",
        "optional": "Optional",
        "form": {
          "photo": "Your profile photo",
          "default_alt": "Photo",
          "remove": "Remove",
          "upload": "Upload"
        }
      }
    },
    "repeated_tasks": {
      "occurrences": {
        "edit": {
          "delete_series": "This task is part of a repeating series. Do you want to delete:",
          "change_series": "This task is part of a repeating series. Do you want to update:",
          "this_event": "This task",
          "this_series": "All future tasks",
          "cancel": "Cancel"
        }
      }
    },
    "resources": {
      "confirmation": {
        "header": "Referrals",
        "title": "Request %{title} via Caredove",
        "description": "Caredove will find available services in your area. Please confirm that the address below is correct, or enter a different address.",
        "address_label": "Address",
        "placeholder": "Enter a street address...",
        "confirmation_label": "Confirmation",
        "self_confirmation_description": "Launch Caredove and pass my contact information (name, date of birth, address, phone)",
        "confirmation_description": "Launch Caredove and pass this person’s contact information (name, date of birth, address, phone)",
        "sso_description": "Already have a Caredove account?",
        "address_update_hint": "Update your Home address in My Profile to prefill this next time.",
        "cancel": "Cancel",
        "next": "Next"
      }
    },
    "tags": {
      "other": "Other",
      "form": {
        "statuses": ["draft", "active", "archived"],
        "edit": "Edit tag",
        "edit_confirm": "Are you sure you want to delete this Tag? This will permanently remove it from any Resources in your admin library and in Action Plans. To temporarily hide the tag, change the status to Archived.",
        "edit_status_help": "Changing the status to Archived will hide the tag on all active Resources. If the status is changed back to Active, it will re-appear on any Resource it was applied to before.",
        "add": "Add tag",
        "delete": "Delete",
        "name": "Name",
        "status": "Status",
        "cancel": "Cancel",
        "submit": "Submit"
      }
    },
    "tasks": {
      "show": {
        "status": {
          "heading": "Status"
        }
      },
      "index": {
        "heading": "All activity",
        "add_task": "Add task",
        "add_appointment": "Add appointment",
        "complete": "Complete",
        "pending": "Pending",
        "revert": "Revert",
        "repeated": "Repeats",
        "resources": {
          "header": "Select resources to link",
          "link_button": "Link resources",
          "back": "Back",
          "cancel": "Cancel",
          "next": "Next",
          "search_placeholder": "Search resources...",
          "service_kinds": {
            "information": "Information",
            "other": "Other",
            "people": "People",
            "practitioner": "Healthcare providers",
            "service": "Services"
          },
          "no_resources_present": "No resources are available to add to this Action Plan"
        },
        "not_available": "Not Available",
        "repeated_task": "Repeated task",
        "repeated_appointment": "Repeated appointment",
        "check_in": {
          "hi": "Hi",
          "thanks": "Thanks %{name}",
          "response_recorded": "Your check-in has been recorded.",
          "submit": "Submit",
          "next": "Next",
          "check_in_link": "See My Check-in",
          "skip": "Skip",
          "past_due_at_warning": "Complete this check-in for %{dueAt} or you can skip and answer the most recent one instead"
        },
        "tasks": {
          "completed": {
            "heading": "Completed tasks",
            "button": "Hide completed",
            "blankslate": "You don't have any completed tasks",
            "filtered_blankslate": "You don't have any completed tasks matching filter"
          },
          "incomplete": {
            "heading": "Tasks",
            "button": "Show completed",
            "blankslate": "Congrats! <br /> You are caught up on your tasks.",
            "filtered_blankslate": "You don't have any incomplete tasks matching filter"
          }
        },
        "agenda": {
          "heading": "Calendar",
          "previous": "Previous week",
          "current": "Today",
          "next": "Next week",
          "blankslate": "You don't have anything scheduled for this week.",
          "filtered_blankslate": "You don't have anything scheduled for this week that is matching the current filter"
        },
        "sidebar": {
          "header": "View tasks by Action Plan",
          "mobile_header": "%{patient}'s Activities",
          "my_activities": "My Activities",
          "filter": "Filter by Action Plan"
        },
        "filters": {
          "heading": "Filter",
          "all": "All",
          "accepted": "Accepted",
          "pending": "Pending",
          "assigned": "Assigned",
          "unassigned": "Unassigned",
          "mine": "Mine"
        },
        "new_task": "New task",
        "new_appointment": "New appointment"
      },
      "nested_appointment_form": {
        "add_appointment": "Would you like to add an appointment for this task?",
        "skip": "Skip"
      },
      "actions_container": {
        "edit": "Edit",
        "revert": "Revert",
        "mark_complete": "Mark complete"
      },
      "form": {
        "add_time": "Add time",
        "assignments": "Assign",
        "callback_header": "Perfect, your submission has been received",
        "callback_body": ["You and your team can ", "see the results", " in your Action plan at any time. Now you can return to your day. Thank you!"],
        "callback_button": "Got it!",
        "cancel": "Cancel",
        "due_at": "Date and time",
        "submit": "Save",
        "text": "Description",
        "repeat": "Repeat",
        "until": "Until",
        "complete_form": "Complete form",
        "frequency": {
          "none": "None",
          "daily": "Daily",
          "weekly": "Weekly",
          "monthly": "Monthly",
          "yearly": "Yearly"
        },
        "every": "Every",
        "interval": {
          "daily": {
            "one": "day",
            "other": "days"
          },
          "weekly": {
            "one": "week",
            "other": "weeks"
          },
          "monthly": {
            "one": "month",
            "other": "months"
          },
          "yearly": {
            "one": "year",
            "other": "years"
          }
        },
        "end_repeat": "End repeat",
        "end": {
          "never": "Never",
          "date": "On a day",
          "count": "After"
        },
        "choose_a_date": "Choose a date",
        "count": {
          "one": "time",
          "other": "times"
        },
        "no_resources_present": "No resources are available to add to this Task",
        "address": {
          "street_address": "Street address",
          "extended_address": "Apartment, suite, etc (optional)",
          "city": "City",
          "province": "Province",
          "state": "State",
          "postal_code": "Postal code",
          "zip_code": "ZIP code",
          "postcode": "Postcode",
          "country": "Country"
        }
      }
    },
    "team": {
      "roles": {
        "patient": "",
        "primary_caregiver": "Contact",
        "caregiver": "Member",
        "support": "Support"
      },
      "invite_code_modal": {
        "title": "Invite team members by A) a private code or B) a private link",
        "subtitle": "When someone uses this code or link to ask to join this Action Plan, you will be able to approve them and decide on their level of access.",
        "get_code": "Get invite code",
        "first_option": "A) Use a private code",
        "second_option": "B) Use a private link",
        "second_option_subtitle": "Once you have copied the link to your clipboard, you can share it by pasting it into an e-mail or a chat message.",
        "first_option_subtitle": "Send them online to <span class=\"invite-code-modal__inline-link text-medium\">%{link}</span> to enter this private code:",
        "link_copied": "Link copied to clipboard",
        "get_link": "Copy invite link",
        "header": "Get invite code",
        "done": "Done"
      },
      "modal": {
        "add_members": "Add team members",
        "add_team_members": "Add team members",
        "build": "Build your support team",
        "welcome": "Welcome to Careteam",
        "invite_by_email": "Invite by email address",
        "invite_by_code": "Invite by private access code",
        "health_add_team_button": "Add to your health team",
        "support_add_team_button": "Add to your support team",
        "health_team_invite_title": "Invite a health team member by %{inviteType}",
        "support_team_invite_title": "Invite a new support team member by %{inviteType}",
        "support_team_invite_subtitle": "Add team members here, decide what they can see and do on the next step.",
        "health_team_explanation": "Invite health care practitioners like physicians or other health care professionals.",
        "support_team_explanation": "Invite family, friends and professional caregivers to help support you and your goals.",
        "support_team_declaration": "%{name} has provided consent for me to allow this support team member to access her/his personal health information and act on behalf of her/him through Careteam.",
        "health_team_declaration": "%{name} has provided consent for me to allow this healthcare provider to access her/his personal health information.",
        "email": {
          "description": "Everyone needs family and friends to support them. Add team members here (you decide what they can see and do on the next step).",
          "email_label": "E-mail address",
          "email_placeholder": "Enter e-mail address...",
          "email_hint": "An invitation to join your Action Plan will be sent to this e-mail.",
          "relationship_label": "Relationship to %{name}",
          "relationship_patient_label": "Relationship to you",
          "empty_relationship": "Select a relationship...",
          "cancel": "Cancel",
          "next": "Next"
        },
        "final": {
          "header_title_html": "Team invitations sent <span class=\"text-normal\">(%{size})</span>",
          "description_html": "The team members you have invited below will receive invitations via email immediately. You can add additional team members now or do it later from your <b>Team</b> page.",
          "done": "I'm done",
          "invite_more": "Invite more",
          "primary_caregiver": "Contact",
          "caregiver": "Member",
          "support": "Extended",
          "unaffiliated_health_care": "Health Team Member"
        },
        "review": {
          "header_title": "Review your team invites",
          "error": "Error while sending invitations",
          "edit": "Edit",
          "remove": "Remove",
          "add_another": "Add another team member",
          "cancel": "Cancel",
          "invite": "Invite",
          "primary_caregiver": "Contacts",
          "caregiver": "Members",
          "support": "Extended",
          "unaffiliated_health_care": "Health Team Members"
        },
        "role": {
          "select": "Select role",
          "header_title": "Select the level of access for this person.",
          "description": "This controls the information they can see and the actions they can take.",
          "back": "Back",
          "cancel": "Cancel",
          "next": "Next"
        }
      },
      "relationship": {
        "index": {
          "change": "Change",
          "label": "Relationship to %{name}"
        },
        "relationship_form": {
          "cancel": "Cancel",
          "label": "Relationship to %{name}"
        }
      },
      "role": {
        "index": {
          "change": "Change"
        },
        "role_form": {
          "cancel": "Cancel"
        }
      },
      "active_team": {
        "primary_caregiver": "Contacts",
        "patient": "Person",
        "caregiver": "Members",
        "support": "Extended"
      },
      "health_team": {
        "title": "Health team members",
        "pending_health_teams": "Pending health teams",
        "invite": "Invite health team",
        "author": "Author",
        "no_members": "No Health team members on the team.",
        "unknown": "No phone number",
        "invitation_modal": {
          "header": "Invite health teams",
          "success": "Invitations sent."
        },
        "edit_health_team": {
          "header": "Edit health team members",
          "subheader": "Select or remove members of your health team staff for this Action Plan",
          "selected_header": "%{place} health team members",
          "unselected_header": "%{place} health team staff",
          "no_selected_members": "No health team members have been selected.",
          "no_unselected_members": "No health team members available.",
          "submit": "Done",
          "cancel": "Cancel",
          "error": "Error while updating health team members.",
          "success": "Health team members updated."
        }
      },
      "unaffiliated_contact_team": {
        "title": "Invited healthcare providers",
        "no_members": "No team members of this type yet - add someone.",
        "other": "Other",
        "pending": "Pending...",
        "practitioner": "Practitioner",
        "care_coordinator": "Care Coordinator",
        "case_manager": "Case Manager",
        "chiropractor": "Chiropractor",
        "counselor": "Counselor",
        "dietician": "Dietician",
        "massage_therapist": "Massage Therapist",
        "nurse": "Nurse",
        "nurse_practitioner": "Nurse Practitioner",
        "occupational_therapist": "Occupational Therapist",
        "osteopath": "Osteopath",
        "pediatrician": "Pediatrician",
        "pharmacist": "Pharmacist",
        "physician_family_doctor": "Physician Family Doctor",
        "physician_specialist": "Physician Specialist",
        "physiotherapist": "Physiotherapist",
        "psychiatrist": "Psychiatrist",
        "psychologist": "Psychologist",
        "social_worker": "Social Worker"
      },
      "service": {
        "practitioner": "Healthcare providers",
        "service": "Services",
        "people": "People",
        "information": "Information",
        "other": "Other"
      },
      "service_form": {
        "titles": {
          "service": "New resource",
          "phone_number": "Phone number",
          "address": "Address"
        },
        "name": "Name",
        "number": "Number",
        "address": "Address",
        "city": "City",
        "region": "Region",
        "postal_code": "Postal code",
        "submit": "Add"
      },
      "service_member": {
        "phone_number": "Phone number",
        "website": "Website",
        "links": "Links",
        "unknown": "N/A"
      },
      "team": {
        "primary_caregiver": {
          "description": "Primary Supporters have the same access as the Patient. They can view all of %{name}'s information, including the Action Plan. They can invite others to the team, assign tasks, and communicate directly with the Health Team on the %{name}'s behalf.",
          "chip": "Most access"
        },
        "caregiver": {
          "description": "A support team member can view all of the information about %{name}, including the Action Plan. They can assign tasks, but can NOT communicate with health care managers or add new resources.",
          "chip": "Less access"
        },
        "support": {
          "description": "An extended team member can only see tasks they have been asked to help with, and message with the support team.",
          "chip": "Least access"
        },
        "no_members": "No team members of this type yet - add someone.",
        "invite_success": "Invitation was sent successfully"
      },
      "user_form": {
        "confirm": "This will email an invitation to the user, are you sure?",
        "email": "E-mail",
        "relationship": "Relationship to %{name}",
        "submit": "Send invitation"
      },
      "support_team_member": {
        "submit": "Manage invitation",
        "confirm": "This will resend an invitation to the user, are you sure?",
        "phone_number": "Phone number",
        "email": "E-mail",
        "unknown": "No phone number",
        "warning_message": "There is an error: '%{bounceMessage}' You can edit and resend the invitation as needed",
        "pending": "Pending",
        "email_invalid": "Email invalid",
        "substitute_decision_maker_abbreviation": "SDM",
        "substitute_decision_maker": "Substitute Decision Maker",
        "most_responsible_provider": "Most Responsible Provider"
      },
      "manage_invitation": {
        "body": "You can <b>Resend</b> the invitation or <b>Edit</b> the e-mail address. In both cases, a new invitation will be sent. You can also <b>Remove</b> the original invitation.",
        "close": "Close",
        "edit": "Edit",
        "header": "Manage invitation",
        "remove": "Remove",
        "resend": "Resend",
        "sent": "Invitation sent!",
        "send_invitation": "Send invitation",
        "place": {
          "header": "Manage health team invitation",
          "body": "You can <b>Resend</b> the invitation or <b>Remove</b> the original invitation."
        }
      },
      "edit_invitation": {
        "header": "Edit invitation",
        "label": "E-mail address",
        "hint": "An invitation to join the Action Plan will be sent to this e-mail.",
        "back": "Back",
        "send_invitation": "Send invitation",
        "cancel": "Cancel"
      },
      "new_invitation": {
        "header": "Send invitation",
        "label": "E-mail address",
        "hint": "An invitation to join the Action Plan will be sent to this e-mail.",
        "send_invitation": "Send invitation",
        "cancel": "Cancel"
      },
      "remove_invitation": {
        "back": "Back",
        "remove": "Remove invitation",
        "confirmation": "Are you sure you want to remove this invitation?",
        "place_confirmation": "Are you sure you want to remove the invitation?",
        "information": "Removing this invitation will also remove the e-mail associated with this person."
      },
      "select": {
        "primary_caregiver": {
          "header": "Contact",
          "chip": "Most access",
          "description": "Usually a close relative or friend.",
          "access": "Contacts can:",
          "list": ["View all health information", "Add and message team members, assign tasks, view activity, and upload documents", "Communicate with health team members"]
        },
        "caregiver": {
          "header": "Member",
          "chip": "Less access",
          "description": "Usually a relative or friend.",
          "access": "Members can:",
          "list": ["Message team members, view all health information", "Assign tasks, view activity, and upload documents"]
        },
        "support": {
          "header": "Extended",
          "chip": "Least access",
          "description": "Usually a friend or neighbor.",
          "access": "Extended members can:",
          "list": ["See tasks they have been asked to help with, and communicate with the person and their support team"]
        },
        "unaffiliated_health_care": {
          "header": "Health Care Provider",
          "chip": "Read-only access",
          "description": "Such as a physician, nurse, social worker",
          "access": "You will be providing this Health Care Provider with access to view your Action Plan. They can:",
          "list": ["View all health information", "View your health care and support team membership", "Be assigned to tasks in the Action Plan and update their status"]
        }
      },
      "access_requests": {
        "header": "Pending requests to join team",
        "approve": "Review request",
        "unknown": "No phone number",
        "approved": "Request approved.",
        "declined": "Request declined.",
        "failure": "Something went wrong."
      },
      "acceptance_modal": {
        "title": "Review team member request",
        "role_select": {
          "header_title": "Select the level of access for %{name}.",
          "approve": "Approve",
          "decline": "Decline",
          "cancel": "Cancel",
          "next": "Next"
        },
        "consent": {
          "header_title": "Approve or decline team member request",
          "description": "Contacts",
          "declaration": "I have permission to allow this new user to access the person's information, and they can act on behalf of the person through Careteam.",
          "cancel": "Cancel",
          "decline": "Decline",
          "approve": "Approve",
          "edit": "Edit"
        }
      }
    },
    "terms_of_service": {
      "declaration": "I agree to Careteam's <a rel='noopener noreferrer' target='_blank' href='/terms_of_service'>%{terms_of_service}</a> and <a rel='noopener noreferrer' target='_blank' href='/privacy_policy'>%{privacy_policy}</a>",
      "terms_of_service": "Terms of Service",
      "privacy_policy": "Privacy Policy",
      "confirmation_prompt": "Please confirm in order to proceed.",
      "welcome_message": "Welcome to Careteam!",
      "error_occurred": "An error occurred.",
      "required": "Required to use Careteam",
      "continue": "Continue"
    },
    "users": {
      "notification_form": {
        "header": "Notifications",
        "info": "Life is busy, and to make your life easier, by default, you'll be notified every time something gets posted to your Careteam. You can adjust your settings below.",
        "preference": "I prefer to receive notifications by:",
        "sms_label": "Text Message",
        "email_label": "Email",
        "save": "Save",
        "confirm": "Just to confirm, you have selected that you do NOT want to be notified by email or SMS. This puts the responsibility on you to regularly log in and check so you don't miss something important! Are you sure?",
        "success": "Changes have been saved successfully."
      },
      "schedule_form": {
        "morning": "Morning",
        "afternoon": "Afternoon",
        "evening": "Evening",
        "sunday": "Sunday",
        "monday": "Monday",
        "tuesday": "Tuesday",
        "wednesday": "Wednesday",
        "thursday": "Thursday",
        "friday": "Friday",
        "saturday": "Saturday",
        "address": "Your location",
        "save": "Save",
        "success": "Changes have been saved successfully.",
        "morning_hours": "8am - 12pm",
        "afternoon_hours": "12pm - 5pm",
        "evening_hours": "5pm - 9pm",
        "schedule": "Schedule availability"
      },
      "profiles_section": {
        "availability": "Availability",
        "volunteer_availability": "Volunteer availability",
        "notifications": "Notifications",
        "notification_preferences": "Notification preferences"
      }
    },
    "welcome_banner": {
      "activity_feed_banner": {
        "header": "View your team's activity",
        "details": "You can share important updates with your team, send encouraging messages, and get an overview of what has been going on here in your activity feed.",
        "button": "Got it!"
      },
      "care_plan_banner": {
        "self_signup_header": "Welcome to your Action Plan!",
        "basic_header": "This Action Plan has been initiated by %{placeName}",
        "self_signup_details": "Your Action Plan is a place to help you and your team plan and track your health.  To see any to-dos related to this Action Plan, check out your",
        "basic_details": "Check here for new tasks, appointments, documents, or notes from your health team. You can update these tasks and your own on your",
        "link_text": "Tasks page.",
        "button": "Got it!"
      },
      "patient_banner": {
        "header": "Track your health history",
        "details": "This is a good place to add information you want to remember or make the health and support teams aware of. This way, everyone can be on the same page!",
        "button": "Got it!"
      },
      "tasks_banner": {
        "header": "Your to-dos",
        "self_signup_details": "Your team's upcoming appointments and incomplete tasks will appear on this page. You can assign other members of your care team to tasks that you need help with.",
        "basic_details": "What tasks or appointments do you have coming up in the future? Add them here - your Support Team will see your tasks too, and can help you get them done (the Health Team will only see the ones that they created).",
        "button": "Got it!"
      },
      "my_tasks_banner": {
        "header": "Your to-dos",
        "basic_details": "This is your personal to-do list: Items you have entered for yourself or items that others have asked you to help with.",
        "button": "Got it!"
      },
      "health_team_banner": {
        "button": "Close",
        "details": "You are now part of %{patient_name}'s health team. You have read-only access to this Action plan from the %{originating_place}. You can view this Action Plan and create new Action Plans.",
        "header": "You've been successfully added to the %{care_plan_title} Action Plan for %{patient_name}!"
      },
      "uhcp_action_plan_index_banner": {
        "invited": "These people have invited you to join their care team. You can view the plan and update the status of items you are looking after.",
        "email": "For full access and to sign up your team, please email support@getcareteam.com"
      },
      "uhcp_action_plan_show_banner": {
        "hello": "Hi %{userName}!",
        "action_plan": "You've been added to %{patientName}'s Action Plan"
      },
      "uacg_care_plan_banner": {
        "banner_header": "Start exploring %{patient_name}'s Action Plan.",
        "banner_subheader": "Welcome to Careteam! Once the team at %{place} starts collaborating on this Action Plan, you'll get access to even more."
      }
    }
  },
  "es": {
    "activities": {
      "kind": {
        "generic": "Genérico",
        "medical": "Cita médica",
        "prescription": "Prescripción",
        "generic_health": "Tarea",
        "referral": "Derivación"
      },
      "assignments": {
        "assignment": {
          "accept": "Aceptar",
          "decline": "Rechazar",
          "assign_someone_else": "Asignar a otra persona",
          "what_would_you_do": "¿Qué desea hacer?",
          "let_anyone_claim": "Permitir que cualquiera lo reclame",
          "claim": "¡Lo haré yo!",
          "message": "Enviar mensaje",
          "help_requested": "Se ha solicitado un voluntario para ayudar con esta Tarea"
        },
        "claim_task": {
          "claim": "¡Lo haré yo!",
          "claim_task": "Al reclamar esta Tarea, usted acepta asumir la responsabilidad de asegurarse de que se complete"
        },
        "index": {
          "no_one_assigned": "Esta Tarea aún no ha sido asignada"
        },
        "my_assignment": {
          "me": "Yo",
          "will_you_accept": "¿Aceptará esta Tarea?",
          "accept": "¡Sí, lo haré!",
          "decline": "Lo siento, no puedo",
          "something_changed": "¿Algo ha cambiado?",
          "claim": "¡Lo haré yo!"
        }
      },
      "delete": "Eliminar",
      "edit_modal": {
        "edit": "Editar"
      }
    },
    "activity_feed": {
      "comments": {
        "comment_header": {
          "comment": {
            "one": "1 Comentario",
            "other": "%{count} Comentarios"
          },
          "hide": "Ocultar"
        },
        "form": {
          "text": "Comparta un comentario con su familia y amigos",
          "note": "Nota: sus comentarios no serán visibles para su proveedor de salud",
          "submit": "Publicar"
        }
      },
      "activity_header": {
        "appointment": "Creó una cita",
        "medicaldocument": "Subió un documento",
        "prescription": "Agregó una prescripción",
        "task": "Agregó una tarea",
        "status": "Publicó una actualización"
      },
      "appointment": {
        "when": "Cuándo: ",
        "with": "Con: ",
        "where": "Dónde: "
      },
      "assignments": {
        "assigned_to": "Asignado a: ",
        "pending": "Pendiente",
        "accepted": "Aceptado",
        "declined": "Rechazado",
        "open": "Abierto"
      },
      "index": {
        "welcome": "¡Publique un mensaje rápido para informar a todos que se ha unido!",
        "empty": "Sin resultados"
      },
      "prescription": {
        "status": "Estado: ",
        "filled": "Surtido",
        "to_be_filled": "Sin surtir"
      }
    },
    "activity_modal": {
      "add_appointment_title": "Agregar Cita",
      "add_check_in_title": "Agregar Control",
      "add_task_title": "Agregar Tarea",
      "add_form_title": "Agregar Formulario",
      "edit_appointment_title": "Editar Cita",
      "edit_task_title": "Editar Tarea",
      "description_field_title": "Descripción",
      "error_empty": "Obligatorio",
      "errors": {
        "api_error": "Error: Algo salió mal",
        "form_api_error": "Algo salió mal y no podemos agregar este formulario. Si el problema continúa, por favor envíe un correo a support@getcareteam.com."
      },
      "footer": {
        "delete_button": {
          "delete": "Eliminar",
          "confirm": "¿Confirmar?"
        },
        "cancel": "Cancelar",
        "save": "Guardar"
      },
      "form_fields": {
        "resources_list": {
          "unnamed_resource": "Recurso sin nombre"
        },
        "validators": {
          "error_empty": "Obligatorio",
          "invalid_link": "Enlace inválido",
          "missing_http": "Por favor agregue http:// o https:// al inicio de su enlace",
          "missing_check_in_template": "Por favor seleccione un tipo de Control de la biblioteca",
          "missing_form_template": "Por favor seleccione un Formulario de la biblioteca"
        },
        "what": {
          "error_empty": "Obligatorio",
          "description": "Qué:",
          "appointment_description": "Descripción de la cita",
          "task_description": "Descripción de la tarea",
          "form_description": "Nombre del formulario"
        },
        "who": {
          "title": "Asignar",
          "description": "Quién:",
          "error_contacts_load": "Error: No se pudieron cargar los contactos.",
          "open_button": "Asignar",
          "no_team_members": "No hay miembros del equipo para mostrar.",
          "notification_header": "Se notificará a %{patientFirstName} y sus cuidadores principales cuando se agreguen nuevas tareas, incluso si no están asignadas o están asignadas a otra persona."
        },
        "where": {
          "description": "Dónde:",
          "virtual_visit_link_description": "Enlace para visita virtual (Opcional)",
          "invalid_link": "Enlace inválido",
          "missing_http": "Por favor agregue http:// o https:// al inicio de su enlace",
          "address": {
            "description": "Dirección",
            "add_address": "Agregar dirección",
            "remove_address": "Eliminar dirección",
            "street_address": "Dirección",
            "extended_address": "Apartamento, suite, etc. (opcional)",
            "city": "Ciudad",
            "province": "Provincia",
            "state": "Estado",
            "postal_code": "Código postal",
            "zip_code": "Código postal",
            "postcode": "Código postal",
            "country": "País"
          }
        },
        "when": {
          "description": "Cuándo",
          "scheduled": "Programado",
          "not_scheduled": "Aún no programado",
          "days": "Día(s)",
          "weeks": "Semana(s)",
          "months": "Mes(es)",
          "repeats_every": "Se repite cada",
          "ends": "Termina:",
          "never": "Nunca",
          "on": "El",
          "after": "Después de",
          "times": "veces",
          "date_and_time": "Fecha y hora",
          "repeats": "Se repite"
        },
        "visibility": {
          "hcp_only": "Solo equipo de salud",
          "default": "Predeterminado",
          "everyone": "Todos",
          "who_should_see": "¿Quién puede ver este Control?"
        },
        "when_should_be_completed": {
          "check_in_label": "¿Cuándo debe completarse este Control?",
          "form_label": "¿Cuándo debe completarse este Formulario?",
          "task_label": "¿Cuándo debe completarse esta Tarea?",
          "appointment_label": "¿Cuándo debe completarse esta Cita?",
          "scheduled": "Programado - única vez o repetición",
          "any_time": "En cualquier momento",
          "to_be_booked": "Sin programar"
        },
        "templates": {
          "unnamed_appointment": "Cita sin nombre",
          "unnamed_task": "Tarea sin nombre",
          "unnamed_form": "Formulario sin nombre",
          "api_error": "Error: No se pudieron cargar las plantillas.",
          "no_templates": "No hay plantillas para mostrar.",
          "fill_using_a_template": "Completar usando una plantilla"
        },
        "select_from_library": {
          "unnamed_check_in": "Control sin nombre",
          "unnamed_form": "Formulario sin nombre",
          "api_error": "Error: No se pudo cargar la biblioteca.",
          "no_templates": "No hay elementos para mostrar.",
          "select_check_in": "Seleccionar Control de la biblioteca",
          "select_form": "Seleccionar Formulario de la biblioteca"
        },
        "resources": {
          "title": "Recursos",
          "api_error": "Error: No se pudieron cargar los recursos.",
          "links": "Enlaces:",
          "no_resources": "No hay recursos para mostrar.",
          "add_resources": "Agregar recursos"
        }
      }
    },
    "appointments": {
      "form": {
        "text": "Descripción",
        "practitioner": "Con",
        "virtual_visit": "Visita virtual URL",
        "assignments": "Asignar",
        "due_at": "Cuando",
        "cancel": "Cancelar",
        "submit": "Guardar"
      },
      "fields": {
        "description": "Descripción",
        "practitioner": "Con",
        "virtual_visit": "URL de visita virtual",
        "time": "Hora",
        "assignments": "Asignar",
        "cancel": "Cancelar",
        "optional": "(Opcional)",
        "completed_at": "La cita necesita ser programada",
        "save": "Guardar",
        "address": {
          "street_address": "Dirección",
          "extended_address": "Apartamento, suite, etc. (opcional)",
          "city": "Ciudad",
          "province": "Provincia",
          "state": "Estado",
          "postal_code": "Código postal",
          "zip_code": "Código postal",
          "postcode": "Código postal",
          "country": "País"
        },
        "validators": {
          "error_empty": "Obligatorio",
          "invalid_link": "Enlace inválido",
          "missing_http": "Por favor, añada http:// o https:// al inicio de su enlace"
        }
      },
      "edit_form": {
        "request_volunteer": "Solicitar voluntario",
        "link_resources": "Vincular recursos",
        "resources": "Recursos",
        "no_resources_present": "No hay recursos disponibles para añadir a esta cita",
        "cancel": "Cancelar",
        "save": "Guardar"
      },
      "actions_container": {
        "edit": "Editar"
      }
    },
    "aside": {
      "tooltips": {
        "close": "Cerrar"
      }
    },
    "base": {
      "action_headers": {
        "add": "Agregar",
        "cancel": "Cancelar",
        "edit": "Editar",
        "carehub_sync": "Campos que pueden sincronizarse con Care Hub"
      },
      "print_button": {
        "print": "Imprimir"
      },
      "error_occurred": "Ha ocurrido un error.",
      "invite_code": {
        "link": "Enlace"
      },
      "invite_qr_code": {
        "download": "Descargar",
        "copy": "Copiar",
        "copied": "¡Copiado!"
      },
      "radio_button_list": {
        "search": "Buscar...",
        "search_aria": "Buscar",
        "required": "Requerido"
      }
    },
    "blocks": {
      "no_data": "Sin datos",
      "days": {
        "Sun": "Dom",
        "Mon": "Lun",
        "Tue": "Mar",
        "Wed": "Mié",
        "Thu": "Jue",
        "Fri": "Vie",
        "Sat": "Sáb"
      },
      "index": {
        "fetch_error": "Ocurrió un error al recuperar los datos."
      },
      "recommendations": {
        "title": "Recomendaciones",
        "fetch_error": "Ocurrió un error al recuperar los datos.",
        "save_error": "Ocurrió un error al guardar los datos.",
        "show_older": "Mostrar recomendaciones anteriores",
        "hide_older": "Ocultar recomendaciones anteriores",
        "empty": "Ninguna para este Plan de Acción.",
        "created_by_with_date": "Creado por %{name} el %{created_at}",
        "updated_by_with_date": "Actualizado por %{name} el %{updated_at}",
        "edit": "Editar",
        "remove": "Eliminar",
        "save": "Guardar",
        "cancel": "Cancelar",
        "discard_changes": "Descartar cambios",
        "required": "Obligatorio",
        "confirm_remove_prompt": "¿Está seguro de que desea eliminar esta recomendación?",
        "confirm_remove": "Sí, eliminar",
        "cancel_remove": "No, mantener",
        "placeholder": "Ingrese su recomendación"
      },
      "symptom_intensity": {
        "title": "Intensidad de los síntomas",
        "fetch_error": "Ocurrió un error al recuperar los datos.",
        "categories": ["Muy baja", "Baja", "Media", "Alta", "Muy alta", "No ocurrió", "Sin datos"]
      },
      "symptom_occurrence": {
        "title": "Cuando ocurrieron los síntomas",
        "fetch_error": "Ocurrió un error al recuperar los datos.",
        "categories": ["Acción tomada", "Dolor de estómago", "Dolor de cabeza", "Dolor de espalda", "Mareos", "Dolor en las extremidades", "Latidos rápidos", "Náuseas", "Debilidad corporal"],
        "legend": ["Ocurrió", "No ocurrió", "Sin datos"]
      },
      "worry_intensity": {
        "title": "Qué tan preocupado estaba",
        "fetch_error": "Ocurrió un error al recuperar los datos.",
        "categories": ["Evitación", "Muy bajo", "Bajo", "Medio", "Alto", "Muy alto"]
      },
      "worried_about": {
        "title": "De qué estaba preocupado",
        "fetch_error": "Ocurrió un error al recuperar los datos.",
        "categories": ["Familia", "Amigos", "Desconocidos", "Escuela", "Deportes", "Salud"],
        "legend": ["Ocurrió", "No ocurrió", "Sin datos"]
      },
      "certainty_expectation": {
        "title": "Qué tan seguro estaba",
        "fetch_error": "Ocurrió un error al recuperar los datos.",
        "extremely": "Extremadamente",
        "not_at_all": "Para nada",
        "event_happened": "El evento ocurrió",
        "legend": ["Qué tan preocupado", "Qué tan seguro", "Sin datos"]
      },
      "expected_vs_reality": {
        "title": "Problemas: expectativa vs realidad",
        "extremely": "Extremadamente",
        "not_at_all": "Para nada",
        "fetch_error": "Ocurrió un error al recuperar los datos.",
        "legend": ["Mañana (esperado)", "Tarde (real)", "Noche (real)", "Sin datos"]
      },
      "going_to_school": {
        "title": "Asistencia a la escuela",
        "fetch_error": "Ocurrió un error al recuperar los datos.",
        "doctor": "Médico",
        "home_school": "Escuela en casa",
        "sick": "Enfermo",
        "online": "En línea",
        "holiday": "Día festivo",
        "vacation": "Vacaciones",
        "weekend": "Fin de semana",
        "attended": "Asistió",
        "chronic_pain": "Dolor crónico",
        "no_data": "Sin datos"
      },
      "friend_interaction": {
        "title": "Mi preocupación por interactuar con amigos",
        "fetch_error": "Ocurrió un error al recuperar los datos.",
        "extremely": "Extremadamente",
        "not_at_all": "Para nada",
        "legend": ["Mañana (esperado)", "Tarde (real)", "Noche (real)", "Sin datos"]
      },
      "friend_reaction": {
        "title": "Cómo me llevo con mis amigos",
        "fetch_error": "Ocurrió un error al recuperar los datos.",
        "legend": ["Mañana (esperado)", "Tarde (real)", "Noche (real)", "Sin datos"],
        "categories": ["Muy negativo", "Negativo", "Neutral", "Positivo", "Muy positivo", "Sin interacciones"]
      },
      "my_emotions": {
        "title": "Mis emociones",
        "subtitle": "Las líneas representan los niveles de emoción a lo largo del tiempo, mientras que las burbujas representan el resumen general",
        "fetch_error": "Ocurrió un error al recuperar los datos.",
        "extremely": "Extremadamente",
        "not_at_all": "Para nada",
        "worried_scared": "Preocupado/asustado",
        "happy": "Feliz",
        "miserable": "Miserable",
        "angry": "Enojado",
        "frustrated": "Frustrado"
      },
      "my_sleep": {
        "title": "Mi sueño",
        "fetch_error": "Ocurrió un error al recuperar los datos.",
        "hours": "Horas",
        "noon": "Mediodía",
        "midnight": "Medianoche",
        "am": "am",
        "pm": "pm",
        "legend": ["Pobre", "Regular", "Bueno", "Excelente", "Sin datos"]
      }
    },
    "care_plan_action_plan_page": {
      "activities_container": {
        "api_error": "Error: No se pudo cargar la información del usuario.",
        "unexpected_error": "Error: Ha ocurrido un error inesperado.",
        "appointments_card": {
          "api_error": "Error: No se pudieron cargar las citas.",
          "unexpected_error": "Error: Ha ocurrido un error inesperado.",
          "empty": "Ninguna para este Plan de Acción.",
          "title": "Citas",
          "appointment": {
            "booked": "Reservada",
            "not_booked": "No reservada",
            "edit": "Editar",
            "help_requested": "Se ha solicitado un voluntario para ayudar con esta tarea.",
            "unnamed_appointment": "Cita sin nombre"
          },
          "repeated_appointment": {
            "repeat_statement": "Se repite %{localizedFrequency} a las %{formattedTime} hasta el %{until}",
            "repeat_statement_infinity": "Se repite %{localizedFrequency} a las %{formattedTime}",
            "unnamed_appointment": "Cita sin nombre"
          }
        },
        "check_ins_card": {
          "api_error": "Error: No se pudieron cargar los registros.",
          "unexpected_error": "Error: Ha ocurrido un error inesperado.",
          "empty": "Ninguno para este Plan de Acción.",
          "title": "Registros",
          "check_in": {
            "completed": "Completado",
            "not_completed": "Incompleto",
            "edit": "Editar",
            "due_at_statement": "A las %{dueDate}",
            "unnamed_check_in": "Registro sin nombre"
          },
          "repeated_check_in": {
            "repeat_statement": "Se repite %{localizedFrequency} hasta el %{until}",
            "repeat_statement_infinity": "Se repite %{localizedFrequency}",
            "unnamed_check_in": "Registro sin nombre"
          }
        },
        "tasks_card": {
          "api_error": "Error: No se pudieron cargar las tareas.",
          "unexpected_error": "Error: Ha ocurrido un error inesperado.",
          "empty": "Ninguna para este Plan de Acción.",
          "title": "Tareas",
          "task": {
            "completed": "Completada",
            "not_completed": "Incompleta",
            "edit": "Editar",
            "due_at_statement": "A las %{dueDate}",
            "unnamed_task": "Tarea sin nombre"
          },
          "repeated_task": {
            "repeat_statement": "Se repite %{localizedFrequency} a las %{formattedTime} hasta el %{until}",
            "repeat_statement_infinity": "Se repite %{localizedFrequency} a las %{formattedTime}",
            "unnamed_task": "Tarea sin nombre"
          }
        },
        "forms_card": {
          "api_error": "Error: No se pudieron cargar los formularios.",
          "unexpected_error": "Error: Ha ocurrido un error inesperado.",
          "empty": "Ninguno para este Plan de Acción.",
          "title": "Formularios",
          "form": {
            "unnamed_form": "Formulario sin nombre",
            "completed": "Completado",
            "not_completed": "Incompleto",
            "edit": "Editar",
            "due_at_statement": "A las %{dueDate}"
          },
          "repeated_form": {
            "unnamed_form": "Formulario sin nombre",
            "repeat_statement": "Se repite %{localizedFrequency} a las %{formattedTime} hasta el %{until}",
            "repeat_statement_infinity": "Se repite %{localizedFrequency} a las %{formattedTime}"
          }
        }
      },
      "activity_modal": {
        "add_appointment_title": "Add appointment",
        "description_field_title": "Description",
        "error_empty": "Requerido",
        "create": {
          "api_error": "Error: Algo salió mal",
          "footer": {
            "cancel": "Cancelar",
            "save": "Salvar",
            "delete_button": {
              "delete": "Borrar",
              "confirm": "¿Confirmar?"
            }
          },
          "form_api_error": "Algo ha ido mal y no podemos añadir este formulario. Si el problema persiste, envíe un correo electrónico a support@getcareteam.com."
        },
        "form_fields": {
          "validators": {
            "error_empty": "Requerido",
            "invalid_link": "Enlace no válido",
            "missing_http": "Añada http:// o https:// al principio de su enlace.",
            "missing_check_in_template": "Seleccione el tipo de registro en la biblioteca",
            "missing_form_template": "Seleccione un formulario de la biblioteca"
          },
          "what": {
            "error_empty": "Requerido",
            "description": "Qué:",
            "appointment_description": "Descripción de la cita",
            "task_description": "Descripción de la tarea",
            "form_description": "Nombre del formulario"
          },
          "who": {
            "description": "Quién:",
            "error_contacts_load": "Error: No se han podido cargar los contactos.",
            "open_button": "Assign",
            "no_team_members": "No hay miembros del equipo para mostrar.",
            "notification_header": "%{patientFirstName} y su contacto del equipo de asistencia recibirán una notificación cuando se añada una nueva tarea, aunque no esté asignada o se haya asignado a otra persona.",
            "title": "Assign"
          },
          "where": {
            "description": "Dónde:",
            "virtual_visit_link_description": "Enlace de visita virtual (Opcional)",
            "invalid_link": "Enlace no válido",
            "missing_http": "Añada http:// o https:// al principio de su enlace.",
            "address": {
              "description": "Dirección",
              "add_address": "Añadir dirección",
              "remove_address": "Eliminar dirección",
              "street": "Dirección",
              "city": "Ciudad",
              "region": "Región",
              "postal_code": "Código postal",
              "country_code": "Código del país",
              "street_address": "Dirección de la calle",
              "extended_address": "Apartamento, suite, etc. (opcional)",
              "province": "Provincia",
              "state": "Estado",
              "zip_code": "Código postal",
              "postcode": "Código postal",
              "country": "Country"
            }
          },
          "when": {
            "description": "Cuando",
            "scheduled": "Programado",
            "not_scheduled": "Aún no programado",
            "days": "Día(s)",
            "weeks": "Semana(s)",
            "months": "Mes(es)",
            "repeats_every": "Se repite cada",
            "ends": "Fin:",
            "never": "Nunca",
            "on": "En",
            "after": "Después",
            "times": "veces",
            "date_and_time": "Fecha y hora",
            "repeats": "Repite"
          },
          "templates": {
            "api_error": "Error: No se han podido cargar las plantillas.",
            "no_templates": "No hay plantillas para mostrar.",
            "fill_using_a_template": "Rellenar con una plantilla",
            "unnamed_appointment": "Nombramiento anónimo",
            "unnamed_task": "Tarea sin nombre",
            "unnamed_form": "Forma sin nombre"
          },
          "resources": {
            "api_error": "Error: No se han podido cargar los recursos.",
            "links": "Enlaces:",
            "no_resources": "No hay recursos para mostrar.",
            "add_resources": "Añadir recursos",
            "title": "Recursos"
          },
          "when_should_be_completed": {
            "check_in_label": "¿Cuándo debe realizarse el registro?",
            "form_label": "¿Cuándo debe cumplimentarse este formulario?",
            "task_label": "¿Cuándo debe completarse esta Tarea?",
            "scheduled": "Programado - una vez o repetido",
            "any_time": "En cualquier momento",
            "appointment_label": "¿Cuándo debe completarse esta Cita?",
            "to_be_booked": "Para ser reservado"
          },
          "select_from_library": {
            "api_error": "Error: No se pudo cargar la biblioteca.",
            "no_templates": "Nada que mostrar.",
            "select_check_in": "Seleccione Registro en la biblioteca",
            "unnamed_check_in": "Registro sin nombre",
            "unnamed_form": "Forma sin nombre",
            "select_form": "Seleccionar formulario de la biblioteca"
          },
          "resources_list": {
            "unnamed_resource": "Recurso sin nombre"
          },
          "visibility": {
            "hcp_only": "Sólo equipo sanitario",
            "default": "Por defecto",
            "everyone": "Todo el mundo",
            "who_should_see": "¿Quién puede ver este Check-in?"
          }
        },
        "add_check_in_title": "Agregar Registro",
        "add_task_title": "Agregar tarea",
        "add_form_title": "Añadir formulario",
        "edit_appointment_title": "Editar cita",
        "edit_task_title": "Editar tarea"
      }
    },
    "care_plan_creators": {
      "name": {
        "title_label": "Nombre de la plantilla del Plan de Acción",
        "title_sublabel": "Este nombre se utilizará internamente para organizar las distintas plantillas disponibles.",
        "title_placeholder": "Ejemplos: \"Demencia leve - zona de Ottawa - Español\"",
        "next": "Siguiente"
      },
      "library_task_templates": {
        "form": {
          "title_label": "* Nombre de la plantilla de tarea",
          "title_sublabel": "Este nombre se utilizará internamente para organizar las distintas plantillas disponibles.",
          "title_placeholder": "Ejemplo: \"Plantilla de tarea previa a la cita\"",
          "description_label": "* Descripción predeterminada de la tarea",
          "description_sublabel": "Esta es la descripción que tendrá la tarea si no es editada por el profesional.",
          "recurrence_label": "Programar tarea",
          "recurrence_sublabel": "Esto es opcional, puede programar la tarea ahora o dejar que el profesional lo haga cuando se cree el Plan de Acción.",
          "next": "Siguiente",
          "link_resources": "Vincular recursos",
          "resources": "Recursos"
        }
      },
      "library_appointment_templates": {
        "form": {
          "address": "Dirección",
          "city": "Ciudad",
          "region": "Región",
          "postal_code": "Código postal",
          "country_code": "Código de país",
          "practitioner": "Con",
          "virtual_visit": "URL de visita virtual",
          "title_label": "* Nombre de la plantilla de cita",
          "title_sublabel": "Este nombre se utilizará internamente para organizar las distintas plantillas disponibles.",
          "title_placeholder": "Ejemplo: \"Seguimiento de oncología a 6 meses\"",
          "description_label": "* Descripción predeterminada de la cita",
          "description_sublabel": "Esta es la descripción que tendrá la cita si no es editada por el profesional.",
          "recurrence_label": "Programar cita",
          "recurrence_sublabel": "Esto es opcional, puede programar la cita ahora o dejar que el profesional lo haga cuando se cree el Plan de Acción.",
          "next": "Siguiente",
          "link_resources": "Vincular recursos",
          "resources": "Recursos",
          "street_address": "Dirección",
          "extended_address": "Apartamento, suite, etc. (opcional)",
          "province": "Provincia",
          "state": "Estado",
          "zip_code": "Código postal",
          "postcode": "Código postal",
          "country": "País"
        }
      },
      "check_in_templates": {
        "name": {
          "title_label": "Nombre de la plantilla de registro",
          "title_sublabel": "Este nombre se utilizará internamente para organizar las distintas plantillas disponibles.",
          "title_placeholder": "Ejemplo: \"Registro de escala de dolor del valle de Ottawa\"",
          "next": "Siguiente"
        },
        "questions": {
          "question_type": "Tipo de pregunta",
          "blank": "--Seleccionar--",
          "pain_scale_5": "Escala de dolor 5",
          "generic_scale_5": "Escala genérica 1-5",
          "generic_scale_10": "Escala genérica 1-10",
          "numeric_input": "Entrada numérica",
          "yes_no": "Sí No",
          "mood_scale_10": "Escala de estado de ánimo 10",
          "multiple_choice": "Opción múltiple",
          "free_text": "Texto libre",
          "title_label": "Título de la pregunta",
          "response_label": "Respuesta a la pregunta",
          "conditional_response": "Agregar confirmación de respuesta condicional",
          "default_response": "Gracias %name. Su registro ha sido guardado.",
          "units": "Unidades",
          "upper_limit": "Límite superior",
          "lower_limit": "Límite inferior",
          "add_response": "Agregar otra opción de respuesta",
          "confirmation": "¿Está seguro de que desea eliminar esta respuesta?",
          "delete_response": "Eliminar respuesta",
          "add_question": "Agregar otra pregunta",
          "save_question": "Guardar pregunta",
          "delete": "Eliminar",
          "cancel": "Cancelar",
          "next": "Siguiente",
          "edit": "Editar",
          "placeholders": {
            "generic_scale_description": "Descripción",
            "generic_scale_text": "Texto",
            "pain_scale_description": "Descripción",
            "custom_response": "Texto de respuesta",
            "pain_scale_text": "Texto",
            "numeric_input_units": "ej. \"Lbs\" o \"Kgs\"",
            "numeric_input_upper_title": "Los límites son opcionales, dejar en blanco si no se necesitan límites",
            "numeric_input_upper_limit": "ej. \"400\"",
            "numeric_input_lower_limit": "ej. \"0\"",
            "mood_scale_description": "Descripción",
            "mood_scale_text": "Texto",
            "free_text_description": "Descripción",
            "free_text_text": "Texto",
            "multiple_choice_response": "Texto de respuesta",
            "multiple_choice_description": "Descripción larga (opcional)",
            "multiple_choice_text": "Descripción corta"
          },
          "generic_scale_11": "Escala genérica 0-10",
          "generic_scale_0_100": "Escala genérica 0-100"
        },
        "visibility": {
          "who_can_see": "¿Quién puede ver este registro?",
          "everyone": "Todos",
          "hcp_only": "Solo equipo de salud",
          "will_not_affect": "Cambiar esta visibilidad no afectará los registros ya añadidos a Planes de Acción individuales.",
          "next": "Siguiente",
          "api_error": "Algo salió mal. Por favor, inténtelo de nuevo."
        }
      },
      "details": {
        "next": "Siguiente",
        "skip": "Omitir",
        "details_form": {
          "title_label": "Nombrar el Plan de Acción",
          "title_sublabel": "Dele al Plan de Acción un nombre que todos los miembros del equipo reconozcan. Intente usar palabras que sean familiares para la persona y su equipo de apoyo.",
          "list_label": "Preocupaciones para este Plan de Acción:",
          "empty_list_none_added": "No se han añadido preocupaciones para este Plan de Acción.<br/> Haga clic en 'Agregar preocupación' para comenzar.",
          "diagnosis_placeholder": "Ingrese descripción de la preocupación...",
          "title_placeholder": "Ejemplos: \"Memoria\" o \"Bienestar\" o \"Vencer el cáncer\"",
          "add_diagnosis": "Agregar preocupación",
          "edit": "Editar",
          "date": "Fecha de inicio del Plan de Acción: %{startAt}",
          "date_update_label": "Cambiar la fecha de inicio aplicará automáticamente el mismo cambio a todas las fechas programadas en el plan.",
          "done": "Listo",
          "apply": "Aplicar cambio",
          "date_modal_header": "Cambiar fecha de inicio del Plan de Acción",
          "revert_to_default_label": "Volver a la fecha original: %{date}"
        }
      },
      "careteam": {
        "patient_form": {
          "header": "Invitar a %{name}",
          "invite": "Enviar invitación",
          "sublabel": "Ingrese un correo electrónico para invitarle al Plan de Acción (si aplica)",
          "placeholder": "Ingrese correo electrónico...",
          "email": "Correo electrónico",
          "skip": "Omitir",
          "save": "Guardar",
          "edit": "Editar",
          "add": "+ Agregar",
          "hint": "Si la persona para quien se creó el Plan de Acción no accederá a Careteam por sí misma, deje este campo en blanco e invite a los cuidadores a continuación.",
          "header_new": "Invitar %{name}",
          "submit_error": "Lo sentimos, no pudimos enviar el correo. Por favor, inténtelo de nuevo o contáctenos."
        },
        "primary_caregiver_form": {
          "sublabel": "Invitar a un Cuidador Principal asegura que %{firstName} tenga mejor apoyo. Usualmente es su cuidador principal o alguien capaz de co-pilotear su cuidado. Tendrán acceso completo a este Plan de Acción igual que %{firstName}. Los Cuidadores Principales pueden agregarse más tarde.",
          "header": "Invitar a un Cuidador Principal",
          "email": "Correo electrónico",
          "empty_list": "No se han agregado Cuidadores Principales aún. <br/>Haga clic en 'Agregar Cuidador Principal' para comenzar.",
          "relationship": "Relación",
          "skip": "Omitir",
          "save": "Guardar",
          "next": "Siguiente",
          "edit": "Editar",
          "add": "Invitar a un Cuidador Principal",
          "permission": "Tengo permiso para permitir que esta persona acceda a la información de %{firstName} y actúe en su nombre a través de Careteam",
          "remove": "Eliminar",
          "first_name": "Nombre",
          "last_name": "Apellido",
          "phone": "Teléfono",
          "sdm_type": {
            "assign_button": "Asignar a esta persona como Tomador de Decisiones Sustituto de %{patient_first_name}",
            "label": "Tomador de Decisiones Sustituto",
            "placeholder": "Seleccione un tipo...",
            "substitute_decision_maker": "Tomador de Decisiones Sustituto",
            "poa_personal_care": "Poder Notarial para Cuidado Personal",
            "guardian_trustee": "Tutor/Fideicomisario"
          },
          "sdm_confirmation": {
            "substitute_decision_maker": "He confirmado su estado como Tomador de Decisiones Sustituto",
            "poa_personal_care": "He confirmado su estado de Poder Notarial, incluyendo la documentación necesaria",
            "guardian_trustee": "He confirmado su estado de tutor/fideicomisario"
          }
        },
        "support_input": {
          "email": "Correo electrónico"
        },
        "supports_form": {
          "header": "Invitar personas a unirse como Equipo de Apoyo Extendido - Contacto",
          "email": "Correo electrónico",
          "skip": "Omitir",
          "save": "Guardar",
          "edit": "Editar",
          "add": "+ Agregar"
        },
        "index": {
          "subtitle": "Agregar a %{name}, un Cuidador Principal, o ambos a este Plan de Acción"
        }
      },
      "documents": {
        "library_documents": "Documentos de biblioteca:",
        "uploaded_documents": "Documentos subidos:",
        "upload_documents": "Subir documentos",
        "file_name": "Nombre del archivo:",
        "file_size": "Tamaño del archivo:",
        "created": "Creado:",
        "updated": "Actualizado:",
        "close": "Cerrar",
        "download": "Descargar",
        "document_preview": "Documento: %{name}",
        "uploaded_document_list": {
          "upload_document": "Subir documento",
          "edit_document": "Editar documento",
          "no_files_uploaded": "No se han subido archivos a este Plan de Acción. <br/>Haga clic en 'Subir documentos' para comenzar."
        }
      },
      "instructions": {
        "subheader": "¿Desea proporcionar otras instrucciones o información para la persona y su equipo de apoyo?",
        "label": "Instrucciones"
      },
      "resources": {
        "from_library": "De la biblioteca",
        "create_new": "Crear nuevo",
        "done": "Listo",
        "next": "Siguiente",
        "cancel": "Cancelar",
        "search_placeholder": "Buscar recursos...",
        "all_resources": "Todos los recursos",
        "resources": "Recursos",
        "service_kinds": {
          "information": "Información",
          "other": "Otro",
          "people": "Personas",
          "practitioner": "Proveedores de salud",
          "service": "Servicios"
        },
        "no_resources_present": "No hay recursos disponibles para agregar a este Plan de Acción",
        "select_all": "Seleccionar todo",
        "show_only_selected": "Mostrar solo elementos seleccionados",
        "add_resource": "Agregar recurso",
        "modal_form_title": {
          "create": "Agregar recurso",
          "update": "Actualizar recurso"
        },
        "form": {
          "placeholder": {
            "name": "Ejemplos: \"Comidas a domicilio\" o \"Laboratorios Médicos del Valle\"",
            "description": "Agregue una breve descripción de este recurso...",
            "tag": "Agregar una etiqueta...",
            "url": "http://www.ejemplo.com",
            "number": "Número de teléfono",
            "kind": "Elija un tipo de recurso",
            "category": "Ej. Programas de fitness",
            "subcategory": "Ej. Aqua fitness"
          },
          "name": "Nombre",
          "description": "Descripción",
          "tag": "Etiquetas",
          "kinds": {
            "information": "Información",
            "other": "Otro",
            "people": "Personas",
            "practitioner": "Proveedores de salud",
            "service": "Servicios"
          },
          "url": "Sitio web",
          "referralLink": "Enlace de remisión",
          "referralLinkLabel": "Etiqueta de enlace de referencia",
          "number": "Número",
          "address": "Dirección",
          "update": "Actualizar recurso",
          "add": "Agregar recurso",
          "cancel": "Cancelar",
          "kind": "Tipo",
          "linkReferral": "Vincular una referencia",
          "removeReferral": "Eliminar referencia",
          "referralDescription1a": "Para vincular una referencia, ingrese la Categoría y Subcategoría de",
          "referralDescription1b": "el sitio web de Caredove.",
          "referralDescription2": "Por favor, ingrese el texto exactamente como se muestra en Caredove.",
          "referralCategoryLabel": "Categoría",
          "referralSubcategoryLabel": "Subcategoría (opcional)",
          "referralLabel": "Etiqueta",
          "linkedReferralDescription": "Su referencia vinculada se mostrará como: ",
          "missingReferralLabel": "(ingrese una etiqueta)"
        },
        "search": {
          "label": "Encontrar un recurso",
          "search_placeholder": "Buscar recursos por título o etiqueta"
        }
      },
      "new": {
        "dd": "DD",
        "mm": "MM",
        "yyyy": "AAAA"
      },
      "tasks": {
        "index": {
          "empty_html": "No se han agregado actividades para este Plan de Acción<br/> Haga clic en 'Agregar actividad' para comenzar.",
          "add_activity": "Agregar actividad",
          "none": "Seleccionar una tarea",
          "cancel": "Cancelar",
          "submit": "Agregar actividad",
          "link_resources": "Vincular recursos a la tarea",
          "referral_header": "Agregar referencia"
        }
      },
      "repeated_options": {
        "after": "Después",
        "blank": "--Seleccionar--",
        "end_never": "Nunca",
        "end_on_a_day": "En un día",
        "once": "Una vez - en cualquier momento",
        "scheduled": "Una vez - programado",
        "recurring": "Recurrente",
        "daily": "Diario",
        "weekly": "Semanal",
        "monthly": "Mensual"
      },
      "forms": {
        "index": {
          "add_form": "Agregar formulario",
          "empty_html": "No se han agregado formularios para este Plan de Acción.<br/> Haga clic en 'Agregar formulario' para comenzar."
        }
      },
      "check_ins": {
        "index": {
          "empty_html": "No se han agregado registros para este Plan de Acción<br/> Haga clic en 'Agregar registro' para comenzar.",
          "add_check_in": "Agregar registro",
          "none": "Seleccionar un registro",
          "cancel": "Cancelar",
          "submit": "Agregar registro",
          "invalid": "es inválido",
          "no_templates_available": "No hay plantillas de registro disponibles."
        },
        "form": {
          "check_in_type": "Tipo de registro",
          "blank": "--Seleccionar--",
          "once": "Una vez - en cualquier momento",
          "scheduled": "Una vez - programado",
          "recurring": "Recurrente",
          "days_offset": "Fecha programada",
          "due_at": "Fecha y hora de inicio",
          "days_offset_text": "después de la fecha de inicio del Plan de Acción",
          "every": "Cada",
          "repeats": "Repite",
          "end_repeat": "Fin de la repetición",
          "end_date": "Fecha de finalización",
          "end_never": "Nunca",
          "after": "Después",
          "end_on_a_day": "En un día",
          "daily": "Diario",
          "weekly": "Semanal",
          "monthly": "Mensual",
          "submit": "Enviar registro",
          "count": {
            "one": "vez",
            "other": "veces"
          },
          "visibility": "¿Quién puede ver este registro?",
          "everyone": "Todos",
          "health_team_only": "Solo equipo de salud"
        }
      },
      "section_footer": {
        "skip": "Omitir",
        "next": "Siguiente"
      },
      "pill_actions": {
        "document-download": "Descargar",
        "file-edit": "Editar",
        "external-link": "Ver",
        "minus-circle": "Eliminar",
        "file-edit-blue": "Editar",
        "minus-circle-blue": "Eliminar"
      }
    },
    "care_plan_place_invitations": {
      "index": {
        "sent_at": "Invitación enviada: %{sent_at}",
        "pending_header": "Invitaciones Pendientes",
        "reviewed_header": "Invitaciones Anteriores",
        "subheader": "Esta es su lista de invitaciones de otras organizaciones para unirse al Plan de Acción de una persona. Esto suele ocurrir cuando usted está colaborando en el cuidado de una persona o cuando la persona está realizando una transición entre diferentes entornos de atención. Puede aceptar o rechazar cada invitación.",
        "status": {
          "pending": "Pendiente",
          "accepted": "Aceptada",
          "declined": "Rechazada",
          "all": "Todos"
        },
        "invitations": "invitaciones",
        "signups": "registros",
        "status_input": {
          "all": "Mostrar todos los estados",
          "pending": "Mostrar pendientes",
          "accepted": "Mostrar aceptadas",
          "declined": "Mostrar rechazadas"
        },
        "search_input": {
          "place_holder": "Buscar por personas en el Plan de Acción"
        },
        "external_team_invitations": "Invitaciones de equipos externos",
        "patient_signups": "Registros de pacientes",
        "support_team_contact_signups": "Registros de Cuidadores Principales"
      },
      "signups_container": {
        "target_signup_modal": {
          "title_hcp": "Invitación de equipo externo",
          "title_patient": "Solicitud de registro de paciente",
          "title_caregiver": "Solicitud de Cuidador Principal",
          "header": "%{caregiverName} se ha registrado por sí mismo como Contacto del Equipo de Apoyo de %{patientName}",
          "warning_accepted": "Esta solicitud fue aceptada el %{resolvedAt}",
          "warning_declined": "Esta solicitud fue rechazada el %{resolvedAt}",
          "personal_details": {
            "support_team_contact": "Contacto del equipo de asistencia",
            "person": "Persona",
            "action_plan": "Plan de Acción",
            "request_date": "Fecha de Solicitud",
            "title_caregiver": "Cuidador Principal",
            "title_hcp": "Invitado por"
          },
          "legal_warning": {
            "title": "Al aceptar, usted",
            "warning_point_1": "Confirme que esta solicitud procede de %{patientName}, miembro del equipo de asistencia de %{caregiverName}.",
            "warning_point_2": "Confirme que tiene el consentimiento de %{patientName} para que %{caregiverName} utilice Careteam para: acceder a la información personal de %{patientName}, invitar a otras personas a unirse a su equipo y comunicarse en su nombre con otras personas.",
            "warning_point_3": "Acepta colaborar en este Plan de Acción en nombre de %{placeName}.",
            "warning_caregiver_point_1": "Confirma que esta solicitud proviene del Cuidador %{caregiverName} de %{patientName}",
            "warning_caregiver_point_2": "Confirma que tiene el consentimiento de %{patientName} para que %{caregiverName} use Careteam para: acceder a la Información Personal de %{patientName}, invitar a otros a unirse a su equipo y comunicarse en su nombre con otros",
            "warning_caregiver_point_3": "Acepta colaborar en este Plan de Acción en nombre de %{placeName}",
            "warning_hcp_point_1": "Confirma que esta solicitud proviene de %{invitedBy}",
            "warning_hcp_point_2": "Acepta colaborar en este Plan de Acción en nombre de %{placeName}",
            "warning_patient_point_1": "Confirma que esta solicitud proviene de %{patientName}",
            "warning_patient_point_2": "Acepta colaborar en este Plan de Acción en nombre de %{placeName}"
          },
          "api_success": "Actualizado exitosamente",
          "action_buttons": {
            "view_action_plan": "Ver Plan de Acción",
            "decline": "Rechazar",
            "done": "Hecho",
            "accept": "Aceptar"
          },
          "row_headers": {
            "patient": "Paciente",
            "health_care_provider": "Proveedor de Atención Médica",
            "support_team_contact": "Cuidador Principal",
            "submitted": "Enviado",
            "resolved": "Resuelto",
            "status": "Estado"
          },
          "header_caregiver": "%{caregiverName} se ha registrado como Cuidador Principal de %{patientName}",
          "header_patient": "%{patientName} se ha registrado como paciente",
          "header_hcp": "%{placeName} ha solicitado colaborar en este plan de acción",
          "warning_accepted_by": "Esta solicitud fue aceptada por %{resolvedBy} el %{resolvedAt}",
          "warning_declined_by": "Esta solicitud fue rechazada por %{resolvedBy} el %{resolvedAt}"
        }
      }
    },
    "care_plans": {
      "export_modal": {
        "header": "Exportar Plan de Acción a PDF",
        "body": "Esta acción exportará una copia PDF no encriptada de este Plan de Acción con los documentos relevantes y quedará registrada en el registro de auditoría. El archivo .zip resultante contendrá información personal de salud (PHI) confidencial que estará fuera de la plataforma Careteam, sujeta a las políticas de su organización para la protección de PHI.",
        "cancel": "Cancelar",
        "export": "Exportar"
      },
      "document_section": {
        "edit_header": "Editar documentos",
        "show_header": "Documentos",
        "show": {
          "no_documents": "Aún no se han subido documentos a su Plan de Acción."
        },
        "edit": {
          "library": "Biblioteca",
          "uploaded": "Subidos",
          "upload_documents": "Subir documentos",
          "done": "Finalizar",
          "library_documents": {
            "subheader": "Seleccione documentos de la biblioteca Careteam relevantes para su Plan de Acción o suba documentos utilizando el botón de abajo.",
            "selected_section_header": "Documentos seleccionados de la biblioteca",
            "unselected_section_header": "Documentos de la biblioteca:",
            "selection_empty": "No se han seleccionado documentos de la biblioteca",
            "library_empty": "Aún no se han subido documentos a la biblioteca.",
            "all_selected": "Ha seleccionado todos los documentos disponibles en la biblioteca",
            "no_search_match": "No se encontraron documentos",
            "search_placeholder": "Buscar documentos..."
          },
          "uploaded_documents": {
            "subheader": "Haga clic en 'Subir documentos' abajo para añadir más documentos a este Plan de Acción.",
            "list_header": "Documentos subidos",
            "upload_document": "Subir documento",
            "edit_document": "Editar documento",
            "document_list": {
              "no_files_uploaded": "No se han subido archivos a este Plan de Acción."
            }
          }
        }
      },
      "goal_section": {
        "index": {
          "header": "Objetivos personales",
          "show_all": "Mostrar todos los objetivos",
          "hide_completed": "Ocultar objetivos completados",
          "empty": "Aún no ha creado ningún objetivo para su cuidado. ¡Piense en algo alcanzable que le gustaría lograr con la ayuda de su equipo y compártalo aquí!",
          "empty_hca": "No se han añadido objetivos aún."
        },
        "goal": {
          "achieved": "Logrado el %{datetime}",
          "complete": "Completado",
          "incomplete": "Incompleto",
          "edit": "Editar"
        },
        "goal_form": {
          "title": "Título",
          "description": "Descripción",
          "photo": "Foto",
          "submit": "Añadir objetivo",
          "upload": "Subir foto",
          "optional": "(opcional)",
          "update": "Actualizar foto",
          "default_alt": "Foto"
        }
      },
      "generic_order_section": {
        "index": {
          "header": "Tareas",
          "add_header": "Añadir tarea",
          "submit": "Añadir",
          "cancel": "Cancelar",
          "empty": "Ninguna para este Plan de Acción.",
          "link_resources": "Vincular recursos a la tarea",
          "resources": "Recursos"
        },
        "generic_order": {
          "complete": "Completada",
          "incomplete": "Incompleta",
          "edit": "Editar",
          "no_help_request": "Las tareas recurrentes no pueden tener solicitudes de ayuda",
          "help_requested": "Se ha solicitado un voluntario para ayudar con esta tarea",
          "view_details": "Ver detalles",
          "request_volunteer": "Solicitar voluntario",
          "resources": "Recursos",
          "link_resources": "Vincular recursos",
          "repeated_task": {
            "repeat_statement": "Se repite %{localizedFrequency} a las %{formattedTime} hasta %{until}",
            "repeat_statement_infinity": "Se repite %{localizedFrequency} a las %{formattedTime}"
          }
        },
        "generic_order_form": {
          "assignments": "Asignar",
          "text": "Descripción",
          "add_request": "Solicitar voluntario",
          "edit_request": "Editar solicitud de voluntario",
          "resources": "Recursos",
          "recurring": "Recurrente",
          "due_at": "Fecha y hora de inicio",
          "repeats": "Se repite",
          "every": "Cada",
          "end_repeat": "Finalizar repetición",
          "after": "Después de",
          "end_date": "Fecha de finalización",
          "task_template": "Elegir tarea de la biblioteca",
          "select_task_template": "Buscar una plantilla de tarea",
          "count": {
            "one": "vez",
            "other": "veces"
          },
          "start_new_task": "o iniciar nueva tarea",
          "assignments_header": "%{name} y sus Cuidadores Principales serán notificados cuando se añadan nuevas Tareas, incluso si no están asignadas o están asignadas a otra persona.",
          "optional": "(Opcional)"
        }
      },
      "medical_order_section": {
        "index": {
          "header": "Citas",
          "add_header": "Añadir cita",
          "submit": "Añadir",
          "cancel": "Cancelar",
          "empty": "Ninguna para este Plan de Acción.",
          "link_resources": "Vincular recursos"
        },
        "medical_order": {
          "booked": "Reservada",
          "not_booked": "No reservada",
          "edit": "Editar",
          "help_requested": "Se ha solicitado un voluntario para ayudar con esta tarea.",
          "view_details": "Ver detalles"
        },
        "medical_order_form": {
          "assignments": "Asignar",
          "practitioner": "Con",
          "virtual_visit": "URL de visita virtual",
          "text": "Descripción",
          "completed_at": "La cita necesita ser reservada",
          "due_at": "Programar para",
          "address": "Dirección",
          "city": "Ciudad",
          "region": "Región",
          "postal_code": "Código postal",
          "country_code": "Código de país",
          "save": "Guardar",
          "cancel": "Cancelar",
          "request_volunteer": "Solicitar voluntario",
          "time": "Hora",
          "resources": "Recursos",
          "choose_appointment_template": "Elegir cita de la biblioteca",
          "select_appointment_template": "Buscar una plantilla de cita",
          "start_new_appointment": "o iniciar nueva cita",
          "assignments_header": "%{name} y sus Cuidadores Principales serán notificados cuando se añadan nuevas Tareas, incluso si no están asignadas o están asignadas a otra persona.",
          "optional": "(Opcional)"
        }
      },
      "form_section": {
        "index": {
          "header": "Formularios",
          "submit": "Añadir",
          "empty": "Ninguno para este Plan de Acción.",
          "invalid": "no es válido"
        },
        "modal": {
          "form_title": "¿Cómo desea llamar a este formulario?",
          "form_type": "Seleccionar formulario de la biblioteca",
          "header": "Añadir formulario",
          "submit": "Añadir formulario",
          "cancel": "Cancelar",
          "check_in_type": "Tipo de formulario",
          "blank": "--Seleccionar--",
          "once": "Una vez - cualquier momento",
          "scheduled": "Una vez - programado",
          "recurring": "¿Cuándo debe completarse?",
          "due_at": "Fecha y hora de inicio",
          "every": "Cada",
          "repeats": "Se repite",
          "end_repeat": "Finalizar repetición",
          "end_date": "Fecha de finalización",
          "end_never": "Nunca",
          "after": "Después de",
          "end_on_a_day": "En una fecha",
          "daily": "Diariamente",
          "weekly": "Semanalmente",
          "monthly": "Mensualmente",
          "count": {
            "one": "vez",
            "other": "veces"
          },
          "days_offset": "Fecha programada",
          "days_offset_text": "después de la fecha de inicio del Plan de Acción",
          "time_offset": "Hora"
        },
        "form_order": {
          "completed": "Completado",
          "not_completed": "Incompleto",
          "edit": "Editar",
          "repeat_statement": "Se repite %{localizedFrequency} hasta %{until}",
          "repeat_statement_infinity": "Se repite %{localizedFrequency}",
          "due_at_statement": "A las %{dueDate}"
        }
      },
      "checkin_section": {
        "index": {
          "header": "Registros",
          "submit": "Añadir",
          "empty": "Ninguno para este Plan de Acción.",
          "invalid": "no es válido"
        },
        "checkin_order": {
          "completed": "Completado",
          "not_completed": "Incompleto",
          "edit": "Editar",
          "repeat_statement": "Se repite %{localizedFrequency} hasta %{until}",
          "repeat_statement_infinity": "Se repite %{localizedFrequency}",
          "due_at_statement": "A las %{dueDate}"
        },
        "modal": {
          "header": "Crear registro",
          "submit": "Añadir registro",
          "cancel": "Cancelar",
          "check_in_type": "Tipo de registro",
          "blank": "--Seleccionar--",
          "once": "Una vez - cualquier momento",
          "scheduled": "Una vez - programado",
          "recurring": "Recurrente",
          "due_at": "Fecha y hora de inicio",
          "every": "Cada",
          "repeats": "Se repite",
          "end_repeat": "Finalizar repetición",
          "end_date": "Fecha de finalización",
          "end_never": "Nunca",
          "after": "Después de",
          "end_on_a_day": "En una fecha",
          "daily": "Diariamente",
          "weekly": "Semanalmente",
          "monthly": "Mensualmente",
          "count": {
            "one": "vez",
            "other": "veces"
          }
        }
      },
      "prescription_section": {
        "index": {
          "header": "Prescripciones",
          "submit": "Añadir",
          "empty": "No hay prescripciones para este Plan de Acción."
        },
        "prescription": {
          "filled": "Surtida",
          "not_filled": "No surtida",
          "edit": "Editar"
        },
        "prescription_form": {
          "medication": "Medicamento",
          "completed_at": "El paciente necesita surtir"
        }
      },
      "health_team_banner": {
        "banner": {
          "header": "¡El Plan de Acción está ahora activado para %{patientName}!",
          "body_one": "Si hay equipos de salud adicionales que desee agregar para ver este Plan de Acción y trabajar con la persona, puede invitarlos ahora.",
          "body_two": "También puede agregar equipos de salud más tarde desde la sección Equipo del Plan de Acción bajo \"Miembros del Equipo de Salud\".",
          "close": "Cerrar",
          "invite": "Invitar equipos de salud"
        },
        "consent_form": {
          "header": "Confirmar equipos de salud para invitar",
          "permission": "Confirmo que tengo el consentimiento de la persona para invitar a los equipos de salud listados arriba a ver este Plan de Acción.",
          "cancel": "Cancelar",
          "back": "Atrás",
          "invite": "Invitar"
        },
        "place_selection_form": {
          "header": "Elija un equipo de salud elegible de la lista siguiente.",
          "list_header": "Equipos de salud disponibles para invitar",
          "cancel": "Cancelar",
          "next": "Siguiente"
        }
      },
      "referral_section": {
        "index": {
          "header": "Referencias",
          "empty": "Ninguna para este Plan de Acción.",
          "add_referral": "Añadir referencia",
          "error_message": "Algo salió mal, por favor intente de nuevo.",
          "title": "Solicitar referencia vía Caredove",
          "description": "Caredove encontrará servicios disponibles en el área geográfica apropiada. Por favor confirme que la dirección de la persona abajo es correcta, o ingrese una dirección diferente:"
        },
        "referral": {
          "service_name": "%{serviceName} vía %{provider}",
          "status": {
            "draft": "Enviada",
            "requested": "Pendiente",
            "received": "Pendiente",
            "accepted": "En progreso",
            "ready": "En progreso",
            "in_progress": "En progreso",
            "rejected": "Rechazada",
            "cancelled": "Cancelada",
            "failed": "Cancelada",
            "entered-in-error": "Cancelada",
            "on_hold": "En espera",
            "completed": "Completada",
            "booked": "Reservada"
          }
        }
      },
      "recurrence_input": {
        "after": "Después de",
        "count": {
          "one": "vez",
          "other": "veces"
        },
        "due_at": "Fecha y hora de inicio",
        "due_at_appt": "Programar para",
        "end_date": "Fecha de finalización",
        "end_repeat": "Finalizar repetición",
        "every": "Cada",
        "recurring": "Recurrente",
        "repeats": "Se repite",
        "time": "Hora",
        "days_offset": "Fecha programada",
        "days_offset_text": "después de la fecha de inicio del Plan de Acción"
      }
    },
    "country_codes": {
      "AF": "Afganistán",
      "AX": "Islas Aland",
      "AL": "Albania",
      "DZ": "Argelia",
      "AS": "Samoa Americana",
      "AD": "Andorra",
      "AO": "Angola",
      "AI": "Anguila",
      "AQ": "Antártida",
      "AG": "Antigua y Barbuda",
      "AR": "Argentina",
      "AM": "Armenia",
      "AW": "Aruba",
      "AU": "Australia",
      "AT": "Austria",
      "AZ": "Azerbaiyán",
      "BS": "Bahamas",
      "BH": "Bahrein",
      "BD": "Bangladesh",
      "BB": "Barbados",
      "BY": "Belarús",
      "BE": "Bélgica",
      "BZ": "Belice",
      "BJ": "Benín",
      "BM": "islas Bermudas",
      "BT": "Bhután",
      "BO": "Bolivia, Estado Plurinacional de",
      "BQ": "Bonaire, San Eustaquio y Saba",
      "BA": "Bosnia y Herzegovina",
      "BW": "Botswana",
      "BV": "Isla Bouvet",
      "BR": "Brasil",
      "IO": "Territorio Británico del Océano Índico",
      "BN": "Brunei Darussalam",
      "BG": "Bulgaria",
      "BF": "Burkina Faso",
      "BI": "Burundi",
      "KH": "Camboya",
      "CM": "Camerún",
      "CA": "Canadá",
      "CV": "Cabo Verde",
      "KY": "Islas Caimán",
      "CF": "República Centroafricana",
      "TD": "Chad",
      "CL": "Chile",
      "CN": "China",
      "CX": "Isla de Navidad",
      "CC": "Islas Cocos (Keeling)",
      "CO": "Colombia",
      "KM": "Comoras",
      "CG": "Congo",
      "CD": "Congo, República Democrática del",
      "CK": "Islas Cook",
      "CR": "Costa Rica",
      "CI": "Costa de Marfil",
      "HR": "Croacia",
      "CU": "Cuba",
      "CW": "Curazao",
      "CY": "Chipre",
      "CZ": "República Checa",
      "DK": "Dinamarca",
      "DJ": "Yibuti",
      "DM": "Dominica",
      "DO": "República Dominicana",
      "EC": "Ecuador",
      "EG": "Egipto",
      "SV": "El Salvador",
      "GQ": "Guinea Ecuatorial",
      "ER": "Eritrea",
      "EE": "Estonia",
      "ET": "Etiopía",
      "FK": "Islas Malvinas (Falkland Islands)",
      "FO": "Islas Faroe",
      "FJ": "Fiyi",
      "FI": "Finlandia",
      "FR": "Francia",
      "GF": "Guyana Francesa",
      "PF": "Polinesia francés",
      "TF": "Territorios Franceses del Sur",
      "GA": "Gabón",
      "GM": "Gambia",
      "GE": "Georgia",
      "DE": "Alemania",
      "GH": "Ghana",
      "GI": "Gibraltar",
      "GR": "Grecia",
      "GL": "Tierra Verde",
      "GD": "Granada",
      "GP": "Guadalupe",
      "GU": "Guam",
      "GT": "Guatemala",
      "GG": "Guernsey",
      "GN": "Guinea",
      "GW": "Guinea-Bissau",
      "GY": "Guyana",
      "HT": "Haití",
      "HM": "Islas Heard y McDonald",
      "VA": "Santa Sede (Estado de la Ciudad del Vaticano)",
      "HN": "Honduras",
      "HK": "Hong Kong",
      "HU": "Hungría",
      "IS": "Islandia",
      "IN": "India",
      "ID": "Indonesia",
      "IR": "Irán, República Islámica del",
      "IQ": "Irak",
      "IE": "Irlanda",
      "IM": "Isla del hombre",
      "IL": "Israel",
      "IT": "Italia",
      "JM": "Jamaica",
      "JP": "Japón",
      "JE": "Jersey",
      "JO": "Jordán",
      "KZ": "Kazajstán",
      "KE": "Kenia",
      "KI": "Kiribati",
      "KP": "República de Corea, Popular Democrática de",
      "KR": "Corea, república de",
      "KW": "Kuwait",
      "KG": "Kirguistán",
      "LA": "República Democrática Popular Lao",
      "LV": "Letonia",
      "LB": "Líbano",
      "LS": "Lesoto",
      "LR": "Liberia",
      "LY": "Libia",
      "LI": "Liechtenstein",
      "LT": "Lituania",
      "LU": "Luxemburgo",
      "MO": "Macao",
      "MK": "Macedonia, República de",
      "MG": "Madagascar",
      "MW": "Malawi",
      "MY": "Malasia",
      "MV": "Maldivas",
      "ML": "Mali",
      "MT": "Malta",
      "MH": "Islas Marshall",
      "MQ": "Martinica",
      "MR": "Mauritania",
      "MU": "Mauricio",
      "YT": "Mayotte",
      "MX": "Méjico",
      "FM": "Micronesia, Estados Federados de",
      "MD": "Moldavia, República de",
      "MC": "Mónaco",
      "MN": "Mongolia",
      "ME": "Montenegro",
      "MS": "Montserrat",
      "MA": "Marruecos",
      "MZ": "Mozambique",
      "MM": "Myanmar",
      "NA": "Namibia",
      "NR": "Nauru",
      "NP": "Nepal",
      "NL": "Países Bajos",
      "NC": "Nueva Caledonia",
      "NZ": "Nueva Zelanda",
      "NI": "Nicaragua",
      "NE": "Níger",
      "NG": "Nigeria",
      "NU": "Niue",
      "NF": "Isla Norfolk",
      "MP": "Islas Marianas del Norte",
      "OM": "Omán",
      "PK": "Pakistán",
      "PW": "Palau",
      "PS": "Palestina, Estado de",
      "PA": "Panamá",
      "PG": "Papúa Nueva Guinea",
      "PY": "Paraguay",
      "PE": "Perú",
      "PH": "Filipinas",
      "PN": "Pitcairn",
      "PL": "Polonia",
      "PT": "Portugal",
      "PR": "Puerto Rico",
      "QA": "Qatar",
      "RE": "Reunión",
      "RO": "Rumania",
      "RU": "Federación Rusa",
      "RW": "Ruanda",
      "BL": "San Bartolomé",
      "SH": "Santa Elena, Ascensión y Tristan da Cunha",
      "KN": "San Cristóbal y Nieves",
      "LC": "Santa Lucía",
      "MF": "Saint Martin (parte francesa)",
      "PM": "San Pedro y Miquelón",
      "VC": "San Vicente y las Granadinas",
      "WS": "Samoa",
      "SM": "San Marino",
      "ST": "Santo Tomé y Príncipe",
      "SA": "Arabia Saudita",
      "SN": "Senegal",
      "RS": "Serbia",
      "SC": "Seychelles",
      "SL": "Sierra Leona",
      "SG": "Singapur",
      "SX": "Sint Maarten (parte holandesa)",
      "SK": "Eslovaquia",
      "SI": "Eslovenia",
      "SB": "Islas Salomón",
      "SO": "Somalia",
      "ZA": "Sudáfrica",
      "GS": "Georgia del sur y las islas Sandwich del Sur",
      "SS": "Sudán del Sur",
      "ES": "España",
      "LK": "Sri Lanka",
      "SD": "Sudán",
      "SR": "Surinam",
      "SJ": "Svalbard y Jan Mayen",
      "SZ": "Suazilandia",
      "SE": "Suecia",
      "CH": "Suiza",
      "SY": "República Árabe Siria",
      "TW": "Taiwan",
      "TJ": "Tayikistán",
      "TZ": "Tanzania, República Unida de",
      "TH": "Tailandia",
      "TL": "Timor Oriental",
      "TG": "Togo",
      "TK": "Tokelau",
      "TO": "Tonga",
      "TT": "Trinidad y Tobago",
      "TN": "Túnez",
      "TR": "Turquía",
      "TM": "Turkmenistán",
      "TC": "Islas Turcas y Caicos",
      "TV": "Tuvalu",
      "UG": "Uganda",
      "UA": "Ucrania",
      "AE": "Emiratos Árabes Unidos",
      "GB": "Reino Unido",
      "UM": "Islas menores alejadas de los Estados Unidos",
      "US": "Estados Unidos",
      "UY": "Uruguay",
      "UZ": "Uzbekistán",
      "VU": "Vanuatu",
      "VE": "Venezuela, República Bolivariana de",
      "VN": "Vietnam",
      "VG": "Islas Vírgenes Británicas",
      "VI": "Islas Vírgenes, EE.UU.",
      "WF": "Wallis y Futuna",
      "EH": "Sáhara Occidental",
      "YE": "Yemen",
      "ZM": "Zambia",
      "ZW": "Zimbabue",
      "blank": "Elige país…",
      "NO": "Noruega"
    },
    "ct": {
      "components": {
        "diagnosis_template": {
          "placeholder": "Ingrese la descripción de la preocupación",
          "delete": "Eliminar",
          "remove": "Quitar"
        },
        "file_upload": {
          "attach_document": "Adjuntar documento"
        },
        "validator": {
          "url": "no es una URL válida. ¿Incluyó http o https?",
          "email": "debe ser un correo electrónico válido",
          "text": "no puede estar en blanco",
          "textarea": "no puede estar en blanco",
          "file": "no puede estar en blanco",
          "oversized_file": "El archivo seleccionado no debe superar los 50 MB",
          "select-one": "por favor seleccione una opción",
          "datetime": "debe ser una fecha válida",
          "datetime-local": "debe ser una fecha válida",
          "tel": "debe ser un número de teléfono válido"
        },
        "remote_handlers": {
          "medical_document": {
            "author": "Agregado por",
            "created_at": "Fecha de agregación",
            "document_file_name": "Nombre del archivo",
            "event_at": "Fecha del evento",
            "name": "Título del documento",
            "tags": "Etiquetas",
            "view": "Ver"
          }
        },
        "confirm": {
          "delete_ok": "Sí, eliminar"
        }
      }
    },
    "dashboard": {
      "glucose_levels": "Niveles de glucosa",
      "insulin_pump_settings": "Configuración de la bomba de insulina",
      "insulin_dosing": "Dosificación de insulina",
      "device": {
        "data_not_available": "Datos no disponibles.",
        "unauthorized": "Aún no se ha otorgado acceso a su dispositivo. Por favor, verifique su cuenta de Care Hub.",
        "connection_lost": "No podemos conectarnos a su dispositivo. Por favor, verifique su cuenta de Care Hub.",
        "consent_missing_hcp_message": "Solo se muestran datos parciales. No se ha otorgado consentimiento para acceder a todos los datos del dispositivo de %{firstName}. Por favor, solicite a %{firstName} (si tiene edad suficiente para dar consentimiento) o a sus padres que otorguen permiso en su Care Hub.",
        "consent_missing_patient_message": "Para ver todos sus datos disponibles, complete la conexión de sus dispositivos.",
        "connection_failed": "Intentamos conectar su dispositivo, pero no es posible. Por favor, verifique que sus dispositivos estén conectados en su cuenta de Care Hub.",
        "average_glucose": "Glucosa promedio",
        "gmi": "IMG",
        "cgm_active": "MCG activo",
        "number_placeholder": "~0.0",
        "percent_placeholder": "~0.0%",
        "unknown_error": "Algo salió mal. Intente nuevamente o contáctenos por correo electrónico ",
        "cgm_active_below_70": "El MCG activo debe ser superior al 70%",
        "time_range_below_14_days": "Se necesitan al menos 14 días de datos",
        "no_data_present": "El dispositivo está conectado, pero no hay datos.",
        "no_chdb_data_present": "Conectado al banco de datos Care Hub, pero no hay datos para el periodo de tiempo seleccionado.",
        "no_data_present_timeframe": "El dispositivo está conectado, pero no hay datos para el período seleccionado.",
        "no_user_accepted_error": "Datos aún no disponibles. %{firstName} o su padre/madre debe iniciar sesión en Careteam y aceptar la invitación primero.",
        "missing_fhir_token": "Los datos no estarán disponibles hasta que %{firstName} o su padre/madre inicie sesión en Careteam.",
        "no_chdb_data_present_patient": "No hay datos para el periodo de tiempo seleccionado. Si está esperando datos, compruebe las fuentes de datos en <a href=\"%{carehubUrl}\">su cuenta de Care Hub</a>.",
        "no_chdb_data_present_placeuser": "No hay datos para el período seleccionado. Si espera ver datos, solicite al paciente que verifique las fuentes de datos en su cuenta de Care Hub.",
        "empty_comparison_chart_data": "No hay datos para el período seleccionado.",
        "no_chdb_data_present_patient_part_1": "No hay datos para el período seleccionado. Si espera ver datos, verifique las fuentes de datos en ",
        "no_chdb_data_present_patient_part_2": "su cuenta de Care Hub.",
        "time_range_below_12_days": "Se necesitan al menos 12 días de datos"
      },
      "insulin": {
        "long_insulin_type": "Tipo de insulina de acción prolongada",
        "rapid_insulin_type": "Tipo de insulina de acción rápida",
        "dose": "Dosis",
        "long": "Prolongada",
        "ICR": "RHC",
        "ISF": "FSI",
        "breakfast": "Desayuno",
        "lunch": "Almuerzo",
        "dinner": "Cena",
        "bedtime": "Hora de dormir",
        "save": "Guardar",
        "save_error": "Ocurrió un error mientras se guardaban los datos.",
        "fetch_error": "Ocurrió un error mientras se recuperaban los datos.",
        "insulin": "Insulina",
        "rapid": "Rápida",
        "start": "Inicio",
        "active": "activo",
        "exceeds_maximum_dose": "No puede ser 100 o superior",
        "last_uploaded_on": "Última carga el %{date}",
        "updated_at": "Última actualización por %{userName} el %{updatedAt}"
      },
      "chart": {
        "glucose": "Glucosa",
        "avg_glucose_mmol_L": "Glucosa promedio (mmol/L)",
        "time_of_day": "Hora del día",
        "50_median": "Mediana 50%",
        "25_75_IQR": "Rango intercuartil 25 / 75%",
        "10_90_IDR": "Rango interdecil 10 / 90%",
        "target_range": "Rango objetivo",
        "time_of_day_abbreviated": "Hora",
        "50_median_abbreviated": "50%",
        "25_75_IQR_abbreviated": "25 / 75%",
        "10_90_IDR_abbreviated": "10 / 90%",
        "monday": "Lunes",
        "tuesday": "Martes",
        "wednesday": "Miércoles",
        "thursday": "Jueves",
        "friday": "Viernes",
        "saturday": "Sábado",
        "sunday": "Domingo",
        "monday_abbreviated": "Lu",
        "tuesday_abbreviated": "Ma",
        "wednesday_abbreviated": "Mi",
        "thursday_abbreviated": "Ju",
        "friday_abbreviated": "Vi",
        "saturday_abbreviated": "Sa",
        "sunday_abbreviated": "Do",
        "reset": "Restablecer",
        "comparison_date_range": "Del %{displayBeginDate} al %{displayEndDate}"
      },
      "time_range": {
        "24hrs": "24 horas",
        "7days": "7 días",
        "14days": "14 días",
        "30days": "30 días",
        "90days": "90 días",
        "24h": "24h",
        "7d": "7d",
        "14d": "14d",
        "30d": "30d",
        "90d": "90d",
        "1day": "1 día",
        "1d": "1d",
        "compare": "Comparar"
      },
      "bolus_calculator": "Abrir calculadora de bolo",
      "percent_symbol": "%",
      "level": "Nivel",
      "mmol_per_litre": "mmol/L",
      "glucose_average": {
        "average_glucose": "Glucosa promedio",
        "gmi": "IMG",
        "gmi_tooltip": "IMG (Indicador de Manejo de Glucosa) es el HbA1c estimado a partir de las lecturas promedio de glucosa de su monitor continuo durante un período de tiempo.",
        "cv": "Coeficiente de variación",
        "cv_tooltip": "CV (Coeficiente de variación) es una medida de la variabilidad de glucosa de su monitor continuo durante un período de tiempo. Las investigaciones sugieren un CV objetivo del 36% o menor.",
        "sd": "DE mmol/L"
      },
      "glucose_range": {
        "low": "Bajo",
        "in_range": "En rango",
        "high": "Alto"
      },
      "days_active": "(%{days} días)",
      "clipboard_copy": {
        "date_of_birth": "Fecha de nacimiento",
        "exported_date": "Fecha de exportación",
        "reporting_period": "Período del informe",
        "average_daily_readings": "Lecturas diarias promedio",
        "copy_as_text": "Copiar como texto",
        "copied": "¡Copiado!",
        "average_daily_insulin": "Insulina diaria promedio",
        "standard_deviation": "Desviación estándar",
        "basal_rates_title": "Tasa basal",
        "insulin_action": "Tiempo de insulina activa",
        "basal_rate_unit": "U/h",
        "i_c_ratio_settings": "Configuración ratio I:C",
        "i_c_ration_unit": "g/U",
        "isf_programs": "Programas FSI",
        "isf_unit": "mmol/L/U",
        "target_blood_glucose": "Glucosa en sangre objetivo",
        "bg_target": "Objetivo",
        "average_daily_dose": "Dosis diaria promedio",
        "average_basal": "Basal/día",
        "average_bolus": "Bolo/día",
        "units": "unidades"
      }
    },
    "editor": {
      "revert": "Volver a predeterminado",
      "edit": "Editar",
      "save": "Guardar",
      "remove": "Eliminar",
      "visit": "Visitar URL:",
      "enter_link": "Introducir enlace:"
    },
    "bounce_messages": {
      "addresschange": "El destinatario ha solicitado un cambio de dirección.",
      "autoresponder": "Respuesta automática de correo electrónico (ej: \"Fuera de la oficina\" o \"De vacaciones\").",
      "bademailaddress": "La dirección de correo electrónico no es válida.",
      "blocked": "Bloqueado por este ISP debido al contenido o inclusión en lista negra.",
      "challengeverification": "El rebote es una solicitud de verificación para confirmar que realmente envió el correo. Las verificaciones típicas son realizadas por Spam Arrest o MailFrontier Matador.",
      "dmarcpolicy": "Correo electrónico rechazado debido a la política DMARC.",
      "dnserror": "Un error temporal del DNS.",
      "hardbounce": "El servidor no pudo entregar su mensaje (ej: usuario desconocido, buzón no encontrado).",
      "inbounderror": "No se pudo entregar el mensaje entrante al destino del enlace de entrada.",
      "invitationerror": "Error de invitación",
      "manuallydeactivated": "El correo electrónico fue desactivado manualmente.",
      "openrelaytest": "El NDR es en realidad un mensaje de prueba para verificar si el servidor de correo es un relay abierto.",
      "smtpapierror": "Ocurrió un error al aceptar un correo electrónico a través de la API SMTP.",
      "softbounce": "No se puede entregar temporalmente el mensaje (ej: buzón lleno, cuenta deshabilitada, excede la cuota, sin espacio en disco).",
      "spamcompliant": "El suscriptor marcó explícitamente este mensaje como spam.",
      "spamnotification": "El mensaje fue entregado, pero fue bloqueado por el usuario o clasificado como spam, correo masivo, o contenía contenido rechazado.",
      "subscribe": "Solicitud de suscripción de alguien que desea ser agregado a la lista de correo",
      "templaterenderingfailed": "Ocurrió un error al intentar procesar su plantilla.",
      "transient": "El servidor no pudo entregar temporalmente su mensaje (ej: El mensaje está retrasado debido a problemas de red).",
      "unconfirmed": "El suscriptor no ha hecho clic en el enlace de confirmación después del registro o importación.",
      "unknown": "No se puede clasificar el NDR.",
      "unsubscribe": "Solicitud de cancelación de suscripción o eliminación",
      "virusnotification": "El rebote es en realidad una notificación de advertencia sobre un mensaje infectado con virus o código malicioso."
    },
    "emergency_instruction": {
      "header": "Instrucciones de emergencia",
      "info": "Esto se mostrará a los pacientes y sus acompañantes cuando estén enviando mensajes a su equipo y organización.",
      "default": "Si esto es una emergencia, por favor diríjase al hospital más cercano o llame al 911.",
      "updated": "Actualizado",
      "by": "Por",
      "characters_left": "caracteres restantes",
      "instruction_translation": "Si alguien tiene configurado otro idioma en sus ajustes, las instrucciones de emergencia predeterminadas se traducirán para ellos. Las instrucciones de emergencia de usted y su equipo no serán traducidas.",
      "discard": "Descartar cambios",
      "preview": "Vista previa",
      "save": "Guardar",
      "error": "Lo sentimos, ha ocurrido un error y no pudimos guardar esta información. Por favor, inténtelo de nuevo o contáctenos.",
      "revert": "Volver al valor predeterminado"
    },
    "errors": {
      "email": {
        "invalid": "Dirección de correo electrónico no válida",
        "already_exists": "El usuario ya pertenece a este Plan de Acción",
        "blank": "La dirección de correo electrónico es obligatoria"
      }
    },
    "locale": {
      "en": "Inglés",
      "fr": "Francés",
      "es": "Español",
      "ko": "Coreano",
      "sv": "Sueco"
    },
    "role": {
      "patient": "Paciente",
      "primary_caregiver": "Cuidador Principal",
      "caregiver": "Equipo de Apoyo",
      "support": "Apoyo Extendido",
      "unaffiliated_health_care": "Profesional Sanitario Invitado"
    },
    "relationship": {
      "empty_relationship": "Seleccione una relación...",
      "other": "Otro",
      "spouse": "Cónyuge",
      "parent": "Padre/Madre",
      "child": "Hijo/a",
      "sibling": "Hermano/a",
      "cousin": "Primo/a",
      "friend": "Amigo/a",
      "neighbour": "Vecino/a",
      "step_parent": "Padrastro/Madrastra",
      "ex_spouse": "Ex-cónyuge",
      "grandchild": "Nieto/a",
      "professional_caregiver": "Cuidador Profesional",
      "volunteer": "Voluntario/a",
      "unaffiliated_health_care": "Miembro del Equipo Sanitario"
    },
    "forms": {
      "no_options": "Sin opciones",
      "address_form": {
        "address": "Dirección",
        "extended_address": "Apartamento, suite, etc. (opcional)",
        "region": "Provincia / Estado",
        "postal_code": "Código postal",
        "city": "Ciudad",
        "country_code": "Country",
        "edit": {
          "label": "Actualizar dirección"
        },
        "province": "Provincia",
        "state": "Estado",
        "zip_code": "Código ZIP",
        "postcode": "Código postal",
        "country": "País"
      },
      "date_time_input": {
        "am": "AM",
        "pm": "PM"
      },
      "document_form": {
        "cancel": "Cancelar",
        "change_file": "Cambiar archivo",
        "save": "Guardar",
        "document_name_label": "Nombre del documento",
        "event_at_label": "Fecha del evento",
        "tags_label": "Etiquetas",
        "file_size_exceeded": "El archivo seleccionado no debe superar los 50 MB"
      },
      "library_document_select": {
        "no_documents_available": "No hay documentos disponibles actualmente en la biblioteca"
      },
      "locale_input": {
        "language": "Idioma"
      },
      "phone_number_form": {
        "mobile": "Móvil",
        "primary_residence": "Residencia principal",
        "secondary_residence": "Residencia secundaria",
        "work": "Trabajo"
      },
      "profile_picture_upload": {
        "default_alt": "Subir una foto de perfil",
        "generic_error": "No se pudo subir la foto de perfil",
        "upload": "Subir foto de perfil",
        "update": "Actualizar foto de perfil",
        "optional": "(opcional)",
        "explanation": "Agregar una foto a su perfil ayuda a mejorar la experiencia para todos.",
        "file_size_exceeded": "El archivo seleccionado no debe superar los 50 MB"
      },
      "help_request": {
        "placeholder": {
          "header": "Conecte con un voluntario para ayudar con una tarea a alguien necesitado.",
          "pickup": "Ejemplos: Supermercado, Casa, etc.",
          "delivery": "Ejemplos: Casa, Apartamento, etc.",
          "recipient_phone": "Número",
          "due_at": "La entrega se intentará dentro de las 2 horas del horario especificado"
        },
        "blank": "no puede estar vacío",
        "invalid_address": "La dirección no es válida",
        "invalid_phone": "El número de teléfono no es válido",
        "task_type": "Tipo de tarea",
        "transportation": "Transporte",
        "pickup_name": "Nombre del lugar de recogida",
        "pickup_address": "Dirección de recogida",
        "delivery_name": "Nombre del lugar de entrega",
        "delivery_address": "Dirección de entrega",
        "recipient_phone": "Número de teléfono del destinatario",
        "due_at": "Hora preferida de entrega",
        "notes": "Notas",
        "cancel": "Cancelar",
        "submit": "Enviar solicitud",
        "success": "Se ha solicitado un voluntario para ayudar con esta tarea.",
        "outside_hours": "Lamentablemente, su solicitud no coincidió con ningún voluntario según su disponibilidad y/o proximidad de ubicación."
      },
      "submit": {
        "save": "Guardar"
      },
      "password_input": {
        "hide": "Ocultar",
        "show": "Mostrar"
      },
      "label": {
        "optional": "Opcional"
      }
    },
    "goals": {
      "show": {
        "status": {
          "header": "Estado",
          "status": {
            "complete": "Meta completa",
            "incomplete": "Objetivo incompleto"
          },
          "action": {
            "complete": "Revertir",
            "incomplete": "Completar"
          }
        }
      }
    },
    "header": {
      "notifications": "Notificaciones",
      "language": "Idioma",
      "menu": {
        "index": {
          "my_profile": "Mi perfil"
        }
      }
    },
    "inbox": {
      "disconnected": "Ha ocurrido un error inesperado. Por favor, actualice la página",
      "conversation": {
        "disclaimer": {
          "header": "Nota de Careteam",
          "non_health_care": "La mensajería es una excelente manera de aclarar dudas sobre su Plan de Acción. Su mensaje será enviado al buzón del equipo y será respondido por el primer miembro disponible. Por favor, tenga en cuenta que esto puede tomar algún tiempo.",
          "health_care": "Este buzón de mensajería segura es para comunicaciones rutinarias. Si es urgente, por favor contacte a la persona por teléfono.",
          "direct_message": "El centro de mensajes es un buen lugar para comunicarse directamente con los miembros de su equipo de asistencia. Sin embargo, si se trata de una emergencia, acuda al hospital más cercano o llame al 911.",
          "health_care_channel": "Tenga en cuenta que los mensajes dentro de un canal no serán automáticamente accesibles para otros miembros del equipo de atención y que la información clínicamente relevante debe registrarse en el Plan de Acción o en los registros de su organización.",
          "non_health_care_channel": "Los canales son una excelente forma de comunicarse con los miembros de su Plan de Acción. Sin embargo, si se trata de una emergencia, acuda al hospital más cercano o llame al 911."
        },
        "form": {
          "body": "Escriba su mensaje aquí…",
          "submit": "Enviar",
          "error": "Algo salió mal, intente nuevamente."
        },
        "header": {
          "view_details": "Ver detalles",
          "view_careplan": "Ver Plan de Acción",
          "loading": "Cargando...",
          "emergency_instruction_header": "Instrucción de emergencia",
          "emergency_instruction_p1": "La mensajería es para comunicación no urgente. Ver",
          "emergency_instruction_p2": "contactos alternativos"
        },
        "messages": {
          "new_desk_conversation_one": "¡Bienvenido al centro de mensajes!",
          "new_desk_conversation_two": "Escriba un mensaje abajo para contactar a %{name} sobre citas u otras consultas.",
          "new_conversation_one": "No ha enviado mensajes a %{name} antes.",
          "new_conversation_two": "Comience su conversación enviando un mensaje abajo.",
          "no_conversations": "Aún no ha iniciado ninguna conversación, haga clic en un contacto para comenzar.",
          "emoji": "Insertar emoji"
        }
      },
      "side_nav": {
        "care_plans": {
          "header": "Seleccione un Plan de Acción",
          "back": "Atrás",
          "no_results": "No hay Planes de Acción",
          "filter": "Buscar un Plan de Acción...",
          "health_care": {
            "carePlans": {
              "info": "Para crear una conversación de escritorio, primero debe seleccionar un Plan de Acción."
            },
            "channelCarePlans": {
              "info": "Para crear un canal de Plan de Acción, primero debe seleccionar un Plan de Acción."
            }
          },
          "non_health_care": {
            "channelCarePlans": {
              "info": "Para hablar con un proveedor de atención médica o iniciar un canal grupal, por favor seleccione el Plan de Acción sobre el que desea hablar."
            }
          }
        },
        "channels": {
          "info": "Miembros del equipo para este Plan de Acción:",
          "next": "Siguiente",
          "channel_name": "Nombre del canal:",
          "channel_name_hint": "Se utilizará el nombre del Plan de Acción si no se especifica un nombre.",
          "start": "Iniciar nuevo canal",
          "invalid_name": "Ese nombre de canal ya existe. Para continuar, por favor actualice el nombre del canal.",
          "channel_members": "Miembros de este Canal (%{count}):",
          "channelMembers": {
            "header": "Seleccionar miembros del canal del Plan de Acción"
          },
          "ongoing": {
            "header": "Seleccionar miembros del canal del Plan de Acción"
          },
          "channelName": {
            "header": "Crear canal del Plan de Acción"
          },
          "select_all": "Seleccionar Todo (%{count})"
        },
        "contacts": {
          "contact_list": {
            "no_contacts": {
              "header": "Construya su equipo de apoyo para intercambiar mensajes de manera segura",
              "info": "Visite la página del Equipo para agregar familiares, amigos y cuidadores que lo apoyan en su viaje de salud. Luego regrese aquí para enviarles mensajes directamente.",
              "no_contacts": "No hay conversaciones"
            }
          },
          "filters": {
            "search": "Buscar un contacto...",
            "newest": "Más reciente",
            "oldest": "Más antiguo",
            "nameAsc": "Nombre A - Z",
            "nameDesc": "Nombre Z - A"
          },
          "header": {
            "desk": "Escritorios del equipo de salud",
            "direct": "Mensajes directos",
            "health_care_direct": "Conversaciones de escritorio",
            "channels": "Canales del Plan de Acción",
            "desk_subtext": "Contactar recepción",
            "direct_subtext": "Mensajes de apoyo 1:1",
            "channels_subtext": "Mensajes 1:1 o grupales",
            "health_care_direct_subtext": "Solicitudes generales",
            "users": "Nuevo canal",
            "user-circle": "Nuevo mensaje",
            "desk-conversations": "Nueva conversación",
            "hospital": "Nueva conversación de escritorio"
          }
        },
        "new_contacts": {
          "header": "Nuevo mensaje directo",
          "desk_header": "Nueva conversación de escritorio",
          "info": "A continuación se muestra una lista de cuidadores y familiares con los que puede enviar mensajes."
        }
      }
    },
    "notifications": {
      "header": "Notificaciones",
      "close": "Cerrar",
      "empty": {
        "title": "¡Felicitaciones!",
        "message": "Está al día con sus notificaciones."
      },
      "tooltips": {
        "close": "Cerrar",
        "remove_notification": "Eliminar notificación"
      }
    },
    "pagination": {
      "one_page": {
        "display_entries": {
          "one": "Mostrando 1 %{entry_name}",
          "other": "Mostrando todos los %{count} %{entry_name}"
        }
      },
      "more_pages": {
        "display_entries": "Mostrando %{entry_name} %{first}-%{last} de un total de %{total}"
      }
    },
    "patient": {
      "edit": {
        "address": {
          "label": "Tipo de dirección",
          "home": "Casa",
          "work": "Trabajo",
          "temporary": "Temporal",
          "mailing_address": "Dirección de correo",
          "submit": "Guardar",
          "country": "País",
          "street_address": "Dirección",
          "extended_address": "Apartamento, suite, etc. (opcional)",
          "city": "Ciudad",
          "province": "Provincia",
          "state": "Estado",
          "postal_code": "Código postal",
          "zip_code": "Código ZIP",
          "postcode": "Código postal",
          "cancel": "Cancelar",
          "save": "Guardar",
          "address": "Dirección"
        },
        "index": {
          "first_name": "Nombre",
          "last_name": "Apellido",
          "female": "Femenino",
          "male": "Masculino",
          "other": "Otro",
          "gender": "Género",
          "birthday": "Fecha de nacimiento",
          "medical_record_number": "Número de seguro médico",
          "contact_information": "Información de contacto",
          "email": "Correo electrónico",
          "manage_invitation": "Gestionar invitación",
          "send_invitation": "Enviar invitación",
          "phone_number": "Número de teléfono",
          "address": "Dirección",
          "new": "+ Añadir",
          "primary_physician": {
            "header": "Proveedor de atención primaria",
            "placeholder": "Ingrese los detalles del médico aquí"
          },
          "submit": "Guardar",
          "select": "Seleccionar",
          "street_address": "Dirección",
          "invitation_message": "Se enviará una invitación para unirse al Plan de Acción a este correo electrónico cuando guarde.",
          "submit_error": "Lo sentimos, ocurrió un error y no pudimos guardar la información. Por favor, inténtelo de nuevo o contáctenos.",
          "update_carehub": "Actualizar en Care Hub"
        },
        "phi_section": {
          "submit": "Guardar"
        }
      },
      "index": {
        "new_care_plan": "Nuevo Plan de Acción",
        "self_signups": "Auto-registros",
        "new_care_plan_menu": {
          "create_new_care_plan": "Crear nuevo",
          "use_template": "Usar plantilla"
        },
        "care_plan_templates_modal": {
          "title": "¿Qué tipo de Plan de Acción desea iniciar?",
          "content_banner": "Para ahorrar tiempo, elija entre las plantillas disponibles creadas por su organización. Las plantillas ya están configuradas para ahorrarle tiempo y esfuerzo.",
          "list_header": "Seleccionar plantilla",
          "modal_failure": "Algo salió mal. Por favor, inténtelo de nuevo.",
          "next": "Siguiente",
          "cancel": "Cancelar",
          "create_action_plan": "Crear Plan de Acción"
        },
        "patient": {
          "medical_record_number": "Número de seguro médico",
          "pending": "Invitaciones pendientes",
          "new_patient": "Nuevo paciente",
          "last_logged_in": "Última sesión iniciada el %{date} a las %{time}",
          "self_registered": "Nuevo auto-registro",
          "unknown": "Desconocido",
          "new_referral": "Nueva referencia",
          "archived": "Archivado",
          "years_old": "años"
        },
        "patients": {
          "empty": "Sin resultados"
        },
        "filter_patients": "Mostrar mi lista",
        "search_patients_placeholder": "Buscar",
        "search_patients_aria": "Buscar",
        "sort_tooltip": "Ordenar",
        "sort_patients_aria": "Ordenar",
        "empty": "Sin resultados",
        "header": "Personas a las que servimos",
        "sort": {
          "createdAtDesc": "Más reciente",
          "createdAtAsc": "Más antiguo",
          "nameAsc": "Apellido A-Z",
          "nameDesc": "Apellido Z-A",
          "sort_tooltip": "Ordenar",
          "sort_patients_aria": "Ordenar"
        },
        "filter_patients_my_list": "Mi lista",
        "filter_patients_archived": "Archivados",
        "error_occurred": "Algo salió mal, por favor inténtelo de nuevo.",
        "filter_checkbox_label": "Mostrar:",
        "add_action_plan": "Iniciar nuevo Plan de Acción",
        "self_signup_link": "Enlace de auto-registro"
      },
      "new": {
        "address": {
          "label": "Tipo de dirección",
          "home": "Casa",
          "work": "Trabajo",
          "temporary": "Temporal",
          "mailing_address": "Dirección de correo",
          "submit": "Guardar"
        }
      },
      "show": {
        "address": {
          "label": "Tipo de dirección",
          "home": "Casa",
          "work": "Trabajo",
          "temporary": "Temporal",
          "mailing_address": "Dirección de correo",
          "submit": "Guardar",
          "edit": "Editar",
          "unknown": "Desconocido"
        },
        "addresses": {
          "header": "Direcciones adicionales"
        },
        "index": {
          "first_name": "Nombre",
          "last_name": "Apellido",
          "female": "Femenino",
          "male": "Masculino",
          "other": "Otro",
          "gender": "Género",
          "birthday": "Fecha de nacimiento",
          "medical_record_number": "Número de seguro médico",
          "contact_information": "Información de contacto",
          "email": "Correo electrónico",
          "manage_invitation": "Gestionar invitación",
          "phone_number": "Número de teléfono",
          "address": "Dirección",
          "new": "+ Añadir",
          "primary_physician": {
            "header": "Proveedor de atención primaria",
            "placeholder": "Ingrese los detalles del médico aquí"
          },
          "submit": "Guardar",
          "name": "Nombre",
          "unknown": "Desconocido",
          "street_address": "Dirección"
        },
        "phone_number": {
          "mobile": "Móvil",
          "primary_residence": "Residencia principal",
          "secondary_residence": "Residencia secundaria",
          "work": "Trabajo",
          "edit": "Editar",
          "unknown": "Desconocido"
        },
        "phone_numbers": {
          "header": "Números de teléfono adicionales"
        }
      },
      "patient": {
        "header": "Perfil de %{name}",
        "resend_invitation": "Reenviar invitación",
        "invitation_sent": "¡Invitación enviada!"
      },
      "phi_section": {
        "current_medications": "Medicamentos actuales",
        "past_medications": "Medicamentos anteriores",
        "immunizations": "Inmunizaciones",
        "allergies": "Alergias",
        "family_history": "Historial familiar",
        "notes": "Notas",
        "none": "No se han añadido detalles aún."
      },
      "check_in_result": {
        "header": "Resultados del check-in",
        "submitted": "Enviado",
        "submittedBy": "Por %{name}",
        "none": "No se han añadido detalles aún.",
        "past_submissions": "Envíos anteriores",
        "skipped": "Omitido",
        "submittedLate": "Presentado tarde",
        "submitted_late": "Enviado tarde",
        "last_checkin": "Último check-in:",
        "previous_checkins": "Check-ins anteriores:",
        "show_more_submissions": "Mostrar más envíos de check-in"
      },
      "form_result": {
        "header": "Formularios",
        "submitted_by": "Enviado el %{date} por %{name}",
        "none": "No se han añadido detalles aún."
      },
      "check_in_score": {
        "0": "No",
        "1": "Sí",
        "min": "Mín - %{val}",
        "max": "Máx - %{val}",
        "placeholder": "Escriba su respuesta",
        "remaining": "Restante - %{val}"
      }
    },
    "place_admin": {
      "place_details": {
        "edit_place_details": "Editar detalles del lugar",
        "cancel": "Cancelar",
        "save": "Save",
        "name": "Nombre",
        "logo": "Logotipo",
        "email": "Email",
        "language": "Idioma",
        "time_zone": "Zona horaria",
        "number": "Número",
        "address": {
          "country": "Country",
          "street_address": "Dirección de la calle",
          "extended_address": "Apartamento, suite, etc. (opcional)",
          "city": "Ciudad",
          "province": "Provincia",
          "state": "Estado",
          "postal_code": "Código postal",
          "zip_code": "Código postal",
          "postcode": "Código postal"
        },
        "errors": {
          "cant_be_blank": "no puede estar en blanco"
        }
      }
    },
    "referrals": {
      "status_badge": {
        "draft": "Borrador: <b>En proceso de envío</b>",
        "submitted": "En curso: <b>Enviado</b>"
      }
    },
    "registration": {
      "index": {
        "welcome": "Bienvenido a Careteam",
        "subtitle": "Su plataforma integral para el cuidado de la salud",
        "teasers": ["Encuentre instrucciones, citas y documentos.", "Acceda las 24 horas del día en un lugar seguro, desde cualquier dispositivo.", "Conéctese con todas las personas que necesitan estar involucradas."],
        "already_have_account": "¿Ya tiene una cuenta?",
        "login": "Iniciar sesión"
      },
      "code": {
        "enter_code": "Ingrese el código",
        "subtitle": "Ingrese su código de registro de Careteam. Si no tiene uno, contáctenos.",
        "label": "Código",
        "next": "Siguiente"
      },
      "representative": {
        "role": "¿Para quién es esta cuenta?",
        "subtitle": "Puede utilizar esta cuenta para sí mismo o como cuidador para futuros planes de acción.",
        "myself": "Para mí",
        "patient_role": "Regístrese para gestionar su propio Plan de Acción.",
        "someone_else": "Para otra persona",
        "caregiver_role": "Regístrese para ayudar a otra persona. Para proteger su privacidad, tendrá acceso limitado hasta que %{place} acepte colaborar en este Plan de Acción.",
        "next": "Siguiente"
      },
      "user": {
        "create_account": "Cree su cuenta",
        "first_name": "Nombre",
        "last_name": "Apellido",
        "email": "Correo electrónico",
        "password": "Contraseña",
        "password_hint": "Debe tener 8 o más caracteres con al menos una letra minúscula, una mayúscula, un número y un símbolo",
        "terms_of_service": "Acepto los <a target=\"_blank\" href=\"/terms_of_service\" id=\"show-terms-of-service\">Términos de Servicio</a> y la <a target=\"_blank\" href=\"/privacy_policy\" id=\"show-privacy-policy\">Política de Privacidad</a>",
        "consent": "Me estoy registrando para usar este Plan de Acción proporcionado por %{place}. El equipo de %{place} será notificado de mi registro. Los miembros de su equipo podrán ver y contribuir a mi Plan de Acción, y comunicarse conmigo y con cualquier persona que yo haya designado en Careteam como miembro de contacto de mi Equipo de Apoyo.",
        "next": "Siguiente",
        "blank_first_name": "No puede estar vacío",
        "blank_last_name": "No puede estar vacío",
        "blank_email": "No puede estar vacío",
        "invalid_email": "Correo electrónico inválido",
        "blank_password": "No puede estar vacía",
        "invalid_password": "Debe contener al menos una letra mayúscula, una minúscula, un dígito y un símbolo",
        "password_hide": "Esconder",
        "password_show": "Mostrar",
        "must_have": "Debe tener al menos",
        "eight_chars": "8 caracteres",
        "upper_case": "1 letra mayúscula",
        "lower_case": "1 letra minúscula",
        "special_char": "1 número o carácter especial (ej. ! @ # $ % ^ & * ( )",
        "passwords_no_match": "Las contraseñas no coinciden"
      },
      "patient": {
        "who": "¿Para quién está creando este plan?",
        "subtitle": "Ingrese la información de la persona para quien actuará como cuidador.",
        "first_name": "Su nombre",
        "last_name": "Su apellido",
        "next": "Siguiente",
        "blank_first_name": "No puede estar vacío",
        "blank_last_name": "No puede estar vacío"
      },
      "avatar": {
        "upload": "Subir foto de perfil",
        "subtitle": "Agregar una foto suya ayuda a mejorar la experiencia para los demás en el Plan de Acción",
        "skip": "Omitir",
        "finish": "Finalizar",
        "form": {
          "photo": "Su foto de perfil",
          "default_alt": "Foto",
          "remove": "Eliminar",
          "upload": "Subir"
        },
        "optional": "Opcional"
      },
      "header": {
        "already_have_an_account": "¿Tiene una cuenta?",
        "login": "Iniciar sesión"
      }
    },
    "repeated_activities": {
      "occurrences": {
        "edit": {
          "delete_series": "Esta actividad forma parte de una serie que se repite. ¿Quieres borrar:",
          "change_series": "Esta actividad forma parte de una serie que se repite. ¿Quieres actualizar:",
          "this_event": "Esta actividad",
          "this_series": "Todas las actividades futuras",
          "cancel": "Cancelar"
        },
        "form": {
          "add_time": "Agregar tiempo",
          "assignments": "Asignar",
          "cancel": "Cancelar",
          "due_at": "Fecha y hora",
          "submit": "Guardar",
          "text": "Descripción",
          "title": "Título",
          "repeat": "Repetir",
          "until": "Hasta",
          "frequency": {
            "none": "Ninguna",
            "daily": "Diario",
            "weekly": "Semanal",
            "monthly": "Mensual",
            "yearly": "Anual"
          },
          "every": "Cada",
          "interval": {
            "daily": {
              "one": "día",
              "other": "días"
            },
            "weekly": {
              "one": "semana",
              "other": "semanas"
            },
            "monthly": {
              "one": "mes",
              "other": "meses"
            },
            "yearly": {
              "one": "año",
              "other": "años"
            }
          },
          "end_repeat": "Fin de repetición",
          "end": {
            "never": "Nunca",
            "date": "En un día",
            "count": "Después"
          },
          "choose_a_date": "Elige una fecha",
          "count": {
            "one": "vez",
            "other": "veces"
          },
          "no_resources_present": "No hay recursos disponibles para añadir a esta Tarea"
        }
      }
    },
    "repeated_tasks": {
      "occurrences": {
        "edit": {
          "delete_series": "Esta tarea es parte de una serie que se repite. Quieres borrar:",
          "change_series": "Esta tarea es parte de una serie que se repite. ¿Quieres actualizar:",
          "this_event": "Esta tarea",
          "this_series": "Todas las tareas futuras",
          "cancel": "Cancelar"
        }
      }
    },
    "resources": {
      "confirmation": {
        "header": "Referencias",
        "title": "Solicitar %{title} a través de Caredove",
        "description": "Caredove encontrará servicios disponibles en su área. Por favor, confirme que la dirección siguiente es correcta o ingrese una dirección diferente.",
        "address_label": "Dirección",
        "placeholder": "Ingrese una dirección postal...",
        "confirmation_label": "Confirmación",
        "confirmation_description": "Iniciar Caredove y compartir la información de contacto de esta persona (nombre, fecha de nacimiento, dirección, teléfono)",
        "address_update_hint": "Actualice su dirección residencial en Mi Perfil para autocompletar este campo la próxima vez.",
        "cancel": "Cancelar",
        "next": "Siguiente",
        "place_user_confirmation_description": "Inicie Caredove y pase la información de contacto de esta persona (nombre, fecha de nacimiento, dirección, teléfono)",
        "self_confirmation_description": "Iniciar Caredove y compartir mi información de contacto (nombre, fecha de nacimiento, dirección, teléfono)",
        "sso_description": "¿Ya tiene una cuenta en Caredove?"
      }
    },
    "tags": {
      "other": "Otro",
      "form": {
        "statuses": ["borrador", "activo", "archivado"],
        "edit": "Editar etiqueta",
        "add": "Añadir etiqueta",
        "delete": "Eliminar",
        "name": "Nombre",
        "status": "Estado",
        "cancel": "Cancelar",
        "submit": "Enviar",
        "edit_confirm": "¿Está seguro de que desea eliminar esta etiqueta? Esto la eliminará permanentemente de cualquier recurso en su biblioteca de administración y en los planes de acción. Para ocultar temporalmente la etiqueta, cambie el estado a Archivado.",
        "edit_status_help": "Cambiar el estado a Archivado ocultará la etiqueta en todos los recursos activos. Si el estado se cambia nuevamente a Activo, volverá a aparecer en cualquier recurso al que se aplicó anteriormente."
      }
    },
    "tasks": {
      "show": {
        "status": {
          "heading": "Estado"
        }
      },
      "index": {
        "heading": "Toda la actividad",
        "add_task": "Añadir tarea",
        "add_appointment": "Añadir cita",
        "complete": "Completar",
        "pending": "Pendiente",
        "revert": "Revertir",
        "repeated": "Repeticiones",
        "resources": {
          "header": "Seleccionar recursos para enlazar",
          "link_button": "Enlazar recursos",
          "back": "Atrás",
          "cancel": "Cancelar",
          "next": "Siguiente",
          "search_placeholder": "Buscar recursos...",
          "service_kinds": {
            "information": "Información",
            "other": "Otro",
            "people": "Personas",
            "practitioner": "Proveedores de salud",
            "service": "Servicios"
          },
          "no_resources_present": "No hay recursos disponibles para añadir a este Plan de Acción"
        },
        "not_available": "No disponible",
        "repeated_task": "Tarea repetida",
        "repeated_appointment": "Cita repetida",
        "check_in": {
          "hi": "Hola",
          "thanks": "Gracias %{name}",
          "response_recorded": "Su registro de entrada ha sido registrado.",
          "submit": "Enviar",
          "next": "Siguiente",
          "check_in_link": "Ver mi registro de entrada",
          "skip": "Omitir",
          "pastDueAtWarning": "Complete este check-in para %{dueAt} o puede omitirlo y responder al más reciente en su lugar",
          "past_due_at_warning": "Complete este registro de entrada para %{dueAt} o puede omitir y responder el más reciente en su lugar"
        },
        "tasks": {
          "completed": {
            "heading": "Tareas completadas",
            "button": "Ocultar completadas",
            "blankslate": "No tiene ninguna tarea completada",
            "filtered_blankslate": "No tiene ninguna tarea completada que coincida con el filtro"
          },
          "incomplete": {
            "heading": "Tareas",
            "button": "Mostrar completadas",
            "blankslate": "¡Felicidades! <br /> Está al día con sus tareas.",
            "filtered_blankslate": "No tiene ninguna tarea incompleta que coincida con el filtro"
          }
        },
        "agenda": {
          "heading": "Calendario",
          "previous": "Semana anterior",
          "current": "Hoy",
          "next": "Próxima semana",
          "blankslate": "No tiene nada programado para esta semana.",
          "filtered_blankslate": "No tiene nada programado para esta semana que coincida con el filtro actual"
        },
        "sidebar": {
          "header": "Ver tareas por Plan de Acción",
          "mobile_header": "Actividades de %{patient}",
          "filter": "Filtrar por Plan de Acción",
          "my_activities": "Mis actividades"
        },
        "filters": {
          "heading": "Filtro",
          "all": "Todo",
          "accepted": "Aceptado",
          "pending": "Pendiente",
          "assigned": "Asignado",
          "unassigned": "No asignado",
          "mine": "Mío"
        },
        "new_task": "Nueva tarea",
        "new_appointment": "Nueva cita"
      },
      "nested_appointment_form": {
        "add_appointment": "¿Le gustaría añadir una cita para esta tarea?",
        "skip": "Omitir"
      },
      "form": {
        "add_time": "Añadir hora",
        "assignments": "Asignar",
        "cancel": "Cancelar",
        "due_at": "Fecha y hora",
        "submit": "Guardar",
        "text": "Descripción",
        "repeat": "Repetir",
        "until": "Hasta",
        "frequency": {
          "none": "Ninguno",
          "daily": "Diario",
          "weekly": "Semanal",
          "monthly": "Mensual",
          "yearly": "Anual"
        },
        "every": "Cada",
        "interval": {
          "daily": {
            "one": "día",
            "other": "días"
          },
          "weekly": {
            "one": "semana",
            "other": "semanas"
          },
          "monthly": {
            "one": "mes",
            "other": "meses"
          },
          "yearly": {
            "one": "año",
            "other": "años"
          }
        },
        "end_repeat": "Fin de repetición",
        "end": {
          "never": "Nunca",
          "date": "En una fecha",
          "count": "Después de"
        },
        "choose_a_date": "Elegir una fecha",
        "count": {
          "one": "vez",
          "other": "veces"
        },
        "no_resources_present": "No hay recursos disponibles para añadir a esta Tarea",
        "callback_header": "Perfecto, su envío ha sido recibido",
        "callback_body": ["Usted y su equipo pueden ", "ver los resultados", " en su Plan de Acción en cualquier momento. Ahora puede volver a su día. ¡Gracias!"],
        "callback_button": "¡Entendido!",
        "complete_form": "Completar formulario",
        "address": {
          "street_address": "Dirección",
          "extended_address": "Apartamento, suite, etc (opcional)",
          "city": "Ciudad",
          "province": "Provincia",
          "state": "Estado",
          "postal_code": "Código postal",
          "zip_code": "Código ZIP",
          "postcode": "Código postal",
          "country": "País"
        }
      },
      "actions_container": {
        "edit": "Editar",
        "revert": "Revertir",
        "mark_complete": "Marcar como completada"
      }
    },
    "team": {
      "roles": {
        "primary_caregiver": "Contacto",
        "caregiver": "Miembro",
        "support": "Apoyo"
      },
      "invite_code_modal": {
        "title": "Invite a miembros del equipo mediante A) un código privado o B) un enlace privado",
        "subtitle": "Cuando alguien utilice este código o enlace para solicitar unirse a este Plan de Acción, usted podrá aprobarlos y decidir su nivel de acceso.",
        "get_code": "Obtener código de invitación",
        "first_option": "A) Usar un código privado",
        "second_option": "B) Usar un enlace privado",
        "second_option_subtitle": "Una vez que haya copiado el enlace a su portapapeles, puede compartirlo pegándolo en un correo electrónico o mensaje.",
        "first_option_subtitle": "Envíelos a <span class=\"invite-code-modal__inline-link text-medium\">%{link}</span> para ingresar este código privado:",
        "link_copied": "Enlace copiado al portapapeles",
        "get_link": "Copiar enlace de invitación",
        "header": "Obtener código de invitación",
        "done": "Listo"
      },
      "modal": {
        "add_members": "Añadir miembros al equipo",
        "add_team_members": "Añadir miembros al equipo",
        "build": "Forme su equipo de apoyo",
        "welcome": "Bienvenido a Careteam",
        "invite_type": "Invitar por %{inviteType}",
        "support_team_invite_subtitle": "Añada miembros al equipo aquí, decida qué pueden ver y hacer en el siguiente paso.",
        "health_team_explanation": "Invite a profesionales de la salud como médicos u otros profesionales sanitarios.",
        "support_team_explanation": "Invite a familiares, amigos y cuidadores profesionales para ayudar a apoyarle y alcanzar sus objetivos.",
        "support_team_declaration": "%{name} ha dado su consentimiento para que permita a este miembro del equipo de apoyo acceder a su información personal de salud y actuar en su nombre a través de Careteam.",
        "health_team_declaration": "%{name} ha dado su consentimiento para que permita a este proveedor de salud acceder a su información personal de salud.",
        "email": {
          "description": "Todos necesitamos familia y amigos que nos apoyen. Añada miembros al equipo aquí (usted decide qué pueden ver y hacer en el siguiente paso).",
          "email_label": "Dirección de correo electrónico",
          "email_placeholder": "Ingrese dirección de correo electrónico...",
          "email_hint": "Se enviará una invitación para unirse a su Plan de Acción a este correo.",
          "relationship_label": "Relación con %{name}",
          "empty_relationship": "Seleccione una relación...",
          "cancel": "Cancelar",
          "next": "Siguiente",
          "relationship_patient_label": "Relación con usted"
        },
        "final": {
          "header_title_html": "Invitaciones enviadas <span class=\"text-normal\">(%{size})</span>",
          "description_html": "Los miembros del equipo que ha invitado recibirán invitaciones por correo electrónico inmediatamente. Puede añadir miembros adicionales ahora o hacerlo más tarde desde su página de <b>Equipo</b>.",
          "done": "He terminado",
          "invite_more": "Invitar más",
          "primary_caregiver": "Contacto",
          "caregiver": "Miembro",
          "support": "Extendido",
          "unaffiliated_health_care": "Miembro del Equipo de Salud"
        },
        "review": {
          "header_title": "Revisar sus invitaciones de equipo",
          "error": "Error al enviar invitaciones",
          "edit": "Editar",
          "remove": "Eliminar",
          "add_another": "Añadir otro miembro",
          "cancel": "Cancelar",
          "invite": "Invitar",
          "primary_caregiver": "Contactos",
          "caregiver": "Miembros",
          "support": "Extendido",
          "unaffiliated_health_care": "Miembros del Equipo de Salud"
        },
        "role": {
          "select": "Seleccionar rol",
          "header_title": "Seleccione el nivel de acceso para esta persona.",
          "description": "Esto controla la información que pueden ver y las acciones que pueden realizar.",
          "back": "Volver",
          "cancel": "Cancelar",
          "next": "Siguiente"
        },
        "invite_by_email": "Invitar por correo electrónico",
        "invite_by_code": "Invitar por código de acceso privado",
        "add_team_button": "Añadir a su equipo %{teamType}.",
        "health_team_invite_title": "Invitar a un miembro del equipo de salud por %{inviteType}",
        "support_team_invite_title": "Invitar a un nuevo miembro del equipo de apoyo por %{inviteType}",
        "health_add_team_button": "Añadir a su equipo de salud",
        "support_add_team_button": "Añadir a su equipo de apoyo"
      },
      "relationship": {
        "index": {
          "change": "Cambiar",
          "label": "Relación con %{name}"
        },
        "relationship_form": {
          "cancel": "Cancelar",
          "label": "Relación con %{name}"
        }
      },
      "role": {
        "index": {
          "change": "Cambiar"
        },
        "role_form": {
          "cancel": "Cancelar"
        }
      },
      "active_team": {
        "primary_caregiver": "Contactos",
        "patient": "Persona",
        "caregiver": "Miembros",
        "support": "Extendido"
      },
      "health_team": {
        "title": "Miembros del equipo de salud",
        "pending_health_teams": "Equipos de salud pendientes",
        "invite": "Invitar equipo de salud",
        "author": "Autor",
        "no_members": "No hay miembros del equipo de salud en el equipo.",
        "unknown": "Sin número de teléfono",
        "invitation_modal": {
          "header": "Invitar equipos de salud",
          "success": "Invitaciones enviadas."
        },
        "edit_health_team": {
          "header": "Editar miembros del equipo de salud",
          "subheader": "Seleccione o elimine miembros del personal de su equipo de salud para este Plan de Acción",
          "selected_header": "Miembros del equipo de salud de %{place}",
          "unselected_header": "Personal del equipo de salud de %{place}",
          "no_selected_members": "No se han seleccionado miembros del equipo de salud.",
          "no_unselected_members": "No hay miembros del equipo de salud disponibles.",
          "submit": "Listo",
          "cancel": "Cancelar",
          "error": "Error al actualizar los miembros del equipo de salud.",
          "success": "Miembros del equipo de salud actualizados."
        }
      },
      "unaffiliated_contact_team": {
        "title": "Proveedores de salud invitados",
        "no_members": "Aún no hay miembros de este tipo - añada a alguien.",
        "other": "Otro",
        "pending": "Pendiente...",
        "practitioner": "Profesional médico",
        "case_manager": "Gestor de casos",
        "chiropractor": "Quiropráctico",
        "counselor": "Consejero",
        "dietician": "Dietista",
        "massage_therapist": "Terapeuta de masaje",
        "nurse": "Enfermero/a",
        "nurse_practitioner": "Enfermero/a practicante",
        "occupational_therapist": "Terapeuta ocupacional",
        "osteopath": "Osteópata",
        "pediatrician": "Pediatra",
        "pharmacist": "Farmacéutico",
        "physician_family_doctor": "Médico de familia",
        "physician_specialist": "Médico especialista",
        "physiotherapist": "Fisioterapeuta",
        "psychiatrist": "Psiquiatra",
        "psychologist": "Psicólogo",
        "social_worker": "Trabajador social",
        "care_coordinator": "Coordinador de cuidados"
      },
      "service": {
        "practitioner": "Proveedores de salud",
        "service": "Servicios",
        "people": "Personas",
        "information": "Información",
        "other": "Otro"
      },
      "service_form": {
        "titles": {
          "service": "Nuevo recurso",
          "phone_number": "Número de teléfono",
          "address": "Dirección"
        },
        "name": "Nombre",
        "number": "Número",
        "address": "Dirección",
        "city": "Ciudad",
        "region": "Región",
        "postal_code": "Código postal",
        "submit": "Añadir"
      },
      "service_member": {
        "phone_number": "Número de teléfono",
        "website": "Sitio web",
        "links": "Enlaces",
        "unknown": "N/D"
      },
      "team": {
        "primary_caregiver": {
          "description": "Los contactos principales tienen el mismo acceso que el Paciente. Pueden ver toda la información de %{name}, incluyendo el Plan de Acción. Pueden invitar a otros al equipo, asignar tareas y comunicarse directamente con el Equipo de Salud en nombre de %{name}.",
          "chip": "Acceso máximo"
        },
        "caregiver": {
          "description": "Un miembro del equipo de apoyo puede ver toda la información sobre %{name}, incluyendo el Plan de Acción. Pueden asignar tareas, pero NO pueden comunicarse con los gestores de salud ni añadir nuevos recursos.",
          "chip": "Acceso limitado"
        },
        "support": {
          "description": "Un miembro del equipo extendido solo puede ver las tareas con las que se les ha pedido ayuda y mensajear con el equipo de apoyo.",
          "chip": "Acceso mínimo"
        },
        "no_members": "Aún no hay miembros de este tipo - añada a alguien.",
        "invite_success": "La invitación se envió exitosamente"
      },
      "user_form": {
        "confirm": "¿Esto enviará una invitación por correo electrónico al usuario, está seguro?",
        "email": "Correo electrónico",
        "relationship": "Relación con %{name}",
        "submit": "Enviar invitación"
      },
      "support_team_member": {
        "submit": "Gestionar invitación",
        "confirm": "¿Esto reenviará una invitación al usuario, está seguro?",
        "phone_number": "Número de teléfono",
        "email": "Correo electrónico",
        "unknown": "Sin número de teléfono",
        "warning_message": "Hay un error: \"%{bounceMessage}\" Puede editar y reenviar la invitación según sea necesario",
        "pending": "Pendiente",
        "email_invalid": "Correo electrónico inválido",
        "substitute_decision_maker_abbreviation": "SDM",
        "substitute_decision_maker": "Sustituto en la toma de decisiones",
        "most_responsible_provider": "Proveedor principal responsable"
      },
      "manage_invitation": {
        "body": "Puede <b>Reenviar</b> la invitación o <b>Editar</b> la dirección de correo electrónico. En ambos casos, se enviará una nueva invitación. También puede <b>Eliminar</b> la invitación original.",
        "close": "Cerrar",
        "edit": "Editar",
        "header": "Gestionar invitación",
        "remove": "Eliminar",
        "resend": "Reenviar",
        "sent": "¡Invitación enviada!",
        "send_invitation": "Enviar invitación",
        "place": {
          "header": "Gestionar invitación del equipo de salud",
          "body": "Puede <b>Reenviar</b> la invitación o <b>Eliminar</b> la invitación original."
        }
      },
      "edit_invitation": {
        "header": "Editar invitación",
        "label": "Dirección de correo electrónico",
        "hint": "Se enviará una invitación para unirse al Plan de Acción a este correo.",
        "back": "Volver",
        "send_invitation": "Enviar invitación",
        "cancel": "Cancelar"
      },
      "new_invitation": {
        "header": "Enviar invitación",
        "label": "Dirección de correo electrónico",
        "hint": "Se enviará una invitación para unirse al Plan de Acción a este correo.",
        "send_invitation": "Enviar invitación",
        "cancel": "Cancelar"
      },
      "remove_invitation": {
        "back": "Volver",
        "remove": "Eliminar invitación",
        "confirmation": "¿Está seguro de que desea eliminar esta invitación?",
        "place_confirmation": "¿Está seguro de que desea eliminar la invitación?",
        "information": "Al eliminar esta invitación también se eliminará el correo electrónico asociado a esta persona."
      },
      "select": {
        "primary_caregiver": {
          "header": "Contacto",
          "chip": "Acceso máximo",
          "description": "Generalmente un familiar cercano o amigo.",
          "access": "Los contactos pueden:",
          "list": ["Ver toda la información de salud", "Añadir y enviar mensajes a miembros del equipo, asignar tareas, ver actividad y subir documentos", "Comunicarse con los miembros del equipo de salud"]
        },
        "caregiver": {
          "header": "Miembro",
          "chip": "Acceso limitado",
          "description": "Generalmente un familiar o amigo.",
          "access": "Los miembros pueden:",
          "list": ["Enviar mensajes a miembros del equipo, ver toda la información de salud", "Asignar tareas, ver actividad y subir documentos"]
        },
        "support": {
          "header": "Extendido",
          "chip": "Acceso mínimo",
          "description": "Generalmente un amigo o vecino.",
          "access": "Los miembros extendidos pueden:",
          "list": ["Ver las tareas con las que se les ha pedido ayuda y comunicarse con la persona y su equipo de apoyo"]
        },
        "unaffiliated_health_care": {
          "header": "Proveedor de Salud",
          "chip": "Acceso de solo lectura",
          "description": "Como un médico, enfermero o trabajador social",
          "access": "Proporcionará a este Proveedor de Salud acceso para ver su Plan de Acción. Pueden:",
          "list": ["Ver toda la información de salud", "Ver su membresía en el equipo de salud y apoyo", "Ser asignados a tareas en el Plan de Acción y actualizar su estado"]
        }
      },
      "access_requests": {
        "header": "Solicitudes pendientes para unirse al equipo",
        "approve": "Revisar solicitud",
        "unknown": "Sin número de teléfono",
        "approved": "Solicitud aprobada.",
        "declined": "Solicitud rechazada.",
        "failure": "Algo salió mal."
      },
      "acceptance_modal": {
        "title": "Revisar solicitud de miembro del equipo",
        "role_select": {
          "header_title": "Seleccionar el nivel de acceso para %{name}.",
          "approve": "Aprobar",
          "decline": "Rechazar",
          "cancel": "Cancelar",
          "next": "Siguiente"
        },
        "consent": {
          "header_title": "Aprobar o rechazar solicitud de miembro del equipo",
          "description": "Contactos",
          "declaration": "Tengo permiso para permitir que este nuevo usuario acceda a la información de la persona y pueda actuar en nombre de la persona a través de Careteam.",
          "cancel": "Cancelar",
          "decline": "Rechazar",
          "approve": "Aprobar",
          "edit": "Editar"
        }
      }
    },
    "terms_of_service": {
      "declaration": "Acepto los <a rel='noopener noreferrer' target='_blank' href='/terms_of_service'>%{terms_of_service}</a> y la <a rel='noopener noreferrer' target='_blank' href='/privacy_policy'>%{privacy_policy}</a> de Careteam",
      "terms_of_service": "Términos de Servicio",
      "privacy_policy": "Política de Privacidad",
      "confirmation_prompt": "Por favor confirme para continuar.",
      "welcome_message": "¡Bienvenido/a a Careteam!",
      "error_occurred": "Ha ocurrido un error.",
      "required": "Requerido para usar Careteam",
      "continue": "Continuar"
    },
    "time_zones": {
      "blank_time_zone": "Elija una zona horaria",
      "America/Los_Angeles": "Hora del Pacífico (EE.UU. y Canadá)",
      "America/Denver": "Hora de montaña (EE.UU. y Canadá)",
      "America/Chicago": "Hora central (EE.UU. y Canadá)",
      "America/New_York": "Hora del Este (EE.UU. y Canadá)",
      "America/Halifax": "Hora del Atlántico (Canadá)",
      "Pacific/Midway": "Isla de Midway",
      "Pacific/Pago_Pago": "Samoa Americana",
      "Pacific/Honolulu": "Hawai",
      "America/Juneau": "Alaska",
      "America/Tijuana": "Tijuana",
      "America/Phoenix": "Arizona",
      "America/Chihuahua": "Chihuahua",
      "America/Mazatlan": "Mazatlán",
      "America/Regina": "Saskatchewan",
      "America/Mexico_City": "Ciudad de México",
      "America/Monterrey": "Monterrey",
      "America/Guatemala": "Centroamérica",
      "America/Indiana/Indianapolis": "Indiana (Este)",
      "America/Bogota": "Bogotá",
      "America/Lima": "Lima",
      "America/Caracas": "Caracas",
      "America/La_Paz": "La Paz",
      "America/Santiago": "Santiago",
      "America/St_Johns": "Terranova",
      "America/Sao_Paulo": "Brasilia",
      "America/Argentina/Buenos_Aires": "Buenos Aires",
      "America/Montevideo": "Montevideo",
      "America/Guyana": "Georgetown",
      "America/Godthab": "Groenlandia",
      "Atlantic/South_Georgia": "Atlántico Medio",
      "Atlantic/Azores": "Azores",
      "Atlantic/Cape_Verde": "Cabo Verde",
      "Europe/Dublin": "Dublín",
      "Europe/Lisbon": "Lisboa",
      "Europe/London": "Londres",
      "Africa/Casablanca": "Casablanca",
      "Africa/Monrovia": "Monrovia",
      "Etc/UTC": "UTC",
      "Europe/Belgrade": "Belgrado",
      "Europe/Bratislava": "Bratislava",
      "Europe/Budapest": "Budapest",
      "Europe/Ljubljana": "Liubliana",
      "Europe/Prague": "Praga",
      "Europe/Sarajevo": "Sarajevo",
      "Europe/Skopje": "Skopje",
      "Europe/Warsaw": "Varsovia",
      "Europe/Zagreb": "Zagreb",
      "Europe/Brussels": "Bruselas",
      "Europe/Copenhagen": "Copenhague",
      "Europe/Madrid": "Madrid",
      "Europe/Paris": "París",
      "Europe/Amsterdam": "Ámsterdam",
      "Europe/Berlin": "Berlín",
      "Europe/Zurich": "Zúrich",
      "Europe/Rome": "Roma",
      "Europe/Stockholm": "Estocolmo",
      "Europe/Vienna": "Viena",
      "Africa/Algiers": "África Centro-Occidental",
      "Europe/Bucharest": "Bucarest",
      "Africa/Cairo": "El Cairo",
      "Europe/Helsinki": "Helsinki",
      "Europe/Kiev": "Kyiv",
      "Europe/Riga": "Riga",
      "Europe/Sofia": "Sofía",
      "Europe/Tallinn": "Tallin",
      "Europe/Vilnius": "Vilna",
      "Europe/Athens": "Atenas",
      "Europe/Istanbul": "Estambul",
      "Europe/Minsk": "Minsk",
      "Asia/Jerusalem": "Jerusalén",
      "Africa/Harare": "Harare",
      "Africa/Johannesburg": "Pretoria",
      "Europe/Kaliningrad": "Kaliningrado",
      "Europe/Moscow": "Moscú",
      "Europe/Volgograd": "Volgogrado",
      "Europe/Samara": "Samara",
      "Asia/Kuwait": "Kuwait",
      "Asia/Riyadh": "Riad",
      "Africa/Nairobi": "Nairobi",
      "Asia/Baghdad": "Bagdad",
      "Asia/Tehran": "Teherán",
      "Asia/Muscat": "Abu Dhabi",
      "Asia/Baku": "Bakú",
      "Asia/Tbilisi": "Tiflis",
      "Asia/Yerevan": "Ereván",
      "Asia/Kabul": "Kabul",
      "Asia/Yekaterinburg": "Ekaterimburgo",
      "Asia/Karachi": "Karachi",
      "Asia/Tashkent": "Tashkent",
      "Asia/Kolkata": "Nueva Delhi",
      "Asia/Kathmandu": "Katmandú",
      "Asia/Dhaka": "Dacca",
      "Asia/Colombo": "Sri Jayawardenepura",
      "Asia/Almaty": "Almatý",
      "Asia/Novosibirsk": "Novosibirsk",
      "Asia/Rangoon": "Rangún",
      "Asia/Bangkok": "Bangkok",
      "Asia/Jakarta": "Yakarta",
      "Asia/Krasnoyarsk": "Krasnoyarsk",
      "Asia/Shanghai": "Pekín",
      "Asia/Chongqing": "Chongqing",
      "Asia/Hong_Kong": "Hong Kong",
      "Asia/Urumqi": "Ürümqi",
      "Asia/Kuala_Lumpur": "Kuala Lumpur",
      "Asia/Singapore": "Singapur",
      "Asia/Taipei": "Taipéi",
      "Australia/Perth": "Perth",
      "Asia/Irkutsk": "Irkutsk",
      "Asia/Ulaanbaatar": "Ulán Bator",
      "Asia/Seoul": "Seúl",
      "Asia/Tokyo": "Tokio",
      "Asia/Yakutsk": "Yakutsk",
      "Australia/Darwin": "Darwin",
      "Australia/Adelaide": "Adelaida",
      "Australia/Melbourne": "Melbourne",
      "Australia/Sydney": "Sídney",
      "Australia/Brisbane": "Brisbane",
      "Australia/Hobart": "Hobart",
      "Asia/Vladivostok": "Vladivostok",
      "Pacific/Guam": "Guam",
      "Pacific/Port_Moresby": "Puerto Moresby",
      "Asia/Magadan": "Magadán",
      "Asia/Srednekolymsk": "Srednekolymsk",
      "Pacific/Guadalcanal": "Salomón Is.",
      "Pacific/Noumea": "Nueva Caledonia",
      "Pacific/Fiji": "Fiyi",
      "Asia/Kamchatka": "Kamchatka",
      "Pacific/Majuro": "Marshall Is.",
      "Pacific/Auckland": "Auckland",
      "Pacific/Tongatapu": "Nuku'alofa",
      "Pacific/Fakaofo": "Tokelau Is.",
      "Pacific/Chatham": "Islas Chatham",
      "Pacific/Apia": "Samoa"
    },
    "users": {
      "notification_form": {
        "header": "Notificaciones",
        "info": "La vida es ocupada, y para facilitarle las cosas, por defecto, recibirá notificaciones cada vez que se publique algo en su Careteam. Puede ajustar su configuración a continuación.",
        "preference": "Prefiero recibir notificaciones por:",
        "sms_label": "Mensaje de texto",
        "email_label": "Correo electrónico",
        "save": "Guardar",
        "confirm": "Para confirmar, ha seleccionado que NO desea recibir notificaciones por correo electrónico ni SMS. Esto le hace responsable de iniciar sesión regularmente para verificar y no perderse algo importante. ¿Está seguro?",
        "success": "Los cambios se han guardado correctamente."
      },
      "schedule_form": {
        "morning": "Mañana",
        "afternoon": "Tarde",
        "evening": "Noche",
        "sunday": "Domingo",
        "monday": "Lunes",
        "tuesday": "Martes",
        "wednesday": "Miércoles",
        "thursday": "Jueves",
        "friday": "Viernes",
        "saturday": "Sábado",
        "address": "Su ubicación",
        "save": "Guardar",
        "success": "Los cambios se han guardado correctamente.",
        "morning_hours": "8:00 - 12:00",
        "afternoon_hours": "12:00 - 17:00",
        "evening_hours": "17:00 - 21:00",
        "schedule": "Disponibilidad de horario"
      },
      "profiles_section": {
        "availability": "Disponibilidad",
        "volunteer_availability": "Disponibilidad de voluntario",
        "notifications": "Notificaciones",
        "notification_preferences": "Preferencias de notificaciones"
      }
    },
    "welcome_banner": {
      "activity_feed_banner": {
        "header": "Vea la actividad de su equipo",
        "details": "Puede compartir actualizaciones importantes con su equipo, enviar mensajes de ánimo y obtener una vista general de lo que ha estado sucediendo aquí en su feed de actividad.",
        "button": "¡Entendido!"
      },
      "care_plan_banner": {
        "self_signup_header": "¡Bienvenido/a a su Plan de Acción!",
        "basic_header": "Este Plan de Acción ha sido iniciado por %{placeName}",
        "self_signup_details": "Su Plan de Acción es un espacio para ayudarle a usted y a su equipo a planificar y dar seguimiento a su salud. Para ver las tareas pendientes relacionadas con este Plan de Acción, consulte su",
        "basic_details": "Revise aquí las nuevas tareas, citas, documentos o notas de su equipo de salud. Puede actualizar estas tareas y las suyas propias en su",
        "link_text": "página de Tareas.",
        "button": "¡Entendido!"
      },
      "patient_banner": {
        "header": "Lleve un registro de su historial de salud",
        "details": "Este es un buen lugar para agregar información que desee recordar o comunicar a los equipos de salud y apoyo. ¡De esta manera, todos pueden estar al tanto!",
        "button": "¡Entendido!"
      },
      "tasks_banner": {
        "header": "Sus tareas pendientes",
        "self_signup_details": "Las próximas citas y tareas pendientes de su equipo aparecerán en esta página. Puede asignar a otros miembros de su equipo de atención las tareas en las que necesite ayuda.",
        "basic_details": "¿Qué tareas o citas tiene próximamente? Añádalas aquí - su Equipo de Apoyo también verá sus tareas y podrá ayudarle a completarlas (el Equipo de Salud solo verá las que ellos crearon).",
        "button": "¡Entendido!"
      },
      "my_tasks_banner": {
        "header": "Sus tareas pendientes",
        "basic_details": "Esta es su lista personal de tareas pendientes: elementos que ha ingresado para sí mismo o tareas en las que otros han solicitado su ayuda.",
        "button": "¡Entendido!"
      },
      "health_team_banner": {
        "button": "Cerrar",
        "details": "Ahora es parte del equipo de salud de %{patient_name}. Tiene acceso de solo lectura a este Plan de Acción desde %{originating_place}. Puede ver este Plan de Acción y crear nuevos Planes de Acción.",
        "header": "¡Se le ha agregado exitosamente al Plan de Acción %{care_plan_title} de %{patient_name}!"
      },
      "uhcp_action_plan_index_banner": {
        "invited": "Estas personas le han invitado a unirse a su equipo de atención. Puede ver el plan y actualizar el estado de los elementos bajo su responsabilidad.",
        "email": "Para acceso completo y para registrar a su equipo, por favor envíe un correo a support@getcareteam.com"
      },
      "uhcp_action_plan_show_banner": {
        "hello": "¡Hola %{userName}!",
        "action_plan": "Se le ha agregado al Plan de Acción de %{patientName}"
      },
      "uacg_care_plan_banner": {
        "plan_of_status": "Puede consultar el plan de situación de los artículos aquí.",
        "contact_team": "Póngase en contacto con su equipo para obtener acceso completo.",
        "banner_header": "Comience a explorar el Plan de Acción de %{patient_name}.",
        "banner_subheader_1": "¡Bienvenido a Careteam! Una vez que el equipo de %{place} empiece a colaborar en este Plan de Acción, tendrás acceso a mucho más.",
        "banner_subheader_2": "en este Plan de Acción, tendrás acceso a aún más.",
        "banner_subheader": "¡Bienvenido/a a Careteam! Una vez que el equipo en %{place} comience a colaborar en este Plan de Acción, tendrá acceso a más funciones."
      }
    }
  },
  "fr": {
    "activities": {
      "kind": {
        "generic": "Générique",
        "medical": "Rendez-vous",
        "prescription": "Ordonnance",
        "generic_health": "Tâche",
        "referral": "Renvoi"
      },
      "assignments": {
        "assignment": {
          "accept": "Accepter",
          "decline": "Refuser",
          "assign_someone_else": "Assigner quelqu'un d'autre",
          "what_would_you_do": "Qu'est-ce que vous aimeriez faire?",
          "let_anyone_claim": "Laisser choix libre",
          "claim": "Je le ferai!",
          "message": "Envoyer le message",
          "help_requested": "Un bénévole a été invité à aider avec cette tâche."
        },
        "claim_task": {
          "claim": "Je le ferai!",
          "claim_task": "En revendiquant cette tâche, vous acceptez de prendre la responsabilité de vous assurer que cette tâche est accomplie."
        },
        "index": {
          "no_one_assigned": "Cette tâche n'a pas encore été attribuée."
        },
        "my_assignment": {
          "me": "Moi",
          "will_you_accept": "Accepterez-vous cette tâche?",
          "accept": "Oui, je peux!",
          "decline": "Désolé, je ne peux pas",
          "something_changed": "Quelque chose a changé?",
          "claim": "Je le ferai!"
        }
      },
      "delete": "Supprimer",
      "edit_modal": {
        "edit": "Modifier"
      }
    },
    "activity_feed": {
      "comments": {
        "comment_header": {
          "comment": {
            "one": "1 commentaire",
            "other": "%{count} commentaires"
          },
          "hide": "Cacher"
        },
        "form": {
          "text": "Partagez un commentaire avec votre famille et vos amis",
          "note": "Remarque: votre professionnel de santé ne verra pas les commentaires.",
          "submit": "Poster"
        }
      },
      "activity_header": {
        "appointment": "A créé un rendez-vous",
        "medicaldocument": "Ajouté un document",
        "prescription": "Ajouté une prescription",
        "task": "A ajouté une tâche",
        "status": "Publié une mise à jour"
      },
      "appointment": {
        "when": "Quand: ",
        "with": "Avec: ",
        "where": "Où: "
      },
      "assignments": {
        "assigned_to": "Assigné à: ",
        "pending": "En attendant",
        "accepted": "Accepté",
        "declined": "Diminué",
        "open": "Ouvrir"
      },
      "index": {
        "welcome": "Envoyez un message pour que tout le monde sache que vous faites partie de l'équipe!",
        "empty": "Aucun résultat"
      },
      "prescription": {
        "status": "Statut: ",
        "filled": "Filled",
        "to_be_filled": "Non remplie"
      }
    },
    "activity_modal": {
      "add_appointment_title": "Ajouter Rendez-vous",
      "add_check_in_title": "Ajouter une Mise à jour",
      "add_task_title": "Ajouter une tâche",
      "add_form_title": "Ajouter un Formulaire",
      "edit_appointment_title": "Modifier Rendez-vous",
      "edit_task_title": "Modifier la tâche",
      "description_field_title": "Description",
      "error_empty": "Obligatoire",
      "errors": {
        "api_error": "Erreur: une erreur s'est produit",
        "form_api_error": "Une erreur s'est produite et nous ne pouvons pas ajouter ce formulaire. Si le problème persiste, veuillez envoyer un e-mail à support@getcareteam.com."
      },
      "footer": {
        "delete_button": {
          "delete": "Supprimer",
          "confirm": "Confirmer?"
        },
        "cancel": "Annuler",
        "save": "Sauvegarder"
      },
      "form_fields": {
        "resources_list": {
          "unnamed_resource": "Ressource sans nom"
        },
        "validators": {
          "error_empty": "Obligatoire",
          "invalid_link": "Lien invalide",
          "missing_http": "Veuillez ajouter http:// ou https:// au début de votre lien",
          "missing_check_in_template": "Choisir un type de Mise à jour de la Bibliothèque",
          "missing_form_template": "Choisir un Formulaire de la Bibliothèque"
        },
        "what": {
          "error_empty": "Obligatoire",
          "description": "Quoi:",
          "appointment_description": "Description du rendez-vous",
          "task_description": "Description de la tâche",
          "form_description": "Nom du Formulaire"
        },
        "who": {
          "title": "Assigner",
          "description": "Qui:",
          "error_contacts_load": "Erreur: On ne peut pas montrer les contacts.",
          "open_button": "Assigner",
          "no_team_members": "Aucun membre de l'équipe pour le moment",
          "notification_header": "%{patientFirstName} et leur Supporteurs Primaires seront avertis lorsque de nouvelles Tâches sont ajoutées, même si elles ne sont pas attribuées ou si elles sont attribuées à quelqu’un d’autre."
        },
        "where": {
          "description": "Où:",
          "virtual_visit_link_description": "URL pour la visite virtuelle",
          "invalid_link": "Lien invalide",
          "missing_http": "Veuillez ajouter http:// ou https:// au début de votre lien",
          "address": {
            "description": "Adresse",
            "add_address": "Ajouter une adresse",
            "remove_address": "Supprimer l'adresse",
            "street_address": "Adresse municipale",
            "extended_address": "Appartement, suite, etc. (facultatif)",
            "city": "Ville",
            "province": "Province",
            "state": "État",
            "postal_code": "Code postal",
            "zip_code": "Code postal",
            "postcode": "Code postal",
            "country": "Pays"
          }
        },
        "when": {
          "description": "Quand",
          "scheduled": "Prévue",
          "not_scheduled": "Pas encore prévue",
          "days": "Jour(s)",
          "weeks": "Semaine(s)",
          "months": "Mois",
          "repeats_every": "Répète tous les",
          "ends": "Prend fin:",
          "never": "Jamais",
          "on": "Le",
          "after": "Après",
          "times": "fois",
          "date_and_time": "Date et heure",
          "repeats": "Répète"
        },
        "visibility": {
          "hcp_only": "Seulement l'Équipe de santé",
          "default": "Défaut",
          "everyone": "Tout le monde",
          "who_should_see": "Qui peut voir cette Mise à jour?"
        },
        "when_should_be_completed": {
          "check_in_label": "Quand cette Mise à jour doit-elle être effectuée ?",
          "form_label": "Quand ce Formulaire doit-il être rempli ?",
          "task_label": "Quand cette Tâche doit-elle être terminée ?",
          "appointment_label": "Quand ce Rendez-vous doit-il être complété ?",
          "scheduled": "Programmée - une fois ou répétée",
          "any_time": "À tout moment",
          "to_be_booked": "Non réservé"
        },
        "templates": {
          "unnamed_appointment": "Rendez-vous sans nom",
          "unnamed_task": "Tâche sans nom",
          "unnamed_form": "Formulaire sans nom",
          "api_error": "Erreur: On ne peut pas montrer les modèles.",
          "no_templates": "Pas de Modèle à afficher.",
          "fill_using_a_template": "Remplir utilisant un modèle"
        },
        "select_from_library": {
          "unnamed_check_in": "Mise à jour sans nom",
          "unnamed_form": "Formulaire sans nom",
          "api_error": "Erreur: On ne peut pas montrer la Bibliothèque.",
          "no_templates": "Rien à afficher.",
          "select_check_in": "Choisir un type de Mise à jour de la Bibliothèque",
          "select_form": "Choisir un Formulaire de la Bibliothèque"
        },
        "resources": {
          "title": "Resources",
          "api_error": "Erreur : On ne peut pas montrer les ressources.",
          "links": "Liens:",
          "no_resources": "Pas de ressources à afficher.",
          "add_resources": "Ajouter des Ressources"
        }
      }
    },
    "applets": {
      "recommendations": {
        "title": "Recommandations",
        "fetch_error": "Une erreur s'est produite lors de la tentative d'aller chercher les données.",
        "save_error": "Une erreur s'est produite lors de la sauvegarde d'enregistrement des données.",
        "show_older": "Afficher les recommandations plus anciennes",
        "hide_older": "Masquer les recommandations plus anciennes",
        "empty": "Aucune pour ce Plan d'Action.",
        "created_by_with_date": "Créé par %{name} le %{created_at}",
        "updated_by_with_date": "Mis à jour par %{name} sur %{updated_at}",
        "edit": "Modifier",
        "remove": "Supprimer",
        "save": "Sauvegarder",
        "cancel": "Annuler",
        "discard_changes": "Annuler les modifications",
        "required": "Obligatoire",
        "confirm_remove_prompt": "Voulez-vous vraiment supprimer cette recommandation?",
        "confirm_remove": "Oui, supprimer",
        "cancel_remove": "Non, conservez",
        "placeholder": "Entrez votre recommandation"
      },
      "symptom_intensity": {
        "title": "Intensité des symptômes",
        "fetch_error": "Une erreur s'est produite lors de la tentative d'aller chercher les données.",
        "legend": ["Très bas", "Faible", "Moyen", "Haut", "Très élevé", "Aucune donnée"],
        "categories": ["Très bas", "Bas", "Moyen", "Élevé", "Très élevée", "Pas de données"]
      },
      "index": {
        "fetch_error": "Une erreur s'est produite lors de la tentative d'aller chercher les données."
      },
      "symptom_occurrence": {
        "title": "Début d'apparition des symptômes",
        "fetch_error": "Une erreur s'est produite lors de la tentative d'aller chercher les données.",
        "medication": "Médicaments",
        "legend": ["Est survenu", "Ne s’est pas produit", "Pas de données", "Pouls rapide", "Nausées", "Maux d'estomac", "Mal au dos", "Douleurs aux membres"],
        "categories": ["Action accomplie", "Maux d'estomac", "Mal de tête", "Mal au dos", "Vertiges", "Douleurs aux membres", "Pouls rapide", "Nausées", "Faiblesse corporelle"]
      },
      "worry_intensity": {
        "title": "Intensité de l'inquiétude",
        "fetch_error": "Une erreur s'est produite lors de la tentative d'aller chercher les données.",
        "legend": ["Évitement", "Très bas", "Bas", "Moyen", "Élevé", "Très élevée"],
        "categories": ["Évitement", "Très bas", "Bas", "Moyen", "Élevé", "Très élevée"]
      },
      "worried_about": {
        "title": "Ce qui m'inquiétait",
        "fetch_error": "Une erreur s'est produite lors de la tentative d'aller chercher les données.",
        "legend": ["A eu lieu", "Ne s’est pas produit", "Pas de données", "École", "Sports", "Santé"],
        "categories": ["Famille", "Amis", "Étrangers", "École", "Sports", "Santé"]
      },
      "certainty_expectation": {
        "title": "Mon niveau de certitude",
        "fetch_error": "Une erreur s'est produite lors de la tentative d'aller chercher les données.",
        "extremely": "Extrêmement",
        "not_at_all": "Pas du tout",
        "event_happened": "L'événement a eu lieu",
        "legend": ["Niveau d'inquiétude", "Niveau de certitude", "Pas de données"]
      },
      "expected_vs_reality": {
        "title": "Problèmes: prévus vs réalité",
        "extremely": "Extrêmement",
        "not_at_all": "Pas du tout",
        "fetch_error": "Une erreur s'est produite lors de la tentative d'aller chercher les données.",
        "legend": ["Matin (prévision)", "Après-midi (réalité)", "Soir (réalité)", "Pas de données"]
      },
      "going_to_school": {
        "title": "Va à l’école",
        "fetch_error": "Une erreur s'est produite lors de la tentative d'aller chercher les données.",
        "doctor": "Médecin",
        "home_school": "École à domicile",
        "sick": "Malade",
        "online": "En ligne",
        "holiday": "Congé",
        "vacation": "Vacances",
        "weekend": "Fin de semaine",
        "attended": "A participé",
        "no_data": "Pas de données",
        "chronic_pain": "Douleur chronique"
      },
      "friend_interaction": {
        "title": "Mon souci d’interagir avec des amis",
        "fetch_error": "Une erreur s'est produite lors de la tentative d'aller chercher les données.",
        "extremely": "Extrêmement",
        "not_at_all": "Pas du tout",
        "legend": ["Matin (prévision)", "Après-midi (réalité)", "Soir (réalité)", "Pas de données"]
      },
      "no_data": "Pas de données",
      "friend_reaction": {
        "title": "Je m'entends bien avec mes amis",
        "fetch_error": "Une erreur s'est produite lors de la tentative d'aller chercher les données.",
        "legend": ["Matin", "Après midi", "Soir", "Pas de données"],
        "categories": ["Très négative", "Négative", "Neutre", "Positive", "Très positive", "Aucune interaction"]
      },
      "my_emotions": {
        "title": "Mes émotions",
        "subtitle": "Les lignes représentent les niveaux d'émotion au fil du temps, tandis que les bulles représentent le résumé général.",
        "fetch_error": "Une erreur s'est produite lors de la tentative d'aller chercher les données.",
        "extremely": "Extrêmement",
        "not_at_all": "Pas du tout",
        "worried_scared": "Inquiet/effrayé",
        "happy": "Heureux",
        "miserable": "Misérable",
        "angry": "Fâché",
        "frustrated": "Frustré"
      },
      "my_sleep": {
        "title": "Mon sommeil",
        "fetch_error": "Une erreur s'est produite lors de la tentative d'aller chercher les données.",
        "hours": "Heures",
        "noon": "Midi",
        "midnight": "Minuit",
        "am": "matin",
        "pm": "après-midi",
        "legend": ["Pas bien", "Moyen", "Bien", "Super", "Pas de données"]
      },
      "days": {
        "Sun": "Dim",
        "Mon": "Lun",
        "Tue": "Mar",
        "Wed": "Mer",
        "Thu": "Jeu",
        "Fri": "Ven",
        "Sat": "Sam"
      }
    },
    "appointments": {
      "form": {
        "text": "Description",
        "practitioner": "Avec",
        "virtual_visit": "URL pour la visite virtuelle",
        "assignments": "Assigner",
        "due_at": "Quand",
        "cancel": "Annuler",
        "submit": "Sauvegarder"
      },
      "fields": {
        "description": "Description",
        "practitioner": "Avec",
        "virtual_visit": "URL pour la visite virtuelle",
        "time": "Quand",
        "assignments": "Assigner",
        "cancel": "Annuler",
        "optional": "(Optionnel)",
        "completed_at": "Rendez-vous doit être créé",
        "save": "Sauvegarder",
        "address": {
          "street_address": "Adresse municipale",
          "extended_address": "Appartement, suite, etc. (facultatif)",
          "city": "Ville",
          "province": "Province",
          "state": "État",
          "postal_code": "Code postal",
          "zip_code": "Code postal",
          "postcode": "Code postal",
          "country": "Pays"
        },
        "validators": {
          "error_empty": "Obligatoire",
          "invalid_link": "Lien invalide",
          "missing_http": "Veuillez ajouter http:// ou https:// au début de votre lien"
        }
      },
      "edit_form": {
        "request_volunteer": "Demander un bénévolat",
        "link_resources": "Lier les ressources",
        "resources": "Resources",
        "no_resources_present": "Pas de Ressources disponibles pour ajouter à ce Plan d'Action",
        "cancel": "Annuler",
        "save": "Sauvegarder"
      },
      "actions_container": {
        "edit": "Modifier"
      }
    },
    "aside": {
      "tooltips": {
        "close": "Fermer"
      }
    },
    "base": {
      "action_headers": {
        "add": "Ajouter",
        "cancel": "Annuler",
        "edit": "Modifier",
        "carehub_sync": "Champs pouvant être synchronisés avec Care Hub"
      },
      "print_button": {
        "print": "Imprimer"
      },
      "error_occurred": "Une erreur est survenue.",
      "invite_code": {
        "link": "Lien"
      },
      "invite_qr_code": {
        "download": "Télécharger",
        "copy": "Copier",
        "copied": "Copié !"
      },
      "radio_button_list": {
        "search": "Rechercher...",
        "search_aria": "Rechercher",
        "required": "Obligatoire"
      }
    },
    "blocks": {
      "no_data": "Pas de données",
      "days": {
        "Sun": "Dim",
        "Mon": "Lun",
        "Tue": "Mar",
        "Wed": "Mer",
        "Thu": "Jeu",
        "Fri": "Ven",
        "Sat": "Sam"
      },
      "index": {
        "fetch_error": "Une erreur s'est produite lors de la tentative d'aller chercher les données."
      },
      "recommendations": {
        "title": "Recommandations",
        "fetch_error": "Une erreur s'est produite lors de la tentative d'aller chercher les données.",
        "save_error": "Une erreur s'est produite lors de la sauvegarde d'enregistrement des données.",
        "show_older": "Afficher les recommandations plus anciennes",
        "hide_older": "Masquer les recommandations plus anciennes",
        "empty": "Aucune pour ce Plan d'Action.",
        "created_by_with_date": "Créé par %{name} le %{created_at}",
        "updated_by_with_date": "Mis à jour par %{name} sur %{updated_at}",
        "edit": "Modifier",
        "remove": "Supprimer",
        "save": "Sauvegarder",
        "cancel": "Annuler",
        "discard_changes": "Annuler les modifications",
        "required": "Obligatoire",
        "confirm_remove_prompt": "Voulez-vous vraiment supprimer cette recommandation?",
        "confirm_remove": "Oui, supprimer",
        "cancel_remove": "Non, conservez",
        "placeholder": "Entrez votre recommandation"
      },
      "symptom_intensity": {
        "title": "Intensité des symptômes",
        "fetch_error": "Une erreur s'est produite lors de la tentative d'aller chercher les données.",
        "categories": ["Très bas", "Bas", "Moyen", "Élevé", "Très élevée", "Ne s’est pas produit", "Pas de données"]
      },
      "symptom_occurrence": {
        "title": "Début d'apparition des symptômes",
        "fetch_error": "Une erreur s'est produite lors de la tentative d'aller chercher les données.",
        "categories": ["Action accomplie", "Maux d'estomac", "Mal de tête", "Mal au dos", "Vertiges", "Douleurs aux membres", "Pouls rapide", "Nausées", "Faiblesse corporelle"],
        "legend": ["Est survenu", "Ne s’est pas produit", "Pas de données"]
      },
      "worry_intensity": {
        "title": "Niveau d'inquiétude",
        "fetch_error": "Une erreur s'est produite lors de la tentative d'aller chercher les données.",
        "categories": ["Évitement", "Très bas", "Bas", "Moyen", "Élevé", "Très élevée"]
      },
      "worried_about": {
        "title": "Ce qui m'inquiétait",
        "fetch_error": "Une erreur s'est produite lors de la tentative d'aller chercher les données.",
        "categories": ["Famille", "Amis", "Étrangers", "École", "Sports", "Santé"],
        "legend": ["Est survenu", "Ne s’est pas produit", "Pas de données"]
      },
      "certainty_expectation": {
        "title": "Mon niveau de certitude",
        "fetch_error": "Une erreur s'est produite lors de la tentative d'aller chercher les données.",
        "extremely": "Extrêmement",
        "not_at_all": "Pas du tout",
        "event_happened": "L'événement a eu lieu",
        "legend": ["Niveau d'inquiétude", "Niveau de certitude", "Pas de données"]
      },
      "expected_vs_reality": {
        "title": "Problèmes: prévus vs réalité",
        "extremely": "Extrêmement",
        "not_at_all": "Pas du tout",
        "fetch_error": "Une erreur s'est produite lors de la tentative d'aller chercher les données.",
        "legend": ["Matin (prévision)", "Après-midi (réalité)", "Soir (réalité)", "Pas de données"]
      },
      "going_to_school": {
        "title": "Aller à l'école",
        "fetch_error": "Une erreur s'est produite lors de la tentative d'aller chercher les données.",
        "doctor": "Médecin",
        "home_school": "École à domicile",
        "sick": "Malade",
        "online": "En ligne",
        "holiday": "Congé",
        "vacation": "Vacances",
        "weekend": "Fin de semaine",
        "attended": "A participé",
        "chronic_pain": "Douleur chronique",
        "no_data": "Pas de données"
      },
      "friend_interaction": {
        "title": "Mon souci d’interagir avec des amis",
        "fetch_error": "Une erreur s'est produite lors de la tentative d'aller chercher les données.",
        "extremely": "Extrêmement",
        "not_at_all": "Pas du tout",
        "legend": ["Matin (prévision)", "Après-midi (réalité)", "Soir (réalité)", "Pas de données"]
      },
      "friend_reaction": {
        "title": "Je m'entends bien avec mes amis",
        "fetch_error": "Une erreur s'est produite lors de la tentative d'aller chercher les données.",
        "legend": ["Matin (prévision)", "Après-midi (réalité)", "Soir (réalité)", "Pas de données"],
        "categories": ["Très négative", "Négative", "Neutre", "Positive", "Très positive", "Aucune interaction"]
      },
      "my_emotions": {
        "title": "Mes émotions",
        "subtitle": "Les lignes représentent les niveaux d'émotion au fil du temps, tandis que les bulles représentent le résumé général.",
        "fetch_error": "Une erreur s'est produite lors de la tentative d'aller chercher les données.",
        "extremely": "Extrêmement",
        "not_at_all": "Pas du tout",
        "worried_scared": "Inquiet/effrayé",
        "happy": "Heureux",
        "miserable": "Misérable",
        "angry": "Fâché",
        "frustrated": "Frustré"
      },
      "my_sleep": {
        "title": "Mon sommeil",
        "fetch_error": "Une erreur s'est produite lors de la tentative d'aller chercher les données.",
        "hours": "Heures",
        "noon": "Midi",
        "midnight": "Minuit",
        "am": "matin",
        "pm": "après-midi",
        "legend": ["Pas bien", "Moyen", "Bien", "Super", "Pas de données"]
      }
    },
    "care_plan_action_plan_page": {
      "activities_container": {
        "api_error": "Erreur: On ne peut pas montrer les informations de l'utilisateur.",
        "unexpected_error": "Erreur: Une erreur inattendue s’est produite.",
        "appointments_card": {
          "api_error": "Erreur: On ne peut pas montrer les rendez-vous.",
          "unexpected_error": "Erreur: Une erreur inattendue s’est produite.",
          "empty": "Aucune pour ce Plan d'Action.",
          "title": "Rendez-vous",
          "appointment": {
            "booked": "Réservé",
            "not_booked": "Non réservé",
            "edit": "Modifier",
            "help_requested": "Un bénévole a été invité à aider à cette tâche.",
            "unnamed_appointment": "Rendez-vous sans nom"
          },
          "repeated_appointment": {
            "repeat_statement": "Se répète %{localizedFrequency} jusqu'à %{until}",
            "repeat_statement_infinity": "Se répète %{localizedFrequency}",
            "unnamed_appointment": "Rendez-vous sans nom"
          }
        },
        "check_ins_card": {
          "api_error": "Erreur: On ne peut pas montrer les Mises à jour.",
          "unexpected_error": "Erreur: Une erreur inattendue s’est produite.",
          "empty": "Aucune pour ce Plan d'Action.",
          "title": "Mises à jour",
          "check_in": {
            "completed": "Accomplie",
            "not_completed": "Incomplet",
            "edit": "Modifier",
            "due_at_statement": "Le %{dueDate}",
            "unnamed_check_in": "Mise à jour sans nom"
          },
          "repeated_check_in": {
            "repeat_statement": "Répétition %{localizedFrequency} jusqu'à %{until}",
            "repeat_statement_infinity": "Répétition %{localizedFrequency}",
            "unnamed_check_in": "Mise à jour sans nom"
          }
        },
        "tasks_card": {
          "api_error": "Erreur: Impossible de montrer les Tâches.",
          "unexpected_error": "Erreur: Une erreur inattendue s'est produite.",
          "empty": "Aucune pour ce Plan d'Action.",
          "title": "Tâches",
          "task": {
            "completed": "Accomplie",
            "not_completed": "Incomplet",
            "edit": "Modifier",
            "due_at_statement": "Le %{dueDate}",
            "unnamed_task": "Tâche sans nom"
          },
          "repeated_task": {
            "repeat_statement": "Se répète %{localizedFrequency} jusqu'à %{until}",
            "repeat_statement_infinity": "Se répète %{localizedFrequency}",
            "unnamed_task": "Tâche sans nom"
          }
        },
        "forms_card": {
          "api_error": "Erreur: On ne peut pas montrer les formulaires.",
          "unexpected_error": "Erreur: Une erreur inattendue s’est produite.",
          "empty": "Aucune pour ce Plan d'Action.",
          "title": "Formulaires",
          "form": {
            "unnamed_form": "Formulaire sans nom",
            "completed": "Accomplie",
            "not_completed": "Incomplet",
            "edit": "Modifier",
            "due_at_statement": "Le %{dueDate}"
          },
          "repeated_form": {
            "unnamed_form": "Formulaire sans nom",
            "repeat_statement": "Répète % {localizedFrequency} à % {formattedTime} jusqu'à % {until}",
            "repeat_statement_infinity": "Répète % {localizedFrequency} à % {formattedTime}"
          }
        }
      },
      "activity_modal": {
        "add_appointment_title": "Ajouter Rendez-vous",
        "description_field_title": "Description",
        "error_empty": "Obligatoire",
        "create": {
          "api_error": "Erreur: Quelque chose n'a pas fonctionné, s'il vous plait réessayez.",
          "footer": {
            "cancel": "Annuler",
            "save": "Sauvegarder",
            "delete_button": {
              "delete": "Supprimer",
              "confirm": "Confirmer?"
            }
          },
          "form_api_error": "Une erreur s'est produite et nous ne pouvons pas ajouter ce formulaire. Si le problème persiste, veuillez envoyer un e-mail à support@getcareteam.com."
        },
        "form_fields": {
          "validators": {
            "error_empty": "Obligatoire",
            "invalid_link": "Lien invalide",
            "missing_http": "Veuillez ajouter http:// ou https:// au début de votre lien",
            "missing_check_in_template": "Choisir un type de Mise à jour de la Bibliothèque",
            "missing_form_template": "Choisir un Formulaire de la Bibliothèque"
          },
          "what": {
            "error_empty": "Obligatoire",
            "description": "Quoi:",
            "appointment_description": "Description du rendez-vous",
            "task_description": "Description de la Tâche",
            "form_description": "Nom du Formulaire"
          },
          "who": {
            "description": "Qui:",
            "error_contacts_load": "Erreur: On ne peut pas montrer les contacts.",
            "open_button": "Assigner",
            "no_team_members": "Aucun membre de l'équipe pour le moment",
            "notification_header": "%{patientFirstName} et le contact de l'Équipe de Support seront informés de l'ajout de nouvelles Tâches, même si elles ne sont pas encore attribuées ou elles sont attribuées à quelqu'un d'autre.",
            "title": "Assigner"
          },
          "where": {
            "description": "Où:",
            "virtual_visit_link_description": "URL pour la visite virtuelle",
            "invalid_link": "Lien invalide",
            "missing_http": "Veuillez ajouter http:// ou https:// au début de votre lien",
            "address": {
              "description": "Adresse",
              "add_address": "Ajouter une adresse",
              "remove_address": "Supprimer cette adresse",
              "street": "Adresse",
              "city": "Ville",
              "region": "Région",
              "postal_code": "Code postal",
              "country_code": "Code de pays",
              "street_address": "Adresse municipale",
              "extended_address": "Appartement, suite, etc. (facultatif)",
              "province": "Province",
              "state": "État",
              "zip_code": "Code postal",
              "postcode": "Code postal",
              "country": "Pays"
            }
          },
          "when": {
            "description": "Quand",
            "scheduled": "Prévue",
            "not_scheduled": "Pas encore prévue",
            "days": "Jour(s)",
            "weeks": "Semaine(s)",
            "months": "Mois",
            "repeats_every": "Répète tous les",
            "ends": "Finit:",
            "never": "Jamais",
            "on": "Le",
            "after": "Après",
            "times": "fois",
            "date_and_time": "Date et heure",
            "repeats": "Se répète"
          },
          "templates": {
            "api_error": "Erreur: On ne peut pas montrer les modèles.",
            "no_templates": "Pas de Modèle à afficher.",
            "fill_using_a_template": "Remplir utilisant un modèle",
            "unnamed_appointment": "Rendez-vous sans nom",
            "unnamed_task": "Tâche sans nom",
            "unnamed_form": "Formulaire sans nom"
          },
          "resources": {
            "api_error": "Erreur : On ne peut pas montrer les ressources.",
            "links": "Liens:",
            "no_resources": "Pas de ressources à afficher.",
            "add_resources": "Ajouter des Ressources",
            "title": "Resources"
          },
          "when_should_be_completed": {
            "check_in_label": "Quand cette Mise à jour doit-elle être effectuée?",
            "form_label": "Quand faut-il remplir ce Formulaire ?",
            "task_label": "Quand cette Tâche doit-elle être effectuée?",
            "scheduled": "Programmée - une fois ou répétée",
            "any_time": "A tout moment",
            "appointment_label": "Quand ce Rendez-vous doit-il être complété ?",
            "to_be_booked": "A réserver"
          },
          "select_from_library": {
            "api_error": "Erreur: On ne peut pas montrer la Bibliothèque.",
            "no_templates": "Rien à afficher.",
            "select_check_in": "Choisir Tâche de la Bibliothèque",
            "unnamed_check_in": "Mise à jour sans nom",
            "unnamed_form": "Formulaire sans nom",
            "select_form": "Choisir Formulaire de la Bibliothèque"
          },
          "resources_list": {
            "unnamed_resource": "Ressource sans nom"
          },
          "visibility": {
            "hcp_only": "Seulement l'Équipe de santé",
            "default": "Défaut",
            "everyone": "Tout le monde",
            "who_should_see": "Qui peut voir cette Mise à jour?"
          }
        },
        "add_check_in_title": "Ajouter Mise à jour",
        "add_task_title": "Ajouter une Tâche",
        "add_form_title": "Ajouter un Formulaire",
        "edit_appointment_title": "Modifier Rendez-vous",
        "edit_task_title": "Modifier la Tâche"
      }
    },
    "care_plan_creators": {
      "name": {
        "title_label": "Nom du modèle de Plan d'Action",
        "title_sublabel": "Ce nom sera utilisé en interne pour organiser les différents modèles disponibles.",
        "title_placeholder": "Exemples: «Démence légère - région d'Ottawa français»",
        "next": "Suivant"
      },
      "library_task_templates": {
        "form": {
          "title_label": "* Nom du modèle de Tâche",
          "title_sublabel": "Ce nom sera utilisé en interne pour organiser les différents modèles disponibles.",
          "title_placeholder": "Exemple: \"Modèle de tâche qui précède le Rendez-vous\"",
          "description_label": "* Description par défaut de la Tâche",
          "description_sublabel": "Ceci sera la description que la Tâche aura, si elle n'est pas modifiée par le praticien.",
          "recurrence_label": "Prévoir une date pour la Tâche",
          "recurrence_sublabel": "Ceci est facultatif, vous pouvez planifier la Tâche maintenant ou demander au praticien de la faire lors de la création du Plan d’action.",
          "next": "Suivant",
          "link_resources": "Lier des ressources",
          "resources": "Ressources"
        }
      },
      "library_appointment_templates": {
        "form": {
          "address": "Adresse",
          "city": "Ville",
          "region": "Région",
          "postal_code": "Code postal",
          "country_code": "Code du pays",
          "practitioner": "Avec",
          "virtual_visit": "URL pour la visite virtuelle",
          "title_label": "* Nom du modèle de Rendez-vous",
          "title_sublabel": "Ce nom sera utilisé en interne pour organiser les différents modèles disponibles.",
          "title_placeholder": "Exemple : \"Suivi en oncologie de 6 mois\"",
          "description_label": "* Description par défaut du Rendez-vous",
          "description_sublabel": "Ceci sera la description que le rendez-vous aura, si elle n'est pas modifiée par le praticien.",
          "recurrence_label": "Planifier Rendez-vous",
          "recurrence_sublabel": "Ceci est facultatif, vous pouvez planifier le Rendez-vous maintenant ou demander au praticien de la faire lors de la création du Plan d’action.",
          "next": "Suivant",
          "link_resources": "Lier des ressources",
          "resources": "Ressources",
          "street_address": "Adresse municipale",
          "extended_address": "Appartement, suite, etc. (facultatif)",
          "province": "Province",
          "state": "État",
          "zip_code": "Code postal",
          "postcode": "Code postal",
          "country": "Pays"
        }
      },
      "check_in_templates": {
        "name": {
          "title_label": "Nom du Modèle de Mise à jour",
          "title_sublabel": "Ce nom sera utilisé en interne pour organiser les différents modèles disponibles.",
          "title_placeholder": "Exemple : « Mise à jour de l’échelle de la douleur de l’Outaouais »",
          "next": "Suivant"
        },
        "questions": {
          "question_type": "Type de question",
          "blank": "--Sélectionner--",
          "pain_scale_5": "Échelle d'humeur sur 5",
          "generic_scale_5": "Échelle générique 1-10",
          "generic_scale_10": "Échelle générique 1-10",
          "numeric_input": "Entrée numérique",
          "yes_no": "Oui Non",
          "mood_scale_10": "Échelle d'humeur sur 10",
          "multiple_choice": "Choix multiple",
          "free_text": "Texte libre",
          "title_label": "Nommer la question",
          "response_label": "Réponse à la question",
          "conditional_response": "Ajouter une confirmation de réponse conditionnelle",
          "default_response": "Merci, %name. Votre enregistrement a été enregistré.",
          "units": "Unités",
          "upper_limit": "Limite supérieure",
          "lower_limit": "Limite inférieure",
          "add_response": "Ajouter une autre option de réponse",
          "confirmation": "Voulez-vous vraiment supprimer cette réponse?",
          "delete_response": "Supprimer la réponse",
          "add_question": "Ajouter une autre question",
          "save_question": "Sauvegarder la question",
          "delete": "Supprimer",
          "cancel": "Annuler",
          "next": "Suivant",
          "edit": "Modifier",
          "placeholders": {
            "generic_scale_description": "Description",
            "generic_scale_text": "Texte",
            "pain_scale_description": "Description",
            "custom_response": "Texte de la réponse",
            "pain_scale_text": "Texte",
            "numeric_input_units": "par exemple \"l\" ou \"kg\"",
            "numeric_input_upper_title": "Les limites sont facultatives, laissez vide si aucune limite n’est nécessaire",
            "numeric_input_upper_limit": "par exemple \"400\"",
            "numeric_input_lower_limit": "par exemple \"0\"",
            "mood_scale_description": "Description",
            "mood_scale_text": "Texte",
            "free_text_description": "Description",
            "free_text_text": "Texte",
            "multiple_choice_response": "Texte de la réponse",
            "multiple_choice_description": "Description longue (facultative)",
            "multiple_choice_text": "Description rapide"
          },
          "generic_scale_11": "Échelle générique 0-10",
          "generic_scale_0_100": "Échelle générique 0-100"
        },
        "visibility": {
          "who_can_see": "Qui peut voir cette Mise à jour?",
          "everyone": "Tout le monde",
          "hcp_only": "Seulement l'Équipe de santé",
          "will_not_affect": "La définition de cette visibilité n'affectera pas les Mises à jour déjà ajoutées aux Plans d'Action individuels.",
          "next": "Suivant",
          "api_error": "Quelque chose n'a pas fonctionné, s'il vous plait réessayez."
        }
      },
      "details": {
        "next": "Suivant",
        "skip": "Sauter",
        "details_form": {
          "title_label": "Nommer le Plan d’action",
          "title_sublabel": "Donnez au Plan d'Action un nom que tous les membres de l'équipe reconnaîtront. Essayez d'utiliser des mots qui sont familiers à la personne et à son équipe.",
          "list_label": "Diagnostic pour ce Plan d'Action",
          "empty_list_none_added": "Aucun diagnostic n'a été ajouté pour ce Plan d'Action. <br/> Cliquez sur \"Ajouter un diagnostic\" pour commencer.",
          "diagnosis_placeholder": "Entrez la description du Problème...",
          "title_placeholder": "Exemples: \"Mémoire\" ou \"Bien-être\" ou \"Battre le cancer\"",
          "add_diagnosis": "Ajouter un Problème",
          "edit": "Modifier",
          "date": "Date de début du Plan d'Action : %{startAt}",
          "date_update_label": "Si vous modifiez la date de début, cette modification s'appliquera automatiquement à toutes les dates programmées du plan.",
          "done": "Terminé",
          "apply": "Appliquer le changement",
          "date_modal_header": "Changer la date de début du Plan d'Action",
          "revert_to_default_label": "Restituer à la date d'origine: %{date}"
        }
      },
      "careteam": {
        "patient_form": {
          "header": "Inviter %{name}",
          "invite": "Envoyer l'invitation",
          "sublabel": "Entrez une adresse e-mail pour les inviter au Plan d'Action (le cas échéant)",
          "placeholder": "Entrer l'adresse e-mail...",
          "email": "E-mail",
          "skip": "Sauter",
          "save": "Sauvegarder",
          "edit": "Modifier",
          "add": "+ Ajouter",
          "hint": "Si la personne pour qui le Plan d’Action a été créé n’aura pas accès à Careteam elle-même, laissez ce champ vide et plutôt invitez les soignants ci-dessous.",
          "header_new": "Inviter %{name}",
          "submit_error": "Nous n'avons pas pu envoyer l'e-mail. Veuillez réessayer ou nous contacter."
        },
        "primary_caregiver_form": {
          "sublabel": "L’invitation d’un Supporteur Primaire permet de donner un meilleur support à %{firstName}. Il s’agit généralement de leur soignant naturel ou d’une personne capable de copiloter leurs soins. Ils auront un accès complet à ce Plan d’Action au même niveau que %{firstName}. Les Supporteurs Primaires peuvent toujours être ajoutés à un autre moment.",
          "header": "Inviter un Supporteur Primaire",
          "email": "E-mail",
          "empty_list": "Les Supporteurs Primaires n’ont pas encore été ajoutés. <br/> Sélectionnez « Ajouter un Supporteur Primaire» pour commencer.",
          "relationship": "Relation",
          "skip": "Sauter",
          "save": "Sauvegarder",
          "next": "Suivant",
          "edit": "Modifier",
          "add": "Inviter un Supporteur Primaire",
          "permission": "J'ai la permission d'autoriser cette personne à accéder aux informations de %{firstName} et d'agir en son nom via Careteam",
          "remove": "Supprimer",
          "first_name": "Prénom",
          "last_name": "Nom de famille",
          "phone": "Téléphone",
          "sdm_type": {
            "assign_button": "Désignez cette personne comme mandataire spécial de %{patient_first_name}.",
            "label": "Mandataire spécial",
            "placeholder": "Sélectionnez un type...",
            "substitute_decision_maker": "Mandataire spécial",
            "poa_personal_care": "Procuration relative au soin de la personne",
            "guardian_trustee": "Tuteur/Fiduciaire"
          },
          "sdm_confirmation": {
            "substitute_decision_maker": "J'ai confirmé leur statut de mandataire spécial",
            "poa_personal_care": "J'ai confirmé le statut de leur procuration, y compris les documents nécessaires",
            "guardian_trustee": "J’ai confirmé son statut de tuteur/curateur"
          }
        },
        "support_input": {
          "email": "E-mail"
        },
        "supports_form": {
          "header": "Inviter des personnes à se joindre à l'Équipe de Support étendue",
          "email": "E-mail",
          "skip": "Sauter",
          "save": "Sauvegarder",
          "edit": "Modifier",
          "add": "+ Ajouter"
        },
        "index": {
          "subtitle": "Ajoutez %{name}, un Supporteur Primaire, ou les deux à ce Plan d’Action"
        }
      },
      "documents": {
        "library_documents": "Documents de bibliothèque:",
        "uploaded_documents": "Documents téléchargés:",
        "upload_documents": "Télécharger les documents",
        "file_name": "Nom de fichier:",
        "file_size": "Taille du fichier:",
        "created": "Créé le:",
        "updated": "Mise à jour:",
        "close": "Close",
        "download": "Télécharger",
        "document_preview": "Document: %{name}",
        "uploaded_document_list": {
          "upload_document": "Télécharger le document",
          "edit_document": "Modifier le document",
          "no_files_uploaded": "Aucun fichier n'a été ajouté dans ce Plan d'Action. <br/> Cliquez sur \"Ajouter des documents\" pour commencer."
        }
      },
      "instructions": {
        "subheader": "Souhaitez-vous fournir d'autres instructions ou informations pour cette personne et son équipe d'assistance?",
        "label": "Instructions"
      },
      "resources": {
        "from_library": "De la Bibliothèque",
        "create_new": "Créer nouvelle",
        "done": "Terminé",
        "next": "Suivant",
        "cancel": "Annuler",
        "search_placeholder": "Rechercher des ressources...",
        "all_resources": "Toutes les ressources",
        "resources": "Ressources",
        "service_kinds": {
          "information": "Information",
          "other": "Autre",
          "people": "Personnes",
          "practitioner": "Professionnels de la santé",
          "service": "Prestations de service"
        },
        "no_resources_present": "Pas de ressources disponibles pour ajouter à ce Plan d'Action",
        "select_all": "Tout sélectionner",
        "show_only_selected": "Afficher uniquement les éléments sélectionnés",
        "add_resource": "Ajouter une ressource",
        "modal_form_title": {
          "create": "Ajouter une ressource",
          "update": "Mettre à jour la ressource"
        },
        "form": {
          "placeholder": {
            "name": "Exemples: \"Popote roulante\" ou \"Laboratoires médicaux\"",
            "description": "Ajoutez une brève description de cette ressource ...",
            "tag": "Ajouter une étiquette...",
            "url": "http://www.example.com",
            "number": "Numéro de téléphone",
            "kind": "Choisir un type de Ressource",
            "category": "P. ex. programmes de conditionnement physique",
            "subcategory": "P. ex. AquaForme"
          },
          "name": "Nom",
          "description": "Description",
          "tag": "Étiquettes",
          "kinds": {
            "information": "Information",
            "other": "Autre",
            "people": "Personnes",
            "practitioner": "Professionnels de la santé",
            "service": "Prestations de service"
          },
          "url": "Site Internet",
          "referralLink": "Lien pour consultation",
          "referralLinkLabel": "Champ pour lien pour consultation",
          "number": "Téléphone",
          "address": "Adresse",
          "update": "Mettre à jour la ressource",
          "add": "Ajouter une ressource",
          "cancel": "Annuler",
          "kind": "Type",
          "linkReferral": "Lier une référence",
          "removeReferral": "Supprimer la référence",
          "referralDescription1a": "Pour lier une référence, entrez la Catégorie et la Sous-catégorie de",
          "referralDescription1b": "Site web de Caredove.",
          "referralDescription2": "Veuillez saisir le texte exactement comme il est affiché dans Caredove.",
          "referralCategoryLabel": "Catégorie",
          "referralSubcategoryLabel": "Sous-catégorie (optionnel)",
          "referralLabel": "Étiquette",
          "linkedReferralDescription": "Votre référence liée s’affichera comme: ",
          "missingReferralLabel": "(saisir une étiquette)"
        },
        "search": {
          "label": "Trouver une ressource",
          "search_placeholder": "Chercher les ressources par titre ou par étiquette"
        }
      },
      "new": {
        "dd": "JJ",
        "mm": "MM",
        "yyyy": "AAAA"
      },
      "tasks": {
        "index": {
          "empty_html": "Aucune activité n'a été ajoutée pour ce Plan d'Action <br/> Cliquez sur «Ajouter une activité» pour commencer.",
          "add_activity": "Ajouter une activité",
          "none": "Sélectionnez une tâche",
          "cancel": "Annuler",
          "submit": "Ajouter une activité",
          "link_resources": "Lier les ressources à la Tâche",
          "referral_header": "Ajouter un renvoi"
        }
      },
      "repeated_options": {
        "after": "Après",
        "blank": "--Sélectionner--",
        "end_never": "Jamais",
        "end_on_a_day": "À un jour spécifié",
        "once": "Une fois - à tout moment",
        "scheduled": "Une fois - prévu",
        "recurring": "Récurrent",
        "daily": "Quotidien",
        "weekly": "Hebdomadaire",
        "monthly": "Mensuel"
      },
      "forms": {
        "index": {
          "add_form": "Ajouter un Formulaire",
          "empty_html": "Aucun Formulaire n'a été ajouté pour ce Plan d'Action. <br/> Cliquez sur «Ajouter un Formulaire» pour commencer."
        }
      },
      "check_ins": {
        "index": {
          "empty_html": "Aucune Mise à jour n'a été ajoutée pour ce Plan d'Action <br/> Cliquez sur «Ajouter Mise à jour» pour commencer.",
          "add_check_in": "Ajouter Mise à jour",
          "none": "Choisir une Mise à jour",
          "cancel": "Annuler",
          "submit": "Ajouter Mise à jour",
          "invalid": "n’est pas valide",
          "no_templates_available": "Pas de Modèles de Mises à jour de disponible"
        },
        "form": {
          "check_in_type": "Type de Mise à jour",
          "blank": "--Sélectionner--",
          "once": "Une fois - à tout moment",
          "scheduled": "Une fois - programmée",
          "recurring": "Récurrentes",
          "days_offset": "Date prévue",
          "due_at": "Date et heure de début",
          "days_offset_text": "après la date de début du Plan d'Action",
          "every": "Chaque",
          "repeats": "Se répète",
          "end_repeat": "Finir la répétition",
          "end_date": "Date de fin",
          "end_never": "Jamais",
          "after": "Après",
          "end_on_a_day": "A un jour donné",
          "daily": "Quotidienne",
          "weekly": "Hebdomadaire",
          "monthly": "Mensuelle",
          "submit": "Soumettre Mise à jour",
          "count": {
            "one": "fois",
            "other": "fois"
          },
          "visibility": "Qui peut voir cette Mise à jour?",
          "everyone": "Tout le monde",
          "health_team_only": "Seulement l'Équipe de santé"
        }
      },
      "section_footer": {
        "skip": "Sauter",
        "next": "Suivant"
      },
      "pill_actions": {
        "document-download": "Télécharger",
        "file-edit": "Modifier",
        "external-link": "Vue",
        "minus-circle": "Supprimer",
        "file-edit-blue": "Modifier",
        "minus-circle-blue": "Supprimer"
      }
    },
    "care_plan_place_invitations": {
      "index": {
        "sent_at": "Invitation envoyée: %{sent_at}",
        "pending_header": "Invitations en attente",
        "reviewed_header": "Invitations antérieures",
        "subheader": "Voici votre liste d'invitations d'autres organisations à rejoindre le Plan d'Action d'une personne. Cela se produit généralement lorsque vous collaborez aux soins d'une personne, ou lorsque la personne passe d'un milieu de soins à un autre. Vous pouvez accepter ou refuser chaque invitation.",
        "status": {
          "pending": "En attente",
          "accepted": "Acceptée",
          "declined": "Refusée",
          "all": "Toutes"
        },
        "invitations": "invitations",
        "signups": "inscriptions",
        "status_input": {
          "all": "Afficher tous les statuts",
          "pending": "Afficher en attente",
          "accepted": "Afficher accepté",
          "declined": "Afficher refusé"
        },
        "search_input": {
          "place_holder": "Recherchez les personnes associées avec ce Plan d'Action"
        },
        "external_team_invitations": "Invitations d'équipes externes",
        "patient_signups": "Inscriptions des patients",
        "support_team_contact_signups": "Inscriptions des Supporteur Primaires"
      },
      "signups_container": {
        "target_signup_modal": {
          "title_hcp": "Invitations d'équipes externes",
          "title_patient": "Inscriptions des patients",
          "title_caregiver": "Demande de Supporteur Primaire",
          "header": "%{caregiverName} s'est auto-inscrit en tant que contact de l'Équipe de Support de %{patientName}",
          "warning_accepted": "Cette demande a été acceptée le{resolvedAt}.",
          "warning_declined": "Cette demande a été refusée le {resolvedAt}",
          "personal_details": {
            "support_team_contact": "Contact de L'Équipe de Support",
            "person": "La personne",
            "action_plan": "Plan d'Action",
            "request_date": "Date de la demande",
            "title_caregiver": "Supporteur principal",
            "title_hcp": "Invité par"
          },
          "legal_warning": {
            "title": "En acceptant, vous",
            "warning_point_1": "Confirmez que cette demande provient du membre %{caregiverName} de l'Équipe de Support de %{patientName}.",
            "warning_point_2": "Confirmez que vous avez le consentement de %{patientName} pour que %{caregiverName} utilise Careteam pour : accéder aux informations personnelles de %{patientName}, inviter d'autres personnes à rejoindre son équipe et communiquer en son nom avec d'autres personnes.",
            "warning_point_3": "Accepter de collaborer à ce plan d'action au nom de %{placeName}",
            "warning_caregiver_point_1": "Confirmez que cette demande provient du Supporteur {caregiverName} de %{patientName} %",
            "warning_caregiver_point_2": "Confirmez que vous avez le consentement de %{patientName} pour que %{caregiverName} utilise Careteam pour : accéder aux informations personnelles de %{patientName}, inviter d'autres personnes à rejoindre son équipe et communiquer en son nom avec d'autres personnes.",
            "warning_caregiver_point_3": "Accepter de collaborer à ce plan d'action au nom de %{placeName}",
            "warning_hcp_point_1": "Confirmez que cette demande provient de % {invitedBy}",
            "warning_hcp_point_2": "Accepter de collaborer à ce plan d'action au nom de %{placeName}",
            "warning_patient_point_1": "Confirmez que cette demande provient de % {patientName}",
            "warning_patient_point_2": "Accepter de collaborer à ce plan d'action au nom de %{placeName}"
          },
          "api_success": "Mise à jour réussie",
          "action_buttons": {
            "view_action_plan": "Voir Plan d'Action",
            "decline": "Refuser",
            "done": "Terminé",
            "accept": "Accepter"
          },
          "row_headers": {
            "patient": "Patient",
            "health_care_provider": "Professionnel de santé",
            "support_team_contact": "Supporteur principal",
            "submitted": "Soumis",
            "resolved": "Résolu",
            "status": "Statut"
          },
          "header_caregiver": "%{caregiverName} s'est inscrit en tant que Supporteur Primaire de %{patientName}",
          "header_patient": "%{patientName} s’est auto-enregistré en tant que patient",
          "header_hcp": "%{placeName} a demandé à collaborer sur ce Plan d’Action",
          "warning_accepted_by": "Cette demande a été acceptée par % {resolvedBy} le % {resolvedAt}",
          "warning_declined_by": "Cette demande a été refusée par % {resolvedBy} le % {resolvedAt}"
        }
      }
    },
    "care_plans": {
      "export_modal": {
        "header": "Exporter ce Plan d'Action en PDF",
        "body": "Cette action exportera une copie PDF non cryptée de ce Plan d'Action et cette action sera ajoutée au registre d'audit. Le fichier PDF résultant contiendra des renseignements personnels sur la santé qui sont confidentiels et devra être traité conformément aux politiques et procédures de votre organisation et à toute législation applicable.",
        "cancel": "Annuler",
        "export": "Exporter"
      },
      "document_section": {
        "edit_header": "Modifier les documents",
        "show_header": "Documents",
        "show": {
          "no_documents": "Personne n'a encore ajouté de documents dans votre Plan d'Action."
        },
        "edit": {
          "library": "Bibliothèque",
          "uploaded": "Ajouté",
          "upload_documents": "Télécharger les documents",
          "done": "Fait",
          "library_documents": {
            "subheader": "Sélectionnez des documents pertinents de la bibliothèque Careteam pour votre Plan d'Action ou ajoutez-en à l'aide du bouton ci-dessous.",
            "selected_section_header": "Documents de bibliothèque sélectionnés",
            "unselected_section_header": "Documents de bibliothèque:",
            "selection_empty": "Aucun document n’a été sélectionné de la bibliothèque",
            "library_empty": "Personne n'a encore ajouté de documents dans la bibliothèque.",
            "all_selected": "Vous avez sélectionné tous les documents disponibles dans la bibliothèque.",
            "no_search_match": "Aucun document trouvé",
            "search_placeholder": "Rechercher des documents ..."
          },
          "uploaded_documents": {
            "subheader": "Cliquez sur \"Ajouter des documents\" ci-dessous pour ajouter plus de documents à ce Plan d'Action.",
            "list_header": "Documents ajoutés",
            "upload_document": "Télécharger le document",
            "edit_document": "Modifier le document",
            "document_list": {
              "no_files_uploaded": "Aucun fichier n'a été ajouté à ce Plan d'Action."
            }
          }
        }
      },
      "goal_section": {
        "index": {
          "header": "Buts personnels",
          "show_all": "Montrer tous les objectifs",
          "hide_completed": "Masquer les objectifs complétés",
          "empty": "Vous n'avez pas encore créé d'objectifs pour vos soins. Pensez à quelque chose de réalisable que vous aimeriez travailler avec l'aide de votre équipe et partagez-le ici!",
          "empty_hca": "Un but n’a pas encore été ajouté."
        },
        "goal": {
          "achieved": "Atteint %{datetime}",
          "complete": "Achevée",
          "incomplete": "Incomplet",
          "edit": "Modifier"
        },
        "goal_form": {
          "title": "Titre",
          "description": "Description",
          "photo": "Photo",
          "submit": "Ajouter un objectif",
          "upload": "Ajouter votre photo!",
          "optional": "(optionnel)",
          "update": "Mettre à jour la photo",
          "default_alt": "Photo"
        }
      },
      "generic_order_section": {
        "index": {
          "header": "Tâches",
          "add_header": "Ajouter une Tâche",
          "submit": "Ajouter",
          "cancel": "Annuler",
          "empty": "Aucun pour ce Plan d'Action.",
          "link_resources": "Relier des ressources à la Tâche",
          "resources": "Ressources"
        },
        "generic_order": {
          "complete": "Accomplie",
          "incomplete": "Incomplet",
          "edit": "Modifier",
          "no_help_request": "Les tâches récurrentes ne peuvent pas avoir de demandes d'aide",
          "help_requested": "Un bénévole a été invité à aider avec cette tâche.",
          "view_details": "Voir les détails",
          "request_volunteer": "Demander un bénévole",
          "resources": "Ressources",
          "link_resources": "Lier les ressources",
          "repeated_task": {
            "repeat_statement": "Se répète %{localizedFrequency} jusqu'à %{until}",
            "repeat_statement_infinity": "Se répète %{localizedFrequency}"
          }
        },
        "generic_order_form": {
          "assignments": "Assigner",
          "text": "Description",
          "add_request": "Demander un bénévole",
          "edit_request": "Modifier la demande de bénévole",
          "resources": "Ressources",
          "recurring": "Récurrent",
          "due_at": "Date et heure de début",
          "repeats": "Répétitions",
          "every": "Chaque",
          "end_repeat": "Finir la répétition",
          "after": "Après",
          "end_date": "Date de fin",
          "task_template": "Choisir tâche dans la bibliothèque",
          "select_task_template": "Trouver un modèle de tâche",
          "count": {
            "one": "fois",
            "other": "fois"
          },
          "start_new_task": "ou créer une nouvelle tâche",
          "assignments_header": "%{name} et leur Supporteurs Primaires seront avertis lorsque de nouvelles Tâches sont ajoutées, même si elles ne sont pas attribuées ou si elles sont attribuées à quelqu’un d’autre.",
          "optional": "(Optionnel)"
        }
      },
      "medical_order_section": {
        "index": {
          "header": "Rendez-vous",
          "add_header": "Ajouter Rendez-vous",
          "submit": "Ajouter",
          "cancel": "Annuler",
          "empty": "Aucun pour ce Plan d'Action.",
          "link_resources": "Lier les ressources"
        },
        "medical_order": {
          "booked": "Réservé",
          "not_booked": "Non réservé",
          "edit": "Modifier",
          "help_requested": "Un bénévole a été invité à aider avec cette tâche.",
          "view_details": "Voir les détails"
        },
        "medical_order_form": {
          "assignments": "Assigner",
          "practitioner": "Avec",
          "virtual_visit": "URL pour la visite virtuelle",
          "text": "Description",
          "completed_at": "Rendez-vous doit être créé",
          "due_at": "Planifier pour",
          "address": "Adresse",
          "city": "Ville",
          "region": "Région",
          "postal_code": "Code postal",
          "country_code": "Code de pays",
          "save": "Sauvegarder",
          "cancel": "Annuler",
          "request_volunteer": "Demander un bénévolat",
          "time": "Quand",
          "resources": "Ressources",
          "choose_appointment_template": "Choisir un rendez-vous de la bibliothèque",
          "select_appointment_template": "Trouver un modèle de rendez-vous",
          "start_new_appointment": "ou créer un nouveau rendez-vous",
          "assignments_header": "%{name} et leur Supporteurs Primaires seront avertis lorsque de nouvelles Tâches sont ajoutées, même si elles ne sont pas attribuées ou si elles sont attribuées à quelqu’un d’autre.",
          "optional": "(Optionnel)"
        }
      },
      "form_section": {
        "index": {
          "header": "Formulaires",
          "submit": "Ajouter",
          "empty": "Aucun pour ce Plan d'Action.",
          "invalid": "n’est pas valide"
        },
        "modal": {
          "form_title": "Nommer ce formulaire",
          "form_type": "Choisir un formulaire",
          "header": "Ajouter Formulaire",
          "submit": "Ajouter Formulaire",
          "cancel": "Annuler",
          "check_in_type": "Type de Formulaire",
          "blank": "--Sélectionner--",
          "once": "Une fois - à tout moment",
          "scheduled": "Une fois - prévu",
          "recurring": "Quand devrait-il être complété?",
          "due_at": "Date et heure de début",
          "every": "Chaque",
          "repeats": "Répétitions",
          "end_repeat": "Finir la répétition",
          "end_date": "Date de fin",
          "end_never": "Jamais",
          "after": "Après",
          "end_on_a_day": "À un jour spécifié",
          "daily": "Quotidien",
          "weekly": "Hebdomadaire",
          "monthly": "Mensuel",
          "count": {
            "one": "fois",
            "other": "fois"
          },
          "days_offset": "Date prévue",
          "days_offset_text": "après la date de début du Plan d'Action",
          "time_offset": "Quand"
        },
        "form_order": {
          "completed": "Accomplie",
          "not_completed": "Incomplet",
          "edit": "Modifier",
          "repeat_statement": "Répétition %{localizedFrequency} jusqu'à %{until}",
          "repeat_statement_infinity": "Répétition %{localizedFrequency}",
          "due_at_statement": "Le %{dueDate}"
        }
      },
      "checkin_section": {
        "index": {
          "header": "Mises à jour",
          "submit": "Ajouter",
          "empty": "Aucune pour ce Plan d'Action.",
          "invalid": "n’est pas valide"
        },
        "checkin_order": {
          "completed": "Accomplie",
          "not_completed": "Incomplet",
          "edit": "Modifier",
          "repeat_statement": "Répète %{localizedFrequency} jusqu'à %{until}",
          "repeat_statement_infinity": "Se répète %{localizedFrequency}",
          "due_at_statement": "À %{dueDate}"
        },
        "modal": {
          "header": "Créer Mise à jour",
          "submit": "Ajouter Mise à jour",
          "cancel": "Annuler",
          "check_in_type": "Type de Mise à jour",
          "blank": "--Sélectionner--",
          "once": "Une fois - à tout moment",
          "scheduled": "Une fois - programmé",
          "recurring": "Récurrent",
          "due_at": "Date et heure de début",
          "every": "Chaque",
          "repeats": "Répétitions",
          "end_repeat": "Finir la répétition",
          "end_date": "Date de fin",
          "end_never": "Jamais",
          "after": "Après",
          "end_on_a_day": "A un jour donné",
          "daily": "Quotidienne",
          "weekly": "Hebdomadaire",
          "monthly": "Mensuel",
          "count": {
            "one": "fois",
            "other": "fois"
          }
        }
      },
      "prescription_section": {
        "index": {
          "header": "Prescriptions",
          "submit": "Ajouter",
          "empty": "Il n'y a aucune prescription pour ce Plan d'Action."
        },
        "prescription": {
          "filled": "Filled",
          "not_filled": "Non remplie",
          "edit": "Modifier"
        },
        "prescription_form": {
          "medication": "Médicaments",
          "completed_at": "Le patient doit remplir"
        }
      },
      "health_team_banner": {
        "banner": {
          "header": "Le Plan d'Action est maintenant activé pour %{patientName} !",
          "body_one": "S'il y a d'autres équipes de santé que vous souhaitez ajouter pour qu'elles puissent consulter ce Plan d'Action et travailler avec cette personne, vous pouvez les inviter dès maintenant.",
          "body_two": "Vous pouvez également ajouter des Équipes de Santé plus tard à partir de la section Équipe du Plan d'Action sous «Membre de l'Équipe de Santé».",
          "close": "Fermer",
          "invite": "Inviter équipes de santé"
        },
        "consent_form": {
          "header": "Confirmer les équipes de santé à inviter",
          "permission": "Je confirme que j'ai le consentement de la personne pour inviter les autres organisations à consulter son Plan de d'Action.",
          "cancel": "Annuler",
          "back": "Retour",
          "invite": "Inviter"
        },
        "place_selection_form": {
          "header": "Choisissez une équipe de santé dans la liste ci-dessous.",
          "list_header": "Équipes de santé disponibles à inviter",
          "cancel": "Annuler",
          "next": "Suivant"
        }
      },
      "referral_section": {
        "index": {
          "header": "Renvois",
          "empty": "Aucun pour ce Plan d'Action.",
          "add_referral": "Ajouter un renvoi",
          "error_message": "Quelque chose n'a pas fonctionné, s'il vous plait réessayer.",
          "title": "Demander un renvoi pour un service via Caredove",
          "description": "Caredove trouvera les services disponibles dans la zone géographique appropriée. Veuillez confirmer que l'adresse de la personne ci-dessous est correcte, ou soumettez une adresse différente:"
        },
        "referral": {
          "service_name": "%{serviceName} via %{provider}",
          "status": {
            "draft": "Envoyé",
            "requested": "En attente",
            "received": "En attente",
            "accepted": "En cours",
            "ready": "En cours",
            "in_progress": "En cours",
            "rejected": "Refusé",
            "cancelled": "Annulé",
            "failed": "Annulé",
            "entered-in-error": "Annulé",
            "on_hold": "En attente",
            "completed": "Complété",
            "booked": "Réservé"
          }
        }
      },
      "recurrence_input": {
        "after": "Après",
        "count": {
          "one": "fois",
          "other": "fois"
        },
        "due_at": "Date et heure de début",
        "due_at_appt": "Prévu pour",
        "end_date": "Date de fin",
        "end_repeat": "Finir la répétition",
        "every": "Chaque",
        "recurring": "Récurrents",
        "repeats": "Répétitions",
        "time": "Quand",
        "days_offset": "Date prévue",
        "days_offset_text": "après la date de début du Plan d'Action"
      }
    },
    "country_codes": {
      "AF": "Afghanistan",
      "AX": "Iles Aland",
      "AL": "Albanie",
      "DZ": "Algérie",
      "AS": "Samoa américaines",
      "AD": "Andorre",
      "AO": "Angola",
      "AI": "Anguilla",
      "AQ": "Antarctique",
      "AG": "Antigua-et-Barbuda",
      "AR": "Argentine",
      "AM": "Arménie",
      "AW": "Aruba",
      "AU": "Australie",
      "AT": "L'Autriche",
      "AZ": "Azerbaïdjan",
      "BS": "Bahamas",
      "BH": "Bahreïn",
      "BD": "Bangladesh",
      "BB": "Barbade",
      "BY": "Bélarus",
      "BE": "Belgique",
      "BZ": "Belize",
      "BJ": "Bénin",
      "BM": "Bermudes",
      "BT": "Bhoutan",
      "BO": "Bolivie, État plurinational de",
      "BQ": "Bonaire, Saint-Eustache et Saba",
      "BA": "Bosnie Herzégovine",
      "BW": "Botswana",
      "BV": "Île Bouvet",
      "BR": "Brésil",
      "IO": "Territoire britannique de l'océan Indien",
      "BN": "Brunei Darussalam",
      "BG": "Bulgarie",
      "BF": "Burkina Faso",
      "BI": "Burundi",
      "KH": "Cambodge",
      "CM": "Cameroun",
      "CA": "Canada",
      "CV": "Cap-Vert",
      "KY": "Îles Caïmans",
      "CF": "République centrafricaine",
      "TD": "Chad",
      "CL": "Chili",
      "CN": "Chine",
      "CX": "L'île de noël",
      "CC": "Îles Cocos (Keeling)",
      "CO": "Colombie",
      "KM": "Comores",
      "CG": "Congo",
      "CD": "Congo, République démocratique du",
      "CK": "Îles Cook",
      "CR": "Costa Rica",
      "CI": "Côte d'Ivoire",
      "HR": "Croatie",
      "CU": "Cuba",
      "CW": "Curaçao",
      "CY": "Chypre",
      "CZ": "République Tchèque",
      "DK": "Danemark",
      "DJ": "Djibouti",
      "DM": "Dominique",
      "DO": "République Dominicaine",
      "EC": "Équateur",
      "EG": "Égypte",
      "SV": "El Salvador",
      "GQ": "Guinée Équatoriale",
      "ER": "Erythrée",
      "EE": "Estonie",
      "ET": "Éthiopie",
      "FK": "Îles Falkland (Malvinas)",
      "FO": "Îles Féroé",
      "FJ": "Fidji",
      "FI": "Finlande",
      "FR": "France",
      "GF": "Guinée Française",
      "PF": "Polynésie Français",
      "TF": "Terres Australes Françaises",
      "GA": "Gabon",
      "GM": "Gambie",
      "GE": "Géorgie",
      "DE": "Allemagne",
      "GH": "Ghana",
      "GI": "Gibraltar",
      "GR": "Grèce",
      "GL": "Groenland",
      "GD": "Grenade",
      "GP": "Guadeloupe",
      "GU": "Guam",
      "GT": "Guatemala",
      "GG": "Guernesey",
      "GN": "Guinée",
      "GW": "Guinée-Bissau",
      "GY": "Guyane",
      "HT": "Haïti",
      "HM": "Île Heard et îles McDonald",
      "VA": "Saint-Siège (État de la Cité du Vatican)",
      "HN": "Honduras",
      "HK": "Hong Kong",
      "HU": "Hongrie",
      "IS": "Islande",
      "IN": "Inde",
      "ID": "Indonésie",
      "IR": "Iran, République islamique d'",
      "IQ": "Irak",
      "IE": "Irlande",
      "IM": "Île de Man",
      "IL": "Israël",
      "IT": "Italie",
      "JM": "Jamaïque",
      "JP": "Japon",
      "JE": "Jersey",
      "JO": "Jordan",
      "KZ": "Kazakhstan",
      "KE": "Kenya",
      "KI": "Kiribati",
      "KP": "République populaire démocratique de la Corée",
      "KR": "Corée, République de",
      "KW": "Koweit",
      "KG": "Kirghizistan",
      "LA": "République Démocratique Populaire du Laos",
      "LV": "Lettonie",
      "LB": "Liban",
      "LS": "Lesotho",
      "LR": "Libéria",
      "LY": "Libye",
      "LI": "Liechtenstein",
      "LT": "Lituanie",
      "LU": "Luxembourg",
      "MO": "Macao",
      "MK": "Macédoine, République de",
      "MG": "Madagascar",
      "MW": "Malawi",
      "MY": "Malaisie",
      "MV": "Maldives",
      "ML": "Mali",
      "MT": "Malte",
      "MH": "Îles Marshall",
      "MQ": "Martinique",
      "MR": "Mauritanie",
      "MU": "Maurice",
      "YT": "Mayotte",
      "MX": "Mexique",
      "FM": "Micronésie, États fédérés de",
      "MD": "Moldavie, République de",
      "MC": "Monaco",
      "MN": "Mongolie",
      "ME": "Monténégro",
      "MS": "Montserrat",
      "MA": "Maroc",
      "MZ": "Mozambique",
      "MM": "Myanmar",
      "NA": "Namibie",
      "NR": "Nauru",
      "NP": "Népal",
      "NL": "Pays-Bas",
      "NC": "Nouvelle Calédonie",
      "NZ": "Nouvelle-Zélande",
      "NI": "Nicaragua",
      "NE": "Niger",
      "NG": "Nigeria",
      "NU": "Nioué",
      "NF": "Île Norfolk",
      "MP": "Îles Mariannes du Nord",
      "OM": "Oman",
      "PK": "Pakistan",
      "PW": "Palau",
      "PS": "Palestine, État de",
      "PA": "Panama",
      "PG": "Papouasie Nouvelle Guinée",
      "PY": "Paraguay",
      "PE": "Pérou",
      "PH": "Philippines",
      "PN": "Pitcairn",
      "PL": "Pologne",
      "PT": "Portugal",
      "PR": "Porto Rico",
      "QA": "Qatar",
      "RE": "Réunion",
      "RO": "Roumanie",
      "RU": "Fédération Russe",
      "RW": "Rwanda",
      "BL": "Saint Barthélemy",
      "SH": "Sainte-Hélène, Ascension et Tristan da Cunha",
      "KN": "Saint-Kitts-et-Nevis",
      "LC": "Sainte-Lucie",
      "MF": "Saint Martin (partie française)",
      "PM": "Saint-Pierre-et-Miquelon",
      "VC": "Saint-Vincent-et-les-Grenadines",
      "WS": "Samoa",
      "SM": "Saint-Marin",
      "ST": "Sao Tomé et Principe",
      "SA": "Arabie Saoudite",
      "SN": "Sénégal",
      "RS": "Serbie",
      "SC": "Seychelles",
      "SL": "Sierra Leone",
      "SG": "Singapour",
      "SX": "Sint Maarten (partie néerlandaise)",
      "SK": "Slovaquie",
      "SI": "Slovénie",
      "SB": "Les îles Salomon",
      "SO": "Somalie",
      "ZA": "Afrique du Sud",
      "GS": "Géorgie du Sud et îles Sandwich du Sud",
      "SS": "Soudan du Sud",
      "ES": "Espagne",
      "LK": "Sri Lanka",
      "SD": "Soudan",
      "SR": "Suriname",
      "SJ": "Svalbard et Jan Mayen",
      "SZ": "Swaziland",
      "SE": "Suède",
      "CH": "Suisse",
      "SY": "République Arabe Syrienne",
      "TW": "Taïwan",
      "TJ": "Tadjikistan",
      "TZ": "Tanzanie, République-Unie de",
      "TH": "Thaïlande",
      "TL": "Timor-Leste",
      "TG": "Togo",
      "TK": "Tokelau",
      "TO": "Tonga",
      "TT": "Trinité-et-Tobago",
      "TN": "Tunisie",
      "TR": "Turquie",
      "TM": "Turkménistan",
      "TC": "îles Turques-et-Caïques",
      "TV": "Tuvalu",
      "UG": "Ouganda",
      "UA": "Ukraine",
      "AE": "Émirats Arabes Unis",
      "GB": "Royaume-Uni",
      "UM": "Îles mineures éloignées des États-Unis",
      "US": "États-Unis",
      "UY": "Uruguay",
      "UZ": "Ouzbékistan",
      "VU": "Vanuatu",
      "VE": "Venezuela, République bolivarienne de",
      "VN": "Vietnam",
      "VG": "Îles Vierges britanniques",
      "VI": "Îles Vierges américaines",
      "WF": "Wallis et Futuna",
      "EH": "Sahara occidental",
      "YE": "Yémen",
      "ZM": "Zambie",
      "ZW": "Zimbabwe",
      "blank": "Choisissez un pays…",
      "NO": "Norvège"
    },
    "ct": {
      "components": {
        "diagnosis_template": {
          "placeholder": "Entrez la description du Problème",
          "delete": "Effacer",
          "remove": "Supprimer"
        },
        "file_upload": {
          "attach_document": "Joindre un document"
        },
        "validator": {
          "url": "n'est pas un URL valide. Avez-vous inclu http ou https?",
          "email": "doit être un e-mail valide",
          "text": "ne peut pas être vide",
          "textarea": "ne peut pas être vide",
          "file": "ne peut pas être vide",
          "oversized_file": "Le fichier sélectionné ne doit pas être de plus de 50 MO",
          "select-one": "veuillez sélectionner une option",
          "datetime": "doit être une date valide",
          "datetime-local": "doit être une date valide",
          "tel": "doit être un numéro de téléphone valide"
        },
        "remote_handlers": {
          "medical_document": {
            "author": "Ajouté par",
            "created_at": "Date ajoutée",
            "document_file_name": "Nom de fichier",
            "event_at": "Date de l'évènement",
            "name": "Titre du document",
            "tags": "Mots clés",
            "view": "Vue"
          }
        },
        "confirm": {
          "delete_ok": "Oui, supprimer"
        }
      }
    },
    "dashboard": {
      "glucose_levels": "Glycémie",
      "insulin_pump_settings": "Réglages de la pompe à insuline",
      "insulin_dosing": "Dosage d'insuline",
      "device": {
        "data_not_available": "Données non disponibles.",
        "unauthorized": "L'accès à votre appareil n'a pas encore été accordé. Veuillez vérifier votre compte Care Hub.",
        "connection_lost": "Nous ne parvenons pas à nous connecter à votre appareil. Veuillez vérifier votre compte Care Hub.",
        "consent_missing_hcp_message": "Seulement des données partielles sont affichées. Le consentement n'a pas été donné pour accéder à toutes les données de l'appareil de %{firstName}. Veuillez demander à %{firstName} (s'ils ont l'age pour consentir) ou à leurs parents de donner leur consentement dans leur Care Hub.",
        "consent_missing_patient_message": "Pour afficher toutes vos données disponibles, terminez de connecter vos appareils.",
        "connection_failed": "Nous avons essayé de connecter votre appareil, mais ce n'est pas possible. Svp vérifier que vos appareils sont connectés dans votre Care Hub.",
        "average_glucose": "Glycémie moyenne",
        "gmi": "SGC",
        "cgm_active": "SGC active",
        "number_placeholder": "~0.0",
        "percent_placeholder": "~0.0%",
        "unknown_error": "Un problème est survenu. Essayez à nouveau ou contactez-nous à ",
        "cgm_active_below_70": "SGC active doit être supérieure à 70%",
        "time_range_below_14_days": "Nécessite au moins 14 jours de données",
        "no_data_present": "L’appareil est connecté, mais il n’y a pas encore de données.",
        "no_chdb_data_present": "Connectés à la banque de données Care Hub, mais il n’y a pas de données pour cette période de temps.",
        "no_data_present_timeframe": "L'appareil est connecté, mais il n'y a pas de données pour la période sélectionnée.",
        "no_user_accepted_error": "Données pas encore disponibles. D'abord, %{firstName} ou leur parent doit se connecter à Careteam et accepter l’invitation.",
        "missing_fhir_token": "Les données ne sont pas disponibles tant que %{firstName} ou son parent ne se connecte pas à Careteam.",
        "no_chdb_data_present_patient": "Pas de données pour la période sélectionnée. Si vous attendez des données, vérifiez les sources de données dans <a href=\"%{carehubUrl}\">votre compte Care Hub</a>.",
        "no_chdb_data_present_placeuser": "Aucune donnée pour la période sélectionnée. Si vous attendez des données, demandez au patient de vérifier les sources de données dans son compte Care Hub.",
        "empty_comparison_chart_data": "Aucune donnée pour la période sélectionnée.",
        "no_chdb_data_present_patient_part_1": "Pas de données pour la période sélectionnée. Si vous attendez des données, vérifiez les sources de données dans ",
        "no_chdb_data_present_patient_part_2": "votre compte Care Hub.",
        "time_range_below_12_days": "Nécessite au moins 12 jours de données"
      },
      "insulin": {
        "long_insulin_type": "Type d'insuline à action prolongée",
        "rapid_insulin_type": "Type d'insuline à action rapide",
        "dose": "Dose",
        "long": "Prolongée",
        "ICR": "(i:g)",
        "ISF": "FSI",
        "breakfast": "Déjeuner",
        "lunch": "Dîner",
        "dinner": "Souper",
        "bedtime": "Heure du coucher",
        "save": "Sauvegarder",
        "save_error": "Une erreur s'est produite lors de la tentative d'enregistrement des données.",
        "fetch_error": "Une erreur s'est produite lors de la tentative d'aller chercher les données.",
        "insulin": "Insuline",
        "rapid": "Rapide",
        "start": "Début",
        "active": "actif",
        "exceeds_maximum_dose": "Ne peut pas être 100 ou plus",
        "last_uploaded_on": "Dernier téléchargement le %{date}",
        "updated_at": "Dernière mise à jour par %{userName} le %{updatedAt}"
      },
      "chart": {
        "glucose": "Glucose",
        "avg_glucose_mmol_L": "Glycémie moyenne (mmol/L)",
        "time_of_day": "Heure de la journée",
        "50_median": "Médiane de 50 %",
        "25_75_IQR": "Écart interquartile 25 / 75%",
        "10_90_IDR": "Écart interdécile 10 / 90%",
        "target_range": "Valeurs cibles",
        "time_of_day_abbreviated": "Quand",
        "50_median_abbreviated": "50%",
        "25_75_IQR_abbreviated": "25 / 75%",
        "10_90_IDR_abbreviated": "10 / 90%",
        "monday": "Lundi",
        "tuesday": "Mardi",
        "wednesday": "mercredi",
        "thursday": "Jeudi",
        "friday": "Vendredi",
        "saturday": "Samedi",
        "sunday": "Dimanche",
        "monday_abbreviated": "Lu",
        "tuesday_abbreviated": "Ma",
        "wednesday_abbreviated": "Me",
        "thursday_abbreviated": "Je",
        "friday_abbreviated": "Ve",
        "saturday_abbreviated": "Sa",
        "sunday_abbreviated": "Di",
        "reset": "Réinitialiser",
        "comparison_date_range": "%{displayBeginDate} à %{displayEndDate}"
      },
      "time_range": {
        "24hrs": "24 heures",
        "7days": "7 jours",
        "14days": "14 jours",
        "30days": "30 jours",
        "90days": "90 jours",
        "24h": "24h",
        "7d": "7j",
        "14d": "14j",
        "30d": "30j",
        "90d": "90j",
        "1day": "1 jour",
        "1d": "1j",
        "compare": "Comparer"
      },
      "bolus_calculator": "Ouvrir le calculateur de bolus",
      "percent_symbol": "%",
      "level": "Niveau",
      "mmol_per_litre": "mmol/L",
      "glucose_average": {
        "average_glucose": "Glycémie moyenne",
        "gmi": "SGC",
        "gmi_tooltip": "Le GMI (Glucose Management Indicator ou Indice de Gestion du Glucose) est l'HbA1C estimé à partir des relevés de glycémie moyens de votre glucomètre continu sur une période donnée.",
        "cv": "Coefficient de variation",
        "cv_tooltip": "CV (coefficient de variation) est une mesure de la variabilité du glucose à partir de votre glucomètre continu sur une période de temps. La recherche suggère un CV cible de 36% ou moins.",
        "sd": "SD mmol/L"
      },
      "glucose_range": {
        "low": "Bas",
        "in_range": "Dans l'objectif",
        "high": "Élevé"
      },
      "days_active": "(%{days} jours)",
      "clipboard_copy": {
        "date_of_birth": "Date de naissance",
        "exported_date": "Date du export",
        "reporting_period": "Période des résultats",
        "average_daily_readings": "Dose quotidienne moyenne",
        "copy_as_text": "Copier en tant que texte",
        "copied": "Copié!",
        "average_daily_insulin": "Insuline quotidienne moyenne",
        "standard_deviation": "Écart-type",
        "basal_rates_title": "Taux de base",
        "insulin_action": "Délai d'action de l'insuline",
        "basal_rate_unit": "U/h",
        "i_c_ratio_settings": "Paramètres du rapport I:C",
        "i_c_ration_unit": "g/U",
        "isf_programs": "Programme FSI",
        "isf_unit": "mmol/L",
        "target_blood_glucose": "Glycémie cible",
        "bg_target": "Cible",
        "average_daily_dose": "Dose quotidienne moyenne",
        "average_basal": "Basale/jour",
        "average_bolus": "Bolus/jour",
        "units": "unités"
      }
    },
    "editor": {
      "revert": "Revenir à la valeur par défaut",
      "edit": "Modifier",
      "save": "Sauvegarder",
      "remove": "Remove",
      "visit": "URL de la visite:",
      "enter_link": "Entrer le lien :"
    },
    "bounce_messages": {
      "addresschange": "Le destinataire a demandé un changement d'adresse.",
      "autoresponder": "Répondeur automatique par e-mail (ex: «Absent du bureau» ou «En vacances»).",
      "bademailaddress": "L’adresse n’est pas une adresse e-mail valide.",
      "blocked": "Bloqué par ce fournisseurs d'accès à Internet (FAI) en raison du contenu ou de la liste noire.",
      "challengeverification": "Le rebond est un défi demandant la vérification que vous avez réellement envoyé l'e-mail. Les défis typiques sont lancés par Spam Arrest ou MailFrontier Matador.",
      "dmarcpolicy": "E-mail rejeté en raison de la politique DMARC.",
      "dnserror": "Une erreur DNS temporaire.",
      "hardbounce": "Le serveur n'a pas pu livrer votre message (ex: utilisateur inconnu, boîte aux lettres introuvable).",
      "inbounderror": "Impossible de livrer le message entrant due a un problème de destination.",
      "invitationerror": "Erreur d'invitation",
      "manuallydeactivated": "L’e-mail a été désactivé manuellement.",
      "openrelaytest": "Le rapport de non-remise (Non-Delivery Report (NDR)) est en fait un message électronique de test pour voir si le serveur de messagerie est un relais ouvert.",
      "smtpapierror": "Une erreur s’est produite lors de l’acceptation d’un e-mail via l’API SMTP.",
      "softbounce": "Impossible de remettre temporairement le message (c-à-d. boîte aux lettres pleine, compte désactivé, dépasse le quota, espace disque insuffisant).",
      "spamcompliant": "L’abonné a désigné ce message comme pourriel (spam).",
      "spamnotification": "Le message a été remis, mais a été soit bloqué par l'utilisateur, soit classé comme pourriel (spam), courrier en masse ou on a rejeté le contenu.",
      "subscribe": "Demande d'abonnement d'une personne qui souhaita être ajoutée à la liste de diffusion",
      "templaterenderingfailed": "Une erreur s'est produite lors de la tentative d'appliquer votre modèle.",
      "transient": "Le serveur n'a pas pu livrer temporairement votre message (ex: le message est retardé en raison de problèmes de réseau).",
      "unconfirmed": "L’invité n’a pas cliqué sur le lien de confirmation lors de l’inscription ou de l’importation.",
      "unknown": "Impossible de classer le rapport de non-remise (Non-Delivery Report (NDR).",
      "unsubscribe": "Se désabonner ou supprimer la demande",
      "virusnotification": "Le rebond est en fait un avertissement de notification de virus au sujet d’un message infecté par un virus/code."
    },
    "emergency_instruction": {
      "header": "Instructions d'urgence",
      "info": "Celui-ci sera affiché aux Patients et aux Supporteurs lorsqu’ils enverront un message à votre équipe et organisation.",
      "default": "S’il s’agit d’une urgence, veuillez vous rendre à l’hôpital le plus proche ou composer le 911.",
      "updated": "Mise à jour",
      "by": "Par",
      "characters_left": "caractères restants",
      "instruction_translation": "Si la personne a défini une autre langue dans ses paramètres, l’instruction d’urgence par défaut est traduite pour elle. Les instructions d’urgence pour vous et votre équipe ne seront pas traduites.",
      "discard": "Annuler les modifications",
      "preview": "Aperçu",
      "save": "Sauvegarder",
      "error": "Désolé, une erreur s'est produite et nous n'avons pas pu enregistrer ces informations. Veuillez réessayer ou contactez-nous."
    },
    "errors": {
      "email": {
        "invalid": "Adresse e-mail invalide",
        "already_exists": "L'utilisateur appartient déjà à ce Plan d'Action",
        "blank": "L'e-mail ne peut pas être vide"
      }
    },
    "forms": {
      "no_options": "Pas d'options",
      "address_form": {
        "address": "Adresse municipale",
        "extended_address": "Appartement, suite, etc. (facultatif)",
        "region": "Province / État",
        "postal_code": "Code postal",
        "city": "Ville",
        "country_code": "Pays",
        "edit": {
          "label": "Mise à jour de l'adresse"
        },
        "province": "Province",
        "state": "État",
        "zip_code": "Code postal",
        "postcode": "Code postal",
        "country": "Pays"
      },
      "date_time_input": {
        "am": "AM",
        "pm": "PM"
      },
      "document_form": {
        "cancel": "Annuler",
        "change_file": "Changer de fichier",
        "save": "Sauvegarder",
        "document_name_label": "Nom du document",
        "event_at_label": "Date de l'évènement",
        "tags_label": "Mots clés",
        "file_size_exceeded": "Le fichier sélectionné ne doit pas être de plus de 50 MO"
      },
      "library_document_select": {
        "no_documents_available": "Il n'y a aucun document actuellement disponible dans la bibliothèque"
      },
      "locale_input": {
        "language": "Langue"
      },
      "phone_number_form": {
        "mobile": "Cellulaire",
        "primary_residence": "Résidence principale",
        "secondary_residence": "Résidence secondaire",
        "work": "Travail"
      },
      "profile_picture_upload": {
        "default_alt": "Ajouter votre photo",
        "generic_error": "La photo de profil n'a pas pu être ajoutée",
        "upload": "Ajouter votre photo",
        "update": "Ajouter votre photo",
        "optional": "(optionnel)",
        "explanation": "Commencez à créer votre profil en ajoutant une photo de vous-même.",
        "file_size_exceeded": "Le fichier sélectionné ne doit pas dépasser 50 Mo"
      },
      "help_request": {
        "placeholder": {
          "header": "Trouvez un bénévole pour aider à accomplir une tâche pour une personne dans le besoin.",
          "pickup": "Exemples: magasin, domicile, etc.",
          "delivery": "Exemples : Maison, Appartement, etc",
          "recipient_phone": "Numéro",
          "due_at": "La livraison sera tentée dans les 2 heures suivant l’heure spécifiée"
        },
        "blank": "ne peut pas être vide",
        "invalid_address": "L'adresse n'est pas valide",
        "invalid_phone": "Le numéro de téléphone n'est pas valide",
        "task_type": "Type de tâche",
        "transportation": "Transport",
        "pickup_name": "Nom du lieu de ramassage",
        "pickup_address": "Adresse de ramassage",
        "delivery_name": "Nom du lieu de livraison",
        "delivery_address": "Adresse de livraison",
        "recipient_phone": "Numéro de téléphone du destinataire",
        "due_at": "Heure de livraison préférée",
        "notes": "Notes",
        "cancel": "Annuler",
        "submit": "Soumettre une demande",
        "success": "Un bénévole a été invité à aider à cette tâche.",
        "outside_hours": "Malheureusement, votre demande ne correspondait à aucun bénévole en fonction de leur disponibilité et/ ou de leur proximité de l’emplacement."
      },
      "submit": {
        "save": "Sauvegarder"
      },
      "password_input": {
        "hide": "Cacher",
        "show": "Afficher"
      },
      "label": {
        "optional": "Optionnel"
      }
    },
    "locale": {
      "en": "Anglais",
      "fr": "Français",
      "es": "Espanol",
      "ko": "coréen",
      "sv": "suédois"
    },
    "role": {
      "patient": "Patient",
      "primary_caregiver": "Supporteur principal",
      "caregiver": "Équipe de support",
      "support": "Support étendu",
      "unaffiliated_health_care": "Professionnels de santé invités"
    },
    "relationship": {
      "empty_relationship": "Choisir le type de lien...",
      "other": "Autre",
      "spouse": "Conjoint",
      "parent": "Parent",
      "child": "Enfant",
      "sibling": "Fraterie",
      "cousin": "Cousin",
      "friend": "Ami",
      "neighbour": "Voisin",
      "step_parent": "Beau-parent",
      "ex_spouse": "Ex-conjoint",
      "grandchild": "Petit-enfant",
      "professional_caregiver": "Soignant professionnel",
      "volunteer": "Bénévole",
      "unaffiliated_health_care": "Membre de l'Équipe de santé"
    },
    "goals": {
      "show": {
        "status": {
          "header": "Statut",
          "status": {
            "complete": "Accomplie",
            "incomplete": "Incomplet"
          },
          "action": {
            "complete": "Restituer",
            "incomplete": "Achevée"
          }
        }
      }
    },
    "header": {
      "notifications": "Alertes",
      "language": "Langue",
      "menu": {
        "index": {
          "my_profile": "Mon profil"
        }
      }
    },
    "inbox": {
      "disconnected": "Une erreur inattendue s’est produite. Veuillez rafraîchir la page",
      "conversation": {
        "disclaimer": {
          "header": "Message du Careteam",
          "non_health_care": "La messagerie est un excellent moyen de clarifier les questions que vous pourriez avoir concernant votre Plan d'Action. Votre message sera envoyé à la boîte de réception de l'équipe et le premier membre de l'équipe disponible répondra. Veuillez noter que cela peut prendre du temps.",
          "health_care": "Cette boîte de réception de messagerie sécurisée est destinée aux communications de routine. Si c'est urgent, veuillez contacter la personne par téléphone.",
          "direct_message": "Le centre de messagerie est un endroit idéal pour communiquer directement avec les membres de votre équipe d'assistance. Toutefois, en cas d'urgence, rendez-vous à l'hôpital le plus proche ou appelez le 911.",
          "health_care_channel": "Veuillez noter que les messages d'une chaine ne seront pas automatiquement accessibles aux autres membres de l'équipe de soins et que les informations pertinentes doivent être enregistrées dans le Plan d'Action ou dans les dossiers de votre organisation.",
          "non_health_care_channel": "Les chaînes sont un excellent moyen de communiquer avec les membres de votre Plan d'Action. Cependant, en cas d'urgence, rendez-vous à l'hôpital le plus proche ou appelez le 911."
        },
        "form": {
          "body": "Écrivez votre message ici...",
          "submit": "Envoyer",
          "error": "Quelque chose n'a pas fonctionné, s'il vous plait réessayez."
        },
        "header": {
          "view_details": "Voir les détails",
          "view_careplan": "Voir Plan d'Action",
          "loading": "Chargement...",
          "emergency_instruction_header": "Instructions d'urgence",
          "emergency_instruction_p1": "La messagerie est destinée aux communications non urgentes. Voir",
          "emergency_instruction_p2": "contacts alternatifs"
        },
        "messages": {
          "new_desk_conversation_one": "Bienvenue au centre de messagerie!",
          "new_desk_conversation_two": "Composez un message ci-dessous pour contacter la clinique %{name} pour prendre un rendez-vous ou pour obtenir d'autres renseignements.",
          "new_conversation_one": "Vous n'avez pas encore envoyé de message avec %{name}.",
          "new_conversation_two": "Commencez votre conversation en écrivant un message ci-dessous.",
          "no_conversations": "Vous n'avez pas encore commencé une conversation, cliquez sur un contact pour commencer.",
          "emoji": "Insérer émoji"
        }
      },
      "side_nav": {
        "care_plans": {
          "header": "Sélectionnez un Plan d'Action",
          "back": "Arrière",
          "no_results": "Aucun Plan d'Action",
          "filter": "Rechercher un Plan d'Action...",
          "health_care": {
            "carePlans": {
              "info": "Pour créer une conversation de bureau, vous devez d'abord sélectionner un Plan d'Action."
            },
            "channelCarePlans": {
              "info": "Pour créer une chaîne de Plan d'Action, vous devez d’abord sélectionner un Plan d'Action."
            }
          },
          "non_health_care": {
            "channelCarePlans": {
              "info": "Pour parler à un professionnel de la santé ou pour commencer une chaîne de groupe, veuillez sélectionner le Plan d'Action dont vous voulez discuter"
            }
          }
        },
        "channels": {
          "info": "Membres de l'équipe pour ce Plan d'Action:",
          "next": "Suivant",
          "channel_name": "Nom de la chaîne:",
          "channel_name_hint": "Le nom du Plan d'Action sera utilisé si aucun autre nom n’est spécifié.",
          "start": "Créer une chaîne",
          "invalid_name": "Cette chaîne existe déjà. Pour procéder, veuillez mettre à jour le nom de la chaîne.",
          "channel_members": "Membres de cette chaîne (%{count}):",
          "channelMembers": {
            "header": "Sélectionnez les membres de la chaîne du Plan d’Action"
          },
          "ongoing": {
            "header": "Sélectionnez les membres de la chaîne du Plan d’Action"
          },
          "channelName": {
            "header": "Créer une chaîne pour le Plan d'Action"
          },
          "select_all": "Tout sélectionner (%{count})"
        },
        "contacts": {
          "contact_list": {
            "no_contacts": {
              "header": "Formez votre équipe de support pour pouvoir communiquer en toute sécurité",
              "info": "Visitez la page Équipe pour ajouter la famille, les amis et les soignants qui vous supportent dans votre parcours de santé. Revenez ensuite ici pour leur envoyer des messages sécurisés.",
              "no_contacts": "Pas de conversations"
            }
          },
          "filters": {
            "search": "Rechercher la personne...",
            "newest": "Plus récent",
            "oldest": "Le plus ancien",
            "nameAsc": "Nom A - Z",
            "nameDesc": "Nom Z - A"
          },
          "header": {
            "desk": "Professionnels de l’équipe de santé",
            "direct": "Messages directs",
            "health_care_direct": "Conversations de bureau",
            "channels": "Chaînes du Plan d'Action",
            "desk_subtext": "Contact reçu",
            "direct_subtext": "Messages de l'équipe de support 1: 1",
            "channels_subtext": "Messages 1: 1 de l'équipe",
            "health_care_direct_subtext": "Demandes générales",
            "users": "Nouvelle chaîne",
            "user-circle": "Nouveau message",
            "desk-conversations": "Nouvelle conversation",
            "hospital": "Nouvelle conversation"
          }
        },
        "new_contacts": {
          "header": "Nouveau message direct",
          "desk_header": "Nouvelle conversation",
          "info": "Vous trouverez ci-dessous une liste des fournisseurs de soins et des membres de la famille avec lesquels vous pouvez envoyer un message."
        }
      }
    },
    "notifications": {
      "header": "Notifications",
      "close": "Fermer",
      "empty": {
        "title": "Félicitations!",
        "message": "Vous êtes à jour par vos notifications."
      },
      "tooltips": {
        "close": "Fermer",
        "remove_notification": "Supprimer la notification"
      }
    },
    "pagination": {
      "one_page": {
        "display_entries": {
          "one": "Affichant 1 %{entry_name}",
          "other": "Affichant tout %{count} %{entry_name}"
        }
      },
      "more_pages": {
        "display_entries": "Affichage de %{entry_name} %{first} - %{last} sur %{total} au total."
      }
    },
    "patient": {
      "edit": {
        "address": {
          "label": "Type d'adresse",
          "home": "Accueil",
          "work": "Travail",
          "temporary": "Temporaire",
          "mailing_address": "Adresse postale",
          "submit": "Sauvegarder",
          "country": "Pays",
          "street_address": "Adresse municipale",
          "extended_address": "Appartement, suite, etc. (facultatif)",
          "city": "Ville",
          "province": "Province",
          "state": "État",
          "postal_code": "Code postal",
          "zip_code": "Code postal",
          "postcode": "Code postal",
          "cancel": "Annuler",
          "save": "Sauvegarder",
          "address": "Adresse"
        },
        "index": {
          "first_name": "Prénom",
          "last_name": "Nom de famille",
          "female": "Femelle",
          "male": "Mâle",
          "other": "Autre",
          "gender": "Sexe",
          "birthday": "Date de naissance",
          "medical_record_number": "Numéro d’assurance maladie",
          "contact_information": "Informations de contact",
          "email": "E-mail",
          "manage_invitation": "Gérer l'invitation",
          "send_invitation": "Envoyer l'invitation",
          "phone_number": "Numéro de téléphone",
          "address": "Adresse",
          "new": "+ Ajouter",
          "primary_physician": {
            "header": "Prestataire de soins primaires",
            "placeholder": "Entrez les détails du médecin ici"
          },
          "submit": "Sauvegarder",
          "select": "Choisissez",
          "street_address": "Adresse municipale",
          "invitation_message": "Une invitation à se joindre au Plan d'Action sera envoyée à cet émail après que vous sauvegardez.",
          "submit_error": "Une erreur s'est produite et nous n'avons pas pu enregistrer l'information. Veuillez réessayer ou nous contacter.",
          "update_carehub": "Mis à jour dans Care Hub"
        },
        "phi_section": {
          "submit": "Sauvegarder"
        }
      },
      "index": {
        "new_care_plan": "Nouveau Plan d'Action",
        "self_signups": "Inscriptions directes",
        "new_care_plan_menu": {
          "create_new_care_plan": "Créer nouveau",
          "use_template": "Utiliser le modèle"
        },
        "care_plan_templates_modal": {
          "title": "Quel type de Plan d’Action souhaitez-vous créer?",
          "content_banner": "Pour gagner du temps, choisissez parmi les Modèles disponibles créés par votre organisation. Les Modèles sont déjà configurés pour vous faire sauver du temps et de l'effort.",
          "list_header": "Sélectionner Modèle",
          "modal_failure": "Quelque chose n'a pas fonctionné, s'il vous plait réessayez.",
          "next": "Suivant",
          "cancel": "Annuler",
          "create_action_plan": "Créer un Plan d'Action"
        },
        "patient": {
          "medical_record_number": "Numéro d’assurance maladie",
          "pending": "Invitations en attente",
          "new_patient": "Nouveau patient",
          "last_logged_in": "Dernière connexion le %{date} à %{time}",
          "self_registered": "Nouvelle inscription directe",
          "unknown": "Inconnu",
          "new_referral": "Nouvelle demande de consultation",
          "archived": "Archivé",
          "years_old": "ans"
        },
        "patients": {
          "empty": "Aucun résultat"
        },
        "filter_patients": "Afficher ma liste",
        "search_patients_placeholder": "Rechercher",
        "search_patients_aria": "Rechercher",
        "sort_tooltip": "Trier",
        "sort_patients_aria": "Trier",
        "empty": "Aucun résultat",
        "header": "Liste des personnes",
        "sort": {
          "createdAtDesc": "Date",
          "createdAtAsc": "Le plus ancien",
          "nameAsc": "Nom de famille A-Z",
          "nameDesc": "Nom de famille Z-A",
          "sort_tooltip": "Trier",
          "sort_patients_aria": "Trier"
        },
        "filter_patients_my_list": "Ma liste",
        "filter_patients_archived": "Archivé",
        "error_occurred": "Quelque chose n'a pas fonctionné, s'il vous plait réessayer.",
        "filter_checkbox_label": "Montrer:",
        "add_action_plan": "Commencer un nouveau Plan d'Action",
        "self_signup_link": "Lien pour inscription directe"
      },
      "new": {
        "address": {
          "label": "Type d'adresse",
          "home": "Accueil",
          "work": "Travail",
          "temporary": "Temporaire",
          "mailing_address": "Adresse postale",
          "submit": "Sauvegarder"
        }
      },
      "show": {
        "address": {
          "label": "Type d'adresse",
          "home": "Accueil",
          "work": "Travail",
          "temporary": "Temporaire",
          "mailing_address": "Adresse postale",
          "submit": "Sauvegarder",
          "edit": "Modifier",
          "unknown": "Inconnu"
        },
        "addresses": {
          "header": "Adresses supplémentaires"
        },
        "index": {
          "first_name": "Prénom",
          "last_name": "Nom de famille",
          "female": "Femelle",
          "male": "Mâle",
          "other": "Autre",
          "gender": "Sexe",
          "birthday": "Date de naissance",
          "medical_record_number": "Numéro d’assurance maladie",
          "contact_information": "Informations de contact",
          "email": "E-mail",
          "manage_invitation": "Gérer l'invitation",
          "phone_number": "Numéro de téléphone",
          "address": "Adresse",
          "new": "+ Ajouter",
          "primary_physician": {
            "header": "Prestataire de soins primaires",
            "placeholder": "Entrez les détails du médecin ici"
          },
          "submit": "Sauvegarder",
          "name": "Nom",
          "unknown": "Inconnu",
          "street_address": "Adresse municipale"
        },
        "phone_number": {
          "mobile": "Cellulaire",
          "primary_residence": "Résidence principale",
          "secondary_residence": "Résidence secondaire",
          "work": "Travail",
          "edit": "Modifier",
          "unknown": "Inconnu"
        },
        "phone_numbers": {
          "header": "Numéros de téléphone supplémentaires"
        }
      },
      "patient": {
        "header": "Profil de %{name}",
        "resend_invitation": "Réenvoyer l'invitation",
        "invitation_sent": "Invitation envoyée!"
      },
      "phi_section": {
        "current_medications": "Médicaments actuels",
        "past_medications": "Médicaments antérieurs",
        "immunizations": "Immunisations",
        "allergies": "Allergies",
        "family_history": "Histoire de famille",
        "notes": "Remarques",
        "none": "Aucun détail ajouté pour le moment."
      },
      "check_in_result": {
        "header": "Résultats des Mises à jour",
        "submitted": "Soumis",
        "submittedBy": " Par %{name}",
        "none": "Aucun détail ajouté pour le moment.",
        "past_submissions": "Soumissions antérieures",
        "skipped": "Sauté",
        "submittedLate": "Soumis en retard",
        "submitted_late": "Soumis en retard",
        "last_checkin": "Dernière Mise à jour:",
        "previous_checkins": "Mises à jour précédentes:",
        "show_more_submissions": "Afficher plus de Mises à jour soumises"
      },
      "form_result": {
        "header": "Formulaires",
        "submitted_by": "Soumis le %{date} par %{name}",
        "none": "Aucun détail ajouté pour le moment."
      },
      "check_in_score": {
        "0": "Non",
        "1": "Oui",
        "min": "Min - %{val}",
        "max": "Max - %{val}",
        "placeholder": "Inscrivez votre réponse",
        "remaining": "Restant - %{val}"
      }
    },
    "place_admin": {
      "place_details": {
        "edit_place_details": "Modifier les détails du lieu",
        "cancel": "Annuler",
        "save": "Sauvegarder",
        "name": "Nom",
        "logo": "Logo",
        "email": "E-mail",
        "language": "Langue",
        "time_zone": "Fuseau horaire",
        "number": "Téléphone",
        "address": {
          "country": "Pays",
          "street_address": "Adresse municipale",
          "extended_address": "Appartement, suite, etc. (facultatif)",
          "city": "Ville",
          "province": "Province",
          "state": "État",
          "postal_code": "Code postal",
          "zip_code": "Code postal",
          "postcode": "Code postal"
        },
        "errors": {
          "cant_be_blank": "ne peut pas être vide"
        }
      }
    },
    "referrals": {
      "status_badge": {
        "draft": "Brouillon: <b> En soumission </b>",
        "submitted": "En cours: <b> Soumis </b>"
      }
    },
    "registration": {
      "index": {
        "welcome": "Bienvenue sur Careteam",
        "subtitle": "Votre plateforme tout-en-un pour les soins de santé",
        "teasers": ["Trouvez des documents et instructions de quoi faire.", "Accès 24/7 dans un seul endroit, en sécurité, sur n'importe quel appareil.", "Incluez toutes les personnes qui ont besoin de participer."],
        "already_have_account": "Vous avez déjà un compte?",
        "login": "Se connecter"
      },
      "code": {
        "enter_code": "Entrez le code",
        "subtitle": "Entrez votre code d'inscription Careteam. Si vous n'en avez pas, contactez-nous.",
        "label": "Code",
        "next": "Suivant"
      },
      "representative": {
        "role": "À qui s'adresse ce compte ?",
        "subtitle": "Vous pouvez utiliser ce compte en tant que patient ou en tant que soignant pour des plans d'action futurs.",
        "myself": "Moi-même",
        "patient_role": "Inscrivez-vous pour gérer votre Plan d'Action",
        "someone_else": "Quelqu'un d'autre",
        "caregiver_role": "Inscrivez-vous pour aider quelqu'un d'autre. Pour protéger leur vie privée, vous aurez un accès limité jusqu'à ce que %{place} accepte de collaborer sur ce Plan d'Action.",
        "next": "Suivant"
      },
      "user": {
        "create_account": "Créez votre compte",
        "first_name": "Prénom",
        "last_name": "Nom de famille",
        "email": "E-mail",
        "password": "Mot de passe",
        "password_hint": "Doit comporter 8 caractères ou plus, dont au moins une lettre minuscule, une lettre majuscule, un chiffre et un symbole.",
        "terms_of_service": "J'accepte les <a target=\"_blank\" href=\"/terms_of_service\" id=\"show-terms-of-service\"> Conditions d'utilisation</a> et la <a target=\"_blank\" href=\"/privacy_policy\" id=\"show-privacy-policy\">Politique de confidentialité</a>",
        "consent": "Je m'inscris pour utiliser ce Plan d'Action fourni par %{place}. L'équipe de %{place} sera informée de mon inscription. Les membres de leur équipe pourront consulter et contribuer à mon Plan d'Action, et communiquer avec moi et toute personne que j'ai désignée sur Careteam en tant que Membre de Contact de mon Équipe d'Assistance.",
        "next": "Suivant",
        "blank_first_name": "Ne peut pas être vide",
        "blank_last_name": "Ne peut pas être vide",
        "blank_email": "Ne peut pas être vide",
        "invalid_email": "E-mail non valide",
        "blank_password": "Ne peut pas être vide",
        "invalid_password": "Doit contenir au moins une lettre majuscule, une lettre minuscule et un chiffre ou un symbole",
        "password_hide": "Cacher",
        "password_show": "Afficher",
        "must_have": "Doit avoir au moins",
        "eight_chars": "8 caractères",
        "upper_case": "1 lettre majuscule",
        "lower_case": "1 lettre minuscule",
        "special_char": "1 chiffre ou caractère spécial (par ex. ! @ # $ % ^ & * ( )",
        "passwords_no_match": "Les mots de passe ne correspondent pas"
      },
      "patient": {
        "who": "Qui est le patient ?",
        "subtitle": "Saisissez les informations relatives à la personne pour laquelle vous agissez en tant qu'aidant.",
        "first_name": "Prénom du patient",
        "last_name": "Nom de famille du patient",
        "next": "Suivant",
        "blank_first_name": "Ne peut pas être vide",
        "blank_last_name": "Ne peut pas être vide"
      },
      "avatar": {
        "upload": "Ajouter votre photo!",
        "subtitle": "L'ajout d'une photo de vous permet d'améliorer l'expérience des autres participants au Plan d'Action.",
        "skip": "Sauter",
        "finish": "Finir",
        "form": {
          "photo": "Votre photo de profil",
          "default_alt": "Photo",
          "remove": "Supprimer",
          "upload": "Télécharger"
        },
        "optional": "Optionnel"
      },
      "header": {
        "already_have_an_account": "Vous avez déjà un compte?",
        "login": "Se connecter"
      }
    },
    "repeated_activities": {
      "occurrences": {
        "edit": {
          "delete_series": "Cette activité fait partie d'une série répétitive. Voulez-vous supprimer :",
          "change_series": "Cette activité fait partie d'une série répétitive. Voulez-vous mettre à jour :",
          "this_event": "Cette activité",
          "this_series": "Toutes les activités futures",
          "cancel": "Annuler"
        },
        "form": {
          "add_time": "Date et heure",
          "assignments": "Attribuer",
          "cancel": "Annuler",
          "due_at": "Date et l'heure",
          "submit": "Sauvegarder",
          "text": "Description",
          "title": "Titre",
          "repeat": "Répéter",
          "until": "Jusqu'au",
          "frequency": {
            "none": "None",
            "daily": "du quotidien",
            "weekly": "Hebdomadaire",
            "monthly": "Mensuel",
            "yearly": "Annuel"
          },
          "every": "Chaque",
          "interval": {
            "daily": {
              "one": "jour",
              "other": "jours"
            },
            "weekly": {
              "one": "semaine",
              "other": "semaines"
            },
            "monthly": {
              "one": "mois",
              "other": "mois"
            },
            "yearly": {
              "one": "année",
              "other": "ans"
            }
          },
          "end_repeat": "Fin de répéter",
          "end": {
            "never": "Jamais",
            "date": "À un jour spécifié",
            "count": "Après"
          },
          "choose_a_date": "Choisissez une date",
          "count": {
            "one": "fois",
            "other": "fois"
          },
          "no_resources_present": "Pas de ressources disponibles pour ajouter à cette Tâche"
        }
      }
    },
    "repeated_tasks": {
      "occurrences": {
        "edit": {
          "delete_series": "Cette tâche fait partie d'une série répétitive. Voulez-vous supprimer:",
          "change_series": "Cette tâche fait partie d'une série répétitive. Voulez-vous mettre à jour:",
          "this_event": "Cette tâche",
          "this_series": "Toutes les tâches futures",
          "cancel": "Annuler"
        }
      }
    },
    "resources": {
      "confirmation": {
        "header": "Renvois",
        "title": "Accéder a %{title} via Caredove",
        "description": "Caredove trouvera des services disponibles dans votre région. Veuillez confirmer que l'adresse ci-dessous est la bonne ou donnez une adresse différente.",
        "address_label": "Adresse",
        "placeholder": "Ajouter une adresse...",
        "confirmation_label": "Confirmation",
        "confirmation_description": "Lancez Caredove et transmettez des données de cette personne (nom, date de naissance, adresse, téléphone)",
        "address_update_hint": "Mettez à jour votre adresse personnelle dans Mon profil pour la préremplir la prochaine fois.",
        "cancel": "Annuler",
        "next": "Suivant",
        "place_user_confirmation_description": "Lancez Caredove et transmettez des données de cette personne (nom, date de naissance, adresse, téléphone)",
        "self_confirmation_description": "Lancer Caredove et transmettre mes données (nom, date de naissance, adresse, téléphone)"
      }
    },
    "tags": {
      "other": "Autre",
      "form": {
        "statuses": ["brouillon", "actif", "archivé"],
        "edit": "Modifier l’étiquette",
        "add": "Ajouter l’étiquette",
        "delete": "Supprimer",
        "name": "Nom",
        "status": "Statut",
        "cancel": "Annuler",
        "submit": "Soumettre",
        "edit_confirm": "Voulez-vous vraiment supprimer cette étiquette? Cela la supprimera définitivement de toutes les Ressources de votre Bibliothèque et des Plans d'Action. Pour masquer temporairement l'étiquette, changez son statut en Archivée.",
        "edit_status_help": "Changer le statut en Archivée masquera l’étiquette sur toutes les Ressources actives. Si le statut redevient Activée, elle réapparaîtra sur n'importe quelle Ressource à laquelle elle avait été appliquée auparavant."
      }
    },
    "tasks": {
      "show": {
        "status": {
          "heading": "Statut"
        }
      },
      "index": {
        "heading": "Toutes les activités",
        "add_task": "Ajouter",
        "add_appointment": "Ajouter rendez-vous",
        "complete": "Accomplie",
        "pending": "En attente",
        "revert": "Restituer",
        "repeated": "Se répète",
        "resources": {
          "header": "Choisir les ressources à lier",
          "link_button": "Lier les ressources",
          "back": "Retour",
          "cancel": "Annuler",
          "next": "Suivant",
          "search_placeholder": "Rechercher des ressources ...",
          "service_kinds": {
            "information": "Information",
            "other": "Autre",
            "people": "Personnes",
            "practitioner": "Professionnels de la santé",
            "service": "Services"
          },
          "no_resources_present": "Pas de ressources disponibles pour ajouter à ce Plan d'Action"
        },
        "not_available": "Pas disponible",
        "repeated_task": "Tâche récurrente",
        "repeated_appointment": "Rendez-vous récurrent",
        "check_in": {
          "hi": "Salut!",
          "thanks": "Merci %{name}",
          "response_recorded": "Votre Mise à jour a été enregistré.",
          "submit": "Soumettre",
          "next": "Suivant",
          "check_in_link": "Voir ma Mise à jour",
          "skip": "Sauter",
          "pastDueAtWarning": "Remplissez cette Mise à jour pour %{dueAt} ou vous pouvez ignorer celle-ci et répondre à la plus récente.",
          "past_due_at_warning": "Remplissez cette Mise à jour pour le %{dueAt} ou vous pouvez ignorer celle-ci et répondre à la plus récente."
        },
        "tasks": {
          "completed": {
            "heading": "Tâches terminées",
            "button": "Cacher terminées",
            "blankslate": "Vous n'avez aucune tâche complétée",
            "filtered_blankslate": "Vous n'avez aucune tâche complétée correspondant au filtre"
          },
          "incomplete": {
            "heading": "Tâches",
            "button": "Voir les tâches terminées",
            "blankslate": "Félicitations! <br /> Vous êtes à jour dans vos taches.",
            "filtered_blankslate": "Vous n'avez pas de tâches incomplètes correspondant au filtre"
          }
        },
        "agenda": {
          "heading": "Rendez-vous",
          "previous": "Semaine précédente",
          "current": "Aujourd'hui",
          "next": "Semaine suivante",
          "blankslate": "Vous n'avez rien de prévu pour cette semaine",
          "filtered_blankslate": "Vous n'avez rien de prévu pour cette semaine qui correspond au filtre actuel"
        },
        "sidebar": {
          "header": "Voir les tâches par Plan d'Action",
          "mobile_header": "%{patient}: Activités",
          "filter": "Filtrer par Plan d'Action",
          "my_activities": "Mes Activités"
        },
        "filters": {
          "heading": "Filtrer",
          "all": "Toutes",
          "accepted": "Acceptée",
          "pending": "En attente",
          "assigned": "Assignées",
          "unassigned": "Non-assignées",
          "mine": "Les miennes"
        },
        "new_task": "Nouvelle tâche",
        "new_appointment": "Nouveau rendez-vous"
      },
      "nested_appointment_form": {
        "add_appointment": "Souhaitez-vous ajouter un rendez-vous pour cette tâche?",
        "skip": "Sauter"
      },
      "form": {
        "add_time": "Date et heure",
        "assignments": "Assigner",
        "cancel": "Annuler",
        "due_at": "Date et l'heure",
        "submit": "Sauvegarder",
        "text": "Description",
        "repeat": "Répéter",
        "until": "Jusqu'à",
        "frequency": {
          "none": "None",
          "daily": "du quotidien",
          "weekly": "Hebdomadaire",
          "monthly": "Mensuel",
          "yearly": "Annuel"
        },
        "every": "Chaque",
        "interval": {
          "daily": {
            "one": "journée",
            "other": "journées"
          },
          "weekly": {
            "one": "la semaine",
            "other": "semaines"
          },
          "monthly": {
            "one": "mois",
            "other": "mois"
          },
          "yearly": {
            "one": "année",
            "other": "années"
          }
        },
        "end_repeat": "Finir la répétition",
        "end": {
          "never": "Jamais",
          "date": "Un jour",
          "count": "Après"
        },
        "choose_a_date": "Choisissez une date",
        "count": {
          "one": "temps",
          "other": "fois"
        },
        "no_resources_present": "Pas de ressources disponibles pour ajouter à cette Tâche",
        "callback_header": "Parfait, votre soumission a été reçue",
        "callback_body": ["Vous et votre équipe pouvez ", "voir les résultats", " dans votre Plan d’Action à tout moment. Maintenant, vous pouvez retourner à votre journée. Merci!"],
        "callback_button": "Compris!",
        "complete_form": "Remplir le formulaire",
        "address": {
          "street_address": "Adresse municipale",
          "extended_address": "Appartement, suite, etc. (facultatif)",
          "city": "Ville",
          "province": "Province",
          "state": "État",
          "postal_code": "Code postal",
          "zip_code": "Code postal",
          "postcode": "Code postal",
          "country": "Pays"
        }
      },
      "actions_container": {
        "edit": "Modifier",
        "revert": "Revenir",
        "mark_complete": "Marquer accomplie"
      }
    },
    "team": {
      "roles": {
        "primary_caregiver": "Contact",
        "caregiver": "Membre",
        "support": "Support"
      },
      "invite_code_modal": {
        "title": "Inviter les membres de l'équipe par A) un code secret ou B) un lien secret",
        "subtitle": "Lorsque quelqu'un utilise ce code ou ce lien pour demander à rejoindre ce Plan d'Action, vous pourrez l'approuver et décider de son niveau d'accès.",
        "get_code": "Obtenir le code d’invitation",
        "first_option": "A) Utilisez un code secret",
        "second_option": "B) Utilisez un lien secret",
        "second_option_subtitle": "Une fois que vous avez copié le lien dans votre presse-papiers, vous pouvez le partager en le collant dans un e-mail ou par messagerie.",
        "first_option_subtitle": "Envoyez-les en ligne à <span class=\"invite-code-modal__inline-link text-medium\">%{link}</span> pour utiliser ce code secret:",
        "link_copied": "Lien copié dans le presse-papiers",
        "get_link": "Copier le lien d'invitation",
        "header": "Obtenir le code d’invitation",
        "done": "Terminé"
      },
      "modal": {
        "add_members": "Ajouter des membres",
        "add_team_members": "Ajouter des membres",
        "build": "Formez votre Équipe de Support!",
        "welcome": "Bienvenue sur Careteam",
        "invite_type": "Invité par %{inviteType}",
        "support_team_invite_subtitle": "Ajoutez des membres de l'équipe ici, décidez de ce qu'ils peuvent voir et faire à l'étape suivante.",
        "health_team_explanation": "Invitez votre équipe de santé, par exemple des médecins ou d’autres professionnels de santé.",
        "support_team_explanation": "Invitez votre famille, vos amis et vos soignants professionnels à vous aider.",
        "support_team_declaration": "J'ai la permission de permettre à ce nouvel utilisateur d'accéder aux informations de ce dossier, et ils peuvent agir au nom de la personne via Careteam.",
        "health_team_declaration": "J’ai la permission de permettre à cet employé d’une autre organisation d’accéder à l’information de la personne.",
        "email": {
          "description": "Tout le monde a besoin d'une famille et d\"amis pour les soutenir. Ajoutez les membres de l’équipe ici (vous pourriez décider ce qu’ils peuvent voir et faire à l’étape suivante).",
          "email_label": "Adresse e-mail",
          "email_placeholder": "Entrer l'adresse e-mail...",
          "email_hint": "Une invitation à rejoindre ce Plan d'Action sera envoyée à cette adresse e-mail.",
          "relationship_label": "Relation avec %{name}",
          "empty_relationship": "Sélectionnez le lien...",
          "cancel": "Annuler",
          "next": "Suivant",
          "relationship_patient_label": "Relation avec vous"
        },
        "final": {
          "header_title_html": "Invitations d'équipe envoyées <span class=\"text-normal\">(%{size})</span>.",
          "description_html": "Les membres de l'équipe que vous avez invitées ci-dessous recevront immédiatement des invitations par e-mail. Vous pouvez ajouter d'autres membres de l'équipe dès maintenant ou le faire plus tard à partir de votre page <b>Équipe</b>.",
          "done": "J'ai fini",
          "invite_more": "Invitez plus",
          "primary_caregiver": "Contact",
          "caregiver": "Membre",
          "support": "Étendu",
          "unaffiliated_health_care": "Membre de l'Équipe de santé"
        },
        "review": {
          "header_title": "Vérifiez les invitations",
          "error": "Erreur lors de l'envoi d'invitations",
          "edit": "Modifier",
          "remove": "Supprimer",
          "add_another": "Ajouter un autre membre de l'équipe",
          "cancel": "Annuler",
          "invite": "Inviter",
          "primary_caregiver": "Contacts",
          "caregiver": "Membres",
          "support": "Étendu",
          "unaffiliated_health_care": "Membres de l'Équipe de santé"
        },
        "role": {
          "select": "Sélectionnez rôle",
          "header_title": "Sélectionnez le niveau d'accès pour cette personne.",
          "description": "Cela contrôle les informations qu'ils peuvent voir et les actions qu'ils peuvent prendre.",
          "back": "Retour",
          "cancel": "Annuler",
          "next": "Suivant"
        },
        "invite_by_email": "Inviter par adresse e-mail",
        "invite_by_code": "Inviter par code secret",
        "add_team_button": "Ajouter à votre équipe de %{teamType}",
        "health_team_invite_title": "Inviter un membre de l'Équipe de Santé par %{inviteType}",
        "support_team_invite_title": "Inviter un nouveau membre de l’Équipe de Support par %{inviteType}",
        "health_add_team_button": "Ajoutez à votre Équipe de Santé",
        "support_add_team_button": "Ajoutez à votre Équipe de Support"
      },
      "relationship": {
        "index": {
          "change": "Change",
          "label": "Lien qui les unit à %{name}"
        },
        "relationship_form": {
          "cancel": "Annuler",
          "label": "Lien qui les unit à %{name}"
        }
      },
      "role": {
        "index": {
          "change": "Change"
        },
        "role_form": {
          "cancel": "Annuler"
        }
      },
      "active_team": {
        "primary_caregiver": "Contacts",
        "patient": "La personne principale",
        "caregiver": "Membres",
        "support": "Étendue"
      },
      "health_team": {
        "title": "Membres de l'équipe de santé",
        "pending_health_teams": "Équipes de santé en attente",
        "invite": "Inviter l'équipe de santé",
        "author": "Auteur",
        "no_members": "Pas de Membres de l'Équipe de Santé",
        "unknown": "N/A",
        "invitation_modal": {
          "header": "Inviter équipes de santé",
          "success": "Invitations envoyées."
        },
        "edit_health_team": {
          "header": "Modifier les Membres de l'Équipe de Santé",
          "subheader": "Ajoutez ou retirez les membres du personnel de votre équipe de santé pour ce Plan d’Action",
          "selected_header": "Membres de l'Équipe de Santé de %{place}",
          "unselected_header": "%{place} personnel de l'Équipe de santé",
          "no_selected_members": "Aucun Membre de l'Équipe de Santé n'a été sélectionné.",
          "no_unselected_members": "Aucun Membre de l'Équipe de Santé n'est disponible.",
          "submit": "Fait",
          "cancel": "Annuler",
          "error": "Erreur lors des mises à jour des Membre de l'Équipe de Santé.",
          "success": "Membre de l'Équipe de Santé mis à jour."
        }
      },
      "unaffiliated_contact_team": {
        "title": "Professionnels de santé invités",
        "no_members": "Aucun membre de l'équipe de ce type pour le moment - ajouter quelqu'un:",
        "other": "Autre",
        "pending": "En attente...",
        "practitioner": "Praticien",
        "case_manager": "Gestionnaire de cas",
        "chiropractor": "Chiropraticien(ne)",
        "counselor": "Conseiller(e)",
        "dietician": "Diététicien(ne)",
        "massage_therapist": "Massothérapeute",
        "nurse": "Infirmier(e)",
        "nurse_practitioner": "Infirmier(e) praticienne",
        "occupational_therapist": "Ergothérapeute",
        "osteopath": "Ostéopathe",
        "pediatrician": "Pédiatre",
        "pharmacist": "Pharmacien(ne)",
        "physician_family_doctor": "Médecin de famille",
        "physician_specialist": "Médecin - Spécialiste",
        "physiotherapist": "Physiothérapeute",
        "psychiatrist": "Psychiatre",
        "psychologist": "Psychologue",
        "social_worker": "Travailleu-r/se social/e",
        "care_coordinator": "Coordinateur de soins"
      },
      "service": {
        "practitioner": "Professionels de la santé",
        "service": "Services",
        "people": "Personnes",
        "information": "Information",
        "other": "Autre"
      },
      "service_form": {
        "titles": {
          "service": "Nouvelle ressource",
          "phone_number": "Numéro de téléphone",
          "address": "Addresse"
        },
        "name": "Nom",
        "number": "Téléphone",
        "address": "Adresse",
        "city": "Ville",
        "region": "Région",
        "postal_code": "Code postal",
        "submit": "Ajouter"
      },
      "service_member": {
        "phone_number": "Numéro de téléphone",
        "website": "Site Internet",
        "links": "Liens",
        "unknown": "N/A"
      },
      "team": {
        "primary_caregiver": {
          "description": "Les Supporteurs Primaires ont le même accès que le Patient. Ils peuvent afficher toutes les informations de %{name}, y compris le Plan d’Action. Ils peuvent inviter d’autres personnes à rejoindre l’équipe, attribuer des Tâches et communiquer directement avec Équipes de Santé au nom de %{name}.",
          "chip": "Le plus d'accès"
        },
        "caregiver": {
          "description": "Un membre de l'Équipe de Support peut voir toutes les informations et attribuer des tâches, mais NE peut PAS communiquer avec les contacts de l'équipe de l'organisation.",
          "chip": "Moins d'accès"
        },
        "support": {
          "description": "Un membre de l'Équipe de Support Étendue ne peut voir que les tâches pour lesquelles il a été invité à aider ou encore, envoyer un message à l'Équipe de Support.",
          "chip": "Le moins d'accès"
        },
        "no_members": "Aucun membre de l'équipe de ce type pour le moment - ajoutez quelqu'un :)",
        "invite_success": "L'invitation a été envoyée avec succès"
      },
      "user_form": {
        "confirm": "Cela enverra une invitation par courrier électronique à l'utilisateur, êtes-vous sûr?",
        "email": "E-mail",
        "relationship": "Lien qui les unit à %{name}",
        "submit": "Envoyer une invitation"
      },
      "support_team_member": {
        "submit": "Gérer l'invitation",
        "confirm": "Ceci va réexpédier une invitation à l'utilisateur. Voulez-vous faire ça?",
        "phone_number": "Numéro de téléphone",
        "email": "E-mail",
        "unknown": "Pas de numéro de téléphone",
        "warning_message": "Il y a une erreur : '%{bounceMessage}' Vous pouvez modifier et renvoyer l'invitation si nécessaire",
        "pending": "en attendant",
        "email_invalid": "E-mail invalide",
        "substitute_decision_maker_abbreviation": "Mandataire spécial",
        "substitute_decision_maker": "Mandataire spécial",
        "most_responsible_provider": "Professionnel le plus responsable"
      },
      "manage_invitation": {
        "body": "Vous pouvez <b>Renvoyer</b> l'invitation ou <b>Modifier</b> l'adresse e-mail. Dans les deux cas, une nouvelle invitation sera envoyée. Vous pouvez également <b>Supprimer</b> l'invitation d'origine.",
        "close": "Fermer",
        "edit": "Modifier",
        "header": "Gérer l'invitation",
        "remove": "Supprimer",
        "resend": "Réexpédier",
        "sent": "Invitation envoyée!",
        "send_invitation": "Envoyer l'invitation",
        "place": {
          "header": "Gérer les invitations de l'équipe de santé",
          "body": "Vous pouvez <b>Réenvoyer</b> l'invitation ou <b>Supprimer</b> l'invitation d'origine."
        }
      },
      "edit_invitation": {
        "header": "Modifier l'invitation",
        "label": "Adresse e-mail",
        "hint": "Une invitation à rejoindre ce Plan d'Action sera envoyée à cette adressé e-mail.",
        "back": "Retour",
        "send_invitation": "Envoyer une invitation",
        "cancel": "Annuler"
      },
      "new_invitation": {
        "header": "Envoyer l'invitation",
        "label": "Adresse e-mail",
        "hint": "Une invitation à rejoindre ce Plan d'Action sera envoyée à cette adressé e-mail.",
        "send_invitation": "Envoyer l'invitation",
        "cancel": "Annuler"
      },
      "remove_invitation": {
        "back": "Retour",
        "remove": "Supprimer l'invitation",
        "confirmation": "Voulez-vous vraiment supprimer cette invitation?",
        "place_confirmation": "Êtes-vous sûr de vouloir supprimer cette invitation?",
        "information": "Annuler cette invitation supprimera également l’e-mail associé à cette personne."
      },
      "select": {
        "primary_caregiver": {
          "header": "Contact",
          "chip": "Le plus d'accès",
          "description": "Habituellement un proche parent ou un ami.",
          "access": "Les Contacts peuvent:",
          "list": ["Voir toutes les informations de la santé", "Ajouter et envoyer des messages aux membres de l'équipe, affecter des tâches, voir le flux d'activité et télécharger des documents", "Communiquer avec les membres de l'équipe de notre organisation au nom du patient"]
        },
        "caregiver": {
          "header": "Membre",
          "chip": "Moins d'accès",
          "description": "Habituellement un membre de la famille ou un ami.",
          "access": "Les Membres peuvent:",
          "list": ["Envoyer des messages aux membres de l'équipe, voir toutes les informations sur la santé", "Attribuer des tâches, voir les activités et télécharger des documents"]
        },
        "support": {
          "header": "Étendu",
          "chip": "Le moins d'accès",
          "description": "Habituellement un ami ou un voisin.",
          "access": "Les Membres de l'Équipe de Support Étendue peuvent",
          "list": ["Voir les tâches auxquelles ils ont été invités à aider et communiquer avec la personne et son Équipe de Support"]
        },
        "unaffiliated_health_care": {
          "header": "Professionnel de santé",
          "chip": "Accès en lecture seule",
          "description": "Par exemple, médecin, infirmier(e), conseiller(e)",
          "access": "Vous donnerez à cette personne l’accès à consulter votre Plan d’Action. Ils peuvent :",
          "list": ["Voir l'information personnelle contenue dans votre compte", "Voir la composition de votre équipe de soins et de support", "Être affecté aux tâches du Plan d’Action et mettre à jour leur statut"]
        }
      },
      "access_requests": {
        "header": "Demandes en attente pour rejoindre l'équipe",
        "approve": "Examiner la demande",
        "unknown": "Pas de numéro de téléphone",
        "approved": "Demande approuvée.",
        "declined": "Demande refusée.",
        "failure": "Quelque chose n'a pas fonctionné, s'il vous plait réessayez."
      },
      "acceptance_modal": {
        "title": "Examiner la demande d'un membre de l'équipe",
        "role_select": {
          "header_title": "Sélectionnez le niveau d'accès pour %{name}.",
          "approve": "Approuver",
          "decline": "Refuser",
          "cancel": "Annuler",
          "next": "Suivant"
        },
        "consent": {
          "header_title": "Approuver ou refuser la demande d'un membre de l'équipe",
          "description": "Contacts",
          "declaration": "J'ai la permission de permettre à ce nouvel utilisateur d'accéder aux informations de ce dossier, et ils peuvent agir au nom de la personne via Careteam.",
          "cancel": "Annuler",
          "decline": "Refuser",
          "approve": "Approuver",
          "edit": "Modifier"
        }
      }
    },
    "terms_of_service": {
      "declaration": "J'accepte les <a rel='noopener noreferrer' target='_blank' href='/terms_of_service'>%{terms_of_service}</a> et <a rel='noopener noreferrer' target='_blank' href='/privacy_policy'>%{privacy_policy}</a> de Careteam.",
      "terms_of_service": "Conditions des services",
      "privacy_policy": "Politique de confidentialité",
      "confirmation_prompt": "Confirmer afin de continuer.",
      "welcome_message": "Bienvenue à Careteam!",
      "error_occurred": "Une erreur est survenue.",
      "required": "Requis pour utiliser Careteam",
      "continue": "Continuer"
    },
    "time_zones": {
      "blank_time_zone": "Choisir un fuseau horaire",
      "America/Los_Angeles": "Heure du Pacifique (États-Unis et Canada)",
      "America/Denver": "Heure des Rocheuses (États-Unis et Canada)",
      "America/Chicago": "Heure centrale (États-Unis et Canada)",
      "America/New_York": "Heure de l'Est (États-Unis et Canada)",
      "America/Halifax": "Heure de l'Atlantique (Canada)",
      "Pacific/Midway": "Iles Midway",
      "Pacific/Pago_Pago": "Samoa américaines",
      "Pacific/Honolulu": "Hawaii",
      "America/Juneau": "Alaska",
      "America/Tijuana": "Tijuana",
      "America/Phoenix": "Arizona",
      "America/Chihuahua": "Chihuahua",
      "America/Mazatlan": "Mazatlan",
      "America/Regina": "Saskatchewan",
      "America/Mexico_City": "Mexico",
      "America/Monterrey": "Monterrey",
      "America/Guatemala": "Amérique centrale",
      "America/Indiana/Indianapolis": "Indiana (Est)",
      "America/Bogota": "Bogota",
      "America/Lima": "Lima",
      "America/Caracas": "Caracas",
      "America/La_Paz": "La Paz",
      "America/Santiago": "Santiago",
      "America/St_Johns": "Terre-Neuve",
      "America/Sao_Paulo": "Brasilia",
      "America/Argentina/Buenos_Aires": "Buenos Aires",
      "America/Montevideo": "Montevideo",
      "America/Guyana": "Georgetown",
      "America/Godthab": "Groenland",
      "Atlantic/South_Georgia": "Mi-atlantique",
      "Atlantic/Azores": "Açores",
      "Atlantic/Cape_Verde": "Cap Vert",
      "Europe/Dublin": "Dublin",
      "Europe/Lisbon": "Lisbonne",
      "Europe/London": "Londres",
      "Africa/Casablanca": "Casablanca",
      "Africa/Monrovia": "Monrovia",
      "Etc/UTC": "Temps universel coordonné (UTC)",
      "Europe/Belgrade": "Belgrade",
      "Europe/Bratislava": "Bratislava",
      "Europe/Budapest": "Budapest",
      "Europe/Ljubljana": "Ljubljana",
      "Europe/Prague": "Prague",
      "Europe/Sarajevo": "Sarajevo",
      "Europe/Skopje": "Skopje",
      "Europe/Warsaw": "Varsovie",
      "Europe/Zagreb": "Zagreb",
      "Europe/Brussels": "Bruxelles",
      "Europe/Copenhagen": "Copenhague",
      "Europe/Madrid": "Madrid",
      "Europe/Paris": "Paris",
      "Europe/Amsterdam": "Amsterdam",
      "Europe/Berlin": "Berlin",
      "Europe/Zurich": "Zurich",
      "Europe/Rome": "Rome",
      "Europe/Stockholm": "Stockholm",
      "Europe/Vienna": "Vienne",
      "Africa/Algiers": "Afrique centrale occidentale",
      "Europe/Bucharest": "Bucarest",
      "Africa/Cairo": "Caire",
      "Europe/Helsinki": "Helsinki",
      "Europe/Kiev": "Kiev",
      "Europe/Riga": "Riga",
      "Europe/Sofia": "Sofia",
      "Europe/Tallinn": "Tallinn",
      "Europe/Vilnius": "Vilnius",
      "Europe/Athens": "Athènes",
      "Europe/Istanbul": "Istanbul",
      "Europe/Minsk": "Minsk",
      "Asia/Jerusalem": "Jérusalem",
      "Africa/Harare": "Harare",
      "Africa/Johannesburg": "Pretoria",
      "Europe/Kaliningrad": "Kaliningrad",
      "Europe/Moscow": "Moscou",
      "Europe/Volgograd": "Volgograd",
      "Europe/Samara": "Samara",
      "Asia/Kuwait": "Kuwait",
      "Asia/Riyadh": "Riyad",
      "Africa/Nairobi": "Nairobi",
      "Asia/Baghdad": "Bagdad",
      "Asia/Tehran": "Téhéran",
      "Asia/Muscat": "Abu Dhabi",
      "Asia/Baku": "Bakou",
      "Asia/Tbilisi": "Tbilissi",
      "Asia/Yerevan": "Erevan",
      "Asia/Kabul": "Kaboul",
      "Asia/Yekaterinburg": "Ekaterinbourg",
      "Asia/Karachi": "Karachi",
      "Asia/Tashkent": "Tachkent",
      "Asia/Kolkata": "New Delhi",
      "Asia/Kathmandu": "Katmandou",
      "Asia/Dhaka": "Dhaka",
      "Asia/Colombo": "Sri Jayawardenepura",
      "Asia/Almaty": "Almaty",
      "Asia/Novosibirsk": "Novosibirsk",
      "Asia/Rangoon": "Rangoon",
      "Asia/Bangkok": "Bangkok",
      "Asia/Jakarta": "Jakarta",
      "Asia/Krasnoyarsk": "Krasnoyarsk",
      "Asia/Shanghai": "Pékin",
      "Asia/Chongqing": "Chongqing",
      "Asia/Hong_Kong": "Hong Kong",
      "Asia/Urumqi": "Urumqi",
      "Asia/Kuala_Lumpur": "Kuala Lumpur",
      "Asia/Singapore": "Singapour",
      "Asia/Taipei": "Taipei",
      "Australia/Perth": "Perth",
      "Asia/Irkutsk": "Irkoutsk",
      "Asia/Ulaanbaatar": "Ulaanbaatar",
      "Asia/Seoul": "Séoul",
      "Asia/Tokyo": "Tokyo",
      "Asia/Yakutsk": "Yakutsk",
      "Australia/Darwin": "Darwin",
      "Australia/Adelaide": "Adélaïde",
      "Australia/Melbourne": "Melbourne",
      "Australia/Sydney": "Sydney",
      "Australia/Brisbane": "Brisbane",
      "Australia/Hobart": "Hobart",
      "Asia/Vladivostok": "Vladivostok",
      "Pacific/Guam": "Guam",
      "Pacific/Port_Moresby": "Port Moresby",
      "Asia/Magadan": "Magadan",
      "Asia/Srednekolymsk": "Srednekolymsk",
      "Pacific/Guadalcanal": "Les îles Salomon",
      "Pacific/Noumea": "New Caledonia",
      "Pacific/Fiji": "Fidji",
      "Asia/Kamchatka": "Kamchatka",
      "Pacific/Majuro": "Îles Marshall",
      "Pacific/Auckland": "Auckland",
      "Pacific/Tongatapu": "Nuku'alofa",
      "Pacific/Fakaofo": "Ile Tokelau",
      "Pacific/Chatham": "Iles Chatham",
      "Pacific/Apia": "Samoa"
    },
    "users": {
      "notification_form": {
        "header": "Notifications",
        "info": "La vie est occupée, et pour vous faciliter la vie, par défaut, vous serez averti à chaque fois que quelque chose est posté sur votre compte Careteam. Vous pouvez ajuster vos paramètres ci-dessous.",
        "preference": "Je préfère recevoir les notifications par:",
        "sms_label": "Message texte",
        "email_label": "E-mail",
        "save": "Sauvegarder",
        "confirm": "Juste pour confirmer, vous avez choisi de NE PAS être averti par e-mail ou SMS. Cela vous obligera à vous connecter régulièrement et vérifier si vous ne manquez pas quelque chose d'important! Êtes-vous sûr de procéder ainsi?",
        "success": "Les modifications ont été enregistrées avec succès."
      },
      "schedule_form": {
        "morning": "Matin",
        "afternoon": "Après midi",
        "evening": "Soir",
        "sunday": "Dimanche",
        "monday": "Lundi",
        "tuesday": "Mardi",
        "wednesday": "Mercredi",
        "thursday": "Jeudi",
        "friday": "Vendredi",
        "saturday": "Samedi",
        "address": "Votre emplacement",
        "save": "Sauvegarder",
        "success": "Les modifications ont été enregistrées avec succès.",
        "morning_hours": "8h00 - 12h00",
        "afternoon_hours": "12h00 - 17h00",
        "evening_hours": "17h00 - 21h00",
        "schedule": "Calendrier de disponibilité"
      },
      "profiles_section": {
        "availability": "Disponibilité",
        "volunteer_availability": "Disponibilité des bénévoles",
        "notifications": "Notifications",
        "notification_preferences": "Préférences de notification"
      }
    },
    "welcome_banner": {
      "activity_feed_banner": {
        "header": "Voir l'activité de votre équipe",
        "details": "Vous pouvez partager des mises à jour importantes avec votre équipe, envoyer des messages encourageants et obtenir un aperçu de ce qui se passe ici dans votre flux d'activités.",
        "button": "Compris!"
      },
      "care_plan_banner": {
        "self_signup_header": "Bienvenu à votre Plan d'Action!",
        "basic_header": "%{placeName} a commencé à mettre en place un Plan d'Action pour vous et votre équipe!",
        "self_signup_details": "Votre Plan d'Action est un endroit pour vous aider, vous et votre équipe, à planifier et à suivre votre état de santé. Pour voir les tâches liées à ce Plan d'Action, consultez votre",
        "basic_details": "Votre Plan de Soins est un endroit pour vous aider, vous et votre équipe, à planifier et à suivre votre état de santé. Pour voir les tâches liées à ce Plan de Soins, consultez votre",
        "link_text": "Page des tâches",
        "button": "Compris!"
      },
      "patient_banner": {
        "header": "Suivez votre historique de santé",
        "details": "C'est un bon endroit pour ajouter des informations dont vous voulez vous souvenir ou pour en informer votre équipe de support. De cette façon, tout le monde peut être sur la même page!",
        "button": "Compris!"
      },
      "tasks_banner": {
        "header": "Vos tâches",
        "self_signup_details": "Les prochains rendez-vous et les tâches incomplètes apparaîtront sur cette page. Vous pouvez affecter d'autres membres de votre Équipe de Support aux tâches pour lesquelles vous avez besoin d'aide.",
        "basic_details": "Quelles tâches ou rendez-vous avez-vous à venir à l’avenir? Ajoutez-les ici - votre Équipe de Support verra vos tâches aussi, et peut vous aider à les faire (l’équipe de santé ne verra que ceux qu’eux ils ont créés).",
        "button": "Compris!"
      },
      "my_tasks_banner": {
        "header": "Vos tâches",
        "basic_details": "Voici votre liste de tâches personnelles: les tâches que vous avez assignées pour vous-même ou les éléments que d'autres vous ont demandé d'aider.",
        "button": "Compris!"
      },
      "health_team_banner": {
        "button": "Fermer",
        "details": "Vous faites maintenant partie de l'équipe de santé de %{patient_name}. Vous n'avez accès qu'à ce Plan d'Action à partir du Plan d'Action de %{originating_place}. Vous pouvez consulter ce Plan d'Action et créer de nouveaux Plans d'Action.",
        "header": "Vous avez été ajouté avec succès au \"%{care_plan_title}\" de %{patient_name}!"
      },
      "uhcp_action_plan_index_banner": {
        "invited": "Ces personnes vous ont invité à rejoindre leur équipe de soins. Vous pouvez consulter et modifier le plan.",
        "email": "Pour un accès complet et pour inscrire votre équipe, veuillez envoyer un e-mail à support@getcareteam.com"
      },
      "uhcp_action_plan_show_banner": {
        "hello": "Salut %{userName}!",
        "action_plan": "Vous avez été ajouté au Plan d'Action de %{patientName}"
      },
      "uacg_care_plan_banner": {
        "plan_of_status": "Vous pouvez consulter le plan d'état des articles ici.",
        "contact_team": "Contactez votre équipe pour un accès complet.",
        "banner_header": "Commencez à explorer le Plan d'Action de %{patient_first_name}.",
        "banner_subheader_1": "Bienvenue à Careteam! Dès que l'équipe de %{place} commencera à collaborer à ce Plan d'Action, vous aurez accès à encore plus d'options.",
        "banner_subheader_2": "dans ce Plan d'Action, vous aurez accès à encore plus.",
        "banner_subheader": "Bienvenue à Careteam! Dès que l'équipe de %{place} commencera à collaborer à ce Plan d'Action, vous aurez accès à encore plus d'options."
      }
    }
  },
  "ko": {
    "activities": {
      "assignments": {
        "assignment": {
          "assign_someone_else": "다른 사람 할당",
          "what_would_you_do": "무엇을하고 싶으십니까?",
          "let_anyone_claim": "누구나 주장하게하십시오.",
          "claim": "나는 그것을 할 거 야!",
          "message": "문자 보내"
        },
        "claim_task": {
          "claim": "나는 그것을 할 거 야!",
          "claim_task": "이 작업을 주장함으로써 귀하는이 작업이 완료되었는지 확인하는 책임을지는 데 동의하게됩니다."
        },
        "index": {
          "no_one_assigned": "이 작업은 아직 지정되지 않았습니다."
        },
        "my_assignment": {
          "me": "나를",
          "will_you_accept": "이 작업을 수락 하시겠습니까?",
          "accept": "예, 할게요!",
          "decline": "죄송합니다.",
          "something_changed": "뭔가 변한거야?",
          "claim": "나는 그것을 할 거 야!"
        }
      }
    },
    "activity_feed": {
      "comments": {
        "comment_header": {
          "comment": {
            "one": "1 개의 코멘트",
            "other": "%{count} 댓글"
          },
          "hide": "숨는 장소"
        },
        "form": {
          "text": "빠른 설명 추가",
          "submit": "우편"
        }
      },
      "activity_header": {
        "appointment": "약속을 만들었습니다.",
        "medicaldocument": "문서를 업로드했습니다.",
        "prescription": "처방전 추가",
        "task": "할 일 추가",
        "status": "업데이트를 게시 함"
      },
      "appointment": {
        "when": "언제: ",
        "with": "와: ",
        "where": "어디에: "
      },
      "assignments": {
        "assigned_to": "할당: ",
        "open": "열다"
      },
      "index": {
        "welcome": "모두에게 내가 가입했음을 알리는 간단한 메시지를 게시하십시오!",
        "empty": "결과 없음"
      },
      "prescription": {
        "status": "지위: ",
        "filled": "끝난!",
        "to_be_filled": "채워질"
      }
    },
    "appointments": {
      "form": {
        "text": "기술",
        "practitioner": "와",
        "assignments": "양수인",
        "due_at": "시각",
        "cancel": "취소",
        "submit": "구하다"
      }
    },
    "base": {
      "print_button": {
        "print": "인쇄"
      },
      "error_occurred": "오류가 발생했습니다.",
      "invite_code": {
        "link": "링크"
      }
    },
    "care_plan_creators": {
      "careteam": {
        "patient_form": {
          "header": "환자를 초대하십시오.",
          "email": "이메일",
          "skip": "버킷",
          "save": "구하다",
          "edit": "편집하다",
          "add": "+ 추가"
        },
        "primary_caregiver_form": {
          "header": "지원 팀으로 사람들 초대 - 문의",
          "email": "이메일",
          "skip": "버킷",
          "save": "구하다",
          "edit": "편집하다",
          "add": "+ 추가",
          "permission": "이 환자가 환자의 의료 정보에 액세스 할 수 있도록 허용하고 환자를 대신하여 Careteam을 통해 조치를 취할 수 있도록 허용합니다."
        },
        "support_input": {
          "email": "이메일",
          "remove": "지우다"
        },
        "supports_form": {
          "header": "연장 지원 팀으로 사람들 초대 - 문의",
          "email": "이메일",
          "skip": "버킷",
          "save": "구하다",
          "edit": "편집하다",
          "add": "+ 추가"
        }
      },
      "tasks": {
        "index": {
          "none": "할 일 선택",
          "prescription": "처방",
          "medical": "약속",
          "generic_health": "다른"
        }
      }
    },
    "care_plans": {
      "goal_section": {
        "index": {
          "header": "환자 목표",
          "add": "더하다",
          "cancel": "취소",
          "show_all": "모든 목표 표시",
          "hide_completed": "완료된 목표 숨기기",
          "empty": "아직 치료 목표를 세우지 않았습니다. 달성 할 수있는 일에 대해 생각해보십시오. 팀원의 도움을 받아 여기에서 공유하고 싶습니다!",
          "empty_hca": "환자는 아직 목표를 세우지 않았습니다."
        },
        "goal": {
          "achieved": "달성 된 %{datetime}",
          "complete": "완전한",
          "incomplete": "불완전한"
        },
        "goal_form": {
          "title": "표제",
          "description": "기술",
          "photo": "사진",
          "submit": "목표 추가"
        }
      },
      "generic_order_section": {
        "index": {
          "header": "다른",
          "add": "더하다",
          "cancel": "취소",
          "empty": "이 케어 플랜에는 아무 것도 없습니다."
        },
        "generic_order": {
          "view": "%{text} 보기",
          "complete": "완전한",
          "incomplete": "불완전한"
        },
        "generic_order_form": {
          "text": "기술",
          "submit": "더하다"
        }
      },
      "medical_order_section": {
        "index": {
          "header": "설비",
          "add": "더하다",
          "cancel": "취소",
          "empty": "이 케어 플랜에는 아무 것도 없습니다."
        },
        "medical_order": {
          "view": "%{text} 보기",
          "booked": "예약 됨",
          "not_booked": "예약하기"
        },
        "medical_order_form": {
          "practitioner": "와",
          "text": "기술",
          "completed_at": "환자는 약속을해야합니다.",
          "due_at": "일정",
          "address": "주소",
          "city": "시티",
          "region": "부위",
          "postal_code": "우편 번호",
          "country_code": "국가 코드",
          "submit": "더하다"
        }
      },
      "prescription_section": {
        "index": {
          "header": "처방전",
          "add": "더하다",
          "cancel": "취소",
          "empty": "이 치료 계획에는 처방전이 없습니다."
        },
        "prescription": {
          "view": "%{text} 보기",
          "filled": "끝난!",
          "not_filled": "채워질"
        },
        "prescription_form": {
          "medication": "약물 치료",
          "completed_at": "환자가 채울 필요가있다.",
          "submit": "더하다"
        }
      }
    },
    "country_codes": {
      "AF": "아프가니스탄",
      "AX": "올란드 제도",
      "AL": "알바니아",
      "DZ": "알제리",
      "AS": "아메리칸 사모아",
      "AD": "안도라",
      "AO": "앙골라",
      "AI": "앵 귈라",
      "AQ": "남극 대륙",
      "AG": "앤티가 바부 다",
      "AR": "아르헨티나",
      "AM": "아르메니아",
      "AW": "아루바",
      "AU": "호주",
      "AT": "오스트리아",
      "AZ": "아제르바이잔",
      "BS": "바하마",
      "BH": "바레인",
      "BD": "방글라데시",
      "BB": "바베이도스",
      "BY": "벨라루스",
      "BE": "벨기에",
      "BZ": "벨리즈",
      "BJ": "베냉",
      "BM": "버뮤다",
      "BT": "부탄",
      "BO": "볼리비아, 다국적 국가",
      "BQ": "보네르, 신트 유스 타티 우스, 사바",
      "BA": "보스니아 헤르체고비나",
      "BW": "보츠와나",
      "BV": "부베 섬",
      "BR": "브라질",
      "IO": "영국령 인도양 지역",
      "BN": "브루나이 다루 살람",
      "BG": "불가리아",
      "BF": "부키 나 파소",
      "BI": "부룬디",
      "KH": "캄보디아",
      "CM": "카메룬",
      "CA": "캐나다",
      "CV": "카보 베르데",
      "KY": "케이맨 군도",
      "CF": "중앙 아프리카 공화국",
      "TD": "차드",
      "CL": "칠레",
      "CN": "중국",
      "CX": "크리스마스 섬",
      "CC": "코코스 군도",
      "CO": "콜롬비아",
      "KM": "코모로",
      "CG": "콩고",
      "CD": "콩고 민주 공화국",
      "CK": "쿡 제도",
      "CR": "코스타리카",
      "CI": "코트 디부 아르",
      "HR": "크로아티아",
      "CU": "쿠바",
      "CW": "쿠라 사오",
      "CY": "키프로스",
      "CZ": "체코 공화국",
      "DK": "덴마크",
      "DJ": "지부티",
      "DM": "도미니카",
      "DO": "도미니카 공화국",
      "EC": "에콰도르",
      "EG": "이집트",
      "SV": "엘살바도르",
      "GQ": "적도 기니",
      "ER": "에리트레아",
      "EE": "에스토니아",
      "ET": "에티오피아",
      "FK": "포클랜드 제도 (말 비나 스)",
      "FO": "페로 제도",
      "FJ": "피지",
      "FI": "핀란드",
      "FR": "프랑스",
      "GF": "프랑스 령 기아나",
      "PF": "프랑스 령 폴리네시아의",
      "TF": "프랑스 남부 지역",
      "GA": "가봉",
      "GM": "감비아",
      "GE": "그루지야",
      "DE": "독일",
      "GH": "가나",
      "GI": "지브롤터",
      "GR": "그리스",
      "GL": "그린란드",
      "GD": "그레나다",
      "GP": "과들루프",
      "GU": "괌",
      "GT": "과테말라",
      "GG": "건지 섬",
      "GN": "기니",
      "GW": "기니 비사우",
      "GY": "가이아나",
      "HT": "아이티",
      "HM": "허드 섬 및 맥도널드 제도",
      "VA": "성좌 (바티칸 시국)",
      "HN": "온두라스",
      "HK": "홍콩",
      "HU": "헝가리",
      "IS": "아이슬란드",
      "IN": "인도",
      "ID": "인도네시아 공화국",
      "IR": "이란, 이슬람 공화국",
      "IQ": "이라크",
      "IE": "아일랜드",
      "IM": "아일 오브 맨",
      "IL": "이스라엘",
      "IT": "이탈리아",
      "JM": "자메이카",
      "JP": "일본",
      "JE": "저지",
      "JO": "요르단",
      "KZ": "카자흐스탄",
      "KE": "케냐",
      "KI": "키리바시",
      "KP": "한국, 조선 민주주의 인민 공화국",
      "KR": "대한민국",
      "KW": "쿠웨이트",
      "KG": "키르기스스탄",
      "LA": "라오스 인민 민주주의 공화국",
      "LV": "라트비아",
      "LB": "레바논",
      "LS": "레소토",
      "LR": "라이베리아",
      "LY": "리비아",
      "LI": "리히텐슈타인",
      "LT": "리투아니아",
      "LU": "룩셈부르크",
      "MO": "마카오",
      "MK": "마케도니아 공화국",
      "MG": "마다가스카르",
      "MW": "말라위",
      "MY": "말레이시아",
      "MV": "몰디브",
      "ML": "말리",
      "MT": "몰타",
      "MH": "마샬 군도",
      "MQ": "마르티니크",
      "MR": "모리타니",
      "MU": "모리셔스",
      "YT": "마 요트",
      "MX": "멕시코",
      "FM": "미크로네시아,",
      "MD": "몰도바 공화국",
      "MC": "모나코",
      "MN": "몽골리아",
      "ME": "몬테네그로",
      "MS": "몬세 라트",
      "MA": "모로코",
      "MZ": "모잠비크",
      "MM": "미얀마",
      "NA": "나미비아",
      "NR": "나우루",
      "NP": "네팔",
      "NL": "네덜란드",
      "NC": "뉴 칼레도니아",
      "NZ": "뉴질랜드",
      "NI": "니카라과",
      "NE": "니제르",
      "NG": "나이지리아",
      "NU": "니우에",
      "NF": "노퍽 섬",
      "MP": "북 마리아나 군도",
      "OM": "오만",
      "PK": "파키스탄",
      "PW": "팔라우",
      "PS": "팔레스타인,",
      "PA": "파나마",
      "PG": "파푸아 뉴기니",
      "PY": "파라과이",
      "PE": "페루",
      "PH": "필리핀 제도",
      "PN": "핏 케언 제도",
      "PL": "폴란드",
      "PT": "포르투갈",
      "PR": "푸에르토 리코",
      "QA": "카타르",
      "RE": "재결합",
      "RO": "루마니아",
      "RU": "러시아 연방",
      "RW": "르완다",
      "BL": "생 바르 텔레 미",
      "SH": "세인트 헬레나, 승천 및 트리스탄 다 쿤하",
      "KN": "세인트 키츠 네비스",
      "LC": "세인트 루시아",
      "MF": "세인트 마틴 (프랑스어 부분)",
      "PM": "생 피에르 미 클롱",
      "VC": "세인트 빈센트 그레나딘",
      "WS": "사모아",
      "SM": "산 마리노",
      "ST": "상투 메 프린시 페",
      "SA": "사우디 아라비아",
      "SN": "세네갈",
      "RS": "세르비아",
      "SC": "세이셸",
      "SL": "시에라 리온",
      "SG": "싱가포르",
      "SX": "신트 마틴 (네덜란드어 부분)",
      "SK": "슬로바키아",
      "SI": "슬로베니아",
      "SB": "솔로몬 제도",
      "SO": "소말리아",
      "ZA": "남아프리카",
      "GS": "사우스 조지아 및 사우스 샌드위치 제도",
      "SS": "남 수단",
      "ES": "스페인",
      "LK": "스리랑카",
      "SD": "수단",
      "SR": "수리남",
      "SJ": "스발 바르와 얀 메이 엔",
      "SZ": "스와질란드",
      "SE": "스웨덴",
      "CH": "스위스",
      "SY": "시리아",
      "TW": "대만",
      "TJ": "타지키스탄",
      "TZ": "탄자니아, 유엔",
      "TH": "태국",
      "TL": "동 티모르",
      "TG": "가다",
      "TK": "토켈 라우",
      "TO": "통가",
      "TT": "트리니다드 토바고",
      "TN": "튀니지",
      "TR": "터키",
      "TM": "투르크 메니스탄",
      "TC": "터크 스 케이 커스 제도",
      "TV": "투발루",
      "UG": "우간다",
      "UA": "우크라이나",
      "AE": "아랍 에미리트",
      "GB": "영국",
      "UM": "미국령 군소 제도",
      "US": "미국",
      "UY": "우루과이",
      "UZ": "우즈베키스탄",
      "VU": "바누아투",
      "VE": "베네수엘라, 볼리바르 공화국",
      "VN": "베트남",
      "VG": "영국령 버진 아일랜드",
      "VI": "미국령 버진 아일랜드",
      "WF": "월리스 푸 투나",
      "EH": "서부 사하라",
      "YE": "예멘 아랍 공화국",
      "ZM": "잠비아",
      "ZW": "짐바브웨",
      "blank": "국가 선택 ...",
      "NO": "노르웨이"
    },
    "ct": {
      "components": {
        "diagnosis_template": {
          "delete": "지우다"
        },
        "file_upload": {
          "attach_document": "문서 첨부"
        },
        "validator": {
          "email": "유효한 이메일이어야합니다.",
          "text": "비워 둘 수 없다.",
          "textarea": "비워 둘 수 없다.",
          "file": "비워 둘 수 없다.",
          "select-one": "옵션을 선택하십시오.",
          "datetime": "유효한 날짜 여야합니다",
          "datetime-local": "유효한 날짜 여야합니다",
          "tel": "유효한 전화 번호 여야합니다."
        }
      }
    },
    "forms": {
      "address_form": {
        "address": "주소",
        "extended_address": "주소 2",
        "region": "도 / 주",
        "postal_code": "우편 번호 / 우편 번호",
        "city": "시티",
        "country_code": "국가",
        "edit": {
          "label": "주소 업데이트"
        }
      },
      "phone_number_form": {
        "mobile": "변하기 쉬운",
        "primary_residence": "1 차 거주지",
        "secondary_residence": "이차 거주",
        "work": "작업"
      },
      "date_time_input": {
        "am": "오전",
        "pm": "오후"
      },
      "submit": {
        "save": "구하다"
      },
      "locale_input": {
        "language": "언어"
      }
    },
    "goals": {
      "show": {
        "status": {
          "header": "지위",
          "status": {
            "complete": "목표 완료",
            "incomplete": "목표 불완전"
          },
          "action": {
            "complete": "돌아가는 것",
            "incomplete": "완전한"
          }
        }
      }
    },
    "header": {
      "notifications": {
        "notifications": {
          "header": "알림",
          "close": "닫기",
          "empty": "알림 없음"
        }
      },
      "menu": {
        "index": {
          "my_profile": "내 프로필"
        }
      }
    },
    "inbox": {
      "contacts": {
        "index": {
          "no_contacts": "연락처 없음",
          "health_teams": "건강 팀",
          "direct_messages": "직접 메시지"
        },
        "filters": {
          "search": "연락처 검색 ...",
          "createdAtDesc": "최신",
          "createdAtAsc": "가장 오래된",
          "nameAsc": "이름 A - Z",
          "nameDesc": "이름 Z - A"
        }
      },
      "conversation": {
        "disclaimer": {
          "header": "Careteam note",
          "non_health_care": "메시지 교환은 귀하가 간병 계획에 대해 가질 수있는 의문점을 명확하게 밝힐 수있는 좋은 방법입니다. 귀하의 메시지는 팀의받은 편지함으로 보내지고 첫 번째 가능한 팀원이 응답합니다. 시간이 걸릴 수 있습니다. 응급 상황 인 경우 가장 가까운 병원으로 가거나 911에 전화하십시오.",
          "health_care": "이 보안 메시징받은 편지함은 일상적인 통신을위한 것입니다. 긴급한 경우 전화로 연락하십시오."
        },
        "form": {
          "body": "여기에 메시지 쓰기",
          "submit": "보내다"
        },
        "header": {
          "view_details": "세부 정보보기"
        }
      }
    },
    "locale": {
      "en": "영어",
      "fr": "프랑스 국민",
      "es": "스페인 사람",
      "ko": "한국어",
      "sv": "스웨덴어"
    },
    "role": {
      "patient": "환자",
      "primary_caregiver": "지원 팀 연락처",
      "caregiver": "지원팀",
      "support": "연장 지원"
    },
    "relationship": {
      "other": "다른",
      "spouse": "배우자",
      "parent": "부모의",
      "child": "어린이",
      "sibling": "형제",
      "cousin": "사촌",
      "friend": "친구",
      "neighbour": "이웃 사람",
      "step_parent": "양부모 님",
      "ex_spouse": "전 배우자",
      "grandchild": "손자",
      "professional_caregiver": "전문 간병인"
    },
    "patient": {
      "edit": {
        "address": {
          "label": "주소",
          "home": "집",
          "work": "작업",
          "temporary": "일시적인",
          "mailing_address": "우편 주소",
          "submit": "구하다"
        },
        "index": {
          "first_name": "이름",
          "last_name": "성",
          "female": "여자",
          "male": "남성",
          "other": "다른",
          "gender": "성별",
          "birthday": "생년월일",
          "medical_record_number": "의료 번호",
          "contact_information": "연락처 정보",
          "email": "이메일",
          "phone_number": "전화 번호",
          "address": "주소",
          "new": "+ 추가",
          "primary_physician": {
            "header": "주치의",
            "placeholder": "여기에 의사 정보 입력"
          },
          "submit": "구하다"
        },
        "phi_section": {
          "submit": "구하다"
        }
      },
      "index": {
        "patient": {
          "medical_record_number": "의료 기록 번호",
          "unknown": "알 수 없는"
        },
        "patients": {
          "empty": "결과 없음"
        }
      },
      "new": {
        "address": {
          "label": "주소",
          "home": "집",
          "work": "작업",
          "temporary": "일시적인",
          "mailing_address": "우편 주소",
          "submit": "구하다"
        }
      },
      "show": {
        "address": {
          "label": "주소",
          "home": "집",
          "work": "작업",
          "temporary": "일시적인",
          "mailing_address": "우편 주소",
          "submit": "구하다",
          "edit": "편집하다",
          "unknown": "알 수 없는"
        },
        "addresses": {
          "header": "추가 주소"
        },
        "index": {
          "first_name": "이름",
          "last_name": "성",
          "female": "여자",
          "male": "남성",
          "other": "다른",
          "gender": "성별",
          "birthday": "생년월일",
          "medical_record_number": "의료 기록 번호",
          "contact_information": "연락처 정보",
          "email": "이메일",
          "phone_number": "전화 번호",
          "address": "주소",
          "new": "+ 추가",
          "primary_physician": {
            "header": "주치의",
            "placeholder": "여기에 의사 정보 입력"
          },
          "submit": "구하다",
          "name": "이름",
          "unknown": "알 수 없는"
        },
        "phone_number": {
          "mobile": "변하기 쉬운",
          "primary_residence": "1 차 거주지",
          "secondary_residence": "이차 거주",
          "work": "작업",
          "edit": "편집하다",
          "unknown": "알 수 없는"
        },
        "phone_numbers": {
          "header": "추가 전화 번호"
        }
      },
      "patient": {
        "header": "환자 세부 정보",
        "edit": "편집하다",
        "cancel": "취소"
      },
      "phi_section": {
        "edit": "편집하다",
        "cancel": "취소",
        "current_medications": "현재 약물",
        "past_medications": "과거의 약물",
        "immunizations": "예방 접종",
        "allergies": "알레르기",
        "family_history": "가족 역사",
        "notes": "노트",
        "none": "없음"
      }
    },
    "repeated_tasks": {
      "occurrences": {
        "edit": {
          "delete_series": "이 작업은 반복되는 시리즈의 일부입니다. 삭제 하시겠습니까?",
          "change_series": "이 작업은 반복되는 시리즈의 일부입니다. 업데이트 하시겠습니까?",
          "this_event": "이 작업",
          "this_series": "향후 모든 작업",
          "cancel": "취소"
        }
      }
    },
    "tasks": {
      "show": {
        "status": {
          "heading": "지위"
        }
      },
      "index": {
        "heading": "모든 활동",
        "add_task": "더하다",
        "add_appointment": "약속 추가",
        "tasks": {
          "completed": {
            "heading": "완료된 작업",
            "button": "완료 숨기기",
            "blankslate": "완료된 작업이 없습니다.",
            "filtered_blankslate": "필터와 일치하는 완료된 작업이 없습니다."
          },
          "incomplete": {
            "heading": "할 일 목록",
            "button": "완료 표시",
            "blankslate": "불완전한 작업이 없습니다.",
            "filtered_blankslate": "필터와 일치하는 불완전한 작업이 없습니다."
          }
        },
        "agenda": {
          "heading": "주간 활동",
          "previous": "너무 이른",
          "current": "흐름",
          "next": "다음 것",
          "blankslate": "이번 주에 예정된 일정이 없습니다.",
          "filtered_blankslate": "이번 주에 예정된 일정이 현재 필터와 일치하지 않습니다."
        },
        "sidebar": {
          "heading": "필터",
          "all": "모든",
          "assigned": "배정 된",
          "unassigned": "할당되지 않음",
          "mine": "광산"
        },
        "new_task": "새 작업",
        "new_appointment": "새로운 약속"
      },
      "nested_appointment_form": {
        "add_appointment": "이 작업에 약속을 추가 하시겠습니까?",
        "skip": "버킷"
      },
      "form": {
        "add_time": "날짜와 시간",
        "assignments": "양수인",
        "cancel": "취소",
        "due_at": "날짜와 시간",
        "submit": "구하다",
        "text": "기술",
        "repeat": "반복",
        "frequency": {
          "none": "없음",
          "daily": "매일",
          "weekly": "주간",
          "monthly": "월간 간행물",
          "yearly": "매년"
        },
        "every": "마다",
        "interval": {
          "daily": {
            "one": "일",
            "other": "일"
          },
          "weekly": {
            "one": "주",
            "other": "주"
          },
          "monthly": {
            "one": "달",
            "other": "개월"
          },
          "yearly": {
            "one": "년",
            "other": "연령"
          }
        },
        "end_repeat": "종료 반복",
        "end": {
          "never": "못",
          "date": "하루 만에",
          "count": "후"
        },
        "choose_a_date": "날짜 선택",
        "count": {
          "one": "시각",
          "other": "타임스"
        }
      },
      "integrated": {
        "heading": "%{name} 의 개요",
        "my_overview": "내 개요"
      }
    },
    "team": {
      "relationship": {
        "index": {
          "change": "변화",
          "label": "환자와의 관계"
        },
        "relationship_form": {
          "cancel": "취소",
          "label": "환자와의 관계"
        }
      },
      "role": {
        "index": {
          "change": "변화"
        },
        "role_form": {
          "cancel": "취소"
        }
      },
      "active_team": {
        "primary_caregiver": "콘택트 렌즈",
        "caregiver": "회원",
        "support": "펼친"
      },
      "health_team": {
        "title": "건강 팀 연락처"
      },
      "service": {
        "practitioner": "의료 서비스 제공자",
        "service": "서비스",
        "people": "사람들",
        "information": "정보",
        "other": "다른"
      },
      "service_form": {
        "titles": {
          "service": "서비스",
          "phone_number": "전화 번호",
          "address": "주소"
        },
        "name": "이름",
        "number": "번호",
        "address": "주소",
        "city": "시티",
        "region": "부위",
        "postal_code": "우편 번호",
        "submit": "더하다"
      },
      "service_member": {
        "phone_number": "전화 번호",
        "address": "주소",
        "unknown": "N / A"
      },
      "team": {
        "add": "더하다",
        "cancel": "취소",
        "no_members": "이 유형의 팀 구성원이 아직 없습니다 - 누군가 추가하십시오 :)"
      },
      "user_form": {
        "confirm": "이렇게하면 초대장이 사용자에게 발송됩니다.",
        "email": "이메일",
        "relationship": "환자와의 관계",
        "submit": "초대장을 보내다"
      },
      "user_member": {
        "phone_number": "전화 번호",
        "email": "이메일",
        "unknown": "N / A"
      }
    },
    "users": {
      "notification_form": {
        "header": "알림",
        "info": "인생은 바쁘고 인생을 편하게하기 위해 기본적으로 무언가가 귀하의 Careteam에 게시 될 때마다 통보됩니다. 아래에서 설정을 조정할 수 있습니다.",
        "preference": "나는 다음과 같은 방법으로 알림을받는 것을 선호합니다.",
        "sms_label": "문자 메세지",
        "email_label": "이메일",
        "save": "구하다",
        "confirm": "확인을 위해 이메일이나 SMS로 통보 받고 싶지 않다고 선택하셨습니다. 이렇게하면 정기적으로 로그인하고 확인하여 중요한 사항을 놓치지 않도록해야합니다. 확실해?"
      }
    },
    "welcome_banner": {
      "activity_feed_banner": {
        "header": "팀 활동보기",
        "details": "중요한 업데이트를 팀과 공유하고, 격려하는 메시지를 보내고, 활동 피드에서 진행된 활동에 대한 개요를 얻을 수 있습니다.",
        "button": "알았다!"
      },
      "care_plan_banner": {
        "header": "케어 플랜에 오신 것을 환영합니다!",
        "full_details": "귀하의 건강 팀이 귀하와 귀하의 팀을위한 치료 계획을 설정하기 시작했습니다. 건강 팀의 새로운 작업, 약속 알림, 문서 또는 메모를 확인하십시오. 모든 불완전한 작업은",
        "basic_details": "귀하의 케어 플랜은 귀하와 귀하의 팀이 귀하의 건강을 계획하고 추적 할 수 있도록 돕는 곳입니다. 이 케어 플랜과 관련된 일을 보려면, 귀하의",
        "link_text": "작업 페이지.",
        "button": "알았다!"
      },
      "patient_banner": {
        "header": "건강 기록 추적",
        "details": "기억하고 싶은 정보를 추가하거나 건강 및 지원 팀이 알 수 있도록 정보를 추가하는 것이 좋습니다. 이렇게하면 모두가 같은 페이지에있을 수 있습니다!",
        "button": "알았다!"
      },
      "tasks_banner": {
        "header": "당신의 할일",
        "full_details": "팀의 예정된 약속 및 불완전한 작업이이 페이지에 표시됩니다. 치료 팀의 다른 구성원을 도움이 필요한 작업에 할당 할 수 있습니다.",
        "basic_details": "앞으로 어떤 건강 관련 업무 또는 약속이 있습니까? 여기에 추가하면 팀원들도 귀하의 업무를 보게되며 귀하의 팀이 귀하를 도와 줄 수 있습니다.",
        "button": "알았다!"
      },
      "team_banner": {
        "header": "케어 팀을 구성하십시오!",
        "details": "Careteam은 복잡한 요구 사항을 관리하는 데 도움이 될 수있는 지원 네트워크를 구축 할 때 가장 효과적입니다. 팀에 다른 역할을 할당하여 각 회원의 의료 정보 액세스 권한을 제어하십시오.",
        "roles": "다양한 팀 역할에 대해 자세히 알아 보려면 여기를 클릭하십시오.",
        "details_2": "가용 자원은 지역 사회 지원 그룹, 교통 서비스, 요가 스튜디오 또는 기타 건강 진료소와 같은 다른 중요한 연락처입니다.",
        "button": "알았다!"
      }
    }
  },
  "sv": {
    "activities": {
      "assignments": {
        "assignment": {
          "assign_someone_else": "Tilldela någon annan",
          "what_would_you_do": "Vad skulle du vilja göra?",
          "let_anyone_claim": "Låt någon hävda",
          "claim": "Jag ska göra det!",
          "message": "Skicka meddelande"
        },
        "claim_task": {
          "claim": "Jag ska göra det!",
          "claim_task": "Genom att hävda denna uppgift, accepterar du att ta ansvaret för att säkerställa att denna uppgift blir klar."
        },
        "index": {
          "no_one_assigned": "Den här uppgiften har inte tilldelats än."
        },
        "my_assignment": {
          "me": "Mig",
          "will_you_accept": "Kommer du att acceptera den här uppgiften?",
          "accept": "Ja det kommer jag!",
          "decline": "Tyvärr, det kan jag inte",
          "something_changed": "Något förändrats?",
          "claim": "Jag ska göra det!"
        }
      }
    },
    "activity_feed": {
      "comments": {
        "comment_header": {
          "comment": {
            "one": "1 kommentar",
            "other": "%{count} Kommentarer"
          },
          "hide": "Dölj"
        },
        "form": {
          "text": "Lägg till en snabb kommentar",
          "submit": "Posta"
        }
      },
      "activity_header": {
        "appointment": "Skapat en tid",
        "medicaldocument": "Uppladdade ett dokument",
        "prescription": "Tillagd recept",
        "task": "Lagt till en uppgift",
        "status": "Skickade en uppdatering"
      },
      "appointment": {
        "when": "När: ",
        "with": "Med: ",
        "where": "Var: "
      },
      "assignments": {
        "assigned_to": "Tilldelats: ",
        "open": "Öppna"
      },
      "index": {
        "welcome": "Skicka ett snabbt meddelande så att alla vet att du har gått med!",
        "empty": "Inga resultat"
      },
      "prescription": {
        "status": "Status: ",
        "filled": "Gjort!",
        "to_be_filled": "Att bli fylld"
      }
    },
    "appointments": {
      "form": {
        "text": "Beskrivning",
        "practitioner": "Med",
        "assignments": "Tilldela",
        "due_at": "Tid",
        "cancel": "Annullera",
        "submit": "Spara"
      }
    },
    "base": {
      "print_button": {
        "print": "Skriva ut"
      },
      "error_occurred": "Ett fel uppstod.",
      "invite_code": {
        "link": "Länk"
      }
    },
    "care_plan_creators": {
      "careteam": {
        "patient_form": {
          "header": "Bjud in patienten",
          "email": "E-post",
          "skip": "Hoppa",
          "save": "Spara",
          "edit": "Redigera",
          "add": "+ Lägg till"
        },
        "primary_caregiver_form": {
          "header": "Bjud in folk att gå med som supportteam - Kontakt",
          "email": "E-post",
          "skip": "Hoppa",
          "save": "Spara",
          "edit": "Redigera",
          "add": "+ Lägg till",
          "permission": "Jag har tillstånd från patienten att tillåta denna person att få tillgång till patientens medicinska information och agera på patientens vägnar genom Careteam."
        },
        "support_input": {
          "email": "E-post",
          "remove": "Radera"
        },
        "supports_form": {
          "header": "Bjud in folk att gå med som ett utökat supportteam - kontakt",
          "email": "E-post",
          "skip": "Hoppa",
          "save": "Spara",
          "edit": "Redigera",
          "add": "+ Lägg till"
        }
      },
      "tasks": {
        "index": {
          "none": "Välj en uppgift",
          "prescription": "Recept",
          "medical": "Utnämning",
          "generic_health": "Andra"
        }
      }
    },
    "care_plans": {
      "goal_section": {
        "index": {
          "header": "Patientmål",
          "add": "Lägg till",
          "cancel": "Annullera",
          "show_all": "Visa alla mål",
          "hide_completed": "Dölj avslutade mål",
          "empty": "Du har inte skapat några mål för din vård än. Tänk på något som kan uppnås med hjälp av ditt team och dela det här!",
          "empty_hca": "Patienten har inte skapat några mål ännu."
        },
        "goal": {
          "achieved": "Uppnått %{datetime}",
          "complete": "Komplett",
          "incomplete": "Ofullständig"
        },
        "goal_form": {
          "title": "Titel",
          "description": "Beskrivning",
          "photo": "Foto",
          "submit": "Lägg till mål"
        }
      },
      "generic_order_section": {
        "index": {
          "header": "Andra",
          "add": "Lägg till",
          "cancel": "Annullera",
          "empty": "Ingen för denna vårdplan."
        },
        "generic_order": {
          "view": "Visa %{text}",
          "complete": "Komplett",
          "incomplete": "Ofullständig"
        },
        "generic_order_form": {
          "text": "Beskrivning",
          "submit": "Lägg till"
        }
      },
      "medical_order_section": {
        "index": {
          "header": "Utnämningar",
          "add": "Lägg till",
          "cancel": "Annullera",
          "empty": "Ingen för denna vårdplan."
        },
        "medical_order": {
          "view": "Visa %{text}",
          "booked": "Bokade",
          "not_booked": "Att bokas"
        },
        "medical_order_form": {
          "practitioner": "Med",
          "text": "Beskrivning",
          "completed_at": "Patienten behöver göra möte",
          "due_at": "Schema för",
          "address": "Adress",
          "city": "Stad",
          "region": "Område",
          "postal_code": "Postnummer",
          "country_code": "Landskod",
          "submit": "Lägg till"
        }
      },
      "prescription_section": {
        "index": {
          "header": "Recept",
          "add": "Lägg till",
          "cancel": "Annullera",
          "empty": "Det finns inga recept för denna vårdplan."
        },
        "prescription": {
          "view": "Visa %{text}",
          "filled": "Gjort!",
          "not_filled": "Att bli fylld"
        },
        "prescription_form": {
          "medication": "Medicin",
          "completed_at": "Patienten måste fylla",
          "submit": "Lägg till"
        }
      }
    },
    "country_codes": {
      "AF": "afghanistan",
      "AX": "Åland",
      "AL": "albanien",
      "DZ": "algeriet",
      "AS": "Amerikanska Samoa",
      "AD": "andorra",
      "AO": "Angola",
      "AI": "Anguilla",
      "AQ": "Antarktis",
      "AG": "Antigua och Barbuda",
      "AR": "Argentina",
      "AM": "Armenien",
      "AW": "Aruba",
      "AU": "Australien",
      "AT": "Österrike",
      "AZ": "Azerbajdzjan",
      "BS": "Bahamas",
      "BH": "Bahrain",
      "BD": "Bangladesh",
      "BB": "Barbados",
      "BY": "Vitryssland",
      "BE": "Belgien",
      "BZ": "Belize",
      "BJ": "Benin",
      "BM": "Bermuda",
      "BT": "Bhutan",
      "BO": "Bolivia, Plurinational State of",
      "BQ": "Bonaire, Sint Eustatius och Saba",
      "BA": "Bosnien och Hercegovina",
      "BW": "Botswana",
      "BV": "Bouvet Island",
      "BR": "Brasilien",
      "IO": "Brittiska territoriet i Indiska oceanen",
      "BN": "Brunei Darussalam",
      "BG": "Bulgarien",
      "BF": "Burkina Faso",
      "BI": "Burundi",
      "KH": "Kambodja",
      "CM": "Kamerun",
      "CA": "Kanada",
      "CV": "Cap Verde",
      "KY": "Caymanöarna",
      "CF": "Centralafrikanska republiken",
      "TD": "Tchad",
      "CL": "Chile",
      "CN": "Kina",
      "CX": "Julön",
      "CC": "Cocos (Keeling) Islands",
      "CO": "Colombia",
      "KM": "Komorerna",
      "CG": "Kongo",
      "CD": "Kongo, Demokratiska republiken",
      "CK": "Cooköarna",
      "CR": "Costa Rica",
      "CI": "Elfenbenskusten",
      "HR": "Kroatien",
      "CU": "Kuba",
      "CW": "Curaçao",
      "CY": "Cypern",
      "CZ": "Tjeckien",
      "DK": "Danmark",
      "DJ": "Djibouti",
      "DM": "Dominica",
      "DO": "Dominikanska republiken",
      "EC": "Ecuador",
      "EG": "Egypten",
      "SV": "El Salvador",
      "GQ": "Ekvatorialguinea",
      "ER": "Eritrea",
      "EE": "Estland",
      "ET": "Etiopien",
      "FK": "Falklandsöarna (Malvinas)",
      "FO": "Färöarna",
      "FJ": "Fiji",
      "FI": "Finland",
      "FR": "Frankrike",
      "GF": "Franska Guyana",
      "PF": "Franska polynesien",
      "TF": "Franska södra territorierna",
      "GA": "Gabon",
      "GM": "Gambia",
      "GE": "Georgien",
      "DE": "Tyskland",
      "GH": "Ghana",
      "GI": "Gibraltar",
      "GR": "Grekland",
      "GL": "Grönland",
      "GD": "Grenada",
      "GP": "Guadeloupe",
      "GU": "Guam",
      "GT": "Guatemala",
      "GG": "Guernsey",
      "GN": "Guinea",
      "GW": "GUINEA-BISSAU",
      "GY": "Guyana",
      "HT": "Haiti",
      "HM": "Heard Island och McDonald Islands",
      "VA": "Holy See (Vatikanstaten)",
      "HN": "Honduras",
      "HK": "Hong Kong",
      "HU": "Ungern",
      "IS": "Island",
      "IN": "Indien",
      "ID": "Indonesien",
      "IR": "Iran, Islamic Republic of",
      "IQ": "Irak",
      "IE": "Irland",
      "IM": "Isle of Man",
      "IL": "Israel",
      "IT": "Italien",
      "JM": "Jamaica",
      "JP": "Japan",
      "JE": "Jersey",
      "JO": "Jordanien",
      "KZ": "Kazakstan",
      "KE": "Kenya",
      "KI": "Kiribati",
      "KP": "Korea, Demokratiska Folkrepubliken",
      "KR": "Korea, Republiken av",
      "KW": "Kuwait",
      "KG": "Kirgizistan",
      "LA": "Demokratiska folkrepubliken Laos",
      "LV": "Lettland",
      "LB": "Libanon",
      "LS": "Lesotho",
      "LR": "Liberia",
      "LY": "Libyen",
      "LI": "Liechtenstein",
      "LT": "Litauen",
      "LU": "Luxemburg",
      "MO": "Macao",
      "MK": "Makedonien, Republiken",
      "MG": "Madagaskar",
      "MW": "Malawi",
      "MY": "Malaysia",
      "MV": "Maldiverna",
      "ML": "Mali",
      "MT": "Malta",
      "MH": "Marshallöarna",
      "MQ": "Martinique",
      "MR": "Mauretanien",
      "MU": "Mauritius",
      "YT": "Mayotte",
      "MX": "Mexico",
      "FM": "Mikronesien, Federated States of",
      "MD": "Moldavien, Republiken",
      "MC": "Monaco",
      "MN": "Mongoliet",
      "ME": "Montenegro",
      "MS": "Montserrat",
      "MA": "Marocko",
      "MZ": "Moçambique",
      "MM": "Myanmar",
      "NA": "Namibia",
      "NR": "Nauru",
      "NP": "Nepal",
      "NL": "Nederländerna",
      "NC": "Nya Kaledonien",
      "NZ": "Nya Zeeland",
      "NI": "Nicaragua",
      "NE": "Niger",
      "NG": "Nigeria",
      "NU": "Niue",
      "NF": "Norfolk Island",
      "MP": "Norra Marianerna",
      "OM": "Oman",
      "PK": "Pakistan",
      "PW": "Palau",
      "PS": "Palestina, State of",
      "PA": "Panama",
      "PG": "Papua Nya Guinea",
      "PY": "Paraguay",
      "PE": "Peru",
      "PH": "Filippinerna",
      "PN": "Pitcairn",
      "PL": "Polen",
      "PT": "Portugal",
      "PR": "Puerto Rico",
      "QA": "Qatar",
      "RE": "Återförening",
      "RO": "Rumänien",
      "RU": "Ryska Federationen",
      "RW": "Rwanda",
      "BL": "Saint Barthélemy",
      "SH": "Saint Helena, Ascension och Tristan da Cunha",
      "KN": "Saint Kitts och Nevis",
      "LC": "Saint Lucia",
      "MF": "Saint Martin (franska delen)",
      "PM": "Saint Pierre och Miquelon",
      "VC": "Saint Vincent och Grenadinerna",
      "WS": "Samoa",
      "SM": "San Marino",
      "ST": "Sao Tomé och Principe",
      "SA": "Saudiarabien",
      "SN": "Senegal",
      "RS": "Serbien",
      "SC": "Seychellerna",
      "SL": "Sierra Leone",
      "SG": "Singapore",
      "SX": "Sint Maarten (nederländsk del)",
      "SK": "Slovakien",
      "SI": "Slovenien",
      "SB": "Salomonöarna",
      "SO": "Somalia",
      "ZA": "Sydafrika",
      "GS": "South Georgia och South Sandwich Islands",
      "SS": "Södra Sudan",
      "ES": "Spanien",
      "LK": "Sri Lanka",
      "SD": "Sudan",
      "SR": "Surinam",
      "SJ": "Svalbard och Jan Mayen",
      "SZ": "Swaziland",
      "SE": "Sverige",
      "CH": "Schweiz",
      "SY": "Syrien",
      "TW": "Taiwan",
      "TJ": "Tadzjikistan",
      "TZ": "Tanzania, Förenta republiken",
      "TH": "Thailand",
      "TL": "Timor-Leste",
      "TG": "Togo",
      "TK": "Tokelau",
      "TO": "Tonga",
      "TT": "Trinidad och Tobago",
      "TN": "Tunisien",
      "TR": "Turkiet",
      "TM": "Turkmenistan",
      "TC": "Turks- och Caicosöarna",
      "TV": "Tuvalu",
      "UG": "Uganda",
      "UA": "Ukraina",
      "AE": "Förenade arabemiraten",
      "GB": "Storbritannien",
      "UM": "USA: s yttersta öar",
      "US": "Förenta staterna",
      "UY": "Uruguay",
      "UZ": "Uzbekistan",
      "VU": "Vanuatu",
      "VE": "Venezuela, Bolivarian Republic of",
      "VN": "Vietnam",
      "VG": "Jungfruöarna, brittiska",
      "VI": "Jungfruöarna, USA",
      "WF": "Wallis och Futuna",
      "EH": "Västra Sahara",
      "YE": "Jemen",
      "ZM": "Zambia",
      "ZW": "Zimbabwe",
      "blank": "Välj land…",
      "NO": "Norge"
    },
    "ct": {
      "components": {
        "diagnosis_template": {
          "delete": "Radera"
        },
        "file_upload": {
          "attach_document": "Fäst dokument"
        },
        "validator": {
          "email": "måste vara ett giltigt e-postmeddelande",
          "text": "kan inte vara tomt",
          "textarea": "kan inte vara tomt",
          "file": "kan inte vara tomt",
          "select-one": "Var god välj ett alternativ",
          "datetime": "måste vara ett giltigt datum",
          "datetime-local": "måste vara ett giltigt datum",
          "tel": "måste vara ett giltigt telefonnummer"
        }
      }
    },
    "forms": {
      "address_form": {
        "address": "Adress",
        "extended_address": "Adress 2",
        "region": "Region / Stat",
        "postal_code": "Postnummer / Postnummer",
        "city": "Stad",
        "country_code": "Land",
        "edit": {
          "label": "Uppdateringsadress"
        }
      },
      "phone_number_form": {
        "mobile": "Mobil",
        "primary_residence": "Primära bostad",
        "secondary_residence": "Sekundär bosättning",
        "work": "Arbete"
      },
      "date_time_input": {
        "am": "AM",
        "pm": "E.M"
      },
      "submit": {
        "save": "Spara"
      },
      "locale_input": {
        "language": "Språk"
      }
    },
    "goals": {
      "show": {
        "status": {
          "header": "Status",
          "status": {
            "complete": "Mål slutfört",
            "incomplete": "Målet är ofullständigt"
          },
          "action": {
            "complete": "Återgå",
            "incomplete": "Komplett"
          }
        }
      }
    },
    "header": {
      "notifications": {
        "notifications": {
          "header": "anmälningar",
          "close": "Stänga",
          "empty": "Inga notifikationer"
        }
      },
      "menu": {
        "index": {
          "my_profile": "Min profil"
        }
      }
    },
    "inbox": {
      "contacts": {
        "index": {
          "no_contacts": "Inga kontakter",
          "health_teams": "Hälsoklubbar",
          "direct_messages": "Direktmeddelanden"
        },
        "filters": {
          "search": "Sök efter en kontakt ...",
          "createdAtDesc": "Nyaste",
          "createdAtAsc": "äldsta",
          "nameAsc": "Namn A - Z",
          "nameDesc": "Namn Z - A"
        }
      },
      "conversation": {
        "disclaimer": {
          "header": "Careteam notering",
          "non_health_care": "Meddelanden är ett utmärkt sätt att klargöra frågor du kan ha om din vårdplan. Ditt meddelande skickas till laginsidan och kommer att besvaras av den första lediga medlemmen. Det kan ta tid. Om det är en nödsituation, vänligen gå till närmaste sjukhus eller ring 911.",
          "health_care": "Den här säkra inboxen är för rutinkommunikation. Om det är brådskande, vänligen kontakta personen via telefon."
        },
        "form": {
          "body": "Skriv ditt meddelande här",
          "submit": "Skicka"
        },
        "header": {
          "view_details": "Visa detaljer"
        }
      }
    },
    "patient": {
      "edit": {
        "address": {
          "label": "Adress",
          "home": "Hem",
          "work": "Arbete",
          "temporary": "Tillfälliga",
          "mailing_address": "Postadress",
          "submit": "Spara"
        },
        "index": {
          "first_name": "Förnamn",
          "last_name": "Efternamn",
          "female": "Kvinna",
          "male": "Manlig",
          "other": "Andra",
          "gender": "Kön",
          "birthday": "Födelsedatum",
          "medical_record_number": "Medicinskt nummer",
          "contact_information": "Kontakt information",
          "email": "E-post",
          "phone_number": "Telefonnummer",
          "address": "Adress",
          "new": "+ Lägg till",
          "primary_physician": {
            "header": "Primär läkare",
            "placeholder": "Ange läkare detaljer här"
          },
          "submit": "Spara"
        },
        "phi_section": {
          "submit": "Spara"
        }
      },
      "index": {
        "patient": {
          "medical_record_number": "Medicinsk rekordnummer",
          "unknown": "Okänd"
        },
        "patients": {
          "empty": "Inga resultat"
        }
      },
      "new": {
        "address": {
          "label": "Adress",
          "home": "Hem",
          "work": "Arbete",
          "temporary": "Tillfälliga",
          "mailing_address": "Postadress",
          "submit": "Spara"
        }
      },
      "show": {
        "address": {
          "label": "Adress",
          "home": "Hem",
          "work": "Arbete",
          "temporary": "Tillfälliga",
          "mailing_address": "Postadress",
          "submit": "Spara",
          "edit": "Redigera",
          "unknown": "Okänd"
        },
        "addresses": {
          "header": "Ytterligare adresser"
        },
        "index": {
          "first_name": "Förnamn",
          "last_name": "Efternamn",
          "female": "Kvinna",
          "male": "Manlig",
          "other": "Andra",
          "gender": "Kön",
          "birthday": "Födelsedatum",
          "medical_record_number": "Medicinsk rekordnummer",
          "contact_information": "Kontakt information",
          "email": "E-post",
          "phone_number": "Telefonnummer",
          "address": "Adress",
          "new": "+ Lägg till",
          "primary_physician": {
            "header": "Primär läkare",
            "placeholder": "Ange läkare detaljer här"
          },
          "submit": "Spara",
          "name": "namn",
          "unknown": "Okänd"
        },
        "phone_number": {
          "mobile": "Mobil",
          "primary_residence": "Primära bostad",
          "secondary_residence": "Sekundär bosättning",
          "work": "Arbete",
          "edit": "Redigera",
          "unknown": "Okänd"
        },
        "phone_numbers": {
          "header": "Ytterligare telefonnummer"
        }
      },
      "patient": {
        "header": "Patient detaljer",
        "edit": "Redigera",
        "cancel": "Annullera"
      },
      "phi_section": {
        "edit": "Redigera",
        "cancel": "Annullera",
        "current_medications": "Nuvarande mediciner",
        "past_medications": "Tidigare mediciner",
        "immunizations": "vaccinationer",
        "allergies": "allergier",
        "family_history": "Familjehistoria",
        "notes": "anteckningar",
        "none": "Ingen"
      }
    },
    "repeated_tasks": {
      "occurrences": {
        "edit": {
          "delete_series": "Denna uppgift är en del av en repeterande serie. Vill du radera:",
          "change_series": "Denna uppgift är en del av en repeterande serie. Vill du uppdatera:",
          "this_event": "Denna uppgift",
          "this_series": "Alla framtida uppgifter",
          "cancel": "Annullera"
        }
      }
    },
    "locale": {
      "en": "engelsk",
      "fr": "franska",
      "es": "spansk",
      "ko": "korean",
      "sv": "svenska"
    },
    "role": {
      "patient": "Patient",
      "primary_caregiver": "Supportteam kontakt",
      "caregiver": "Supportteam",
      "support": "Utökat stöd"
    },
    "relationship": {
      "other": "Andra",
      "spouse": "Make",
      "parent": "Förälder",
      "child": "Barn",
      "sibling": "Syskon",
      "cousin": "Kusin",
      "friend": "Vän",
      "neighbour": "Granne",
      "step_parent": "Styvförälder",
      "ex_spouse": "Ex-make",
      "grandchild": "Barnbarn",
      "professional_caregiver": "Professionell vårdgivare"
    },
    "tasks": {
      "show": {
        "status": {
          "heading": "Status"
        }
      },
      "index": {
        "heading": "All aktivitet",
        "add_task": "Lägg till",
        "add_appointment": "Lägg till möte",
        "tasks": {
          "completed": {
            "heading": "Avslutade uppgifter",
            "button": "Dölj avslutad",
            "blankslate": "Du har inga avslutade uppgifter",
            "filtered_blankslate": "Du har inga slutförda uppgifter som matchar filtret"
          },
          "incomplete": {
            "heading": "Uppgifter",
            "button": "Visa avslutat",
            "blankslate": "Du har inga ofullständiga uppgifter",
            "filtered_blankslate": "Du har inga ofullständiga uppgifter som matchar filtret"
          }
        },
        "agenda": {
          "heading": "Veckans aktivitet",
          "previous": "Tidigare",
          "current": "Nuvarande",
          "next": "Nästa",
          "blankslate": "Du har inget planerat för den här veckan",
          "filtered_blankslate": "Du har inget planerat för den här veckan som matchar det aktuella filtret"
        },
        "sidebar": {
          "heading": "Filtrera",
          "all": "Allt",
          "assigned": "Tilldelade",
          "unassigned": "Inte tilldelats",
          "mine": "Mina"
        },
        "new_task": "Ny uppgift",
        "new_appointment": "Ny möte"
      },
      "nested_appointment_form": {
        "add_appointment": "Vill du lägga till ett möte för den här uppgiften?",
        "skip": "Hoppa"
      },
      "form": {
        "add_time": "Datum och tid",
        "assignments": "Tilldela",
        "cancel": "Annullera",
        "due_at": "Datum och tid",
        "submit": "Spara",
        "text": "Beskrivning",
        "repeat": "Upprepa",
        "frequency": {
          "none": "Ingen",
          "daily": "Dagligen",
          "weekly": "Varje vecka",
          "monthly": "En gång i månaden",
          "yearly": "Årlig"
        },
        "every": "Varje",
        "interval": {
          "daily": {
            "one": "dag",
            "other": "dagar"
          },
          "weekly": {
            "one": "vecka",
            "other": "veckor"
          },
          "monthly": {
            "one": "månad",
            "other": "månader"
          },
          "yearly": {
            "one": "år",
            "other": "år"
          }
        },
        "end_repeat": "Avsluta upprepa",
        "end": {
          "never": "Aldrig",
          "date": "På en dag",
          "count": "Efter"
        },
        "choose_a_date": "Välj ett datum",
        "count": {
          "one": "tid",
          "other": "gånger"
        }
      },
      "integrated": {
        "heading": "%{name} s översikt",
        "my_overview": "Min översikt"
      }
    },
    "team": {
      "relationship": {
        "index": {
          "change": "Byta",
          "label": "Förhållande till patienten"
        },
        "relationship_form": {
          "cancel": "Annullera",
          "label": "Förhållande till patienten"
        }
      },
      "role": {
        "index": {
          "change": "Byta"
        },
        "role_form": {
          "cancel": "Annullera"
        }
      },
      "active_team": {
        "primary_caregiver": "Kontakter",
        "caregiver": "medlemmar",
        "support": "Utökad"
      },
      "health_team": {
        "title": "Hälsa lagkontakter"
      },
      "service": {
        "practitioner": "Vårdgivare",
        "service": "tjänster",
        "people": "människor",
        "information": "Information",
        "other": "Andra"
      },
      "service_form": {
        "titles": {
          "service": "Service",
          "phone_number": "Telefonnummer",
          "address": "Adress"
        },
        "name": "namn",
        "number": "siffra",
        "address": "Adress",
        "city": "Stad",
        "region": "Område",
        "postal_code": "Postnummer",
        "submit": "Lägg till"
      },
      "service_member": {
        "phone_number": "Telefonnummer",
        "address": "Adress",
        "unknown": "N / A"
      },
      "team": {
        "add": "Lägg till",
        "cancel": "Annullera",
        "no_members": "Inga lagmedlemmar av denna typ än - lägg till någon :)"
      },
      "user_form": {
        "confirm": "Det här skickar en inbjudan till användaren, är du säker?",
        "email": "E-post",
        "relationship": "Förhållande till patienten",
        "submit": "Skicka inbjudan"
      },
      "user_member": {
        "phone_number": "Telefonnummer",
        "email": "E-post",
        "unknown": "N / A"
      }
    },
    "users": {
      "notification_form": {
        "header": "anmälningar",
        "info": "Livet är upptaget, och för att göra ditt liv enklare, kommer du som regel att få besked varje gång något kommer att skickas till din Careteam. Du kan justera dina inställningar nedan.",
        "preference": "Jag föredrar att få meddelanden av:",
        "sms_label": "Textmeddelande",
        "email_label": "E-post",
        "save": "Spara",
        "confirm": "Bara för att bekräfta, du har valt att du inte vill bli underrättad via e-post eller SMS. Detta ställer ansvaret för dig att regelbundet logga in och kontrollera så att du inte saknar något viktigt! Är du säker?"
      }
    },
    "welcome_banner": {
      "activity_feed_banner": {
        "header": "Visa ditt lags aktivitet",
        "details": "Du kan dela viktiga uppdateringar med ditt team, skicka uppmuntrande meddelanden och få en översikt över vad som hänt här i ditt aktivitetsflöde.",
        "button": "Jag fattar!"
      },
      "care_plan_banner": {
        "header": "Välkommen till din vårdplan!",
        "full_details": "Ditt hälsoklubb har börjat inrätta en vårdplan för dig och ditt lag. Kolla här för nya uppgifter, avtalets påminnelser, dokument eller anteckningar från ditt hälsoklubb. Alla dina ofullständiga uppgifter finns också på din",
        "basic_details": "Din vårdplan är en plats för att hjälpa dig och din lagplan och spåra din hälsa. För att se någon till-dos relaterad till denna vårdplan, kolla in din",
        "link_text": "Uppgiftssida.",
        "button": "Jag fattar!"
      },
      "patient_banner": {
        "header": "Följ din hälsohistoria",
        "details": "Det här är ett bra ställe att lägga till information som du vill komma ihåg eller göra hälso- och supportteamet medvetna om. På så sätt kan alla vara på samma sida!",
        "button": "Jag fattar!"
      },
      "tasks_banner": {
        "header": "Din till-dos",
        "full_details": "Ditt lags kommande möten och ofullständiga uppgifter visas på den här sidan. Du kan tilldela andra medlemmar av ditt vårdteam till uppgifter som du behöver hjälp med.",
        "basic_details": "Vilka hälsorelaterade uppgifter eller möten har du kommit upp i framtiden? Lägg till dem här, ditt lag kommer också att se dina uppgifter och kan hjälpa dig att få dem gjort.",
        "button": "Jag fattar!"
      },
      "team_banner": {
        "header": "Bygg ditt vårdlag!",
        "details": "Careteam fungerar bäst när du kan bygga ett stödjande nätverk kring dig som kan hjälpa till att hantera dina komplexa behov. Kontrollera hur mycket tillgång varje medlem har till din medicinska information genom att tilldela dem olika roller på laget:",
        "roles": "Klicka här för att lära dig om de olika lagrollerna",
        "details_2": "Dina tillgängliga resurser är andra viktiga kontakter, till exempel dina samhällssupportgrupper, transporttjänster, yogastudier eller andra hälsokliniker.",
        "button": "Jag fattar!"
      }
    }
  }
};
{}
var I18n = {};

for (var key in I18nJS) {
  if (typeof I18nJS[key] == 'function') {
    I18n[key] = I18nJS[key].bind(I18nJS);
  }
}

createReactElements(I18n);
createRollbarReporter(I18nJS, options);
module.exports = I18n;